import React from "react";
import TimePicker from "react-time-picker";

const TimePickerBox = (props) => {
  const {
    label,
    parentClass = "form-group",
    inputClass = "form-control",
    required = true,
    error,
    value,
    onChange,
  } = props;

  return (
    <div className={parentClass}>
      <label>
        {label}
        {required ? <sup>*</sup> : null}
      </label>
      <TimePicker
        className={inputClass}
        format="hh:mm a"
        disableClock={true}
        clearIcon={null}
        onChange={onChange}
        value={value ? value : null}
      />
      <span className="form-field-error">{error ? error : ""}</span>
    </div>
  );
};

export default React.memo(TimePickerBox);
