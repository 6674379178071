import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import "../../Styles/style.scss";
import "../../Containers/Add-Product/calendar.scss";
import PleaseGif from "../../assets/images-2/please-wait.gif";

const AlertModal = (props) => {
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState('')
    const [singleButton, setSingleButton] = useState(true)
    const [title, setTitle] = useState('Alert')
    const [multiButtonState, setMultiButtonState] = useState({
        positive: '',
        negative: ''
    })

    useEffect(() => {
        setLoader(props.data.pleaseWaitLoader)
        setShow(props.data.open)
        setMessage(props.data.message)
        setSingleButton(props.data.singleButton)
        setTitle(props.data.title ? props.data.title : 'Alert')
        if (!props.data.singleButton) {
            setMultiButtonState({
                positive: props.data.positiveButton,
                negative: props.data.negativeButton
            })
        }
    }, [props])

    const handleModalHide = () => {
        if(!props.data?.from){
            props.closeAlertModal()
            setShow(false)
            setMessage('')
            setSingleButton(false)
        }
    }

    const handleButtonClick = (type) => {
        props.handleAlertResponse(type, props.data.id, props.data.data)
        handleModalHide()
    }

    return (
        <Modal
            show={show}
            onHide={handleModalHide}
            className="calendar-modal"
        >
            <Modal.Header className="calendar-header"><h3>{title}</h3></Modal.Header>
            <Modal.Body className="text-center">
                <p>{message}</p>
                {
                  !props.data?.from &&  singleButton ?
                        <button className="orange-btn" onClick={handleButtonClick}>okay</button>
                        :
                        <>
                            {
                                                   !props.data?.from &&


                                                   props.data.negativeButton &&
                                <button className="orange-btn mr-2"
                                        onClick={() => handleButtonClick('negative')}>{multiButtonState.negative}</button>
                            }
                            {
                                !props.data?.from &&
                                <button className="orange-btn"
                                    onClick={() => handleButtonClick('positive')}>{multiButtonState.positive}</button>
                            }
                            
                        </>
                }
                {loader &&
                <img src={PleaseGif} alt="gif" style={{width:"150px", height: "150px"}} />}

            </Modal.Body>
        </Modal>
    )

}

export default AlertModal;