import React, { useState } from "react";

import "./login.css";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { socialLogin, socialSignup } from "../../Redux/Actions/authAction";
import facebook from "../../assets/images-2/facebook.svg";

const FacebookLogin = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      loadFbLoginApi();
    }, 1000);
  }, []);

  const [userInfo, setUserInfo] = useState({});

  React.useEffect(() => {
    if (!props.isForVerification && props.isLoggedIn) {
      localStorage.setItem("sellerId", props.authData?.id);
      localStorage.setItem("sellerData", JSON.stringify(props.authData));
      localStorage.setItem("token", props.authData?.token);
      localStorage.removeItem("companyId");
      props.navigate("/dashboard");
    }

    if (!props.isForVerification && props.isacceptedtnc) {
      props.socialSignup({ ...userInfo });
    }
  }, [props.isLoggedIn, props.isacceptedtnc]);

  async function loadFbLoginApi() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FB_ID,
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v2.1", // use version 2.1
      });
      window.FB.AppEvents.logPageView();
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  function testAPI() {
    window.FB.api(
      "/me",
      "get",
      { fields: "id, first_name, last_name, email" },
      async function (response) {
        if (response) {
          if (response.error) {
            console.log("error response", response.error);
          } else if (response.email) {
            let body = {
              id: response,
              first_name: response.first_name,
              last_name: response.last_name,
              email: response.email,
              type: "facebook",
            };
            setUserInfo(body);
            localStorage.setItem("socialLogin", true);
            if (props.isForVerification) {
              props.socialVerification(body);
            } else {
              await props.socialLogin(body);
            }
          } else {
            alert(
              "We are unable to fetch your details from facebook due to privacy settings of your account. Kindly use Signup feature of Subsciety!"
            );
          }
        } else {
        }
      }
    );
  }

  function statusChangeCallback(response) {
    if (response.status === "connected") {
      testAPI();
    } else if (response.status === "not_authorized") {
      console.log("Please log into this app.");
    } else {
      console.log("Please log into this facebook.");
      // testAPI();
    }
  }

  function checkLoginState() {
    window.FB.getLoginStatus(
      function (response) {
        statusChangeCallback(response);
      }.bind(this)
    );
  }

  function handleFBLogin() {
    window.FB.login(
      () => {
        window.FB.getLoginStatus(
          function (response) {
            statusChangeCallback(response);
          }.bind(this)
        );
      },
      {
        scope: "email",
        return_scopes: true,
      }
    );
  }
  return (
    <div className="icon-btn-google">
      <div className="icon-most">
        <img src={facebook} />
        <button
          type="button"
          color="none"
          className="icon-fast"
          onClick={(event) => {
            handleFBLogin();
            return false;
          }}
        >
          Facebook
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ socialLogin, socialSignup }, dispatch);
};

export default connect(null, mapDispatchToProps)(FacebookLogin);
