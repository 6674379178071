import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const getMessagesBySellerId = (seller_id,offset) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/message/getMessagesBySellerId/${seller_id}?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getMessagesByConversationId = (message_id, sellerId, buyerId, offset) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/message/getMessagesByConversationId/${message_id}/${sellerId}/${buyerId}?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};


export const getMessageCountBySellerId = (sellerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/message/count/${sellerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_MESSAGE_COUNT",
      payload: { data: res.data },
  })
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const getOrderReturnRequestCountBySellerId = (sellerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/order/return-request/count/${sellerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "PENDING_RETURN_REQUEST_COUNT",
      payload: { data: res.data },
  })
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};



export const createMessage = (data) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/message/createMessage`,
      data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};


export const sendMultipleMessages = (data) => async (dispatch) => {
  try {
    const res = await axios({
      method: 'post',
      url: `${URL}/api/v1/seller/message/send-multiple`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: {
        data
      }
    });
    return res;
  }
  catch (error) {
    dispatch(handleError(error));
  }
}

export const searchMessages = (key, sellerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/message/search?sellerId=${sellerId}&key=${key}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_MESSAGE_COUNT",
      payload: { data: res.data },
    })
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

