import React, { Suspense } from "react";
import PrivateGuard from "./privateGuard";
import { Routes, Route } from "react-router-dom";
import Sidebar from "../Containers/Sidebar/Sidebar";
import Header from "../Components/Header/Header";
import Dashboard from "../Containers/Dashboard/Dashboard";
import Order_List from "../Containers/Order-List/Order_List";
import Order_Detail from "../Containers/Order-Detail/Order_Detail";
import Order_Track from "../Containers/Order-Track/Order_Track";

import Add_Product from "../Containers/Add-Product/Add_Product";
import Product_List from "../Containers/Product-List/Product_List";
import Change_Password from "../Containers/Change-Password/Change_Password";
import Subscription_Packages from "../Containers/Subscription-Packages/Subscription_Packages";

import Login from "../Containers/login/Login";
import SignUp from "../Containers/SignUp/signup";
import ForgotPassword from "../Containers/ForgotPassword/ForgotPassword";
import ResetPassword from "../Containers/ResetPassword/ResetPassword";
import Attribute_List from "../Containers/Attribute_List/Attribute_List";
import Add_Attribute from "../Containers/Add-Attribute/Add_Attribute";
import StripeConnect from "../Containers/StripeConnect/StripeConnectAccount";
import PaymentList from "../Containers/PaymentList/PaymentList";
import Shopify from "../Containers/Shopify/Shopify_Account";
import Woocommerce from "../Containers/WooCommerce/woocommerce";
import Messages from "../Containers/Messages/messages";

import Zoom from "../Containers/ZoomMeeting/Zoom";
import ZoomMeeting from "../Containers/ZoomMeeting/zoomMeeting";
import MeetingList from "../Containers/MeetingList/MeetingList";
import GlobalCalendar from "../Containers/GlobalCalendar/GlobalCalendar";
import Page from "../Containers/Page/page";
import DocumentList from "../Containers/Document_List/DocumentList";
import Dispute_List from "../Containers/Dispute-List/Dispute_List";
import Dispute_Detail from "../Containers/Dispute-Detail/Dispute_Detail";
import Review_List from "../Containers/Review-List/Review_List";
import Review_Detail from "../Containers/Review-Detail/Review_Detail";
import OneOnOneMeetings from "../Containers/OneOnOneMeetings/OneOnOneMeetings";
import OneOnOneMeetingsDetail from "../Containers/OneOnOneMeetings/OneOnOneMeetingDetail";
import Order_return_request from "../Containers/Order-return-request/Order_return_request";
import PersonalDetails from "../Containers/Onboarding/PersonalDetails";
import StoreDetails from "../Containers/Onboarding/StoreDetails";
import PaymentConfiguration from "../Containers/Onboarding/PaymentConfiguration";
import OnboardingSubscription from "../Containers/Onboarding/OnboardingSubscription";
import StorePreview from "../Containers/Onboarding/StorePreview";
import OnboardStep from "../Containers/Onboarding/OnboardStep";

export const WithSidebarProtected = (props) => {
  return (
    <>
      <div class="main-body">
        <Sidebar {...props} />
        <div class="main-content">
          <div class="container-fluid">
            <Header />
            <PrivateGuard {...props} />
            <div class="main-footer">
              <p>
                © {new Date().getFullYear()} Subsciety. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const WithOutSidebarProtected = (props) => {
  return (
    <>
      <div class="main-body d-block">
            <PrivateGuard {...props} />
      </div>
    </>
  );
};

class PrivateRoutes extends React.Component {
  render() {
    return (
      <div>
        <div className="PrivateArea__content">
          <div className="main_sec">
            <Suspense fallback={<p>Loading...</p>}>
              <Routes>
                <Route path="/page/:slug" element={<Page />} />
                <Route
                  path={"/"}
                  element={
                    <WithSidebarProtected
                      component={Dashboard}
                      route={"/dashboard"}
                    />
                  }
                />
                <Route
                  path={"/dashboard"}
                  element={
                    <WithSidebarProtected
                      component={Dashboard}
                      route={"/dashboard"}
                    />
                  }
                />
                <Route
                  path={"/onboard-step"}
                  element={
                    <WithOutSidebarProtected
                      component={OnboardStep}
                      route={"/onboard-step"}
                    />
                  }
                />
                <Route
                  path={"/store-preview"}
                  element={
                    <WithOutSidebarProtected
                      component={StorePreview}
                      route={"/store-preview"}
                    />
                  }
                />
                <Route
                  path={"/onboarding-subscription"}
                  element={
                    <WithOutSidebarProtected
                      component={OnboardingSubscription}
                      route={"/onboarding-subscription"}
                    />
                  }
                />
                <Route
                  path={"/payment-configuration"}
                  element={
                    <WithOutSidebarProtected
                      component={PaymentConfiguration}
                      route={"/payment-configuration"}
                    />
                  }
                />
                <Route
                  path={"/store-details"}
                  element={
                    <WithOutSidebarProtected
                      component={StoreDetails}
                      route={"/store-details"}
                    />
                  }
                />
                <Route
                  path={"/personal-details"}
                  element={
                    <WithOutSidebarProtected
                      component={PersonalDetails}
                      route={"/personal-details"}
                    />
                  }
                />
                <Route
                  path={"/login"}
                  element={
                    <WithOutSidebarProtected
                      component={Login}
                      route={"/login"}
                    />
                  }
                />
                <Route
                  path={"/signup"}
                  element={
                    <WithOutSidebarProtected
                      component={SignUp}
                      route={"/signup"}
                    />
                  }
                />

                <Route
                  path={"/messages"}
                  element={
                    <WithSidebarProtected
                      component={Messages}
                      route={"/messages"}
                    />
                  }
                />

                <Route
                  path={"/orders"}
                  element={
                    <WithSidebarProtected
                      component={Order_List}
                      route={"/orders"}
                    />
                  }
                />

                <Route
                  path={"/disputes"}
                  element={
                    <WithSidebarProtected
                      component={Dispute_List}
                      route={"/disputes"}
                    />
                  }
                />
                <Route
                  path={"/one-on-one-meeting-list"}
                  element={
                    <WithSidebarProtected
                      component={OneOnOneMeetings}
                      route={"/one-on-one-meeting-list"}
                    />
                  }
                />
                <Route
                  path={"/reviews"}
                  element={
                    <WithSidebarProtected
                      component={Review_List}
                      route={"/reviews"}
                    />
                  }
                />
                <Route
                  path={"/payments"}
                  element={
                    <WithSidebarProtected
                      component={PaymentList}
                      route={"/payments"}
                    />
                  }
                />

                <Route
                  path={"/orders/:orderId"}
                  element={
                    <WithSidebarProtected
                      component={Order_Detail}
                      route={"/orders/:orderId"}
                    />
                  }
                />

                <Route
                  path={"/dispute/:disputeId"}
                  element={
                    <WithSidebarProtected
                      component={Dispute_Detail}
                      route={"/dispute/:disputeId"}
                    />
                  }
                />
                <Route
                  path={"/review/:orderId"}
                  element={
                    <WithSidebarProtected
                      component={Review_Detail}
                      route={"/review/:orderId"}
                    />
                  }
                />
                <Route
                  path={"/reviews/product/:productId"}
                  element={
                    <WithSidebarProtected
                      component={Review_List}
                      route={"/reviews/product/:productId"}
                    />
                  }
                />
                <Route
                  path={"/orders/track/:trackId"}
                  element={
                    <WithSidebarProtected
                      component={Order_Track}
                      route={"/orders/track/:trackId"}
                    />
                  }
                />
                <Route
                  path={"/add-product"}
                  element={
                    <WithSidebarProtected
                      component={Add_Product}
                      route={"/add-product"}
                    />
                  }
                />
                <Route
                  path={"/edit-product/:slug"}
                  element={
                    <WithSidebarProtected
                      component={Add_Product}
                      route={"/edit-product/:slug"}
                    />
                  }
                />
                <Route
                  path={"/zoom"}
                  element={
                    <WithSidebarProtected component={Zoom} route={"/zoom"} />
                  }
                />
                <Route
                  path={"/zoom-meeting"}
                  element={
                    <WithSidebarProtected
                      component={ZoomMeeting}
                      route={"/zoom-meeting"}
                    />
                  }
                />
                {/* <Route
                  path={"/zoom-meeting/join"}
                  element={
                    <WithSidebarProtected
                      component={JoinZoomMeeting}
                      route={"/zoom-meeting/join"}
                    />
                  }
                /> */}
                <Route
                  path={"/meeting-list"}
                  element={
                    <WithSidebarProtected
                      component={MeetingList}
                      route={"/meeting-list"}
                    />
                  }
                />
                <Route
                  path={"/global-calendar"}
                  element={
                    <WithSidebarProtected
                      component={GlobalCalendar}
                      route={"/global-calendar"}
                    />
                  }
                />
                <Route
                  path={"/product-list"}
                  element={
                    <WithSidebarProtected
                      component={Product_List}
                      route={"/product-list"}
                    />
                  }
                />
                <Route
                  path={"/attribute-list"}
                  element={
                    <WithSidebarProtected
                      component={Attribute_List}
                      route={"/attribute-list"}
                    />
                  }
                />
                <Route
                  path={"/add-attribute"}
                  element={
                    <WithSidebarProtected
                      component={Add_Attribute}
                      route={"/add-attribute"}
                    />
                  }
                />
                <Route
                  path={"/vendor/store-sy"}
                  element={
                    <WithSidebarProtected
                      component={Shopify}
                      route={"/vendor/store-sy"}
                    />
                  }
                />
                <Route
                  path={"/vendor/woocommerce"}
                  element={
                    <WithSidebarProtected
                      component={Woocommerce}
                      route={"/vendor/woocommerce"}
                    />
                  }
                />
                <Route
                  path={"/edit-attribute/:attributeId"}
                  element={
                    <WithSidebarProtected
                      component={Add_Attribute}
                      route={"/edit-attribute/:attributeId"}
                    />
                  }
                />
                <Route
                  path={"/change-password"}
                  element={
                    <WithSidebarProtected
                      component={Change_Password}
                      route={"/change-password"}
                    />
                  }
                />
                <Route
                  path={"/stripe-connect"}
                  element={
                    <WithSidebarProtected
                      component={StripeConnect}
                      route={"/stripe-connect"}
                    />
                  }
                />
                <Route path={"/document-list"} element={<DocumentList />} />
                <Route
                  path={"/subscription-packages"}
                  element={
                    <WithSidebarProtected
                      component={Subscription_Packages}
                      route={"/subscription-packages"}
                    />
                  }
                />
                <Route
                  path={"/order-return-request"}
                  element={
                    <WithSidebarProtected
                      component={Order_return_request}
                      route={"/order-return-request"}
                    />
                  }
                />
                <Route
                  path={"/one-on-one-meetings/:meetingId"}
                  element={
                    <WithSidebarProtected
                      component={OneOnOneMeetingsDetail}
                      route={"/one-on-one-meetings/:meetingId"}
                    />
                  }
                />
                <Route
                  path={"/forgot-password"}
                  element={
                    <WithOutSidebarProtected
                      component={ForgotPassword}
                      route={"/forgot-password"}
                    />
                  }
                />
                <Route
                  path={"/reset-password"}
                  element={
                    <WithOutSidebarProtected
                      component={ResetPassword}
                      route={"/reset-password"}
                    />
                  }
                />

                {/* wildcard route */}
                <Route
                  path="*"
                  element={
                    <WithSidebarProtected
                      component={Dashboard}
                      route={"*"}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivateRoutes;
