import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getPaymentsList,
  searchPayment,
  checkSynButtonActivate,
  requestPayOuts,
} from "../../Redux/Actions/paymentAction";
import { debounce } from "lodash";

class Payment_List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      modalInfo: "",
      showLoader: false,
      paymentStatus: null,
      orderStatus: null,
      payoutButtonActive: false,
      requestPayOutsButtonDisable: false,
    };
    this.sellerData = JSON.parse(localStorage.getItem("sellerData"))
  }

  componentDidMount() {
    this.sellerId = localStorage.getItem("sellerId");
    this.fetchMoreData(true);
    this.checkPayoutsPending();
  }

  checkPayoutsPending = async () => {
    const response = await this.props.checkSynButtonActivate(this.sellerId);
    if (response && response.data && response.data.data > 0) {
      this.setState({
        payoutButtonActive: true,
      });
    } else {
      this.setState({
        payoutButtonActive: false,
      });
    }
  };

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (
          (this.state.searchKeyword && this.state.searchKeyword.length > 0) ||
          this.state.paymentStatus ||
          this.state.orderStatus
        ) {
          result = await this.props.searchPayment(
            this.state.offset,
            this.state.searchKeyword.trim(),
            this.sellerId,
            this.state.paymentStatus,
            this.state.orderStatus
          );
        } else {
          result = await this.props.getPaymentsList(
            this.state.offset,
            this.sellerId
          );
        }

        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    let value = event.target.value;
    if (field === "maxLimit") {
      await this.setState({
        [field]: value === "all" ? value : +value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit:10
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  openModal = (payment) => {
    this.setState({
      modalInfo: "",
    });
    if (Object.keys(payment).length > 0) {
      let payoutResponse = payment.payout_response
        ? JSON.parse(payment.payout_response)
        : null;
      if (payoutResponse) {
        let payoutDate = new Date(payoutResponse.created * 1000)
          .toISOString()
          .substring(0, 10);
        let { id, amount } = payoutResponse;
        this.setState({
          modalInfo: (
            <tr>
              <td>
                <h5>Payout Date</h5>
                <p>{payoutDate}</p>
              </td>
              <td>
                <h5>payment transaction:</h5>
                <p>{id}</p>
              </td>
              <td>
                <h5>Receivable amount:</h5>
                {parseFloat((amount / 100).toFixed(2))}
              </td>
              <td>
                <h5>Status:</h5>
                <p>{payment?.payout_status.toUpperCase()}</p>
              </td>
            </tr>
          ),
        });
      } else {
        this.setState({
          modalInfo: <tr>Payout not initiated!</tr>,
        });
      }
    } else {
      this.setState({
        modalInfo: <tr>Something went wrong!</tr>,
      });
    }
  };

  handleRequestPayOuts = async () => {
    this.setState({
      requestPayOutsButtonDisable: true,
    });
    const res = await this.props.requestPayOuts(this.sellerId);
    if (!res.isError) {
      this.checkPayoutsPending();
      this.setState({
        [`successMessage`]: true,
      });
      setTimeout(() => {
        this.setState({
          [`successMessage`]: false,
        });
      }, 5 * 1000);
    }
  };
  render() {
    const payments = this.state.items.map((i) => {
      const date = i.createTS
        ? new Date(i.createTS).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td>
            <Link className="orange" to={`/orders/${i.orderId}`}>
              #{i.orderId ? i.orderId : `-`}
            </Link>
          </td>
          <td>{date}</td>
          <td>{i.method ? i.method : `-`}</td>
          <td>${i.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td>
            ${i.totalamount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
          <td>
            <p className="orange">{i.orderStatus ? i.orderStatus : `-`}</p>
          </td>
          <td>
            <p className="orange">{i.status ? i.status : `-`}</p>
          </td>
          <td>
            <p className="orange">{i.payout_status ? i.payout_status : `-`}</p>
          </td>
          <td align="center">
            {/* <p>58307</p> */}
            <a
                className="orange"
              href="javascript:;"
              onClick={() => this.openModal(i)}
              data-toggle="modal"
              data-target="#paymentdetails"
            >
              View Detail
            </a>
            {/* <a className="orange" href="javascript:;" onClick={this.openModal}>Track this order</a> */}
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="admin-card">
          <div className="admin-card-head">
            <h2>payments list</h2>
          </div>
          <div className="admin-card-box">
            <div className="vendor-table">
              <div className="vendor-table-head">
                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <div className="entries-drop">
                      <label>show</label>
                      <select
                          className="entries-controls"
                        onChange={(e) => this.handleChange(e, "maxLimit")}
                        value={this.state.maxLimit}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="all">All</option>
                      </select>
                      <p>Entries</p>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="vendor-table-head-right">
                      <div className="entries-drop">
                        <label>order Status</label>
                        <select
                            className="entries-controls"
                          onChange={(e) => this.handleChange(e, "orderStatus")}
                          value={this.state.orderStatus}
                        >
                          <option value="">All</option>
                          <option value="pending">PENDING</option>
                          <option value="confirmed">CONFIRMED</option>
                          <option value="succeeded">SUCCEEDED</option>
                          <option value="canceled">CANCELED</option>
                          <option value="completed">COMPLETED</option>
                          {/* <option value="refund">REFUNDED</option> */}
                        </select>
                      </div>
                      <div className="entries-drop">
                        <label>Payment Status</label>
                        <select
                            className="entries-controls"
                          onChange={(e) =>
                            this.handleChange(e, "paymentStatus")
                          }
                          value={this.state.paymentStatus}
                        >
                          <option value="">All</option>
                          <option value="pending">PENDING</option>
                          <option value="succeeded">SUCCEEDED</option>
                          <option value="paid">PAYMENT RECEIVED</option>
                        </select>
                      </div>
                      <div className="vendor-table-search mr-2">
                        <input
                            className="form-control"
                          onChange={(e) =>
                            this.handleChange(e, "searchKeyword")
                          }
                          value={this.state.searchKeyword}
                          type="text"
                          placeholder="search"
                        />
                      </div>
                      {/* <div className="download-statement-btn">
                                                <button type="button" className="hyper-link-btn">download statement</button>
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="mb-3">
                {this.state.payoutButtonActive && this.sellerData?.seller_type !== "doba" &&(
                  <button
                    className={`orange-outline-btn ${
                      this.state.requestPayOutsButtonDisable
                        ? "disable-btn"
                        : null
                    }`}
                    disabled={this.state.requestPayOutsButtonDisable}
                    onClick={this.handleRequestPayOuts}
                  >
                    Request Payouts
                  </button>
                )}
                <div>
                  {this.state[`successMessage`] && (
                    <div className="success-msg">
                      <p>Your request submitted successfully</p>
                    </div>
                  )}
                </div>
              </div> */}
              <div className="vendor-table-body">
                <div className="table-responsive">
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={() => this.fetchMoreData(false)}
                    hasMore={this.state.hasMore}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <td>OrderId</td>
                          <td>created at</td>
                          <td>Method</td>
                          <td>Total</td>
                          <td>receivable Amount</td>
                          <td>Order Status</td>
                          <td>Order Payment status</td>
                          <td>Payout Status</td>
                          <td align="center">Payout Details</td>
                        </tr>
                      </thead>
                      <tbody>{payments}
                      {this.state.showLoader ? (
                                                <tr>
                                                    <td colSpan={9} className="text-center">
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    </td>
                                                </tr>
                                            ) : (null)}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal paymentdetails-modal" id="paymentdetails">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="paymentdetails-heading">
                  <h4>Payout details</h4>
                </div>
                <button type="button" className="close" data-dismiss="modal">
                  <i className="fas fa-times"></i>
                </button>
              </div>

              <div className="modal-body">
                <div className="paymentdetails-table">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>{this.state.modalInfo}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getPaymentsList, searchPayment, checkSynButtonActivate, requestPayOuts },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Payment_List);
