import axios from 'axios';
import { handleError } from "./commonAction";
import {MEETING_ALREADY_EXISTS} from "../ActionType";
const URL = process.env.REACT_APP_API_URL;

export const addProduct = (productInfo, userId) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (var key in productInfo) {
            formData.append(key, productInfo[key]);
        }

        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/product/add/${userId}`,
            data: formData,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const updateProductDetail = (productInfo, userId, productId) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (var key in productInfo) {
            formData.append(key, productInfo[key]);
        }

        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/product/update/${userId}?id=${productId}`,
            data: formData,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}
export const createFreeSeminar = (seminarInfo, userId, productId, timezone) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/product/create-free-seminar/${productId}/${userId}?timezone=${timezone}`,
            data: seminarInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const addGroupAndOneOnOneMeetingDetails = (meetingInfo, productId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/product/add-group-one-on-one-meeting/${productId}`,
            data: meetingInfo,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}
export const getGroupAndOneOnOneMeetingDetails = (productId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${URL}/api/v1/seller/product/get-group-one-on-one-meeting/${productId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const getProductList = (userId, start) => async (dispatch) => {
    try {

        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/product/list/${userId}?offset=${start}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;

    } catch (error) {
        dispatch(handleError(error));
    }
}

export const deleteProduct = (productId) => async (dispatch) => {
    try {

        const res = await axios({
            method: "delete",
            url: `${URL}/api/v1/seller/product/delete-product/${productId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;

    } catch (error) {
        dispatch(handleError(error));
    }
}


export const getProductDetail = (userId, slug) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/product/detail/${userId}/${slug}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        return res;

    } catch (error) {
        dispatch(handleError(error));
    }
}


function getCategoryID(categoryName) {
    let categoryId;
    switch (categoryName) {
        case "new-arrival":
            categoryId = 1;
            break;
        case "best-sellers":
            categoryId = 2;
            break;
        case "featured":
            categoryId = 3;
            break;
        case "weekly":
            categoryId = 4;
            break;
        case "monthly":
            categoryId = 5;
            break;
        case "annual":
            categoryId = 6;
            break;
        default:
            categoryId = 1;
    }
    return categoryId;
}

export const changeCategory = (categoryName) => async (dispatch) => {
    let categoryId = getCategoryID(categoryName);

    dispatch({
        type: "CATEGORY_CHANGE",
        payload: { categoryId, categoryName }
    })
}


export const searchProduct = (offset, userId, searchKeyword) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/product/by/search/${userId}?offset=${offset}&search=${searchKeyword}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const searchProductStatus = (offset, userId, productStatus) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/product/status/${userId}?offset=${offset}&search=${productStatus}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}


export const updateProductSubscripotion = () => async (dispatch) => {
    try {

    } catch (error) {

    }
}

export const updateProductFiles = (data, userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/product/update-product-files/${userId}`,
            data: data,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const removeProductFile = (data) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/product/remove-product-files`,
            data: data,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}



export const getActiveSubscriptionCustomerListByProudctId = (product_id, offset) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/product/active_subscription_list/${product_id}?offset=${offset}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });

        dispatch({ type: "ACTIVE_SUBCRIPTION_USER_LIST", payload: { data: res.data } })
        return res;

    } catch (error) {
        dispatch(handleError(error));
    }
}


export const sendInvitationRequest = (data, userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'post',
            url: `${URL}/api/v1/seller/schedule/send_invitation`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
                data
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}

export const updateGroupInviteMeeting = (data, userId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'PATCH',
            url: `${URL}/api/v1/seller/schedule/update_group_invitation`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data
        });
        if (res.data && res.data.isError) {
            dispatch({
                type: MEETING_ALREADY_EXISTS,
                payload: true
            })
        }
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}
export const cancelGroupInviteMeeting = (meetingId) => async (dispatch) => {
    try {
        const res = await axios({
            method: 'get',
            url: `${URL}/api/v1/seller/schedule/${meetingId}/cancel_group_invitation`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}