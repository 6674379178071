import React, { Component } from "react";
import "./App.css";
import "./Styles/all.scss";
import "./Styles/bootstrap.min.css";
import "./Styles/media.scss";
import "./Styles/style.scss";
import PrivateRoutes from "./Routing/privateRoutes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetail, setUserInfo } from "./Redux/Actions/userAction";
import { FileDownloadIcon } from "./Components/svgElements/FileDownloadIcon";
import { ChatIcon } from "./Components/svgElements/ChatIcon";
import HeaderMessage from "./Components/Header/HeaderMessage";

class App extends Component {
  userData = localStorage.getItem("sellerData");

  constructor(props) {
    super(props);
    if (this.userData) {
      this.fetchUserDetail();
    }
    this.childDiv = React.createRef();
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      setTimeout(() => {
        this.childDiv.current.scrollIntoView(true);
      }, 100);
    }
  };

  async fetchUserDetail() {
    const userDetail = await this.props.getUserDetail(
      JSON.parse(this.userData)
    );
    if (userDetail?.data?.data) {
      this.props.setUserInfo(userDetail.data.data);
    }
  }

  render() {
    return (
      <div ref={this.childDiv}>
        <HeaderMessage />
        <PrivateRoutes />
        <div className="fixed-btns">
          <button type="button" className="icon-button primary-btn">
            <a target="_blank" href={`${process.env.REACT_APP_ASSETS_BASE_URL}/documents/vendor-onboarding-guide.pdf`} rel="noreferrer">
              <span>Download Guide</span> <FileDownloadIcon />
            </a>
          </button>
          <button
            type="button"
            className="icon-button primary-btn"
          >
            <a href={"mailto:Info@Subciety.com"}>
              <span>Request Assistance</span> <ChatIcon />
            </a>
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserDetail, setUserInfo }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
