import React from "react";
import Select from "react-select";

const SelectBox = (props) => {
  const {
    label,
    value,
    options,
    onChange,
    error,
    required,
    parentClass = "form-group",
    inputClass = "basic-multi-select",
    ...extraProps
  } = props;

  return (
    <div className={parentClass}>
      <label>
        {label}
        {required ? <sup>*</sup> : null}
      </label>
      <Select
        value={value}
        name="value"
        options={options}
        className={inputClass}
        classNamePrefix="select"
        placeholder={label}
        onChange={onChange}
        {...extraProps}
      />
      <span className="form-field-error">{error ? error : ""}</span>
    </div>
  );
};

export default React.memo(SelectBox);
