export const initialState = {
    categoryId: "",
    categoryName: "",
    productList: [],
    productDetail: {},
    activeSubscriptionMemberList: [],
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CATEGORY_CHANGE": {
            return {
                categoryId: action.payload.categoryId,
                categoryName: action.payload.categoryName,
                productList: []

            }
        }
        case "PRODUCT_LIST":
            return {
                ...state,
                productList: [...action.payload.data.data]
            }
        case "PRODUCT_DETAIL":
            return {
                ...state,
                productDetail: action.payload.data.data
            }

        case 'ACTIVE_SUBCRIPTION_USER_LIST':
            return {
                ...state,
                activeSubscriptionMemberList: action.payload.data
            }
        default:
            return state
    }
}