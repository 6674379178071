import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createMeeting,
  getMeetingsByProductIdAndSellerId,
  updateSelectedEvent,
  deleteSelectedEvent,
  deleteOnetimeMeeting,
  getMeetingsBySellerId,
  validateCancelSlot,
} from "../../Redux/Actions/meetingActions";
import { addGroupAndOneOnOneMeetingDetails } from "../../Redux/Actions/productAction";
import { Button, Modal } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../Styles/calendar.scss";
import {
  CLOSE_MEETING_MODAL,
  MEETING_ALREADY_EXISTS,
} from "../../Redux/ActionType";
import { WEEKLY, MONTHLY, BI_WEEKLY, GROUP, ONE_TIME } from "../../Constant/Constant";
import { days } from "../../Constant/Constant";
import AlertModal from "../../Components/AlertModal/AlertModal";
import { useLocation } from "react-router";
import TimePicker from "react-time-picker";
import { isInteger } from "lodash";

import axios from "axios";
let source;
source = axios.CancelToken.source();

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const eventStyleGetter = function (event, start, end, isSelected) {
  let background = "#fff";
  let color = "#000";

  if (event.status === "pending") {
    background = "#ccc";
    color = "#000";
  }
  if((event.meetingDurationType === ONE_TIME && (event.groupType == GROUP || event.isSlotBooked)) || (event.type == "free_event")) {
    background = "#e25e52";
    color = "#fff";
  }
  if (event.meetingDurationType === WEEKLY && (event.groupType == GROUP || event.isSlotBooked)) {
    background = "#00c12a";
    color = "#fff";
  }
  if (event.meetingDurationType === BI_WEEKLY && (event.groupType == GROUP || event.isSlotBooked)) {
    background = "#0423ff";
    color = "#fff";

  }
  if (event.meetingDurationType === MONTHLY && (event.groupType == GROUP || event.isSlotBooked)) {
    background = "#e4a900";
    color = "#fff";

  }
  var style = {
    background,
    borderRadius: "10px",
    opacity: 1,
    color,
    border: "0px",
    display: "block",
    padding: "2px 10px",
    textAlign: "center",
    margin: "3px",
    outline: "none",
  };
  return {
    style: style,
  };
};

let link = ""
let host_link = "";

const MeetingCalendar = (props) => {
  let dispatch = useDispatch();
  const location = useLocation();
  let { meetingList: eventList, meetingExists,loading } = useSelector(
    (state) => state.meetingReducer
  );
  const [meetingType, setMeetingType] = useState(0); // meeting type = 0 for group and 1 for one-one-one
  const [editMeetingType, setEditMeetingType] = useState(0); // meeting type = 0 for recurring and 1 for one time
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [zoomLink, setZoomMeetingLink] = useState("");
  const [originalSlot, setOriginalSlot] = useState({
    start: "",
    end: "",
  });

  const [formData, setFormData] = useState({
    start: "",
    end: "",
  });
  const [originalMeetingDurationType, setOriginalMeetingDurationType] =
    useState(WEEKLY);
  let [eventSelectedDay, setEventSelectedDay] = useState(new Date());

  const [reccuringPopup, setReccuringPopup] = useState(false);
  const [cancelMeeting, setCancelMeeting] = useState(false);
  const [editMeeting, setEditMeeting] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [meetingEvent, setMeetingEvent] = useState(null);
  const [originalMeetingType, setOriginalMeetingType] = useState(0);
  const [meetingDurationType, setMeetingDurationType] = useState(WEEKLY);
  const [view, setView] = useState("week");
  const [createMeetingState, setCreateMeetingState] = useState({});
  const [selectedSlot, setSelectedSlot] = useState("");
  const [cancelReason, setCancelReason] = useState("")
  const [groupMeetingDetails, setGroupMeetingDetails] = useState({
    id: null,
    meetingName: "",
    subscriptionCost: 0,
    sessionHour: "00",
    sessionMinute: "00",
    meetingMonthlyStartTime: "",
    meetingMonthlyEndTime: "",
    meetingMonthlyWeekDay: "",
    meetingMonthlyWeekOfMonth: "",
    groupMeetingFrequency: "",
    isMonthlyGroupFrequencyChecked: false,
    notDeletePreviousEntries: true,
    isWeekDaySelected: {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    },
    slots: {},
  });

  const [trialCost, setTrialCost] = useState(0);
  const [subscriptionCost, setSubscriptionCost] = useState(0);
  const [oneOnOneSlots, setOneOnOneSlots] = useState({});
  const [isCreatedFromCalendar, setIsCreatedFromCalendar] = useState(false);

  const [sessionHour, setSessionHour] = useState("00");
  const [sessionMinute, setSessionMinute] = useState("00");
  const [originalGroupMeetingDetails, setOriginalGroupMeetingDetails] = useState({});
  const sessionHours = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const sessionMinutes = ["00", "15", "30", "45"];
  const selectOneOnOneFrequency = [
    "All",
    "One Time",
    "Weekly",
    "Bi weekly",
    "Monthly",
  ];
  const [selectedFrequency, setSelectedFrequency] = useState({
    All: false,
    "One Time": false,
    Weekly: false,
    "Bi weekly": false,
    Monthly: false,
  });
  const selectGroupFrequency = ["Weekly", "Bi weekly", "Monthly"];
  const weekOfMonth = ["First", "Second", "Third"];
  const [isWeekDaySelected, setIsWeekDaySelected] = useState({
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
  });
  const weekDays = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    singleButton: true,
  });

  const [oneOnOneMeetingErrorData, setOneOnOneMeetingErrorData] = useState({
    selectedOneOnOneFrequency: [],
    trialCost: [],
    subscriptionCost: [],
    sessionHour: [],
    selectedOneOnOneMeetingSlots: [],
  });

  const [groupMeetingErrorData, setGroupMeetingErrorData] = useState({
    meetingName: [],
    selectedOneOnOneFrequency: [],
    subscriptionCost: [],
    sessionHour: [],
    sessionMinute: [],
    selectedWeekDay: [],
    meetingMonthlyWeekDay: [],
    meetingMonthlyStartTime: [],
    meetingMonthlyWeekOfMonth: [],
    selectedOneOnOneMeetingSlots: [],
  });

  const [oneOnOneId, setOneOnOneId] = useState(null);
  const [isOneOnOneMeetingChecked, setIsOneOnOneMeetingChecked] =
    useState(false);
  const [isGroupMeetingChecked, setIsGroupMeetingChecked] = useState(true);
  const [oneTimeDate, setOneTimeDate] = useState(null);
  const [cancelType, setCancelType] = useState(0);

  useEffect(() => {
    props.showLoaderForCalander(loading)
  },[loading])


  useEffect(() => {
    setEvents(eventList);
  }, [eventList]);

  useEffect(() => {
    fetchMeetingList();
  }, [selectedDay, view,props.title]);


  // useEffect(() => {
  //   getGroupAndOneOnOneMeetingDetails(props.productId);
  // }, []);

  // const getGroupAndOneOnOneMeetingDetails = async () => {
  //   const groupAndOneOnOneData = await props.getGroupAndOneOnOneMeetingDetails(
  //     props.productId
  //   );
  //   setPreviousMeetingList(groupAndOneOnOneData);
  // };

  const handleGroupMeetingFrequencyChange = (e, freq) => {
    let freqType = freq.toLowerCase();
    if (freqType === "bi weekly") freqType = "bi_weekly";

    setMeetingDurationType(freqType);
    let tempGroupMeetingDetails = { ...groupMeetingDetails };
    setGroupMeetingErrorData((prevState) => ({
      ...prevState,
      selectedOneOnOneFrequency: [],
    }));

    tempGroupMeetingDetails["groupMeetingFrequency"] = freq;
    tempGroupMeetingDetails["slots"] = {};
    Object.entries(groupMeetingDetails["isWeekDaySelected"]).map(
      ([weekDay, bool]) => {
        tempGroupMeetingDetails["isWeekDaySelected"][weekDay] = false;
      }
    );
    tempGroupMeetingDetails["isMonthlyGroupFrequencyChecked"] =
      freq == "Monthly" ? true : false;

    tempGroupMeetingDetails["meetingMonthlyStartTime"] = formData.start
    tempGroupMeetingDetails["meetingMonthlyEndTime"] = formData.end
    setGroupMeetingDetails({ ...tempGroupMeetingDetails });
  };

  const fetchMeetingList = async () => {
    if (source){
      source.cancel("Landing Component got props changed");
    }
    source = axios.CancelToken.source();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let startOfWeekTime, endOfWeekTime;
    if (view === "week") {
      startOfWeekTime = moment(selectedDay)
        .startOf("week")
        .format("YYYY-MM-DD HH:mm:ss");
      endOfWeekTime = moment(selectedDay)
        .endOf("week")
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (view === "month") {
      startOfWeekTime = moment(selectedDay)
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      endOfWeekTime = moment(selectedDay)
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if (props.productId) {
      let response = await props.getGroupAndOneOnOneMeetingDetails(
        props.productId
      );

      if (
        response &&
        !response?.data?.isError &&
        response?.data?.data?.length > 0
      ) {
        if (props.isOneOnOneMeetingChecked || isOneOnOneMeetingChecked) {
          response.data.data.map((productDetail, index) => {
            if (productDetail.type == "one_on_one") {
              setOneOnOneId(productDetail.id);
              setSubscriptionCost(productDetail.subscription_cost);
              setTrialCost(productDetail.one_time_cost);
              let sessionDuration = JSON.parse(productDetail.session_duration);
              setSessionHour(sessionDuration.sessionHour);
              setSessionMinute(sessionDuration.sessionMinute);

              let tempSelectedFrequency = { ...selectedFrequency };
              let oneOnOneFreq = productDetail.frequency.split(",");
              oneOnOneFreq.map((frequency) => {
                tempSelectedFrequency[frequency] = true;
              });

              setSelectedFrequency(tempSelectedFrequency);

              let tempSlots = { ...oneOnOneSlots };
              let oneOnOneSelectedWeekDays = { ...isWeekDaySelected };

              let meetingSlot = productDetail.availability
                ? JSON.parse(productDetail.availability)
                : null;

              if (meetingSlot) {
                tempSlots = { ...meetingSlot };
                Object.entries(tempSlots).map(([weekDay, timeSlot]) => {
                  oneOnOneSelectedWeekDays[weekDay] = true;
                });
              }
              setOneOnOneSlots(tempSlots);
            }
          });
        }
      }

      dispatch(
        getMeetingsByProductIdAndSellerId(
          props.seller_id,
          props.productId,
          startOfWeekTime,
          endOfWeekTime,
          timezone,
          view,
          source.token
        )
      );
    } else {
      const { timezone } = JSON.parse(localStorage.getItem("sellerData"));
      dispatch(
        getMeetingsBySellerId(
          props.seller_id,
          startOfWeekTime,
          endOfWeekTime,
          timezone,
          source.token
        )
      );
    }
  };

  const handleChange = (e, name) => {

    formData[name] = e ? e : "";
    setFormData({ ...formData });
    setIsCreatedFromCalendar(true)
  };

  const getDatesArrayOccuringOnSameDayOfMonth = (day, eventSelectedDay) => {
    try {
      // day is long day string
      let dateArray = [];
      const date = moment(new Date(eventSelectedDay)).format('YYYY-MM-DD')
      let weekDay = moment(date).startOf("month").day(day);
      if (weekDay.date() > 7) weekDay.add(7, "d");
      let month = weekDay.month();
      while (month === weekDay.month()) {
        dateArray.push(
          moment(weekDay.year().toString() +
            "-" +
            (weekDay.month() + 1).toString() +
            "-" +
            weekDay.date().toString(), "YYYY-MM-DD").format("YYYY-MM-DD"));
        weekDay.toString();
        weekDay.add(7, "d");
      }
      return dateArray

    } catch (err) {
      return err
    }
  }

  const handleOriginalSlotData = (e) => {
  }

  const handleSetMeetingEvent = (e, value) => {
    e.preventDefault();
    setMeetingDurationType(WEEKLY);
    setMeetingType(+value);
    setEditMeetingType(+value);
  };

  const handleModalShowHide = () => {
    setShowHide(false);
    setCancelType(0);
    //setEditMeeting(false);
    setEditMeetingType(0);
    setCancelMeeting(false);
    setFormData({ start: "", end: "" });
    setGroupMeetingDetails({
      id: null,
      meetingName: "",
      subscriptionCost: 0,
      sessionHour: "00",
      sessionMinute: "00",
      meetingMonthlyStartTime: "",
      meetingMonthlyEndTime: "",
      meetingMonthlyWeekDay: "",
      meetingMonthlyWeekOfMonth: "",
      groupMeetingFrequency: "",
      isMonthlyGroupFrequencyChecked: false,
      notDeletePreviousEntries: true,
      isWeekDaySelected: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      slots: {},
    });
  };

  const handleFillTime = (event) => {
    let dayKey;
    let tempWeekSelected = {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    };

    handleOriginalSlotData(event)
    setSelectedSlot(event ? JSON.stringify(event) : null)
    if (event) {
      let { start, end } = event;
      let diff = moment(start).diff(new Date(), "days");
      if (diff >= 0 && start && end) {
        setEventSelectedDay(new Date(start));
        let startTime = moment(new Date(start), "hh:mm A").format("HH:mm");
        let endTime = moment(new Date(end), "hh:mm A").format("HH:mm");
        formData["start"] = startTime;
        formData["end"] = endTime;
        let type = event.groupType === "group" ? 0 : 1;
        setMeetingType(type);

        setEditMeetingType(0);
        if (event.groupType === "group") {
          setIsGroupMeetingChecked(true);
          setIsOneOnOneMeetingChecked(false);
        } else {
          setIsOneOnOneMeetingChecked(true);
          setIsGroupMeetingChecked(false);
        }
        setOriginalMeetingType(event?.meetingType);

        if (event?.meetingDurationType) {
          setMeetingDurationType(event.meetingDurationType);
          setOriginalMeetingDurationType(event.meetingDurationType);
        } else if (event?.frequency) {
          let meetingTypee = event.frequency.split(",")[0].toLowerCase();
          if (meetingTypee === "bi weekly") meetingTypee = "bi_weekly";
          if (meetingTypee === "one time") meetingTypee = "one_time";

          setMeetingDurationType(meetingTypee);
          setOriginalMeetingDurationType(meetingTypee);
        }
        setZoomMeetingLink(event?.zoomLink);
        setOriginalSlot({ ...formData });
        setFormData({ ...formData });

        // if(event.groupType==="one_on_one"){
        //   let frequency=[];
        //   let freqType = event.frequency.toLowerCase();
        //   freqType.split(",").map((freq,index)=>{
        //     if (freq === "bi weekly"){
        //      frequency.push("bi_weekly");
        //     }else{
        //       frequency.push(freq);
        //     }
        //   });

        //   setMeetingDurationType(frequency.toString());
        //   //setOriginalMeetingDurationType(frequency.toString());
        // }

        if (event.groupType === "group") {
          let groupSessionDuration = event?.sessionDuration
            ? JSON.parse(event?.sessionDuration)
            : null;
          // let slots = event?.slots
          //   ? JSON.parse(event?.slots)
          //   : null;

          let tempGroupMeetingObj = { ...groupMeetingDetails };

          tempGroupMeetingObj["id"] = event?.meetingId ? event?.meetingId : null;
          tempGroupMeetingObj["meetingName"] = event?.meetingName;
          tempGroupMeetingObj["subscriptionCost"] = event?.subscriptionCost;
          tempGroupMeetingObj["sessionHour"] = groupSessionDuration?.sessionHour;
          tempGroupMeetingObj["sessionMinute"] =
            groupSessionDuration?.sessionMinute;
          tempGroupMeetingObj["groupMeetingFrequency"] = event?.frequency;
          // tempGroupMeetingObj["slots"] = { ...slots };

          if (event?.frequency !== "Monthly") {
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = false;

            // Object.entries(slots).map(([weekDay, timeSlot]) => {
            //   tempGroupMeetingObj["isWeekDaySelected"][weekDay] = true;
            // });
          }
          //else {
          //     tempGroupMeetingObj["meetingMonthlyStartTime"] =
          //       slots.startTime;
          //     tempGroupMeetingObj["meetingMonthlyEndTime"] =
          //       slots.endTime;
          //     tempGroupMeetingObj["meetingMonthlyWeekOfMonth"] =
          //       slots.weekOfMonth;
          //     tempGroupMeetingObj["meetingMonthlyWeekDay"] =
          //       slots.weekDay;
          //     tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = true;
          //     //groupMeetingDetails.push({ ...tempGroupMeetingObj });
          //  }
          let dayKey;
          let tempWeekSelected = { ...isWeekDaySelected };

          dayKey = Object.keys(groupMeetingDetails.isWeekDaySelected)[
            new Date(start).getDay()
          ];
          let tempGroupDetails = { ...groupMeetingDetails };
          tempGroupMeetingObj["isWeekDaySelected"][dayKey] = true;


          let tempSlots = {};

          tempSlots = { [dayKey]: [[formData.start, formData.end]] };

          let tempGroupMeetingDetails = { ...tempGroupMeetingObj };
          // let tempAllGroupMeetingDetails = [];
          if (isGroupMeetingChecked) {

            let tempAvailability = event?.availability ? JSON.parse(event?.availability) : null
            let groupMeetingLink = event?.group_meeting_link ? JSON.parse(event?.group_meeting_link) : null

            tempAvailability && Object.entries(tempAvailability).map(([weekDay, timeslotArray]) => {

              if (weekDay == dayKey) {

                timeslotArray.map((timeslot) => {

                  let startTimeHour = +formData.start.split(':')[0]
                  let startTimeMinute = +formData.start.split(':')[1]
                  let endTimeHour = +formData.end.split(':')[0]
                  let endTimeMinute = +formData.end.split(':')[1]

                  let incStartTimeHour = +timeslot[0].split(':')[0]
                  let incStartTimeMinute = +timeslot[0].split(':')[1]
                  let incEndTimeHour = +timeslot[1].split(':')[0]
                  let incEndTimeMinute = +timeslot[1].split(':')[1]

                  if (startTimeHour == incStartTimeHour && startTimeMinute == incStartTimeMinute && endTimeHour == incEndTimeHour && endTimeMinute == incEndTimeMinute
                    && groupMeetingLink && groupMeetingLink.join_url && groupMeetingLink.start_url) {

                    link = groupMeetingLink?.join_url
                    host_link = groupMeetingLink?.start_url

                  }

                })
              }


            })

            tempGroupMeetingDetails.slots = {
              [dayKey]: [[formData.start, formData.end]],
            };

            if (tempGroupMeetingDetails?.groupMeetingFrequency?.toLowerCase() == "monthly") {
              // let tempAvailability = event?.availability ? JSON.parse(event?.availability) : null
              if (tempAvailability) {
                // if (formData.start && formData.end){
                //   tempGroupMeetingDetails["meetingMonthlyEndTime"] = formData.end
                //   tempGroupMeetingDetails["meetingMonthlyStartTime"] = formData.start
                // }
                tempGroupMeetingDetails["meetingMonthlyEndTime"] = formData.end
                tempGroupMeetingDetails["meetingMonthlyStartTime"] = formData.start
                tempGroupMeetingDetails["meetingMonthlyWeekDay"] = tempAvailability.weekDay
                tempGroupMeetingDetails["meetingMonthlyWeekOfMonth"] = tempAvailability.weekOfMonth
              }
              tempGroupMeetingDetails["isMonthlyGroupFrequencyChecked"] = true

            }
            // setGroupMeetingDetails({...tempGroupMeetingDetails});

            // if(!editMeeting){
            //   for (const meetingDetails of previousMeetingList?.data?.data) {
            //     if (meetingDetails.type === "group") {
            //       if (meetingDetails.id === tempGroupMeetingDetails.meetingId) {
            //         tempAllGroupMeetingDetails.push(tempGroupMeetingDetails);
            //       } else {
            //         tempAllGroupMeetingDetails.push(meetingDetails);
            //       }
            //     }
            //   }
            // }

            // if(!tempGroupMeetingDetails.meetingId && !editMeeting){
            //   tempAllGroupMeetingDetails.push(tempGroupMeetingDetails);
            // }
          }
      
          setOriginalGroupMeetingDetails({ ...tempGroupMeetingDetails })
          setGroupMeetingDetails({ ...tempGroupMeetingDetails });
        }
      } else {
        alertModal({
          message: `You cannot add events to past date!`,
          singleButton: true,
        });
      }
    }
  };

  const handleSelectSlot = (event) => {

    let { start, end } = event;
    let diff = moment(start).diff(new Date());
    if (diff >= 0 && start && end) {
      let isError = false;
      let isTimeError = false;
      let meetingStartTimeStamp = start.getTime();
      let meetingEndTimeStamp = end.getTime();
      for (const event of events) {
        let startTimeStamp = event.start.getTime();
        let endTimeStamp = event.end.getTime();
        if (
          meetingStartTimeStamp > startTimeStamp &&
          meetingStartTimeStamp < endTimeStamp
        ) {
          isTimeError = true;
          break;
        } else if (
          startTimeStamp > meetingStartTimeStamp &&
          startTimeStamp < meetingEndTimeStamp
        ) {
          isTimeError = true;
          break;
        }
        if (
          meetingEndTimeStamp < endTimeStamp &&
          meetingEndTimeStamp > startTimeStamp
        ) {
          isTimeError = true;
          break;
        } else if (
          endTimeStamp < meetingEndTimeStamp &&
          endTimeStamp > meetingStartTimeStamp
        ) {
          isTimeError = true;
          break;
        }
        else if (
          startTimeStamp == meetingStartTimeStamp &&
          endTimeStamp == meetingEndTimeStamp
        ) {
          isTimeError = true;
          break;
        }
      }
      if (isError) {
        alertModal({
          message: `You cannot add events to past date!`,
          singleButton: true,
        });
        return;
      } 
      else if (isTimeError) {
        alertModal({
          message: `You cannot Overlap The Time!`,
          singleButton: true,
        });
        return;
      } 
      setFormData({
        start: "",
        end: "",
      });
      setShowHide(true);
      setMeetingType(0);
      setEditMeetingType(0);
      setEditMeeting(false);
      setIsGroupMeetingChecked(true);
      setIsOneOnOneMeetingChecked(false);
      setOneTimeDate(moment(start).format("YYYY-MM-DD"));
      setEventSelectedDay(new Date(start));
      let startTime = moment(new Date(start), "hh:mm A").format("HH:mm");
      let endTime = moment(new Date(end), "hh:mm A").format("HH:mm");
      formData["start"] = view === "week" ? startTime : "";
      formData["end"] = view === "week" ? endTime : "";
      setFormData({ ...formData });
    } else {
      alertModal({
        message: `You cannot add events to past date!`,
        singleButton: true,
      });
    }
  };

  const validateMeetingInfo = () => {
    let isFormValid = true;
   

    if (isOneOnOneMeetingChecked) {
      const slotsNumber = calculateHourMinuteDiff(
        [formData["start"], formData["end"]],
        sessionHour,
        sessionMinute
      );

      if (!!!slotsNumber || !!!isInteger(slotsNumber)) {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          selectedOneOnOneMeetingSlots: [
            "The selected slot should be as per the session duration",
          ],
        }));
      } else {
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          selectedOneOnOneMeetingSlots: [],
        }));
      }

      let notSelectedOneOnOneFrequencyCount = 0;

      Object.entries(selectedFrequency)
        .slice(1, 5)
        .map(([freq, bool]) => {
          if (!bool) {
            notSelectedOneOnOneFrequencyCount += 1;
          }
          if (notSelectedOneOnOneFrequencyCount == 4) {
            isFormValid = false;
            setOneOnOneMeetingErrorData((prevState) => ({
              ...prevState,
              selectedOneOnOneFrequency: ["Please enter frequency"],
            }));
          }
        });

      if (trialCost == 0) {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          trialCost: ["Please enter trial cost"],
        }));
      }

      if (subscriptionCost == 0) {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          subscriptionCost: ["Please enter subscription cost"],
        }));
      }

      if (sessionHour == "00" && sessionMinute == "00") {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          sessionHour: ["Please enter session duration"],
        }));
      }
    }

    if (isGroupMeetingChecked) {
      if (!groupMeetingDetails["meetingName"].length) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          meetingName: ["Enter meeting name"],
        }));
      }
      if (!groupMeetingDetails["subscriptionCost"]) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          subscriptionCost: ["Enter subscription cost"],
        }));
      }
      if (
        groupMeetingDetails["sessionHour"] == "00" &&
        groupMeetingDetails["sessionMinute"] == "00"
      ) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          sessionHour: ["Enter session duration"],
        }));
      }

      const slotsNumber = calculateHourMinuteDiff(
        [
          groupMeetingDetails["isMonthlyGroupFrequencyChecked"]
            ? (groupMeetingDetails.meetingMonthlyStartTime,
              groupMeetingDetails.meetingMonthlyEndTime)
            : formData["start"],
          formData["end"],
        ],
        groupMeetingDetails.sessionHour,
        groupMeetingDetails.sessionMinute
      );

      if (!groupMeetingDetails["isMonthlyGroupFrequencyChecked"]) {
        if (!!!slotsNumber || !!!isInteger(slotsNumber) || slotsNumber> 1) {
          isFormValid = false;
          setGroupMeetingErrorData((prevState) => ({
            ...prevState,
            selectedOneOnOneMeetingSlots: [
              "The selected slot should be as per the session duration",
            ],
          }));
        } else {
          setGroupMeetingErrorData((prevState) => ({
            ...prevState,
            selectedOneOnOneMeetingSlots: [],
          }));
        }
      }

      if (!groupMeetingDetails["groupMeetingFrequency"].length) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          selectedOneOnOneFrequency: ["Select group frequency"],
        }));
      }

      if (groupMeetingDetails["isMonthlyGroupFrequencyChecked"]) {
        if (!groupMeetingDetails["isMonthlyGroupFrequencyChecked"]) {
          let notSelectedGroupWeekDayCount = 0;
          Object.entries(groupMeetingDetails["isWeekDaySelected"]).map(
            ([weekDay, bool]) => {
              if (!bool) {
                notSelectedGroupWeekDayCount += 1;
              }
              if (notSelectedGroupWeekDayCount == 7) {
                isFormValid = false;
                setGroupMeetingErrorData((prevState) => ({
                  ...prevState,
                  selectedWeekDay: ["Select week day"],
                }));
              }
            }
          );
        } else {
          if (!groupMeetingDetails["meetingMonthlyWeekDay"]) {
            isFormValid = false;
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyWeekDay: ["Select week day"],
            }));
          }

          if (!groupMeetingDetails["meetingMonthlyWeekOfMonth"]) {
            isFormValid = false;
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyWeekOfMonth: ["Select month week"],
            }));
          }

          const slotsNumber = calculateHourMinuteDiff(
            [
              groupMeetingDetails["meetingMonthlyStartTime"],
              groupMeetingDetails["meetingMonthlyEndTime"],
            ],
            groupMeetingDetails.sessionHour,
            groupMeetingDetails.sessionMinute
          );

          if (
            (!groupMeetingDetails["meetingMonthlyEndTime"].split(":")[0] &&
              !groupMeetingDetails["meetingMonthlyEndTime"].split(":")[1]) ||
            (!groupMeetingDetails["meetingMonthlyStartTime"].split(":")[0] &&
              !groupMeetingDetails["meetingMonthlyStartTime"].split(":")[1]) ||
            !slotsNumber ||
            !isInteger(slotsNumber)
          ) {
            isFormValid = false;
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyStartTime: ["Select valid time"],
            }));
          } else {
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyStartTime: [],
            }));
          }
        }
      }
    }
    try{
      let start=formData.start;
      let end=formData.end;

      if(start && start.startsWith("0")){
        start=start.replace("0","");
      }
       if(start && start.endsWith("00")){
        
        start= start.replace("00","0")
      }
      if(end && end.startsWith("0")){
        end=end.replace("0","");
      }
       if(end && end.endsWith("00")){
        
        end=end.replace("00","0")
      }

      if(eventList && eventList.length>0){
        for(const event of eventList){
          const availability=event.availability ? JSON.parse(event.availability): null;
          if(availability){
            if(event.meetingDurationType!=="monthly" && event.groupType!=="group"){
              const slotsArray=Object.keys(JSON.parse(event.availability));
            if(slotsArray && slotsArray.length>0){
              for(const slot of slotsArray){
                for(let time of JSON.parse(event.availability)[slot]){                  
                  if(time[0]===start && time[1]===end){
                    isFormValid= false;
                    if(isGroupMeetingChecked){
                      setGroupMeetingErrorData((prevState) => ({
                        ...prevState,
                        selectedOneOnOneMeetingSlots: [
                          "Metting of this duration already exists",
                        ],                    
                      }));
                    }
                    if(isOneOnOneMeetingChecked){
                      setOneOnOneMeetingErrorData((prevState) => ({
                        ...prevState,
                        selectedOneOnOneMeetingSlots: [
                          "Metting of this duration already exists",
                        ],                        }));

                    }
                    return isFormValid;
                  }
                }
              }
            }

            }
            else{
              let startMonthly=availability.startTime
              let endMonthly=availability.endTime

              if(startMonthly && startMonthly.startsWith("0")){
                startMonthly=startMonthly.replace("0","");
              }
               if(startMonthly && startMonthly.endsWith("00")){
                
                startMonthly= startMonthly.replace("00","0")
              }
              if(endMonthly && endMonthly.startsWith("0")){
                endMonthly=endMonthly.replace("0","");
              }
               if(endMonthly && endMonthly.endsWith("00")){
                
                endMonthly=endMonthly.replace("00","0")
              }
              if(start===startMonthly && end===endMonthly){
                if(isGroupMeetingChecked){
                  setGroupMeetingErrorData((prevState) => ({
                    ...prevState,
                    selectedOneOnOneMeetingSlots: [
                      "Metting of this duration already exists",
                    ],                    
                  }));
                }
                if(isOneOnOneMeetingChecked){
                  setOneOnOneMeetingErrorData((prevState) => ({
                    ...prevState,
                    selectedOneOnOneMeetingSlots: [
                      "Metting of this duration already exists",
                    ],                        }));
                    return isFormValid;
                }
              }
            }
          }
        }
      }
    }
    catch(error){
      console.error(error,"errorerrorerror");
    }

 
    return isFormValid;
  };

  const calculateHourMinuteDiff = (
    startEndTimeArray,
    sessionHour,
    sessionMinute
  ) => {
    let numberOfSlots = null;
    let sessionDuration = 1;
    if (startEndTimeArray[0] && startEndTimeArray[1]) {
      let startTimeHour = startEndTimeArray[0].split(":")[0];
      let startTimeMinute = startEndTimeArray[0].split(":")[1];
      let endTimeHour = startEndTimeArray[1].split(":")[0];
      let endTimeMinute = startEndTimeArray[1].split(":")[1];
      const start = new Date("August 19, 1975 23:15:30");
      const end = new Date("August 19, 1975 23:15:30");
      start.setMinutes(startTimeMinute);
      start.setHours(startTimeHour);
      end.setMinutes(endTimeMinute);
      end.setHours(endTimeHour);

      let timeDifferenceFromDates = end - start;

      if (timeDifferenceFromDates < 0) {
        return numberOfSlots;
      }

      let timeSlotDiff = parseFloat(
        (timeDifferenceFromDates * (0.001 / 3600)).toFixed(2)
      );
      sessionDuration =
        parseInt(sessionHour) + parseFloat((sessionMinute / 60).toFixed(2));
      numberOfSlots = timeSlotDiff / sessionDuration;
    }
    return numberOfSlots;
  };

  const handleSessionHourMinuteTrialSubscriptionCostChange = (e, key) => {
    if (meetingType) {
      oneOnOneMeetingErrorData[key] = [];

      if (key == "sessionHour") {
        setSessionHour(e.target.value);
      } else if (key == "Minute") {
        setSessionMinute(e.target.value);
      } else if (key == "trialCost") {
        setTrialCost(e.target.value);
      } else if (key == "subscriptionCost") {
        setSubscriptionCost(e.target.value);
      }
    } else if (key != "trialCost" && meetingType == 0) {
      groupMeetingErrorData[key] = [];

      let tempGroupMeetingDetails = { ...groupMeetingDetails };
      tempGroupMeetingDetails[key] = e.target.value;
      setGroupMeetingDetails({ ...tempGroupMeetingDetails });
    }
  };

  const isMeetingAlreadyCancelled = async () => {
    const eventSelectedDayStr =
    eventSelectedDay.getFullYear() +
    "-" +
    (eventSelectedDay.getMonth() + 1) +
    "-" +
    eventSelectedDay.getDate();

  let frequency = [];
  if (isOneOnOneMeetingChecked) {
    selectOneOnOneFrequency.map((freq, freqIndex) => {
      if (selectedFrequency[freq]) {
        let freqType = freq.toLowerCase();
        if (freqType === "bi weekly") freqType = "bi_weekly";
        if (freqType === "one time") freqType = "one_time";
        frequency.push(freqType);
      }
    });
  }
  if (isGroupMeetingChecked) {
    selectOneOnOneFrequency.map((freq) => {
      if (groupMeetingDetails.groupMeetingFrequency === freq) {
        let freqType = freq.toLowerCase();
        if (freqType === "bi weekly") freqType = "bi_weekly";
        frequency.push(freqType);
      }
    });
  }
  let payload = {
    seller_id: props.seller_id,
    product_id: props.productId,
    slot: [formData],
    day: days[new Date(eventSelectedDay).getDay()],
    originalSlot: [originalSlot],
    date: eventSelectedDayStr,
    meetingType: editMeetingType,
    reschdule_id: meetingEvent?.meetingId,
    structureId: meetingEvent?.structure_id,
    originalMeetingType,
    meetingDurationType,
    originalMeetingDurationType,
    frequency: frequency,
    isFormCalendar: true,
  }
  setShowHide(false);
  const response = await props.validateCancelSlot(payload, true) //true is sent to check whether the selected time to be rescheduled is cancelled or not.
  return response;
  }

  const createNewEvent = async () => {
    if (editMeeting){
     const response = await isMeetingAlreadyCancelled()
    if (!response) {
      return;
    }

    }
    if (validateMeetingInfo()) {
      let dayKey;
      let tempWeekSelected = props.isOneOnOneMeetingChecked
        ? { ...props.isWeekDaySelected }
        : { ...isWeekDaySelected };
      setIsWeekDaySelected({ ...tempWeekSelected })
      let tempSlotsToBeShownOnCalendar = [...events]
      if (isGroupMeetingChecked) {
        let selectedDateObj = eventSelectedDay
        let startTime = formData["start"]
        let endTime = formData["end"]
        if (groupMeetingDetails.groupMeetingFrequency.toLowerCase() == "monthly") {
          let dateArray = getDatesArrayOccuringOnSameDayOfMonth(groupMeetingDetails.meetingMonthlyWeekDay, eventSelectedDay)
          let weekDate = groupMeetingDetails.meetingMonthlyWeekOfMonth.toLowerCase() == "first"
            ? dateArray[0]
            : groupMeetingDetails.meetingMonthlyWeekOfMonth.toLowerCase() == "second"
              ? dateArray[1]
              : dateArray[2]
          let tempDate = new Date(weekDate)
          selectedDateObj = tempDate
          startTime = groupMeetingDetails.meetingMonthlyStartTime
          endTime = groupMeetingDetails.meetingMonthlyEndTime
        }
        let sessionDuration = {
          sessionHour: groupMeetingDetails.sessionHour,
          sessionMinute: groupMeetingDetails.sessionMinute,
        };
        let freqType =
          groupMeetingDetails.groupMeetingFrequency.toLowerCase();
        if (freqType === "bi weekly") freqType = "bi_weekly";
        if (freqType === "one time") freqType = "one_time";
        tempSlotsToBeShownOnCalendar.push({
          start: new Date(`${moment(new Date(selectedDateObj)).format("YYYY-MM-DD")} ${startTime}`),
          end: new Date(`${moment(new Date(selectedDateObj)).format("YYYY-MM-DD")} ${endTime}`),
          meetingName: groupMeetingDetails.meetingName,
          sessionHour: groupMeetingDetails.sessionHour,
          sessionMinute: groupMeetingDetails.sessionMinute,
          sessionDuration: sessionDuration ? JSON.stringify(sessionDuration) : null,
          subscriptionCost: groupMeetingDetails.subscriptionCost,
          availability: groupMeetingDetails.groupMeetingFrequency?.toLowerCase() == "monthly" ? JSON.stringify({
            endTime: groupMeetingDetails?.meetingMonthlyEndTime,
            startTime: groupMeetingDetails?.meetingMonthlyStartTime,
            weekDay: groupMeetingDetails?.meetingMonthlyWeekDay,
            weekOfMonth: groupMeetingDetails?.meetingMonthlyWeekOfMonth
          }) : null,
          // type: "bi_weekly", 
          // frequency:"bi_weekly",
          frequency: groupMeetingDetails.groupMeetingFrequency,
          groupType: meetingType ? "one_on_one" : "group",
          meetingDurationType: freqType,
          type: freqType
        })
        eventList = [...tempSlotsToBeShownOnCalendar]
        setEvents(eventList)
      }
      if (isOneOnOneMeetingChecked && meetingType == 1) {
        let sessionDuration = {
          sessionHour,
          sessionMinute
        }
        Object.entries(selectedFrequency).map(([key, boolVal]) => {
          if (boolVal && key !== "All") {
            let freqType = key.toLowerCase()
            if (freqType === "bi weekly") freqType = "bi_weekly";
            if (freqType === "one time") freqType = "one_time";
            let tempEventSelectedDayStart = new Date(eventSelectedDay)
            let tempEventSelectedDayEnd = new Date(eventSelectedDay)
            tempEventSelectedDayStart.setHours(formData["start"].split(':')[0])
            tempEventSelectedDayStart.setMinutes(formData["start"].split(':')[1])
            tempEventSelectedDayEnd.setHours(formData["end"].split(':')[0])
            tempEventSelectedDayEnd.setMinutes(formData["end"].split(':')[1])
            tempSlotsToBeShownOnCalendar.push({
              start: tempEventSelectedDayStart,
              end: tempEventSelectedDayEnd,
              trialCost,
              subscriptionCost,
              sessionDuration: sessionDuration ? JSON.stringify(sessionDuration) : null,
              meetingDurationType: freqType,
              type: freqType,
              groupType: "one_on_one"
            })
          }
        })
        eventList = [...tempSlotsToBeShownOnCalendar]
        setEvents(eventList)
      }

      if (isOneOnOneMeetingChecked && meetingType == 1) {
        dayKey = Object.keys(isWeekDaySelected)[eventSelectedDay.getDay()];
        tempWeekSelected[dayKey] = true;
        setIsWeekDaySelected({ ...tempWeekSelected });
      } else {
        dayKey = Object.keys(groupMeetingDetails.isWeekDaySelected)[
          eventSelectedDay.getDay()
        ];
        let tempGroupDetails = { ...groupMeetingDetails };
        tempGroupDetails["isWeekDaySelected"][dayKey] = true;

        setGroupMeetingDetails(tempGroupDetails);
      }

      let appendSlot = false;
      let isSlotUpdated = false;
      let tempSlots = { ...oneOnOneSlots };
      let existingSlot = selectedSlot ? JSON.parse(selectedSlot) : null;
      let startTime = new Date(existingSlot?.start)
      let endTime = new Date(existingSlot?.end)

      if (!editMeetingType) {
        if (props.isOneOnOneMeetingChecked && meetingType == 1) {
          Object.entries(oneOnOneSlots).map(([weekDay, timeSlot]) => {
            timeSlot && timeSlot.map((time, index) => {
              if (weekDay === dayKey) {
                appendSlot = true;
                let startHour = +time[0]?.split(':')[0]
                let startMinute = +time[0]?.split(':')[1]
                let endHour = +time[1]?.split(':')[0]
                let endMinute = +time[1]?.split(':')[1]
                if (startTime.getHours() == startHour && startTime.getMinutes() == startMinute && endTime.getHours() == endHour && endTime.getMinutes() == endMinute) {
                  isSlotUpdated = true
                  tempSlots[weekDay][index] = [formData.start, formData.end, "","",0,oneTimeDate]
                } else {
                  if (!isSlotUpdated && index == timeSlot.length - 1) {
                    tempSlots[weekDay].push([formData.start, formData.end, "","",0,oneTimeDate]);
                  }
                }
              }

            })
          });
        }

        if (!appendSlot) {
          if (
            props.isOneOnOneMeetingChecked &&
            Object.entries(oneOnOneSlots).length > 0 && meetingType == 1
          ) {
            tempSlots = {
              ...tempSlots,
              [dayKey]: [[formData.start, formData.end, "","",0,oneTimeDate]],
            };
          } else {
            tempSlots = { [dayKey]: [[formData.start, formData.end, "","",0,oneTimeDate]] };
          }
        }

      }

      setOneOnOneSlots(tempSlots);

      let tempGroupMeetingDetails = { ...groupMeetingDetails };
      // let tempAllGroupMeetingDetails = [];
      if (isGroupMeetingChecked) {
        tempGroupMeetingDetails.slots = {
          [dayKey]: [[formData.start, formData.end, link, host_link]],
        };
        setGroupMeetingDetails(tempGroupMeetingDetails);

        // if(!editMeeting){
        //   for (const meetingDetails of previousMeetingList?.data?.data) {
        //     if (meetingDetails.type === "group") {
        //       if (meetingDetails.id === tempGroupMeetingDetails.meetingId) {
        //         tempAllGroupMeetingDetails.push(tempGroupMeetingDetails);
        //       } else {
        //         tempAllGroupMeetingDetails.push(meetingDetails);
        //       }
        //     }
        //   }
        // }

        // if(!tempGroupMeetingDetails.meetingId && !editMeeting){
        //   tempAllGroupMeetingDetails.push(tempGroupMeetingDetails);
        // }
      }

      const dateOfEvent = new Date(eventSelectedDay).getDate();

      let selectedStartTimeStamp = new Date(
        `${moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")} ${formData["start"]
        }`
      ).getTime();

      let selectedEndTimeStamp = new Date(
        `${moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")} ${formData["end"]
        }`
      ).getTime();

      if (dateOfEvent > 28 && meetingDurationType === MONTHLY) {
        alertModal({
          message: `Some months have fewer than ${dateOfEvent} days, so meeting will appear at end date of month.`,
          singleButton: true,
        });
      }

      if (selectedStartTimeStamp > selectedEndTimeStamp) {
        alertModal({
          message: "Please select time correctly",
          singleButton: true,
        });
      } else if (selectedEndTimeStamp - selectedStartTimeStamp < 60000 * 5) {
        alertModal({
          message:
            "Minimum 5 mins difference required between meeting start time and end time !",
          singleButton: true,
        });
      } else {
        let SelectedDateList = events.filter(
          (item) =>
            moment(new Date(item.start)).format("MM-DD-YYYY") ===
            moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")
        );
        let isError = false;

        if (editMeeting) {
          for (const event of SelectedDateList) {
            if (
              isGroupMeetingChecked &&
              groupMeetingDetails.id === event.meetingId
            ) {
              let sessionDuration = event?.sessionDuration
                ? JSON.parse(event?.sessionDuration)
                : null;

              let slotsNumber = calculateHourMinuteDiff(
                [formData["start"], formData["end"]],
                sessionDuration?.sessionHour,
                sessionDuration?.sessionMinute
              );
              let startTimeStamp = new Date(event.start).getTime();
              let endTimeStamp = new Date(event.end).getTime();
              if (!!!slotsNumber || !!!isInteger(slotsNumber)) {
                isError = true;
                alertModal({
                  message:
                    "Please select time slots according to session duration",
                  singleButton: true,
                });
                return;
              }
              if (
                selectedStartTimeStamp >= startTimeStamp &&
                selectedStartTimeStamp <= endTimeStamp
              ) {
                isError = true;
                break;
              } else if (
                startTimeStamp >= selectedStartTimeStamp &&
                startTimeStamp <= selectedEndTimeStamp
              ) {
                isError = true;
                break;
              }
              if (
                selectedEndTimeStamp <= endTimeStamp &&
                selectedEndTimeStamp >= startTimeStamp
              ) {
                isError = true;
                break;
              } else if (
                endTimeStamp <= selectedEndTimeStamp &&
                endTimeStamp >= selectedStartTimeStamp
              ) {
                isError = true;
                break;
              }
            }
          }
        }

        const eventSelectedDayStr =
          eventSelectedDay.getFullYear() +
          "-" +
          (eventSelectedDay.getMonth() + 1) +
          "-" +
          eventSelectedDay.getDate();

        let data = {
          oneOnOneMeetingDetails: isOneOnOneMeetingChecked
            ? {
              id: oneOnOneId,
              isWeekDaySelected: tempWeekSelected,
              isFrequencySelected: selectedFrequency,
              slots: tempSlots,
              sessionHour: sessionHour,
              sessionMinute: sessionMinute,
              trialCost: trialCost,
              subscriptionCost: subscriptionCost,
            }
            : null,
          groupMeetingDetails: isGroupMeetingChecked
            ? tempGroupMeetingDetails
            : null,
        };

        if (!editMeeting) {
          setIsCreatedFromCalendar(true)


          await props.setGroupAndOneOnOneMeetingDetailsFromCalendar(data);
          setShowHide(false);


          setGroupMeetingErrorData((prevState) => ({
            ...prevState,
            selectedOneOnOneFrequency: [],
          }));
          // await dispatch(
          //   addGroupAndOneOnOneMeetingDetails(
          //     {
          //       oneOnOneMeetingDetails: isOneOnOneMeetingChecked
          //         ? {
          //             id: oneOnOneId,
          //             isWeekDaySelected: isWeekDaySelected,
          //             isFrequencySelected: selectedFrequency,
          //             slots: tempSlots,
          //             sessionHour: sessionHour,
          //             sessionMinute: sessionMinute,
          //             trialCost: trialCost,
          //             subscriptionCost: subscriptionCost,
          //           }
          //         : null,
          //       groupMeetingDetails: isGroupMeetingChecked
          //         ? [tempGroupMeetingDetails]
          //         : null,
          //       deletedGroupMeetingsIdArray: [],
          //     },
          //     props.productId
          //   )
          // );
        } else {
          let frequency = [];
          if (isOneOnOneMeetingChecked) {
            selectOneOnOneFrequency.map((freq) => {
              if (selectedFrequency[freq]) {
                let freqType = freq.toLowerCase();
                if (freqType === "bi weekly") freqType = "bi_weekly";
                if (freqType === "one time") freqType = "one_time";
                frequency.push(freqType);
              }
            });
          }
          if (isGroupMeetingChecked) {
            selectOneOnOneFrequency.map((freq) => {
              if (groupMeetingDetails.groupMeetingFrequency === freq) {
                let freqType = freq.toLowerCase();
                if (freqType === "bi weekly") freqType = "bi_weekly";
                frequency.push(freqType);
              }
            });
          }

          let body;
          body = {
            seller_id: props.seller_id,
            product_id: props.productId,
            slot: [formData],
            day: days[new Date(eventSelectedDay).getDay()],
            originalSlot: [originalSlot],
            date: eventSelectedDayStr,
            meetingType: editMeetingType,
            reschdule_id: meetingEvent?.meetingId,
            structureId: meetingEvent?.structure_id,
            originalMeetingType,
            meetingDurationType,
            originalMeetingDurationType,
            validate: true,
            frequency: frequency,
            isFormCalendar: true,
          };
          let tempEventList = [...events]
          tempEventList.map((item, index) => {
            let tempItem = item ? JSON.stringify(item) : null
            let tempSelectedSlot = selectedSlot ? JSON.parse(selectedSlot) : null
            let startItem = new Date(item.start).getTime()
            let endItem = new Date(item.end).getTime()
            let startSelectedSlot = new Date(tempSelectedSlot.start).getTime()
            let endSelectedSlot = new Date(tempSelectedSlot.end).getTime()
            if (tempItem === selectedSlot || (item.groupType == "one_on_one" && startItem == startSelectedSlot && endItem == endSelectedSlot)) {
              let tempStartDate = new Date(item["start"])
              let tempEndDate = new Date(item["end"])
              if (groupMeetingDetails?.isMonthlyGroupFrequencyChecked && groupMeetingDetails?.groupMeetingFrequency?.toLowerCase() == "monthly") {
                tempStartDate.setHours(groupMeetingDetails.meetingMonthlyStartTime?.split(':')[0])
                tempStartDate.setMinutes(groupMeetingDetails.meetingMonthlyStartTime?.split(':')[1])
                tempEndDate.setHours(groupMeetingDetails.meetingMonthlyEndTime?.split(':')[0])
                tempEndDate.setMinutes(groupMeetingDetails.meetingMonthlyEndTime?.split(':')[1])
                body["slot"] = { start: moment(tempStartDate).format("HH:mm"), end: moment(tempEndDate).format("HH:mm") }
              } else {
                tempStartDate.setHours(formData["start"].split(":")[0])
                tempStartDate.setMinutes(formData["start"].split(":")[1])
                tempEndDate.setHours(formData["end"].split(":")[0])
                tempEndDate.setMinutes(formData["end"].split(":")[1])
              }
              item["start"] = tempStartDate
              item["end"] = tempEndDate
              tempEventList[index] = { ...item }
            }
          })
          setEvents([...tempEventList])
          if (body.reschdule_id)
            await props.updateSelectedEvent(body)
          if (body.meetingType !== 1) {
            await props.setGroupAndOneOnOneMeetingDetailsFromCalendar(data, originalGroupMeetingDetails);
          }
          // await dispatch(updateSelectedEvent(body));
          setCreateMeetingState(body);
          setFormData({ start: "", end: "" });
          setMeetingDurationType(WEEKLY);
          // setTimeout(() => {
          //   fetchMeetingList();
          // }, 2 * 1000);
          setIsGroupMeetingChecked(true);
          setIsOneOnOneMeetingChecked(false);
          handleModalShowHide();
        }

        //setFormData({ start: "", end: "" });
        //setMeetingDurationType(WEEKLY);
        // setTimeout(() => {
        //   fetchMeetingList();
        // }, 2 * 1000);
        setGroupMeetingDetails({
          id: null,
          meetingName: "",
          subscriptionCost: 0,
          sessionHour: "00",
          sessionMinute: "00",
          meetingMonthlyStartTime: "",
          meetingMonthlyEndTime: "",
          meetingMonthlyWeekDay: "",
          meetingMonthlyWeekOfMonth: "",
          groupMeetingFrequency: "",
          isMonthlyGroupFrequencyChecked: false,
          notDeletePreviousEntries: true,
          isWeekDaySelected: {
            Sun: false,
            Mon: false,
            Tue: false,
            Wed: false,
            Thu: false,
            Fri: false,
            Sat: false,
          },
          slots: {},
        })
        setIsGroupMeetingChecked(true);
        setIsOneOnOneMeetingChecked(false);
        //handleModalShowHide();

        // if (!editMeeting) {
        //   body = {
        //     seller_id: props.seller_id,
        //     product_id: props.productId,
        //     slot: [formData],
        //     day: days[new Date(eventSelectedDay).getDay()],
        //     originalSlot: [originalSlot],
        //     date: eventSelectedDayStr,
        //     meetingType,
        //     meetingDurationType,
        //     validate: true,
        //   };
        //   dispatch(createMeeting(body));
        // } else {
        //   body = {
        //     seller_id: props.seller_id,
        //     product_id: props.productId,
        //     slot: [formData],
        //     day: days[new Date(eventSelectedDay).getDay()],
        //     originalSlot: [originalSlot],
        //     date: eventSelectedDayStr,
        //     meetingType,
        //     reschdule_id: meetingEvent?.meetingId,
        //     originalMeetingType,
        //     meetingDurationType,
        //     originalMeetingDurationType,
        //     validate: true,
        //   };
        //   await dispatch(updateSelectedEvent(body));
        // }

        // setCreateMeetingState(body);
        // setMeetingDurationType(WEEKLY);
        // setTimeout(() => {
        //   fetchMeetingList();
        // }, 2 * 1000);
        // handleModalShowHide();
      }
      alertModal({
        message: `Please resubmit offering at the bottom to save changes`,
        singleButton: true,
      });
    }
  };

  const handleSetMeetingType = (e, type, eventData) => {
    setMeetingType(type);
    setEditMeetingType(0);
    setReccuringPopup(false);
    if (!editMeeting) {
      dispatch(createMeeting({ ...createMeetingState, validate: false }));
    } else {
      dispatch(updateSelectedEvent({ ...createMeetingState, validate: false }));
    }
    setMeetingDurationType(WEEKLY);
    // setTimeout(() => {
    //   fetchMeetingList();
    // }, 2 * 1000);
    handleCloseMeetingExistsModal();
  };

  const createTempPayload = (payloadArray) => {
    let parsedArray = []
    
    payloadArray && payloadArray.map((item) => {

      const eventSelectedDayStr = item?.start_time?.split(" ")[0];


      parsedArray.push({
        ...item,
        seller_id: props.seller_id,
        product_id: props.productId,
        slot: [ { start: item?.start_time?.split(" ")[1], end: item?.end_time?.split(" ")[1] } ],
        day: days[new Date(eventSelectedDayStr).getDay()],
        originalSlot: [ { start: item?.start_time?.split(" ")[1], end: item?.end_time?.split(" ")[1] } ],
        date: eventSelectedDayStr,
        meetingType: editMeetingType,
        reschdule_id: item?.meetingId,
        structureId: item?.structure_id,
        originalMeetingType,
        meetingDurationType,
        originalMeetingDurationType,
        frequency: [item?.frequency?.toLowerCase()],
        isFormCalendar: true,
        cancelType: cancelType,
        groupType: meetingType
      })
      
    })
    
    return parsedArray;
  }
  
  const handleDeleteRecurringMeeting = async () => {
    setCancelType(0);
    const eventSelectedDayStr =
      eventSelectedDay.getFullYear() +
      "-" +
      (eventSelectedDay.getMonth() + 1) +
      "-" +
      eventSelectedDay.getDate();
    let payloadArray = []
    let tempSlots = {}

    if (meetingType == 0){ // For Group meetings only
     let tempEvents = [...events]
     let tempSelectedSlot = selectedSlot ? JSON.parse(selectedSlot) : null;
     let selectedWeekDay = days[new Date(eventSelectedDayStr).getDay()];
     if (tempSelectedSlot) {
      let tempOriginalSlots = {}
      let filteredEvents = null;
      let tempAvailability = tempSelectedSlot.availability ? JSON.parse(tempSelectedSlot.availability): null;
      
      
      filteredEvents = tempEvents.filter(i => i.structure_id == tempSelectedSlot.structure_id)

      if (editMeetingType == 0){ // Only for recurring meetings we are updating isRunningsubscriptionCancelled cancelled, In case of one time update we are only inserting records in vendor_rescheduled table.

        //  for recurring entire series cancellation
        if (groupMeetingDetails["groupMeetingFrequency"]?.toLowerCase() !== "monthly"){

          tempAvailability && Object.entries(tempAvailability).map(([weekDay, slotsArray]) => {
  
            slotsArray.map((i) => {
              if (cancelType == 1){
  
                let stTimeHour = +i[0].split(":")[0]
                let stTimeMin = +i[0].split(":")[1]
                let endTimeHour = +i[1].split(":")[0]
                let endTimeMin = +i[1].split(":")[1]
  
                let selectedStTime = tempSelectedSlot?.start_time?.split(" ")[1]
                let selectedEndTime = tempSelectedSlot?.end_time?.split(" ")[1]
  
                let selectedStTimeHour = +selectedStTime.split(":")[0]
                let selectedStTimeMin = +selectedStTime.split(":")[1]
                let selectedEndTimeHour = +selectedEndTime?.split(":")[0]
                let selectedEndTimeMin = +selectedEndTime?.split(":")[1]
  
                if (selectedWeekDay?.slice(0,3) == weekDay?.toLowerCase() && i[0] &&
                stTimeHour ==selectedStTimeHour && stTimeMin == selectedStTimeMin && endTimeHour == selectedEndTimeHour 
                && endTimeMin == selectedEndTimeMin){
                  i[4]=1
  
              }
    
            }else if (cancelType == 2){
              i[4] = 1 // updating flag for runningslot cancelled in availability.
              
            }
  
            tempSlots[weekDay] = tempSlots[weekDay] ? [...tempSlots[weekDay], i] : [i]
          })
          })
        }else{
          tempSlots={...tempAvailability}
          tempSlots["isRunningSubscriptionCancelled"]=1;
        }

      }

      // Preparing temporary payload with every slot to handle each slot lies in particular structure id.
      filteredEvents[0]["availability"]=tempSlots && Object.keys(tempSlots)?.length ? JSON.stringify(tempSlots) : null
      payloadArray = createTempPayload(filteredEvents)      
     }
    //  let filteredRes = tempAvailability.filter((i) => i.structure_id == meetingEvent?.structure_id)
    }
    setShowHide(false);
    setCancelMeeting(false);
    setEditMeeting(false);
    let frequency = [];
    if (isOneOnOneMeetingChecked) {
      selectOneOnOneFrequency.map((freq, freqIndex) => {
        if (selectedFrequency[freq]) {
          let freqType = freq.toLowerCase();
          if (freqType === "bi weekly") freqType = "bi_weekly";
          if (freqType === "one time") freqType = "one_time";
          frequency.push(freqType);
        }
      });
    }
    if (isGroupMeetingChecked) {
      selectOneOnOneFrequency.map((freq) => {
        if (groupMeetingDetails.groupMeetingFrequency === freq) {
          let freqType = freq.toLowerCase();
          if (freqType === "bi weekly") freqType = "bi_weekly";
          frequency.push(freqType);
        }
      });
    }
    setIsCreatedFromCalendar(true)
    let payload = {
      seller_id: props.seller_id,
      product_id: props.productId,
      slot: [formData],
      day: days[new Date(eventSelectedDay).getDay()],
      originalSlot: [originalSlot],
      date: eventSelectedDayStr,
      meetingType: editMeetingType,
      reschdule_id: meetingEvent?.meetingId,
      structureId: meetingEvent?.structure_id,
      originalMeetingType,
      meetingDurationType,
      originalMeetingDurationType,
      frequency: frequency,
      isFormCalendar: true,
      cancelType: cancelType,
      groupType: meetingType,
      payloadArray: payloadArray?.length ? payloadArray : null,
      availability: tempSlots && Object.keys(tempSlots)?.length ? tempSlots : null,
      cancelReason: cancelReason
    }
    const response = await props.validateCancelSlot(payload)
    if (response) {
      // This code removes slot from calendar when cancelled
      // {
      let tempEvents = [...events]

      let selectedSlotObj = selectedSlot ? JSON.parse(selectedSlot) : null
      let tempEventsList = []

      tempEvents && tempEvents.map((item, index) => {

        let tempSlot = JSON.stringify(item)

        if (tempSlot == selectedSlot ||
          (selectedSlotObj.groupType == "one_on_one" && selectedSlotObj.structure_id == item.structure_id && new Date(item?.start)?.getTime() == new Date(selectedSlotObj?.start)?.getTime()
            && new Date(item?.end)?.getTime() == new Date(selectedSlotObj?.end)?.getTime())) {

          // tempEvents.splice(index, 1)
        } else {
          tempEventsList.push(item)
        }
      })
      setEvents([...tempEventsList])
      // }
    }
    // props.deleteSelectedEvent(payload)
    // await dispatch(
    //   deleteSelectedEvent({
    //     seller_id: props.seller_id,
    //     product_id: props.productId,
    //     slot: [formData],
    //     day: days[new Date(eventSelectedDay).getDay()],
    //     originalSlot: [originalSlot],
    //     date: eventSelectedDayStr,
    //     meetingType: editMeetingType,
    //     reschdule_id: meetingEvent?.meetingId,
    //     structureId: meetingEvent?.structure_id,
    //     originalMeetingType,
    //     meetingDurationType,
    //     originalMeetingDurationType,
    //     frequency: frequency,
    //     isFormCalendar: true,
    //   })
    // );
    // } else {
    //     await dispatch(deleteOnetimeMeeting(meetingEvent?.meetingId))
    // }
    // setTimeout(() => {
    //   fetchMeetingList();
    // }, 2 * 1000);
    setFormData({ start: "", end: "" });
    setIsGroupMeetingChecked(true);
    setIsOneOnOneMeetingChecked(false);
    setGroupMeetingDetails({
      id: null,
      meetingName: "",
      subscriptionCost: 0,
      sessionHour: "00",
      sessionMinute: "00",
      meetingMonthlyStartTime: "",
      meetingMonthlyEndTime: "",
      meetingMonthlyWeekDay: "",
      meetingMonthlyWeekOfMonth: "",
      groupMeetingFrequency: "",
      isMonthlyGroupFrequencyChecked: false,
      notDeletePreviousEntries: true,
      isWeekDaySelected: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      slots: {},
    });
  };

  const handleDeleteGroupMeetingType = async (cancelType) => {
    // cancelType == 1 --> Cancel particular slot (Rescheduling it with type cancel and inserting into vendor_reschedule table)
    // cancelType == 2 --> Cancel entire plan (including other slots as well under same meeting name)
    setCancelType(cancelType)
  }



  const handleToolTip = (event) => {

    if (new Date(event.start).getTime() < Date.now()) {
      alertModal({
        message: `You cannot add/edit events to past date!`,
        singleButton: true,
      });
      return null;
    }
    if (event.meetingType === 2) {
      return props?.handleOpenMeetingSchedule(event);
    }
    // if (!props.productId) {
    //   return props?.handleMeetingClick(event);
    // }
    if (event.type == "free_event") {
      props.showSeminarPopup()
      return
    }
    let type = event.groupType === "group" ? 0 : 1;
    setMeetingType(type);
    setEditMeetingType(0);
    setShowHide(true);
    setEditMeeting(true);
    setMeetingEvent(event);
    handleFillTime(event);
    setOneTimeDate(moment(event.start).format("YYYY-MM-DD"))
  };

  const handleSetMeetingDurationType = (e) => {
    const { value } = e.target;
    setMeetingDurationType(value);
  };

  const alertModal = (data) => {
    setAlertState({ ...data, open: true });
  };

  const closeAlertModal = () => {
    setAlertState({
      open: false,
      message: "",
      singleButton: true,
    });
  };

  const handleAlertResponse = (type, id, data) => {
    if (id === 1) {
      if (type === "positive") {
        handleSetMeetingType(data[0], data[1]);
      } else {
        handleCloseMeetingExistsModal();
      }
      props.handleSubmit(true)
    }
    closeAlertModal();
  };

  const handleGroupMeetingDetailsChange = (e, key) => {
    let tempGroupMeetingDetails = { ...groupMeetingDetails };

    groupMeetingErrorData[key] = [];

    if (key == "meetingMonthlyStartTime" || key == "meetingMonthlyEndTime") {
      tempGroupMeetingDetails[key] = e;
      setGroupMeetingDetails({ ...tempGroupMeetingDetails });
      return;
    }

    tempGroupMeetingDetails[key] = e.target.value;

    setGroupMeetingDetails({ ...tempGroupMeetingDetails });
  };

  const handleGroupMeetingMonthlyWeekDayAndWeekChange = (e, key) => {
    groupMeetingErrorData[key] = [];
    let tempGroupMeetingDetails = { ...groupMeetingDetails };
    tempGroupMeetingDetails[key] = e.target.value;
    setGroupMeetingDetails({ ...tempGroupMeetingDetails });
  };

  const handleChangeChk = (e, key, weekDay, frequency) => {
    oneOnOneMeetingErrorData.selectedOneOnOneFrequency = [];
    if (key == "selectedFrequency") {
      let tempFrequencyArray = { ...selectedFrequency };
      tempFrequencyArray[`${frequency}`] = e.target.checked;

      let isAllFrequencySelected = 0;
      Object.entries(tempFrequencyArray)
        .slice(1, 5)
        .map(([freq, bool]) => {
          if (bool) {
            isAllFrequencySelected += 1;
          }
        });

      if (isAllFrequencySelected == 4) {
        tempFrequencyArray["All"] = true;
      } else {
        tempFrequencyArray["All"] = false;
      }

      setSelectedFrequency({ ...tempFrequencyArray });
      return;
    } else if (key == "allFrequencySelected") {
      let tempFrequencyArray = { ...selectedFrequency };
      selectOneOnOneFrequency.map((item) => {
        tempFrequencyArray[item] = e.target.checked;
      });

      setSelectedFrequency({ ...tempFrequencyArray });
    }
  };

  const modal = () => (
    <Modal
      show={showHide}
      onHide={handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={() => handleModalShowHide()}>
          <i className="fas fa-times"></i>
        </span>
        {!editMeeting ? (
          <h3>Create Meeting</h3>
        ) : (
          <div className="join-sub-header">
            <div>
              <p>Product Name</p>
              <p>
                <strong>{props.title}</strong>
              </p>
            </div>

            {props.zoomLink && (
              <a href={props.zoomLink} target="_blank">
                <button className="orange-btn">Join Meeting</button>
              </a>
            )}
            {zoomLink && (
              <a href={zoomLink} target="_blank">
                <button className="orange-btn">Join Meeting</button>
              </a>
            )}
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        {editMeeting ? (
          <>
            <div className="form-group">
              <label>Edit Meeting</label>

              <select
                value={editMeetingType}
                onChange={handleSelectMeetingChange}
                className="form-control"
              >
                <option value={0}>Recurring</option>
                <option value={1}>one time</option>
              </select>
            </div>
            <div className="details-slot">
              <button
                className="orange-btn"
                onClick={() => setCancelMeeting(false)}
              >
                {!cancelMeeting && <i className="far fa-check-circle"></i>}{" "}
                Reschdule
              </button>
              <button
                className="orange-btn"
                onClick={() => {
                  setCancelMeeting(true)
                  }}
              // className="orange-outline-btn"
              >
                {cancelMeeting && <i className="far fa-check-circle"></i>}{" "}
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="details-slot">
              <p>Slot details</p>
              {/*<button
                className={
                  meetingType === 0
                    ? "orange-btn-nohover"
                    : "orange-outline-btn-nohover"
                }
                onClick={(e) => handleSetMeetingEvent(e, 0)}
              >
                Recurring
              </button>
              <button
                className={
                  meetingType === 1
                    ? "orange-btn-nohover"
                    : "orange-outline-btn-nohover"
                }
                onClick={(e) => handleSetMeetingEvent(e, 1)}
              >
                One Time
              </button>*/}
              <h5>
                {moment(new Date(eventSelectedDay)).format("MMMM Do YYYY")}
              </h5>
            </div>
          </>
        )}
        <div className="row">
          {/* {!editMeeting && ( */}
          <div className="col-md-12">
            {!editMeeting && (
              <div className="form-group">
                <label>PRODUCT NAME</label>
                <p>{props.title}</p>
              </div>
            )}
            <div className="form-group">
              <label className="custom-label-product">
                Select Type
                <div className="tooltip-custom">
                  <i
                    className="fa fa-info-circle info-icon"
                    aria-hidden="true"
                  ></i>
                  <div className="together-tooltip">
                    <p>
                      Select The Type You Would Like To Offer
                      <span class="arrow-down"></span>
                    </p>
                  </div>
                </div>
              </label>
              <select
                value={meetingType}
                disabled={editMeeting}
                onChange={handleSelectChange}
                className="form-control"
              >
                <option value={0}>Group</option>
                <option value={1}>One-On-One</option>
              </select>
            </div>
          </div>
          {/* )} */}

          {!cancelMeeting && (
            <>
              {meetingType == 1 ? (
                <div className="col-md-12">
                  <div className="form-group mb-1">
                    <label className="custom-label-product">
                      Select frequency
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Select the type of Frequency you would like to offer
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                  {selectOneOnOneFrequency.map((frequency, freqIndex) => {
                    return (
                      <div className="check-outer" key={freqIndex}>
                        <label className="outer-label mb-0">
                          <input
                            type="checkbox"
                            disabled={props.isOneOnOneMeetingChecked}
                            checked={selectedFrequency[frequency]}
                            onChange={(e) =>
                              handleChangeChk(
                                e,
                                frequency == `All`
                                  ? `allFrequencySelected`
                                  : `selectedFrequency`,
                                "",
                                frequency
                              )
                            }
                          />{" "}
                          {frequency}
                          <span class="checkmark" />
                        </label>
                      </div>
                    );
                  })}
                  <p className="form-field-error">
                    {oneOnOneMeetingErrorData?.selectedOneOnOneFrequency &&
                      oneOnOneMeetingErrorData.selectedOneOnOneFrequency[0]}
                  </p>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="form-group m-0">
                    <label className="custom-label-product">
                      Meeting Name
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Enter The Meeting Name
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      disabled={groupMeetingDetails.id || editMeeting}
                      value={groupMeetingDetails["meetingName"]}
                      onChange={(e) =>
                        handleGroupMeetingDetailsChange(e, "meetingName")
                      }
                    />
                    <p className="form-field-error">
                      {groupMeetingErrorData?.meetingName &&
                        groupMeetingErrorData.meetingName[0]}
                    </p>
                  </div>

                  <div className="form-group mb-1 mt-2">
                    <label className="custom-label-product">
                      Select frequency
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Select the type of Frequency you would like to offer
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>

                  {selectGroupFrequency.map((frequency, freqIndex) => {
                    return (
                      <div className="check-outer" key={freqIndex}>
                        <label className="outer-label mb-0">
                          <input
                            type="checkbox"
                            value={frequency}
                            id={frequency}
                            disabled={groupMeetingDetails.id || editMeeting}
                            checked={
                              groupMeetingDetails["groupMeetingFrequency"] ==
                              frequency
                            }
                            onChange={(e) =>
                              handleGroupMeetingFrequencyChange(e, frequency)
                            }
                          />{" "}
                          {frequency}
                          <span class="checkmark" />
                        </label>
                      </div>
                    );
                  })}
                  <p className="form-field-error">
                    {groupMeetingErrorData?.selectedOneOnOneFrequency &&
                      groupMeetingErrorData.selectedOneOnOneFrequency[0]}
                  </p>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group d-flex mb-0">
                        <label className="custom-label-product">
                          Subscription Cost
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Enter the cost you would like to charge per
                                recurring session. For Example: A $20/per
                                session cost for a weekly option will create a
                                $80/month subscription offering
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <input
                        className="form-control"
                        min={0}
                        onChange={(e) =>
                          handleSessionHourMinuteTrialSubscriptionCostChange(
                            e,
                            "subscriptionCost"
                          )
                        }
                        disabled={groupMeetingDetails.id || editMeeting}
                        value={groupMeetingDetails.subscriptionCost}
                        type="number"
                      />
                      <p className="form-field-error">
                        {groupMeetingErrorData?.subscriptionCost &&
                          groupMeetingErrorData.subscriptionCost[0]}
                      </p>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group d-flex mb-0">
                        <label className="custom-label-product">
                          Session Duration
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Enter duration you would like to offer per
                                session
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="d-flex ">
                        <label className="d-flex align-items-center mr-2 mb-0">
                          Hours
                        </label>
                        <select
                          className="form-control mr-2"
                          disabled={groupMeetingDetails.id || editMeeting}
                          onChange={(e) =>
                            handleSessionHourMinuteTrialSubscriptionCostChange(
                              e,
                              "sessionHour"
                            )
                          }
                          value={groupMeetingDetails.sessionHour}
                        >
                          <option value="Hours" selected disabled>
                            Hours
                          </option>
                          {sessionHours.map((hour, index) => {
                            return (
                              <option key={index} value={hour}>
                                {hour}
                              </option>
                            );
                          })}
                        </select>
                        <label className="d-flex align-items-center mr-2 mb-0">
                          Minutes
                        </label>
                        <select
                          className="form-control "
                          disabled={groupMeetingDetails.id || editMeeting}
                          onChange={(e) =>
                            handleSessionHourMinuteTrialSubscriptionCostChange(
                              e,
                              "sessionMinute"
                            )
                          }
                          value={groupMeetingDetails.sessionMinute}
                        >
                          <option value="Minutes" selected disabled>
                            Minutes
                          </option>
                          {sessionMinutes.map((minute, index) => {
                            return (
                              <option key={index} value={minute}>
                                {minute}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <p className="form-field-error">
                        {groupMeetingErrorData?.sessionHour &&
                          groupMeetingErrorData.sessionHour[0]}
                      </p>
                    </div>
                  </div>

                  {groupMeetingDetails["isMonthlyGroupFrequencyChecked"] && (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-0 d-flex">
                            <label className="custom-label-product">
                              Week
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Select the week number
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="w-100">
                              <select
                                className="form-control"
                                value={
                                  groupMeetingDetails[
                                  "meetingMonthlyWeekOfMonth"
                                  ]
                                }
                                onChange={(e) =>
                                  handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                    e,
                                    "meetingMonthlyWeekOfMonth"
                                  )
                                }
                                disabled={groupMeetingDetails.id || editMeeting}
                              >
                                <option value="" selected disabled>
                                  --Select--
                                </option>
                                {weekOfMonth.map((week, index) => {
                                  return (
                                    <option value={week} key={index}>
                                      {week}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {groupMeetingErrorData?.meetingMonthlyWeekOfMonth &&
                              groupMeetingErrorData
                                .meetingMonthlyWeekOfMonth[0]}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group d-flex m-0">
                            <label className="custom-label-product">
                              Week Day
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Select the day of the week
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="w-100">
                              <select
                                className="form-control"
                                value={
                                  groupMeetingDetails["meetingMonthlyWeekDay"]
                                }
                                onChange={(e) =>
                                  handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                    e,
                                    "meetingMonthlyWeekDay"
                                  )
                                }
                                disabled={groupMeetingDetails.id || editMeeting}
                              >
                                <option value="" selected disabled>
                                  --Select--
                                </option>
                                {Object.entries(weekDays).map(
                                  ([key, weekDayVal], index) => {
                                    return (
                                      <option value={weekDayVal} key={index}>
                                        {weekDayVal}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {groupMeetingErrorData?.meetingMonthlyWeekDay &&
                              groupMeetingErrorData.meetingMonthlyWeekDay[0]}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group d-flex m-0">
                            <label className="custom-label-product">
                              Time
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Enter The Time You Would Like To Offer
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="d-flex align-items-center">
                              <div className="mr-2">
                                <TimePicker
                                  value={
                                    groupMeetingDetails[
                                    "meetingMonthlyStartTime"
                                    ]
                                  }
                                  format="hh:mm a"
                                  name="start"
                                  disableClock={true}
                                  onChange={(e) =>
                                    handleGroupMeetingDetailsChange(
                                      e,
                                      "meetingMonthlyStartTime"
                                    )
                                  }
                                  required={true}
                                  clearIcon={null}
                                  className="form-control"
                                />
                              </div>
                              <p className="mb-0 mx-2">To</p>
                              <div className="ml-2">
                                <TimePicker
                                  value={
                                    groupMeetingDetails["meetingMonthlyEndTime"]
                                  }
                                  format="hh:mm a"
                                  name="start"
                                  disableClock={true}
                                  onChange={(e) =>
                                    handleGroupMeetingDetailsChange(
                                      e,
                                      "meetingMonthlyEndTime"
                                    )
                                  }
                                  required={true}
                                  clearIcon={null}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {groupMeetingErrorData?.meetingMonthlyStartTime &&
                              groupMeetingErrorData.meetingMonthlyStartTime[0]}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {meetingType === 1 ? (
                <>
                  <div className="col-md-6">
                    <div className="form-group d-flex mb-0">
                      <label className="custom-label-product">
                        Trial or 1-time cost
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter the cost you would like to charge for a
                              trial or one time meeting
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <input
                      className="form-control"
                      min={0}
                      disabled={props.isOneOnOneMeetingChecked}
                      onChange={(e) =>
                        handleSessionHourMinuteTrialSubscriptionCostChange(
                          e,
                          "trialCost"
                        )
                      }
                      value={trialCost}
                      type="number"
                    />
                    <p className="form-field-error">
                      {oneOnOneMeetingErrorData?.trialCost &&
                        oneOnOneMeetingErrorData.trialCost[0]}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group d-flex mb-0">
                      <label className="custom-label-product">
                        Subscription Cost
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter the cost you would like to charge per
                              recurring session. For Example: A $20/per session
                              cost for a weekly option will create a $80/month
                              subscription offering
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <input
                      className="form-control"
                      min={0}
                      disabled={props.isOneOnOneMeetingChecked}
                      onChange={(e) =>
                        handleSessionHourMinuteTrialSubscriptionCostChange(
                          e,
                          "subscriptionCost"
                        )
                      }
                      value={subscriptionCost}
                      type="number"
                    />
                    <p className="form-field-error">
                      {oneOnOneMeetingErrorData?.subscriptionCost &&
                        oneOnOneMeetingErrorData.subscriptionCost[0]}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group d-flex mb-0">
                      <label className="custom-label-product">
                        Session Duration
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter duration you would like to offer per session
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="d-flex">
                      <label className="d-flex align-items-center mr-2 mb-0">
                        Hours
                      </label>
                      <select
                        className="form-control mr-2"
                        disabled={props.isOneOnOneMeetingChecked}
                        onChange={(e) =>
                          handleSessionHourMinuteTrialSubscriptionCostChange(
                            e,
                            "sessionHour"
                          )
                        }
                        value={sessionHour}
                      >
                        <option value="Hours" selected disabled>
                          Hours
                        </option>
                        {sessionHours.map((hour, index) => {
                          return (
                            <option key={index} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                      <label className="d-flex align-items-center mr-2 mb-0">
                        Minutes
                      </label>
                      <select
                        className="form-control "
                        disabled={props.isOneOnOneMeetingChecked}
                        onChange={(e) =>
                          handleSessionHourMinuteTrialSubscriptionCostChange(
                            e,
                            "Minute"
                          )
                        }
                        value={sessionMinute}
                      >
                        <option value="Minutes" selected disabled>
                          Minutes
                        </option>
                        {sessionMinutes.map((minute, index) => {
                          return (
                            <option key={index} value={minute}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <p className="form-field-error">
                      {oneOnOneMeetingErrorData?.sessionHour &&
                        oneOnOneMeetingErrorData.sessionHour[0]}
                    </p>
                  </div>
                </>
              ) : null}
            </>
          )}

          {!cancelMeeting &&
            !groupMeetingDetails["isMonthlyGroupFrequencyChecked"] && (
              <>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="custom-label-product">
                      START TIME
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Enter The Start Time
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                    <div className="form-relative">
                      {/*<input
                                        type="time"
                                        name="start"
                                        min="00:00"
                                        max="23:59"
                                        value={formData["start"]}
                                        required
                                        onChange={(e) => handleChange(e,"start")}
                                        className="form-control"
                                    />*/}

                      <TimePicker
                        value={formData["start"]}
                        format="hh:mm a"
                        name="start"
                        disableClock={true}
                        onChange={(e) => handleChange(e, "start")}
                        required={true}
                        clearIcon={null}
                        className="form-control"
                      />
                      <span className="clock-icon icon-unique-clock">
                        <i class="far fa-clock"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className="custom-label-product">
                      END TIME
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Enter The End Time
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                    <div className="form-relative">
                      {/*<input
                                        type="time"
                                        name="end"
                                        min="00:00"
                                        max="23:59"
                                        value={formData["end"]}
                                        onChange={(e) => handleChange(e)}
                                        required
                                        className="form-control"
                                    />*/}
                      <TimePicker
                        value={formData["end"]}
                        format="hh:mm a"
                        name="end"
                        disableClock={true}
                        onChange={(e) => handleChange(e, "end")}
                        required={true}
                        clearIcon={null}
                        className="form-control"
                      />
                      <span className="clock-icon icon-unique-clock">
                        <i class="far fa-clock"></i>
                      </span>
                    </div>
                  </div>
                  <p className="form-field-error">
                    {isOneOnOneMeetingChecked
                      ? oneOnOneMeetingErrorData?.selectedOneOnOneMeetingSlots &&
                      oneOnOneMeetingErrorData.selectedOneOnOneMeetingSlots[0]
                      : groupMeetingErrorData?.selectedOneOnOneMeetingSlots &&
                      groupMeetingErrorData.selectedOneOnOneMeetingSlots[0]}
                  </p>
                </div>
              </>
            )}
          {!meetingType && cancelMeeting && (
            <div className="col-md-12">
              <div className="form-group">
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to cancel?
                </span>
              </div>
              <div className="button-center">
                <button
                  onClick={() => handleDeleteGroupMeetingType(1)}
                  className="orange-btn"
                >
                  {cancelType == 1 && <i className="far fa-check-circle"></i>}
                  Cancel this slot
                </button>
                <button
                  onClick={() => handleDeleteGroupMeetingType(2)}
                  className="orange-outline-btn"
                >
                  {cancelType == 2 && <i className="far fa-check-circle"></i>}Cancel entire series
                </button>
              </div>
            </div>
          )}
          {((meetingType && cancelMeeting) || (!meetingType && cancelType)) ? (
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  <b>Add note</b>
                </label>
                <textarea onChange={(e) =>
                cancelMeetingReason(e)}
                value={cancelReason}
                 rows="4" className="form-control" />
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to cancel?
                </span>
              </div>
              <div className="button-center">
                <button
                  onClick={handleDeleteRecurringMeeting}
                  className="orange-btn"
                >
                  Yes
                </button>
                <button
                  onClick={() => handleModalShowHide()}
                  className="orange-outline-btn"
                >
                  No
                </button>
              </div>
            </div>
          ) : null}
          <div className="button-center w-100">
            {!cancelMeeting && (
              <button onClick={() => createNewEvent()} className="orange-btn">
                Save Changes
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  useEffect(() => {
    if (meetingExists && location.pathname.includes("/edit-product")) {
      alertModal({
        message:
          "For this slot meeting already Exists! Do you still want to continue?",
        singleButton: false,
        positiveButton: "Yes, Continue",
        negativeButton: "No, Cancel",
        id: 1,
        data: [{}, 0],
      });
    }
  }, [meetingExists]);

  const meetingModal = () => (
    <Modal
      show={meetingExists}
      onHide={handleCloseMeetingExistsModal}
      className="calendar-modal "
    >
      <Modal.Header
        className="calendar-header"
        closeButton
        onClick={() => handleCloseMeetingExistsModal()}
      >
        <h3>Alert</h3>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          For this slot meeting already Exists! Do you still want to continue?
        </p>
        <button
          className="orange-btn mr-2"
          onClick={(e) => handleSetMeetingType(e, 0)}
        >
          Yes, Continue
        </button>
        <button className="orange-btn" onClick={handleCloseMeetingExistsModal}>
          No, Cancel
        </button>
      </Modal.Body>
    </Modal>
  );



  const handleCloseMeetingExistsModal = () => {
    setShowHide(false);
    setEditMeeting(false);
    setIsGroupMeetingChecked(true);
    setIsOneOnOneMeetingChecked(false);
    setCancelMeeting(false);
    setEditMeetingType(0);
    dispatch({ type: CLOSE_MEETING_MODAL, payload: false });
    setFormData({ start: "", end: "" });
    dispatch({
      type: MEETING_ALREADY_EXISTS,
      payload: false,
    });
  };

 const cancelMeetingReason = (e) => {
  setCancelReason(e.target.value)
 }
  const handleSelectChange = (e) => {
    const { value } = e.target;

    setGroupMeetingErrorData({});
    setOneOnOneMeetingErrorData({});

    setMeetingDurationType(WEEKLY);
    setMeetingType(+value);
    if (value == 0) {
      setIsGroupMeetingChecked(true);
      setIsOneOnOneMeetingChecked(false);
    } else {
      setIsGroupMeetingChecked(false);
      setIsOneOnOneMeetingChecked(true);
    }
  };

  const handleSelectMeetingChange = (e) => {
    const { value } = e.target;
    setEditMeetingType(+value);
  };

  const onNavigateCalendar = (day) => {
    if (groupMeetingDetails.meetingName || isCreatedFromCalendar) {
      alertModal({
        message: `Please save your changes!`,
        singleButton: true,
      });
    } else {
      setSelectedDay(day);
    }
  };

  const onViewCalendar = (view) => {
    if (groupMeetingDetails.meetingName || isCreatedFromCalendar) {
      alertModal({
        message: `Please save your changes!`,
        singleButton: true,
      });
    } else {
      setView(view);
    }
  };

  return (
    <div className="calendar-colorbox">
      <ul className="represent-status">
        <li>
          <div className="color-bar green-color"></div>
          <p>Weekly</p>
        </li>
        <li>
          <div className="color-bar purple-color"></div>
          <p>Biweekly</p>
        </li>
        <li>
          <div className="color-bar yellow-color"></div>
          <p>Monthly</p>
        </li>
        <li>
          <div className="color-bar red-color"></div>
          <p>One Time</p>
        </li>
      </ul>
      <div id="ZoomMeetingCalendarModal">
        <Calendar
          localizer={localizer}
          events={events}
          showMultiDayTimes={true}
          style={{ height: 700 }}
          selectable
          startAccessor="start"
          endAccessor="end"
          step={30}
          view={view}
          onView={(view) => onViewCalendar(view)}
          views={["week", "month"]}
          onSelectEvent={(event) => handleToolTip(event)}
          onSelectSlot={(event) => handleSelectSlot(event)}
          eventPropGetter={eventStyleGetter}
          date={selectedDay}
          onNavigate={(day) => {
            onNavigateCalendar(day);
          }}
          dayLayoutAlgorithm="no-overlap"
          messages={{ previous: "Previous" }}
          popup={true}
        />
        {modal()}
        {/*{meetingModal()}*/}
        <AlertModal
          data={alertState}
          closeAlertModal={closeAlertModal}
          handleAlertResponse={(type, id, data) =>
            handleAlertResponse(type, id, data)
          }
        />
      </div>
    </div>
  );
};

export default MeetingCalendar;
