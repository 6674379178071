import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
class PrivateGuard extends React.Component {
  dontShowAfterLogin=[
    '/',
    '/signup',
    '/signUp',
    '/login',
    '/reset-password',
    '/forgot-password'

  ]
  render() {
    const { route, component: RouteComponent } = this.props;
    let returnData = "";

    if (localStorage.getItem("sellerId")) {
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = <Navigate to={"/dashboard"} />;
      } else returnData = <RouteComponent />;
    } else {
      if (this.dontShowAfterLogin.includes(route))
        returnData = <RouteComponent />;
      else returnData = <Navigate to={"/login"} />;
    }
    return <div>{returnData}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    authData: state.authReducer.authData,
  };
};

export default connect(mapStateToProps)(PrivateGuard);
