import React from "react";
import { Rating } from "react-simple-star-rating";

const StarRating = ({
  value,
  readOnly,
  onChange,
  onChangeActive,
  onPointerLeave,
}) => {
  return (
    <div
      style={{ pointerEvents: readOnly ? "none" : "auto" }}
      className="MuiRating-root"
    >
      <Rating
        onClick={onChange}
        onPointerMove={onChangeActive}
        onPointerLeave={onPointerLeave}
        readonly={readOnly}
        initialValue={value}
        size={30}
        fillColor={"#e25e52"}
        starGap={5}
        transition
        allowFraction
      />
    </div>
  );
};

export default React.memo(StarRating);
