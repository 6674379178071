import React from "react";
import "../../Styles/subscription.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSubscriptionPackages } from "../../Redux/Actions/subscriptionAction";

class Subscription_Packages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
    };
  }

  componentDidMount() {
    this.fetchSubscriptionPackages();
  }

  fetchSubscriptionPackages = async () => {
    const result = await this.props.getSubscriptionPackages();
    if (result?.data?.data?.length > 0) {
      this.setState({
        packages: result.data.data,
      });
    }
  };

  render() {
    const packageList = this.state.packages.map((i) => {
      const options = i.options ? JSON.parse(i.options) : [];
      return (
        <div key={i.id} className="col-lg-4 col-md-4">
          <div className="subscription-box">
            <div className="subscription-box-head">
              <h4>{i.name}</h4>
              <p>{i.fee_percent}% + Transaction Fee</p>
              <h2>
                ${i.amount}
                <span>/Month</span>
              </h2>
            </div>
            <div className="subscription-box-mid">
              <ul>
                {options.map((option, index) => {
                  return (
                    <li key={index}>
                      <p>{option}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="subscription-box-bottom">
              <button type="button" className="orange-outline-btn">
                get {i.name}
              </button>
              {/* <p>$199 Set Up Cost</p> */}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>subscription packages</h2>
        </div>
        <div className="admin-card-box">
          <div className="subscription">
            <div className="subscription-heading">
              <h2>Start up your Bussiness today</h2>
              <p>Choose a plan that suits you the best.</p>
            </div>

            <div className="subscription-package">
              <div className="row">{packageList}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSubscriptionPackages }, dispatch);
};

export default connect(null, mapDispatchToProps)(Subscription_Packages);
