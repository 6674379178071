import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getPaymentsList = (offset, userId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/${userId}/paymentList?offset=${offset}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const searchPayment = (
  offset,
  searchKeyword,
  userId,
  paymentStatus,
  orderStatus
) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/${userId}/payments/by/search?offset=${offset}&search=${searchKeyword}&paymentStatus=${paymentStatus}&orderStatus=${orderStatus}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const checkSynButtonActivate = (userId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/${userId}/checkPayouts`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const requestPayOuts = (userId) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/${userId}/requestPayouts`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};
