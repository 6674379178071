import React from "react";

export const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{
        stroke: "none",
      }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.62 3.31644C12.8887 3.19514 13.2035 3.24251 13.4246 3.4375L21.6456 10.6875C22.4356 11.3842 22.4356 12.6158 21.6456 13.3125L13.4246 20.5625C13.2035 20.7575 12.8887 20.8049 12.62 20.6836C12.3513 20.5623 12.1786 20.2948 12.1786 20V16.2508C10.2309 16.2572 8.5654 16.3108 7.05331 16.7441C5.44347 17.2054 3.98166 18.1078 2.6 19.95C2.40631 20.2083 2.06909 20.3136 1.76283 20.2115C1.45657 20.1095 1.25 19.8229 1.25 19.5C1.25 14.8813 2.77895 11.9061 5.0667 10.1022C7.1607 8.45108 9.78889 7.86264 12.1786 7.76515V4.00001C12.1786 3.7052 12.3513 3.43773 12.62 3.31644ZM13.6786 5.66142V8.30001C13.6786 8.82341 13.257 9.24533 12.7376 9.25106C10.3761 9.27711 7.88399 9.79097 5.99547 11.2801C4.4679 12.4846 3.25485 14.3913 2.87461 17.4072C4.04191 16.3382 5.29319 15.6881 6.64011 15.3021C8.52958 14.7607 10.5807 14.7505 12.7277 14.75C13.2493 14.7499 13.6786 15.1721 13.6786 15.7V18.3386L20.6535 12.1875C20.7663 12.088 20.7663 11.912 20.6535 11.8125L13.6786 5.66142Z"
       
      />
    </svg>
  );
};
