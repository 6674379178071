// check users and replace with items
// confirm status column value in the list to display

import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
  getActiveSubscriptionCustomerListByProudctId,
  getProductList,
  deleteProduct,
  searchProduct,
  searchProductStatus,
  sendInvitationRequest,
} from "../../Redux/Actions/productAction";
import { Modal } from "react-bootstrap";
import "../../Styles/user-detail.scss";
import Select from "react-select";
import moment from "moment";
import { sendMultipleMessages } from "../../Redux/Actions/messageAction";
import TimePicker from "react-time-picker";
import DatePicker from "react-date-picker";

class Product_List extends Component {
  userId = localStorage.getItem("sellerId");
  sellerData = JSON.parse(localStorage.getItem("sellerData"));
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      subscriberList: [],
      showSubscriberModal: false,
      productSelected: {},
      subscriberPrevOffset: 0,
      subscriberOffset: 0,
      subscriberHasMore: true,
      showScheduleMeetingModal: false,
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingStartTime: "",
      meetingEndTime: "",
      errorData: {},
      showMessageModal: false,
      subscriberMessage: "",
      successmsg: "",
      isSharelinkDisabled: false,
      productStatus: null,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;
        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchProduct(
            this.state.offset,
            localStorage.getItem("sellerId"),
            this.state.searchKeyword.trim()
          );
        } else if (
          this.state.productStatus &&
          this.state.productStatus.length > 0
        ) {
          result = await this.props.searchProductStatus(
            this.state.offset,
            localStorage.getItem("sellerId"),
            this.state.productStatus
          );
        } else {
          result = await this.props.getProductList(
            localStorage.getItem("sellerId"),
            this.state.offset
          );
        }

        // let result = await this.props.getUsersList(this.state.offset);
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();

    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else if (field === "searchKeyword") {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    } else if (field === "productStatus") {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });
      this.debounceSearchCall();
    } else {
      await this.setState({
        [field]: event.target.value,
      });
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  handleViewSubscriptionButton = async (product) => {
    this.setState({
      productSelected: product,
      showSubscriberModal: true,
    });
    const activeSubscriptionList =
      await this.props.getActiveSubscriptionCustomerListByProudctId(
        product.id,
        0
      );
    if (activeSubscriptionList && activeSubscriptionList.data?.length > 0) {
      this.setState((prevState) => {
        return {
          ...prevState,
          subscriberPrevOffset: prevState.subscriberOffset,
          subscriberOffset: prevState.subscriberOffset + 2,
          subscriberList: prevState.subscriberList.concat(
            activeSubscriptionList.data
          ),
        };
      });
    } else {
      this.setState({ subscriberHasMore: false });
    }
    if (activeSubscriptionList && activeSubscriptionList.data?.length <= 4) {
      this.setState({ subscriberHasMore: false });
    }
  };

  deleteConfirmation = async (productId) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete the product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteProducts(productId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  deleteProducts = async (productId) => {
    const res = await this.props.deleteProduct(productId);
    if (res?.status === 200) {
      confirmAlert({
        title: "",
        message: "Product Deleted!",
        buttons: [
          {
            label: "Okay",
          },
        ],
      });
      const result = await this.props.getProductList(
        localStorage.getItem("sellerId"),
        0
      );
      if (result?.data?.data?.length > 0) {
        this.setState({ ...this.state, items: result?.data?.data });
      } else {
        this.setState({ hasMore: false, showLoader: false });
      }
    } else {
      console.error("product not deleted");
    }
  };

  handleModalShowHide = () => {
    this.setState((prevState) => ({
      ...prevState,
      showSubscriberModal: false,
      productSelected: {},
      subscriberList: [],
      showScheduleMeetingModal: false,
      showMessageModal: false,
      subscriberMessage: "",
      errorData: {
        ...prevState.errorData,
        setMeetingError: "",
        meetingDate: "",
      },
      successmsg: "",
      isSharelinkDisabled: false,
    }));
  };

  handleSelectCustomer = (index, event) => {
    let subscriberList = this.state.subscriberList;
    subscriberList.map((customer, i) => {
      if (i === index) {
        customer.isChecked =
          event.target.checked !== undefined
            ? event.target.checked
            : customer.isChecked !== undefined
            ? !customer.isChecked
            : true;
      }
    });
    this.setState({
      subscriberList: [...subscriberList],
    });
  };

  handleSelectAllCustomers = async (event) => {
    let customerList = this.state.subscriberList;
    customerList.forEach((item) => {
      item.isChecked = event.target.checked;
    });
    this.setState({ subscriberList: [...customerList] });
  };

  onLoadMore = async () => {
    if (this.state.subscriberHasMore === true) {
      const activeSubscriptionList =
        await this.props.getActiveSubscriptionCustomerListByProudctId(
          this.state.productSelected.id,
          this.state.subscriberOffset
        );
      if (activeSubscriptionList && activeSubscriptionList.data?.length > 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            subscriberPrevOffset: prevState.subscriberOffset,
            subscriberOffset: prevState.subscriberOffset + 2,
            hasMore: true,
            subscriptionList: prevState.subscriptionList.concat(
              activeSubscriptionList.data
            ),
          };
        });
      } else {
        this.setState({ subscriberHasMore: false });
      }
      if (activeSubscriptionList && activeSubscriptionList.data?.length < 2) {
        this.setState({ subscriberHasMore: false });
      }
    }
  };

  handleSendInvite = async () => {
    this.setState({ isSharelinkDisabled: true });
    this.setState({ showLoader: true });
    const { meetingDate, meetingStartTime, meetingEndTime, errorData } =
      this.state;
    const validStart = new Date(
      meetingDate.split("-")[0],
      meetingDate.split("-")[1] - 1,
      meetingDate.split("-")[2],
      meetingStartTime.split(":")[0],
      meetingStartTime.split(":")[1]
    ).getTime();
    const validEnd = new Date(
      meetingDate.split("-")[0],
      meetingDate.split("-")[1] - 1,
      meetingDate.split("-")[2],
      meetingEndTime.split(":")[0],
      meetingEndTime.split(":")[1]
    ).getTime();
    const currentTime = new Date().getTime();
    if (
      !meetingStartTime ||
      !meetingEndTime ||
      validStart >= validEnd ||
      validStart < currentTime
    ) {
      this.setState({
        errorData: {
          setMeetingError: "Set valid start time and end time",
        },
        showLoader: false,
        isSharelinkDisabled: false,
      });
      return;
    }
    if (errorData?.setMeetingError) {
      this.setState({
        errorData: {
          setMeetingError: "",
        },
      });
    }
    // errorData?.setMeetingError ?
    //     this.setState({
    //         errorData: {
    //             setMeetingError: "",
    //         },
    //     }) : null

    let selectedCustomerList = this.state.subscriberList.filter(
      (item) => item.isChecked === true
    );
    let invitationRequestResponse = await this.props.sendInvitationRequest({
      meetingDate,
      meetingStartTime,
      meetingEndTime,
      seller_id: this.userId,
      productId: this.state.productSelected.id,
      seller_timezone: this.timezone,
      CustomerList: selectedCustomerList,
      meeting_link: "",
      sellerEmail: this.sellerData.email,
    });
    if (
      invitationRequestResponse &&
      invitationRequestResponse.data.isError === false
    ) {
      this.setState({
        successmsg: "Invitation sent successfully!",
        showLoader: false,
      });
      setTimeout(async () => {
        await this.handleModalShowHide();
      }, [2000]);
    } else {
      this.setState({
        errorData: {
          setMeetingError: "Meeting is already scheduled",
        },
        showLoader: false,
      });
    }
    this.setState({
      isSharelinkDisabled: false,
    });
  };

  modal = () => (
    <Modal
      show={this.state.showSubscriberModal}
      onHide={this.handleModalShowHide}
      className="calendar-modal"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
        <p>ACTIVE SUBSCRIBERS</p>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="schedule-show-b active">
              <div className="schedule-check">
                <input
                  type="checkbox"
                  onClick={(event) => this.handleSelectAllCustomers(event)}
                  value="checkedall"
                />
                <p>Customer Name</p>
              </div>
              <p>Email Address</p>
            </div>
            <div className="scroll-infinite">
              {this.state.subscriberList.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={(event) => this.handleSelectCustomer(index, event)}
                    className="schedule-show-b"
                  >
                    <div className="schedule-check">
                      <input
                        onClick={(event) =>
                          this.handleSelectCustomer(index, event)
                        }
                        type="checkbox"
                        checked={item.isChecked}
                      />{" "}
                      <p>
                        {item.firstname} {item.lastname}
                      </p>
                    </div>
                    <p>{item.email}</p>
                  </div>
                );
              })}
            </div>
            {this.state.subscriberHasMore === true && (
              <div className="text-right">
                <a
                  href="javascript:;"
                  className="load-button"
                  onClick={() => this.onLoadMore()}
                >
                  Load more
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="button-center">
          <button
            disabled={
              this.state.subscriberList.filter((i) => i.isChecked).length === 0
            }
            onClick={() => {
              this.setState({
                showSubscriberModal: false,
                showMessageModal: true,
              });
            }}
            className={
              this.state.subscriberList.filter((i) => i.isChecked).length === 0
                ? "orange-btn  disable-btn"
                : "orange-btn"
            }
          >
            Direct Message
          </button>
          <button
            disabled={
              this.state.subscriberList.filter((i) => i.isChecked).length === 0
            }
            onClick={() => {
              this.setState({
                showSubscriberModal: false,
                showScheduleMeetingModal: true,
              });
            }}
            className={
              this.state.subscriberList.filter((i) => i.isChecked).length === 0
                ? "orange-btn  disable-btn"
                : "orange-btn"
            }
          >
            schedule meeting
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  scheduleMeetingModal = () => (
    <Modal
      show={this.state.showScheduleMeetingModal}
      onHide={() => this.handleModalShowHide("scheduleMeeting")}
      className="calendar-modal"
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        onClick={() => this.handleModalShowHide("scheduleMeeting")}
      >
        <p>
          <strong>SET MEETING</strong>
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <>
            <div className="col-md-12">
              {this.state.successmsg.length !== 0 && (
                <div className="success-msg">
                  <p>{this.state.successmsg}</p>
                </div>
              )}
              <span className="form-field-error">
                {this.state.errorData.meetingDate}
                {this.state.errorData.setMeetingError}
              </span>
              <div className="form-group">
                <label>
                  <b>DATE</b>
                </label>
                {/* <input
                                    type="date"
                                    // name="date"
                                    required
                                    format="yyyy-mm-dd"
                                    value={this.state.meetingDate}
                                    onChange={(e) => this.handleChangeMeetingDetails(e, "meetingDate")}
                                    className="form-control"
                                />*/}
                <DatePicker
                  name="date"
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  required
                  clearIcon={null}
                  onChange={(e) =>
                    this.handleChangeMeetingDetails(e, "meetingDate")
                  }
                  className="form-control"
                  value={new Date(this.state.meetingDate)}
                />
                <span className="form-field-error">
                  {this.state.errorData.meetingDateError}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>START TIME</b>
                </label>
                {/*<input
                                    type="time"
                                    name="start"
                                    min="09:00"
                                    max="18:00"
                                    required
                                    value={this.state.meetingStartTime}
                                    onChange={(e) => this.handleChangeMeetingDetails(e, "meetingStartTime")}
                                    className="form-control"
                                />*/}
                <TimePicker
                  value={this.state.meetingStartTime}
                  format="hh:mm a"
                  name="start"
                  disableClock={true}
                  onChange={(e) =>
                    this.handleChangeMeetingDetails(e, "meetingStartTime")
                  }
                  required={true}
                  clearIcon={null}
                  className="form-control"
                />
                <span className="clock-icon">
                  <i class="far fa-clock"></i>
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>END TIME</b>
                </label>
                <TimePicker
                  value={this.state.meetingEndTime}
                  format="hh:mm a"
                  name="start"
                  disableClock={true}
                  onChange={(e) =>
                    this.handleChangeMeetingDetails(e, "meetingEndTime")
                  }
                  required={true}
                  clearIcon={null}
                  className="form-control"
                />
                <span className="clock-icon">
                  <i class="far fa-clock"></i>
                </span>
              </div>
            </div>
          </>
        </div>
        <div className="button-center">
          <button onClick={() => this.handleBack()} className="orange-btn">
            Back
          </button>
          <button
            onClick={() => this.handleSendInvite()}
            className="orange-btn"
            disabled={this.state.isSharelinkDisabled}
          >
            Share Link
            {this.state.showLoader ? (
              <div className="spinner-border spinner-border-sm ml-1"></div>
            ) : null}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  removeSubscriber = (index) => {
    let subscriberList = this.state.subscriberList;
    let isMessageModalOpen = true;
    subscriberList.map((subscriber, i) => {
      if (i === index) {
        subscriber.isChecked = false;
      }
    });
    if (subscriberList.filter((s) => s.isChecked).length === 0) {
      isMessageModalOpen = false;
    }
    this.setState({
      subscriberList: [...subscriberList],
      showMessageModal: isMessageModalOpen,
      showSubscriberModal: !isMessageModalOpen,
    });
  };

  messageModal = () => {
    let addedSubscriberNameList = [];
    this.state.subscriberList.map((i) => {
      if (i.isChecked) {
        addedSubscriberNameList.push(`${i.firstname} ${i.lastname}`);
      }
    });
    return (
      <Modal
        show={this.state.showMessageModal}
        onHide={() => this.handleModalShowHide()}
        className="calendar-modal"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
          <p>
            <strong>MESSAGING</strong>
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <>
              <div className="col-md-12">
                <span className="form-field-error">
                  {this.state.errorData.setMeetingError}
                </span>
                <div className="message-popup form-group">
                  <label>Receivers</label>
                  <ul>
                    {this.state.subscriberList.map((subscriber, index) => {
                      if (!subscriber.isChecked) {
                        return null;
                      } else {
                        return (
                          <li>
                            {subscriber.firstname} {subscriber.lastname}
                            <span>
                              <i
                                className="fas fa-times"
                                onClick={() => this.removeSubscriber(index)}
                              />
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                  {/*</div>*/}

                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      placeholder="Your text here..."
                      value={this.state.subscriberMessage}
                      onChange={(e) =>
                        this.handleChange(e, "subscriberMessage")
                      }
                      rows="5"
                      className="form-control"
                    />
                  </div>
                  <button className="orange-btn" onClick={this.sendMessage}>
                    <span>
                      <i className="far fa-paper-plane" />
                    </span>{" "}
                    Send
                  </button>
                </div>
              </div>
            </>
          </div>
          <div className="button-center"></div>
        </Modal.Body>
      </Modal>
    );
  };

  sendMessage = async () => {
    let receiverList = this.state.subscriberList.filter((s) => s.isChecked);
    if (
      this.state.subscriberMessage.trim().length === 0 ||
      receiverList.length === 0
    ) {
      return null;
    }
    receiverList = receiverList.map((i) => i.id);
    const response = await this.props.sendMultipleMessages({
      message: this.state.subscriberMessage,
      buyerIdList: receiverList,
      senderType: "seller",
      sellerId: this.userId,
      productId: this.state.productSelected.id,
    });
    if (!response.data.data.isError) {
      this.handleModalShowHide();
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorData: {
          ...prevState.errorData,
          setMeetingError: "Something went wrong!",
        },
      }));
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      ...prevState,
      showScheduleMeetingModal: false,
      showSubscriberModal: true,
      errorData: {
        ...prevState.errorData,
        setMeetingError: "",
        meetingDate: "",
      },
    }));
  };

  handleChangeMeetingDetails = (e, key) => {
    if (key === "meetingDate") {
      e = moment(e).format("YYYY-MM-DD");
      if (new Date(e).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
        // alert('Please enter future date.')
        this.setState({
          errorData: {
            meetingDateError: "Please enter future date.",
          },
        });
      } else {
        this.setState({
          errorData: {
            meetingDate: "",
          },
        });
      }
    }
    this.setState({
      [key]: e,
    });
  };

  stripeConnectAlert = (message) => {
    confirmAlert({
      title: "",
      message: message,
      buttons: [
        {
          label: "Okay",
        },
      ],
    });
  };

  getProductAlertView() {
    let message = "";
    const { userInfo } = this.props;
    const isStripeConnected = JSON.parse(
      localStorage.getItem("sellerData")
    ).stripe_details_submitted;
    if (
      !isStripeConnected ||
      !userInfo?.phonenumber ||
      userInfo?.phonenumber === "null" ||
      !userInfo?.storename ||
      userInfo?.storename === "null"
    ) {
      message = "Please Complete Your Profile!";
    }

    if (message !== "") {
      return (
        <button
          onClick={() => this.stripeConnectAlert(message)}
          type="text"
          className="orange-outline-btn"
        >
          <i className="fas fa-plus"></i> Add Product
        </button>
      );
    }
    return (
      <Link to={"/add-product"}>
        <button type="text" className="orange-outline-btn">
          <i className="fas fa-plus"></i> Add Product
        </button>
      </Link>
    );
  }

  render() {
    const itemsList = this.state.items.map((i) => {
      let viewProductPath = `${process.env.REACT_APP_HOME_URL}/product/${i.slug}`;
      viewProductPath =
        (i.status && i.status == "pending") || i.isActive !== 1
          ? `${viewProductPath}?preview`
          : viewProductPath;
      return (
        <tr key={i.id}>
          <td>{i.id ? i.id : "-"}</td>
          <td className="productItemName">{i.name ? i.name : "-"}</td>
          <td>{i.price ? `$${i.price}` : "-"}</td>

          <td>{i.isActive === 1 ? "Yes" : "No"}</td>
          <td>
            <p className="orange">
              {i.status && i.status !== "" ? i.status : "-"}
            </p>
          </td>
          <td align="center">
            <div className="vendor-table-btn justify-content-center">
              <Link to={viewProductPath} target="_blank">
                <button type="button" className="orange-outline-btn">
                  View
                </button>
              </Link>

              <Link to={`/edit-product/${i.slug}`}>
                <button type="button" className="orange-outline-btn">
                  Edit
                </button>
              </Link>
            </div>
          </td>
          <td align="center">
            <div className="vendor-table-btn justify-content-center">
              <button
                type="button"
                className="orange-outline-btn"
                disabled={
                  this.state.showSubscriberModal ||
                  this.state.showScheduleMeetingModal ||
                  this.state.showMessageModal
                }
                onClick={() => this.handleViewSubscriptionButton(i)}
              >
                View
              </button>
            </div>
          </td>
          <td align="center">
            <div className="vendor-table-btn justify-content-center">
              <Link
                to={{ pathname: `/reviews/product/${i.id}`, state: i.name }}
              >
                <button type="button" className="orange-outline-btn">
                  View
                </button>
              </Link>
            </div>
          </td>
          <td align="center">
            <div className="vendor-table-btn justify-content-center">
              <button
                type="button"
                className="orange-outline-btn"
                onClick={() => this.deleteConfirmation(i.id)}
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      );
    });

    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>Product list</h2>
          {this.getProductAlertView()}
        </div>
        <div className="admin-card-box">
          <div className="vendor-table">
            <div className="vendor-table-head">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="entries-drop">
                    <label>show</label>
                    <select
                      className="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="vendor-table-head-right">
                    <div className="entries-drop">
                      <label>Product Status</label>
                      <select
                        className="entries-controls"
                        onChange={(e) => this.handleChange(e, "productStatus")}
                        value={this.state.productStatus}
                      >
                        <option value="">All</option>
                        <option value="pending">PENDING</option>
                        <option value="approved">APPROVED</option>
                        <option value="denied">DENIED</option>
                      </select>
                    </div>
                    <div className="vendor-table-search">
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table-body">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Product ID</td>
                        <td>title</td>
                        <td>price</td>
                        {/* <td>sales</td> */}
                        <td>active</td>
                        <td>status</td>
                        <td align="center">product</td>
                        <td align="center">subscribers</td>
                        <td align="center">comments</td>
                        <td align="center">Delete</td>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsList?.length ? (
                        itemsList
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            <div className="text-center">
                              <p className="mb-0">No Product Found</p>
                            </div>
                          </td>
                        </tr>
                      )}

                      {/* <tr>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum dolor</td>
                                            <td>$499.00</td>
                                            <td>1</td>
                                            <td>
                                                <p className="orange">pending</p>
                                            </td>
                                            <td align="center">
                                                <div className="vendor-table-btn">
                                                    <button type="button" className="orange-outline-btn">View</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center">5420</td>
                                            <td>Loren ipsum dolor</td>
                                            <td>$499.00</td>
                                            <td>1</td>
                                            <td>
                                                <p className="black">published</p>
                                            </td>
                                            <td align="center">
                                                <div className="vendor-table-btn">
                                                    <button type="button" className="orange-outline-btn">View</button>
                                                </div>
                                            </td>
                                        </tr> */}
                      {this.state.showLoader ? (
                        <tr>
                          <td colSpan={9} className="text-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
            {this.modal()}
            {this.scheduleMeetingModal()}
            {this.messageModal()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductList,
      deleteProduct,
      searchProduct,
      searchProductStatus,
      getActiveSubscriptionCustomerListByProudctId,
      sendInvitationRequest,
      sendMultipleMessages,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Product_List);
