import React from "react";

const CommonButton = (props) => {
  const { value, type, loading, buttonClass,  ...extraProps } = props;

  return (
    <>
      <button type={type} className={buttonClass} {...extraProps}>
        {loading ? <span className="spinner-border spinner-border-sm ml-1"></span> : value}
      </button>
    </>
  );
};

export default React.memo(CommonButton);
