import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getSubscriptionPackages = () => async (dispatch) => {
    try {
        dispatch({ type: "LOADING_STARTED" });
        const res = await axios({
            method: 'get',
            url: `${URL}/api/v1/seller/subscription/list`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        dispatch({ type: "LOADING_COMPLETED" });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
    }
}