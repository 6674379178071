export const initialState = {
  userInfo: null,
  userData: {},
  isUserAdded: "",
  isDeleted: "",
  isUserUpdated: "",
  isUpdated: "",
  userDetails: [],
  unreadMessageCount: 0,
  pendingReturnRequestCount:0,
  unreadDisputeCount: 0,
  unreadOMeetingCount: 0,
  storeSetupMessage: false,
  unreadReviewsCount: 0
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SETUSERINFO":
      localStorage.setItem('sellerData', JSON.stringify(action.payload))
      return {
        ...state,
        userInfo: action.payload,
        storeSetupMessage: action.payload.storeSetupMessage
      }
    case "GETUSERBYCOMPANY":
      return {
        ...state,
        userData: action.payload.data.data,
      };
    case "ADDUSERS":
      return {
        ...state,
        isUserAdded: action.payload.data.message,
      };
    case "DELETEUSERS":
      return {
        ...state,
        isUserDeleted: action.payload.data.message,
      };
    case "UPDATEUSERS":
      return {
        ...state,
        isUserUpdated: action.payload.data.message,
      };
    case "GETUSERDETAILS":
      return {
        ...state,
        userDetails: action.payload.data.data,
      };

    case 'UNREAD_MESSAGE_COUNT':
      return {
        ...state,
        unreadMessageCount: action.payload.data.data.count
      }
    case 'PENDING_RETURN_REQUEST_COUNT':
      return {
        ...state,
        pendingReturnRequestCount: action.payload.data.data.count
      }
    case 'UNREAD_DISPUTE_COUNT':
      return {
        ...state,
        unreadDisputeCount: action.payload.data.count
      }
    case 'UNREAD_0NE_ON_ONE_MEETING_COUNT':
      return {
        ...state,
        unreadOMeetingCount: action.payload.data.count
      }
    case 'UNREAD_REVIEW_COUNT':
      return {
        ...state,
        unreadReviewsCount: action.payload.data.data.count
      }
    case "LOGOUT":
      return {
        ...state,
        userInfo: null,
        storeSetupMessage: false
      }
    default:
      return state;
  }
};
