export const initialState = {
    images: [],
    errorImages: [],
    documents: [],
    errorDocuments: [],
    videos: [],
    errorVideos: [],
    acsCatVideos: [],
    errorAcsCatVideos: [],
    acsCatAudios: [],
    errorAcsCatAudios: [],
    acsCatImages: [],
    errorAcsCatImages: [],
    acsCatDocuments: [],
    errorAcsCatDocuments: []
};

export const fileUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case "IMAGES":
            return {
                ...state,
                images: action.payload.files
            }
        case "ADD_IMAGES":
            return {
                ...state,
                images: [...state.images, ...action.payload.files]
            }
        case "ERROR_IMAGES":
            return {
                ...state,
                errorImages: action.payload.errors
            }
        case "DOCUMENTS":
            return {
                ...state,
                documents: action.payload.files
            }
        case "ADD_DOCUMENTS":
            return {
                ...state,
                documents: [...state.documents, ...action.payload.files]
            }
        case "ERROR_DOCUMENTS":
            return {
                ...state,
                errorDocuments: action.payload.errors
            }
        case "VIDEOS":
            return {
                ...state,
                videos: [...action.payload.files]
            }
        case "ADD_VIDEOS":
            return {
                ...state,
                videos: [...state.videos, ...action.payload.files]
            }
        case "ERROR_VIDEOS":
            return {
                ...state,
                errorVideos: action.payload.errors
            }
        case "ACS_CAT_VIDEOS":
            return {
                ...state,
                acsCatVideos: [...action.payload.files]
            }
        case "ADD_ACS_CAT_VIDEOS":
            return {
                ...state,
                acsCatVideos: [...state.acsCatVideos, ...action.payload.files]
            }
        case "ERROR_ACS_CAT_VIDEOS":
            return {
                ...state,
                errorAcsCatVideos: action.payload.errors
            }
        case "ACS_CAT_AUDIOS":
            return {
                ...state,
                acsCatAudios: [...action.payload.files]
            }
        case "ADD_ACS_CAT_AUDIOS":
            return {
                ...state,
                acsCatAudios: [...state.acsCatAudios, ...action.payload.files]
            }
        case "ERROR_ACS_CAT_AUDIOS":
            return {
                ...state,
                errorAcsCatAudios: action.payload.errors
            }
        case "ACS_CAT_IMAGES":
            return {
                ...state,
                acsCatImages: action.payload.files
            }
        case "ADD_ACS_CAT_IMAGES":
            return {
                ...state,
                acsCatImages: [...state.acsCatImages, ...action.payload.files]
            }
        case "ERROR_ACS_CAT_IMAGES":
            return {
                ...state,
                errorAcsCatImages: action.payload.errors
            }
        case "ACS_CAT_DOCUMENTS":
            return {
                ...state,
                acsCatDocuments: action.payload.files
            }
        case "ADD_ACS_CAT_DOCUMENTS":
            return {
                ...state,
                acsCatDocuments: [...state.acsCatDocuments, ...action.payload.files]
            }
        case "ERROR_ACS_CAT_DOCUMENTS":
            return {
                ...state,
                errorAcsCatDocuments: action.payload.errors
            }
        case "UPDATE_FILE":
            let tempStatePer = state;
            let files = tempStatePer[action.payload.type];
            let file = files[action.payload.index];
            file[action.payload.key] = action.payload.value;
            return {
                ...tempStatePer,
            }
        default:
            return state
    }
}