import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getReviewsList = (sellerId, offset,productId,limit = 10) => async (
    dispatch
  ) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/reviews/${sellerId}?offset=${offset}&limit=${limit}${productId?`&productId=${productId}`: ''}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

  export const searchReviews = (sellerId, offset, searchKeyword, productId) => async (
    dispatch
  ) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/reviews/by/search-reviews/${sellerId}?offset=${offset}&search=${searchKeyword}${productId?`&productId=${productId}`: ''}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };


  export const getReviewDetailByOrderId = (orderId) => async (dispatch) => {
    try {
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/reviews/get-review/${orderId}`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        return res
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const updateProductRating = (orderId, data) => async (dispatch) => {
  try {
      const res = await axios({
          method: "post",
          url: `${URL}/api/v1/seller/reviews/update/${orderId}`,
          data,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
          }
      });
      return res;
  }
  catch (error) {
      dispatch(handleError(error));
  }
}

export const getReviewCountBySellerId = (sellerId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/reviews/count/${sellerId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    dispatch({
      type: "UNREAD_REVIEW_COUNT",
      payload: { data: res.data },
  })
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
 