import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import {
  requestRefund,
  openDispute,
  createDisputeMessage,
  getDisputeConversationById,
  getDisputeDetailByOrderId,
} from "../../Redux/Actions/disputeAction";
import { getOrderDetail } from "../../Redux/Actions/orderAction";
import moment from "moment";
import {
  convertTime,
  fileFormats,
  getFileNameFromUrl,
  getFileSizeByByteLength,
  getShortName,
  getUrlByString,
} from "../../Utils/helper";
import "../../Styles/profile.scss";
import AlertModal from "../../Components/AlertModal/AlertModal";

const Dispute_Detail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const orderId = params?.disputeId || '';
  const [isDisputeRaised, setIsDisputeRaised] = useState(false);
  const [disputeState, setDisputeState] = useState({});
  const [orderDetail, setOrderDetail] = useState({});
  const [show, setShow] = useState(false);
  const keepVerifyingRef = useRef(true);
  const [files, setFiles] = useState([]);
  const [fileFormat, setFileFormat] = useState([]);
  const [message, setMessage] = useState("");
  const [messageSubmitButton, setMessageSubmitButton] = useState(false);
  const [messageDetails, setMessageDetails] = useState([]);
  const [offset, setOffSet] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [closeDisputeState, setCloseDisputeState] = useState({ open: false });
  const [responseMsg, setResponseMsgState] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    singleButton: true,
  });
  const navigate = useNavigate();

  let clearLoadMore;
  let allowScroll = true;
  let fileUploadVideo = "";
  let fileUploadImage = "";
  let fileUploadDoc = "";
  let fileUploadAddMore = "";
  let cleartime;

  useEffect(() => {
    fetchOrderDetail();
    fetchDisputeData();
  }, []);

  const fetchOrderDetail = async () => {
    const response = await dispatch(getOrderDetail(orderId));
    if (response?.data?.data) {
      setOrderDetail(response.data.data);
    }
  };

  const fetchDisputeData = async () => {
    const response = await dispatch(getDisputeDetailByOrderId(orderId));
    const isAlreadyDisputeRaised = !!response?.data?.id;
    setIsDisputeRaised(isAlreadyDisputeRaised);
    if (isAlreadyDisputeRaised) {
      setDisputeState(response.data);
      if (
        response.data.status === "open" ||
        response.data.status === "closed"
      ) {
        const messageResponse = await dispatch(
          getDisputeConversationById(response.data.id, offset)
        );
        setMessageDetails(messageResponse?.data);
        scrollItem();
      }
    }
  };

  const getFileName = (url) => {
    let name = url.split("/");
    return name[4] + ".jpg";
  };

  const download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };

  const OrderDetail = () => {
    let variationKey = [];
    let variationValue = [];
    if (orderDetail.variant_values) {
      const variants = JSON.parse(orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      orderDetail.items && orderDetail.items.length > 0 ? (
        orderDetail.items.map((i, index) => {
          return (
            <div key={i.id} className="cart-box w-100">
              <div className="cart-box-detail w-100">
                <div className="cart-box-img">
                  <img src={i.imageName} alt="" />
                </div>
                <div className="cart-box-item-detail w-100">
                  <h2>{i.name}</h2>
                  <p>
                    <strong>Frequency: </strong>
                    <span>
                      {orderDetail.order_type === "one_time"
                        ? "one time"
                        : orderDetail.subscription_timeframe}
                    </span>
                  </p>
                  {i.customizationNote && i.customizationNote.length !== 0 && (
                    <p>
                      <strong>Customization Note:</strong>
                      <span className="item-custom-desc">
                        {i.customizationNote}
                      </span>
                    </p>
                  )}
                  {i.customizationImage && i.customizationImage.length !== 0 && (
                    <p className="d-flex align-items-center justify-content-between">
                      <div>
                        <strong>Customization Image:</strong>
                        <span>{getFileName(i.customizationImage)}</span>
                      </div>
                      <span>
                        <a href={i.customizationImage} target="_blank">
                          View
                        </a>
                        <a onClick={() => download(i.customizationImage)}>
                          Download
                        </a>
                      </span>
                    </p>
                  )}
                  {variationKey.length > 0 && (
                    <p>
                      <strong>Variation:- </strong> <br />
                      {variationKey.map((key, index) => (
                        <div className="margin-variation">
                          <strong>{key}: </strong>{" "}
                          <span>{variationValue[index]}</span>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );
    return orderItems;
  };

  let showData = 0;
  const setValue = (timestamp) => {
    let unixTimeStamp = new Date(timestamp).getTime();
    let message = new Date(Math.ceil(unixTimeStamp));
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      if (showData === 1) {
        showData = 2;
      }
      if (showData === 0) {
        showData = 1;
      }
    }
  };

  const getFileSizeByUrl = (item) => {
    const tempMessageDetails = messageDetails;
    const messageDetailMap = tempMessageDetails?.find(
      (map) => map.id === item.messagesId
    );
    const messageAttachmentMap = messageDetailMap?.messageAttachmentList?.find(
      (map) => map.id === item.id
    );

    if (messageAttachmentMap) {
      // start the fetch
      fetch(item.attachmentName, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          // get total length
          const contentLength = +response.headers.get("Content-Length");

          const contentLengthSize = getFileSizeByByteLength(contentLength);
          messageAttachmentMap["contentLength"] = contentLengthSize;
          setMessageDetails([...tempMessageDetails]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const downloadFileByUrl = (e, item) => {
    keepVerifyingRef.current = true;
    const fileName = getFileNameFromUrl(item.attachmentName, item.type);
    const tempMessageDetails = messageDetails;
    const messageDetailMap = tempMessageDetails?.find(
      (map) => map.id === item.messagesId
    );
    const messageConversationId = messageDetailMap?.message_conversation_id;
    const messageAttachmentMap = messageDetailMap?.messageAttachmentList?.find(
      (map) => map.id === item.id
    );

    if (messageAttachmentMap) {
      // start the fetch
      fetch(item.attachmentName, {
        method: "GET",
        headers: {},
      })
        .then(async (response) => {
          // obtain a reader
          const reader = response.body.getReader();

          // get total length
          const contentLength = +response.headers.get("Content-Length");
          // read the data
          let receivedLength = 0; // received that many bytes at the moment
          let chunks = []; // array of received binary chunks (comprises the body)
          while (true) {
            if (!keepVerifyingRef.current) {
              break;
            }

            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            // if (copyactiveMsgConversationId !== messageConversationId) {
            //   break;
            // }
            chunks.push(value);
            receivedLength += value.length;
            const receivedFileSize = getFileSizeByByteLength(receivedLength);
            messageAttachmentMap["receivedLength"] = receivedFileSize;
            messageAttachmentMap["receivedLengthPercent"] = Math.round(
              (receivedLength / contentLength) * 100
            );
            setMessageDetails([...tempMessageDetails]);
          }

          // concatenate chunks into single Uint8Array
          let chunksAll = new Uint8Array(receivedLength);
          let position = 0;
          for (let chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
          }

          // download file with chunksAll
          const url = window.URL.createObjectURL(new Blob([chunksAll]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleScroll = (e) => {
    let element = e.target;
    if (clearLoadMore) {
      clearTimeout(clearLoadMore);
    }
    if (
      element.scrollTop <=
        (2 * (element.clientHeight + element.scrollHeight)) / 100 &&
      allowScroll
    ) {
      // do something at end of scroll
      clearLoadMore = setTimeout(() => {
        allowScroll = false;
        loadMore();
      }, 1000);
    }
  };

  const scrollAfterLoadMore = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      myDiv.scrollTop = myDiv.clientHeight * 0.8;
    }
    allowScroll = true;
  };

  const loadMore = async () => {
    if (loadingData) {
      let res = await dispatch(
        getDisputeConversationById(disputeState.id, offset ? offset : 10)
      );
      if (res && res.length > 0) {
        setMessageDetails([...messageDetails, ...res]);
        setOffSet(offset + 10);
        scrollAfterLoadMore();
        if (res.length < 10) {
          setLoadingData(false);
          setOffSet(0);
        }
      }
    } else {
      allowScroll = false;
    }
  };

  const handleSubmit = async (e) => {
    const data = {
      sender_id: localStorage.getItem("sellerId"),
      source_type: "seller",
      message,
      files,
    };
    let isValidFormat = 0;
    let totalMediaSize = 0;
    data.files.length && data.files.map((i) => {
      totalMediaSize += i.size
      if (!fileFormat.includes(i?.type.split("/")[1])){
        isValidFormat+=1;
      }
    })
    if(totalMediaSize > 19000000 && !isValidFormat){
      setErrorMessage({sizeError : "You can not upload more then 20 mb size"})
      setMessageSubmitButton(false);
    } else {
      e.preventDefault();
      if (!(message.trim().length || files.length > 0)) {
        return null;
      }
      setMessageSubmitButton(true);
      setErrorMessage("");
      let res = await dispatch(createDisputeMessage(disputeState.id, data));
      if (res?.data && res.data?.messageId) {
        setMessage("");
        // setPreviousMessageListOffSet(0)
        // setMessageListOffSet(0)
        setFiles([]);
        scrollItem();
        setLoadingData(true);
        setMessageSubmitButton(false);
        const messageResponse = await dispatch(
          getDisputeConversationById(disputeState.id, 0)
        );
        setMessageDetails(messageResponse?.data);
      }
    }
  };

  const scrollItem = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      if (cleartime) {
        clearTimeout(cleartime);
      }
      if (clearLoadMore) {
        clearTimeout(clearLoadMore);
      }
      myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollHeight * 0.8;
      allowScroll = false;
      cleartime = setTimeout(() => {
        if (clearLoadMore) {
          clearTimeout(clearLoadMore);
        }
        myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollTop;
        allowScroll = true;
      }, 1000);
    }
  };

  const fileSelectHandler = (e) => {
    e.preventDefault();
    setShow(false);
    fileUploadHandler(e.target.files, false);
  };

  const fileUploadHandler = (fileList, addNew) => {
    const errors = [];
    const fileArray = [];

    for (const file of fileList) {
      //read file stream and set in state
      function readFile(file) {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          fileArray.push({
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            stream: event.currentTarget.result,
          });
          if (addNew) {
            setFiles([...files, ...fileArray]);
          } else {
            setFiles([...fileArray]);
          }
        };
        fileReader.readAsDataURL(file);
      }
      let imgType = file?.type?.split("/");
      if (
        fileFormats.video.includes(imgType[1]) ||
        fileFormats.image.includes(imgType[1]) ||
        fileFormats.doc.includes(imgType[1])
      ) {
        if (
          !files?.find(
            (item) => item.name === file.name && item.type === file.type
          )
        ) {
          readFile(file);
        }
      } else {
        errors.push("File [" + file.name + "] Format is not valid.");
      }
    }
  };

  const fileUploadVideoClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.video);
    fileUploadVideo.click();
    return false;
  };
  const fileUploadImageClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.image);
    fileUploadImage.click();
    return false;
  };
  const fileUploadDocClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.doc);
    fileUploadDoc.click();
    return false;
  };
  const fileUploadAddMoreClick = (e) => {
    e.preventDefault();
    fileUploadAddMore.click();
    return false;
  };
  const addMoreFiles = (e) => {
    e.preventDefault();
    fileUploadHandler(e.target.files, true);
    return false;
  };

  const handleOpenDispute = async () => {
    await dispatch(openDispute(disputeState.id));
    fetchDisputeData();
  };

  const deleteSelectedFile = (e, index) => {
    e.preventDefault();
    files.splice(index, 1);
    setFiles([...files]);
  };

  const onSubmit = () => {
    let message = "suggest no refund to the customer?";
    if (closeDisputeState.isAddRefund) {
      message = `suggest for  ${parseInt(
        closeDisputeState.ratio.customer
      )}% refund to the customer for this order`;
    }
    setAlertState({
      open: true,
      message: `Are you sure you want to ${message}?`,
      singleButton: true,
    });
  };
  const handleCloseDispute = async () => {
    setCloseDisputeState({
      open: true,
      isAddRefund: false,
      ratio: {
        vendor: 100,
        customer: 0,
      },
    });
    fetchDisputeData();
  };

  const calculateAmount = (ratio) => {
    if (!ratio) {
      return 0;
    }
    const refundableAmount = parseFloat(disputeState?.refundableAmount);
    return parseFloat((refundableAmount * ratio) / 100).toFixed(2);
  };

  const closeAlertModal = () => {
    setAlertState({
      open: false,
      message: "",
      singleButton: true,
      request: "",
    });
  };

  const handleAlertResponse = () => {
    handleCloseSubmit();
  };

  const handleCloseSubmit = async () => {
    setResponseMsgState({});
    setShowLoader(true);
    const result = await dispatch(
      requestRefund(disputeState.id, closeDisputeState)
    );
    const response = result.data;
    if (response.isError) {
      setCloseDisputeState({ open: false });
      setResponseMsgState({ error: response?.message });
    } else {
      setResponseMsgState({ success: response?.message });
    }
    setShowLoader(false);
    setTimeout(() => setResponseMsgState({}), 2000);
    window.scrollTo(0, 0);
    await fetchDisputeData();
    navigate("/disputes");
  };

  const PendingDispute = () => (
    <div>
      <p>
        <strong>Order Number : </strong>
        {disputeState.orderNumber
          ? disputeState.orderNumber
          : disputeState?.order_id}
      </p>
      <p>
        <strong>Dispute Description : </strong>
        {disputeState?.comment}
      </p>
      <p>
        <strong>Status : </strong>
        {disputeState?.status}
      </p>
      <p>
        <strong>Raised On : </strong>
        {disputeState.disputeDate
          ? new Date(disputeState.disputeDate).toISOString().substring(0, 10)
          : "-"}
      </p>
      <p>
        <strong>Order Amount : </strong>$
        {parseFloat(disputeState?.orderAmount).toFixed(2)}
      </p>
    </div>
  );

  const handleChange = async (event, key) => {
    let { value } = event.target;
    if (key === "isAddRefund") {
      value = value === "false" ? false : true;
      setCloseDisputeState((prevState) => ({ ...prevState, [key]: value }));
    } else if (key === "customer" || key === "vendor") {
      if (isNaN(parseInt(value))) {
        value = 0;
      }
      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
      let ratio = {
        customer: key === "customer" ? value : 100 - value,
        vendor: key === "vendor" ? value : 100 - value,
      };
      setCloseDisputeState((prevState) => ({
        ...prevState,
        ratio: { ...ratio },
      }));
    } else {
      setDisputeState((prevState) => ({ ...prevState, [key]: value }));
    }
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>Dispute</h2>
      </div>
      <div className="admin-card-box">
        {!!responseMsg.success && (
          <div className="alert alert-success">{responseMsg.success}</div>
        )}
        {!!responseMsg.error && (
          <div className="alert alert-danger">{responseMsg.error}</div>
        )}
        <div>
          <OrderDetail />
          {disputeState.status === "pending" && <PendingDispute />}

          {disputeState.status === "pending" && (
            <div>
              <button onClick={handleOpenDispute}>Open Dispute</button>
            </div>
          )}

          {disputeState.status === "open" && (
            <div>
              <p>
                <strong>Order Number : </strong>
                {disputeState.orderNumber
                  ? disputeState.orderNumber
                  : disputeState?.order_id}
              </p>
              <p>
                <strong>Dispute Description : </strong>
                {disputeState?.comment}
              </p>
              <p>
                <strong>Status : </strong>
                {disputeState?.status}
              </p>
              <p>
                <strong>Raised On : </strong>
                {disputeState.disputeDate
                  ? new Date(disputeState.disputeDate)
                      .toISOString()
                      .substring(0, 10)
                  : "-"}
              </p>
              <p>
                <strong>Order Amount : </strong>$
                {parseFloat(disputeState?.orderAmount).toFixed(2)}
              </p>
              {disputeState.reported_by === "customer" ? (
                <p className="note-admin-p">
                  <span>
                    Note<sup>*</sup>
                  </span>
                  Customer has reported this dispute to Subsciety admin.
                </p>
              ) : null}
              {/* {
disputeState.vendor_suggested_refund !== null && disputeState.vendor_suggested_refund !== undefined && (<p>{`You have requested Subsciety for ${disputeState.vendor_suggested_refund}% refund to this customer for this order.`}</p>)
} */}
              {!!disputeState.vendor_suggested_refund && (
                <p className="note-admin-p">
                  <span>
                    Note<sup>*</sup>
                  </span>
                  {`You have requested Subsciety for ${disputeState.vendor_suggested_refund}% refund to this customer for this order.`}
                </p>
              )}
              {disputeState.vendor_suggested_refund === 0 && (
                <p className="note-admin-p">
                  <span>
                    Note<sup>*</sup>
                  </span>{" "}
                  {`You have suggested Admin not to refund this order`}
                </p>
              )}

              {
                // (messageDetails?.length > 0) &&

                <div className="row">
                  <div className="col-md-6">
                    <div className="messages-box">
                      <div className="message-desc-chat desc-chat-custom">
                        <div
                          className="message-chat-date"
                          id="hey1"
                          onScroll={handleScroll}
                        >
                          <ul className="chat-desc-sub">
                            {messageDetails
                              .sort((a, b) => a.id - b.id)
                              .map((message, index) => (
                                <li key={index} className="differ-username">
                                  <div>
                                    {setValue(message.created_ts)}
                                    {showData === 1 ? (
                                      <div className="message-date">
                                        <p>Today</p>
                                      </div>
                                    ) : null}
                                  </div>
                                  {message.source_type == "seller" ? (
                                    <div className="flex-image-vendorside">
                                      <div className="username-short username-short-sub">
                                        {getShortName(message.sender_name)}
                                      </div>
                                      <div className="username-desc">
                                        <div className="time-add-sub">
                                          <h6>{message.sender_name}</h6>
                                          <span className="time-msg">
                                            {convertTime(message.created_ts)}
                                          </span>
                                        </div>
                                        {getUrlByString(message.message)}
                                        {/*{message.read_status == 1 && (
                                                                <span>&#10003; Seen</span>
                                                              )}*/}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex-image-username">
                                      <div className="username-short username-short-sub">
                                        {getShortName(message.sender_name)}
                                      </div>
                                      <div className="username-desc">
                                        <div className="time-add-sub">
                                          <h6>{message.sender_name}</h6>
                                          <span className="time-msg">
                                            {convertTime(message.created_ts)}
                                          </span>
                                        </div>
                                        {getUrlByString(message.message)}
                                        {/*{message.read_status == 1 && (
                                                              <span>&#10003; Seen</span>
                                                            )}*/}
                                      </div>
                                    </div>
                                  )}
                                  {message.attachmentList?.length > 0 ? (
                                    <div
                                      className={`username-desc-map-image ${
                                        message.source_type !== "seller"
                                          ? "map-image-left"
                                          : null
                                      }`}
                                    >
                                      {message.attachmentList.map(
                                        (item, index) => (
                                          <div key={index}>
                                            {fileFormats.image.includes(
                                              item.type
                                            ) ? (
                                              message.source_type ==
                                              "seller" ? (
                                                <div
                                                  className="chat-img-desc-sub"
                                                  title={getFileNameFromUrl(
                                                    item.attachmentName,
                                                    item.type
                                                  )}
                                                  onClick={(e) => {
                                                    if (!item.receivedLength) {
                                                      downloadFileByUrl(
                                                        e,
                                                        item
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    className="sellerImg"
                                                    src={item.attachmentName}
                                                    alt=""
                                                  />
                                                </div>
                                              ) : (
                                                <div
                                                  className="chat-img-desc-sub-vendor"
                                                  title={getFileNameFromUrl(
                                                    item.attachmentName,
                                                    item.type
                                                  )}
                                                  onClick={(e) => {
                                                    if (!item.receivedLength) {
                                                      downloadFileByUrl(
                                                        e,
                                                        item
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    className="vendorImg"
                                                    src={item.attachmentName}
                                                    alt=""
                                                  />
                                                </div>
                                              )
                                            ) : null}

                                            {message.source_type == "seller" ? (
                                              [
                                                ...fileFormats.doc,
                                                ...fileFormats.video,
                                              ].includes(item.type) ? (
                                                <div
                                                  className="download-doc-preview"
                                                  title={getFileNameFromUrl(
                                                    item.attachmentName,
                                                    item.type
                                                  )}
                                                  onClick={(e) => {
                                                    if (!item.receivedLength) {
                                                      downloadFileByUrl(
                                                        e,
                                                        item
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <h5>
                                                    {getFileNameFromUrl(
                                                      item.attachmentName,
                                                      item.type
                                                    )}
                                                  </h5>
                                                  {item.receivedLength ? (
                                                    <>
                                                      <p>
                                                        {item.receivedLength}{" "}
                                                        Download Complete
                                                      </p>
                                                    </>
                                                  ) : (
                                                    <p>
                                                      {item.contentLength ||
                                                        getFileSizeByUrl(item)}
                                                    </p>
                                                  )}
                                                  <span>
                                                    {fileFormats.video.includes(
                                                      item.type
                                                    ) ? (
                                                      <>
                                                        <i className="far fa-file-video"></i>{" "}
                                                        Video
                                                      </>
                                                    ) : (
                                                      <>
                                                        <i className="far fa-file"></i>{" "}
                                                        File
                                                      </>
                                                    )}
                                                  </span>
                                                  {item.receivedLengthPercent &&
                                                  item.receivedLengthPercent <
                                                    100 ? (
                                                    <progress
                                                      id="file"
                                                      value={
                                                        item.receivedLengthPercent
                                                      }
                                                      max="100"
                                                    >
                                                      {" "}
                                                      {
                                                        item.receivedLengthPercent
                                                      }
                                                      %{" "}
                                                    </progress>
                                                  ) : null}
                                                  <div className="download-option-preview">
                                                    <h6>Download</h6>
                                                  </div>
                                                </div>
                                              ) : null
                                            ) : [
                                                ...fileFormats.doc,
                                                ...fileFormats.video,
                                              ].includes(item.type) ? (
                                              <div
                                                className="download-doc-preview-vendor"
                                                title={getFileNameFromUrl(
                                                  item.attachmentName,
                                                  item.type
                                                )}
                                                onClick={(e) => {
                                                  if (!item.receivedLength) {
                                                    downloadFileByUrl(e, item);
                                                  }
                                                }}
                                              >
                                                <h5>
                                                  {getFileNameFromUrl(
                                                    item.attachmentName,
                                                    item.type
                                                  )}
                                                </h5>
                                                {item.receivedLength ? (
                                                  <>
                                                    <p>
                                                      {item.receivedLength}{" "}
                                                      Download Complete
                                                    </p>
                                                  </>
                                                ) : (
                                                  <p>
                                                    {item.contentLength ||
                                                      getFileSizeByUrl(item)}
                                                  </p>
                                                )}
                                                <span>
                                                  {fileFormats.video.includes(
                                                    item.type
                                                  ) ? (
                                                    <>
                                                      <i className="far fa-file-video"></i>{" "}
                                                      Video
                                                    </>
                                                  ) : (
                                                    <>
                                                      <i className="far fa-file"></i>{" "}
                                                      File
                                                    </>
                                                  )}
                                                </span>
                                                {item.receivedLengthPercent &&
                                                item.receivedLengthPercent <
                                                  100 ? (
                                                  <progress
                                                    id="file"
                                                    value={
                                                      item.receivedLengthPercent
                                                    }
                                                    max="100"
                                                  >
                                                    {" "}
                                                    {item.receivedLengthPercent}
                                                    %{" "}
                                                  </progress>
                                                ) : null}
                                                <div className="download-option-preview">
                                                  <h6>Download</h6>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : null}
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="chat-box">
                          <form onSubmit={(e) => handleSubmit(e)}>
                            <div
                              className="chat-icon"
                              onClick={() => setShow(!show)}
                              style={
                                files.length > 0
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              <span>
                                <i className="fas fa-plus-circle"></i>
                              </span>
                            </div>
                            {show && files.length <= 0 ? (
                              <>
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  ref={(input) => {
                                    fileUploadVideo = input;
                                  }}
                                  accept={fileFormats.video
                                    .map((item) => "." + item)
                                    .join(",")}
                                  onChange={(e) => fileSelectHandler(e)}
                                />
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  ref={(input) => {
                                    fileUploadImage = input;
                                  }}
                                  accept={fileFormats.image
                                    .map((item) => "." + item)
                                    .join(",")}
                                  onChange={(e) => fileSelectHandler(e)}
                                />
                                <input
                                  type="file"
                                  hidden
                                  multiple
                                  ref={(input) => {
                                    fileUploadDoc = input;
                                  }}
                                  accept={fileFormats.doc
                                    .map((item) => "." + item)
                                    .join(",")}
                                  onChange={(e) => fileSelectHandler(e)}
                                />
                                <ul>
                                  <li onClick={fileUploadVideoClick}>
                                    <p>
                                      <i className="fas fa-video"></i>
                                    </p>
                                  </li>
                                  <li onClick={fileUploadImageClick}>
                                    <p>
                                      <i className="fas fa-image"></i>
                                    </p>
                                  </li>
                                  <li onClick={fileUploadDocClick}>
                                    <p>
                                      <i className="fas fa-file-alt"></i>
                                    </p>
                                  </li>
                                </ul>
                              </>
                            ) : null}

                            <textarea
                              placeholder="Your text here..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              rows="1"
                            ></textarea>
                            <div
                              className="chat-icon"
                              onClick={(e) => {
                                if (!messageSubmitButton) {
                                  handleSubmit(e);
                                }
                              }}
                            >
                              {!messageSubmitButton ? (
                                <span>
                                  <i
                                    className="far fa-paper-plane"
                                    type="submit"
                                  ></i>
                                </span>
                              ) : (
                                <span className="spinner-border spinner-border-sm ml-1"></span>
                              )}
                            </div>
                          </form>

                          {files.length > 0 ? (
                            <>
                              <input
                                type="file"
                                hidden
                                multiple
                                ref={(input) => {
                                  fileUploadAddMore = input;
                                }}
                                accept={fileFormat
                                  .map((item) => "." + item)
                                  .join(",")}
                                onChange={(e) => addMoreFiles(e)}
                              />
                              <ul className="preview-copy-img">
                                {files.map((item, index) => (
                                  <li key={index}>
                                    {fileFormats.image.includes(
                                      item.type.split("/")[1]
                                    ) ? (
                                      <>
                                        <img src={item.stream} alt="preview" />
                                        <p
                                          onClick={(e) =>
                                            deleteSelectedFile(e, index)
                                          }
                                        >
                                          <i className="far fa-times-circle">
                                            {" "}
                                          </i>
                                        </p>
                                      </>
                                    ) : null}
                                    {[
                                      ...fileFormats.doc,
                                      ...fileFormats.video,
                                    ].includes(item.type.split("/")[1]) ? (
                                      <div className="filesize-main-depth">
                                        <h6>{item.name}</h6>
                                        <label>
                                          {getFileSizeByByteLength(item.size)}
                                        </label>
                                        <p
                                          onClick={(e) =>
                                            deleteSelectedFile(e, index)
                                          }
                                        >
                                          <i className="far fa-times-circle">
                                            {" "}
                                          </i>
                                        </p>
                                      </div>
                                    ) : null}
                                  </li>
                                ))}
                                <li onClick={fileUploadAddMoreClick}>
                                  <span>
                                    <i className="fas fa-plus-square"></i>
                                  </span>
                                </li>
                              </ul>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      {!closeDisputeState.open &&
                        disputeState.reported_by !== "customer" &&
                        disputeState.vendor_suggested_refund === null && (
                          <a
                            onClick={handleCloseDispute}
                            className="orange-btn"
                          >
                            Move this dispute to Subsciety Admin for resolution?
                          </a>
                        )}
                      <p style={{color: "#ff0000", fontSize: '14px', marginTop: '10px'}}>{errorMessage.sizeError}</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          )}
          {disputeState.status === "closed" && (
            <div>
              <p>
                <strong>Order Number : </strong>
                {disputeState.orderNumber
                  ? disputeState.orderNumber
                  : disputeState?.order_id}
              </p>
              <p>
                <strong>Dispute Description : </strong>
                {disputeState?.comment}
              </p>
              <p>
                <strong>Status : </strong>
                {disputeState?.status}
              </p>
              <p>
                <strong>Raised On : </strong>
                {disputeState.disputeDate
                  ? new Date(disputeState.disputeDate)
                      .toISOString()
                      .substring(0, 10)
                  : "-"}
              </p>
              <p>
                <strong>Order Amount : </strong>$
                {parseFloat(disputeState?.orderAmount).toFixed(2)}
              </p>
              <p>
                <strong>Closed By : </strong>
                {disputeState.closed_by}
              </p>
              <p>
                <strong>Is Refund intiated : </strong>
                {!!disputeState.refund_status ? "Yes" : "No"}
              </p>

              {messageDetails?.length > 0 && (
                <div className="messages-box">
                  <div className="message-desc-chat desc-chat-custom">
                    <div
                      className="message-chat-date"
                      id="hey1"
                      onScroll={handleScroll}
                    >
                      <ul className="chat-desc-sub">
                        {messageDetails
                          .sort((a, b) => a.id - b.id)
                          .map((message, index) => (
                            
                            <li key={index} className="differ-username">
                              <div>
                                {setValue(message.created_ts)}
                                {showData === 1 ? (
                                  <div className="message-date">
                                    <p>Today</p>
                                  </div>
                                ) : null}
                              </div>
                              {message.source_type == "seller" ? (
                                <div className="flex-image-vendorside">
                                  <div className="username-short username-short-sub">
                                    {getShortName(message.sender_name)}
                                  </div>
                                  <div className="username-desc">
                                    <div className="time-add-sub">
                                      <h6>{message.sender_name}</h6>
                                      <span className="time-msg">
                                        {convertTime(message.created_ts)}
                                      </span>
                                    </div>
                                    {getUrlByString(message.message)}
                                    {/*{message.read_status == 1 && (
                                        <span>&#10003; Seen</span>
                                      )}*/}
                                  </div>
                                </div>
                              ) : (
                                <div className="flex-image-username">
                                  <div className="username-short username-short-sub">
                                    {getShortName(message.sender_name)}
                                  </div>
                                  <div className="username-desc">
                                    <div className="time-add-sub">
                                      <h6>{message.sender_name}</h6>
                                      <span className="time-msg">
                                        {convertTime(message.created_ts)}
                                      </span>
                                    </div>
                                    {getUrlByString(message.message)}
                                    {/*{message.read_status == 1 && (
                                      <span>&#10003; Seen</span>
                                    )}*/}
                                  </div>
                                </div>
                              )}

                              {message.attachmentList?.length > 0 ? (
                                <div className="username-desc-map-image">
                                  {message.attachmentList.map((item, index) => (
                                    <div key={index}>
                                      {fileFormats.image.includes(item.type) ? (
                                        message.source_type == "seller" ? (
                                          <div
                                            className="chat-img-desc-sub"
                                            title={getFileNameFromUrl(
                                              item.attachmentName,
                                              item.type
                                            )}
                                            onClick={(e) => {
                                              if (!item.receivedLength) {
                                                downloadFileByUrl(e, item);
                                              }
                                            }}
                                          >
                                            <img
                                              className="sellerImg"
                                              src={item.attachmentName}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            className="chat-img-desc-sub-vendor"
                                            title={getFileNameFromUrl(
                                              item.attachmentName,
                                              item.type
                                            )}
                                            onClick={(e) => {
                                              if (!item.receivedLength) {
                                                downloadFileByUrl(e, item);
                                              }
                                            }}
                                          >
                                            <img
                                              className="vendorImg"
                                              src={item.attachmentName}
                                              alt=""
                                            />
                                          </div>
                                        )
                                      ) : null}

                                      {message.source_type == "seller" ? (
                                        [
                                          ...fileFormats.doc,
                                          ...fileFormats.video,
                                        ].includes(item.type) ? (
                                          <div
                                            className="download-doc-preview"
                                            title={getFileNameFromUrl(
                                              item.attachmentName,
                                              item.type
                                            )}
                                            onClick={(e) => {
                                              if (!item.receivedLength) {
                                                downloadFileByUrl(e, item);
                                              }
                                            }}
                                          >
                                            <h5>
                                              {getFileNameFromUrl(
                                                item.attachmentName,
                                                item.type
                                              )}
                                            </h5>
                                            {item.receivedLength ? (
                                              <>
                                                <p>
                                                  {item.receivedLength} Download
                                                  Complete
                                                </p>
                                              </>
                                            ) : (
                                              <p>
                                                {item.contentLength ||
                                                  getFileSizeByUrl(item)}
                                              </p>
                                            )}
                                            <span>
                                              {fileFormats.video.includes(
                                                item.type
                                              ) ? (
                                                <>
                                                  <i className="far fa-file-video"></i>{" "}
                                                  Video
                                                </>
                                              ) : (
                                                <>
                                                  <i className="far fa-file"></i>{" "}
                                                  File
                                                </>
                                              )}
                                            </span>
                                            {item.receivedLengthPercent &&
                                            item.receivedLengthPercent < 100 ? (
                                              <progress
                                                id="file"
                                                value={
                                                  item.receivedLengthPercent
                                                }
                                                max="100"
                                              >
                                                {" "}
                                                {
                                                  item.receivedLengthPercent
                                                }%{" "}
                                              </progress>
                                            ) : null}
                                            <div className="download-option-preview">
                                              <h6>Download</h6>
                                            </div>
                                          </div>
                                        ) : null
                                      ) : [
                                          ...fileFormats.doc,
                                          ...fileFormats.video,
                                        ].includes(item.type) ? (
                                        <div
                                          className="download-doc-preview-vendor"
                                          title={getFileNameFromUrl(
                                            item.attachmentName,
                                            item.type
                                          )}
                                          onClick={(e) => {
                                            if (!item.receivedLength) {
                                              downloadFileByUrl(e, item);
                                            }
                                          }}
                                        >
                                          <h5>
                                            {getFileNameFromUrl(
                                              item.attachmentName,
                                              item.type
                                            )}
                                          </h5>
                                          {item.receivedLength ? (
                                            <>
                                              <p>
                                                {item.receivedLength} Download
                                                Complete
                                              </p>
                                            </>
                                          ) : (
                                            <p>
                                              {item.contentLength ||
                                                getFileSizeByUrl(item)}
                                            </p>
                                          )}
                                          <span>
                                            {fileFormats.video.includes(
                                              item.type
                                            ) ? (
                                              <>
                                                <i className="far fa-file-video"></i>{" "}
                                                Video
                                              </>
                                            ) : (
                                              <>
                                                <i className="far fa-file"></i>{" "}
                                                File
                                              </>
                                            )}
                                          </span>
                                          {item.receivedLengthPercent &&
                                          item.receivedLengthPercent < 100 ? (
                                            <progress
                                              id="file"
                                              value={item.receivedLengthPercent}
                                              max="100"
                                            >
                                              {" "}
                                              {item.receivedLengthPercent}%{" "}
                                            </progress>
                                          ) : null}
                                          <div className="download-option-preview">
                                            <h6>Download</h6>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {closeDisputeState.open && (
          <div className="mt-2">
            <label>Suggest Refund for this order to admin ?</label>
            <div className="custom-radio-input">
              <p className="container-sub mr-2">
                No
                <span>
                  <input
                    type="radio"
                    value={false}
                    onChange={(event) => handleChange(event, "isAddRefund")}
                    checked={!closeDisputeState.isAddRefund}
                    name="isApproveRadio"
                  />
                </span>
                <span className="checkmark"></span>
              </p>

              <p className="container-sub mr-2">
                Yes
                <span>
                  <input
                    type="radio"
                    value={true}
                    onChange={(event) => handleChange(event, "isAddRefund")}
                    checked={closeDisputeState.isAddRefund}
                    name="isApproveRadio"
                  />
                </span>
                <span className="checkmark"></span>
              </p>
            </div>
            {closeDisputeState.isAddRefund && (
              <div>
                <p>
                  <strong>Total Refundable Amount Available: </strong>$
                  {parseFloat(disputeState?.refundableAmount).toFixed(2)}
                </p>
                <label>
                  <b>Refund order amount in percentage :</b>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <b>Customer's Percentage</b>
                      </label>
                      <input
                        placeholder="Vendor's ration"
                        type="number"
                        max={100}
                        value={closeDisputeState.ratio.customer}
                        onChange={(e) => handleChange(e, "customer")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <b>Customer's Amount </b>
                      </label>
                      ${calculateAmount(closeDisputeState.ratio.customer)}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <button
              type="button"
              onClick={onSubmit}
              disabled={showLoader}
              className="orange-outline-btn"
            >
              submit
              {showLoader && (
                <span className="spinner-border spinner-border-sm ml-1"></span>
              )}
            </button>
          </div>
        )}
        <AlertModal
          data={alertState}
          closeAlertModal={closeAlertModal}
          handleAlertResponse={() => handleAlertResponse()}
        />
      </div>
    </div>
  );
};
export default Dispute_Detail;
