import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  createMessage,
  getMessagesBySellerId,
  getMessagesByConversationId,
  getMessageCountBySellerId,
  searchMessages,
} from "../../Redux/Actions/messageAction";
import "../Messages/messages.scss";
import { isEmpty } from "lodash";

const Messages = (props) => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [error, setError] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileFormat, setFileFormat] = useState([]);
  const [show, setShow] = useState(false);
  const [messageConversationList, setMessageConversationList] = useState([]);
  const [activeMsgConversationId, setActiveMsgConversationId] = useState("");
  const [messageDetails, setMessageDetails] = useState([]);
  const [buyerId, setBuyerId] = useState();
  const [errorMessage, setErrorMessage] = useState({});
  const [messageHeader, setMessageHeader] = useState({
    messageSourceType: "",
    messageSourceId: "",
    sellerId: "",
    buyerId: "",
    title: "",
    sellerStoreName: "",
    sellerTimezone: "",
    sellerAvailableFrom: "",
    sellerAvailableTo: "",
  });
  const [messageSubmitButton, setMessageSubmitButton] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [offset, setOffSet] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [messageListOffSet, setMessageListOffSet] = useState(0);
  const [prevMessageListOffSet, setPreviousMessageListOffSet] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [timeOutState, setTimeOutState] = useState(null);
  const [allowReload, setAllowReload] = useState(false);
  // const [currentControllers, setCurrentControllers] = useState([]);

  let sellerId = localStorage.getItem("sellerId");
  const dispatch = useDispatch();

  const keepVerifyingRef = useRef(true);

  const fileFormats = {
    video: [
      "mp4",
      "webm",
      "3gp",
      "3gpp",
      "3g2",
      "3gpp2",
      "mpg",
      "mpeg",
      "ogg",
      "ogv",
      "ts",
      "vnd.dlna.mpeg-tts",
      "mp2t",
    ],
    image: ["jpeg", "png", "jpg", "gif"],
    doc: [
      "pdf",
      "txt",
      "plain",
      "xls",
      "vnd.ms-excel",
      "xlsx",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "doc",
      "msword",
      "docx",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  };
  let fileUploadVideo = "";
  let fileUploadImage = "";
  let fileUploadDoc = "";
  let fileUploadAddMore = "";
  const messagesEndRef = useRef(null);
  let cleartime;
  let showData = 0;
  let allowScroll = true;
  let clearLoadMore;

  useEffect(() => {
    if (loadData) {
      fetchMessages(true);
      const selectedConversation = messageConversationList.find(
        (item) => item.id === activeMsgConversationId
      );
      selectMessageConversation(selectedConversation);
    }
  }, [loadData]);

  useEffect(() => {
    fetchCount();
    fetchMessages(true, location.state?.message_source_id);
  }, []);

  const fetchCount = () => {
    dispatch(getMessageCountBySellerId(sellerId));
  };

  const fetchMessages = async (firstLoad, message_source_id) => {
    if (!!message_source_id) {
      setMessageHeader({
        ...messageHeader,
        messageSourceType: location.state.message_source_type,
        messageSourceId: location.state.message_source_id,
        sellerId: location.state.seller_id,
        buyerId: location.state.buyer_id,
        title: location.state.source_detail,
        sellerStoreName: location.state.buyer_name,
        //   sellerTimezone: selectedConversation.seller_timezone,
        //   sellerAvailableFrom: selectedConversation.seller_available_from,
        //   sellerAvailableTo: selectedConversation.seller_available_to,
      });
    } else {
      setHasMore(false);
    }
    if (loadData) {
      setPreviousMessageListOffSet(0);
      setMessageListOffSet(0);
    }
    if (messageListOffSet !== prevMessageListOffSet || firstLoad || loadData) {
      const res = await dispatch(
        getMessagesBySellerId(sellerId, messageListOffSet)
      );
      if (res && res.length > 0) {
        setMessageConversationList(
          firstLoad
            ? res
            : [...(!loadData ? messageConversationList : []), ...res]
        );
        setPreviousMessageListOffSet(messageListOffSet);
        setMessageListOffSet(prevMessageListOffSet + 10);
        const messageSourceType = location.state?.message_source_type;
        const messageSourceId = location.state?.message_source_id;
        let selectedConversationId = "";
        let selectedConversation = {};
        if (messageSourceType && messageSourceId) {
          selectedConversation = res.find(
            (item) =>
              item.message_source_type === messageSourceType &&
              item.message_source_id === messageSourceId
          );
          if (selectedConversation?.id) {
            selectedConversationId = selectedConversation.id;
          }
          if (selectedConversation?.id && !activeMsgConversationId) {
            setActiveMsgConversationId(selectedConversation.id);
          }
        } else if (activeMsgConversationId) {
          selectedConversationId = activeMsgConversationId;
        }
        if (selectedConversationId) {
          await getMessageDetails(
            selectedConversationId,
            buyerId ? buyerId : selectedConversation.buyer_id
          );
        }
        if (
          !selectedConversationId &&
          !location.state?.message_source_id
        ) {
          await selectMessageConversation(res[0]);
        }
        scrollItem();
      } else {
        setHasMore(false);
      }
      // if (res && !activeMsgConversationId) {
      //     await selectMessageConversation(res[0]);
      // }
      // }
      setLoadData(false);
    }
  };

  const getMessageDetails = async (id, buyerId) => {
    setBuyerId(buyerId ? buyerId : location.state?.buyer_id);
    await setOffSet(0);
    let res = await dispatch(
      getMessagesByConversationId(
        id,
        sellerId,
        buyerId ? buyerId : location.state?.buyer_id,
        offset
      )
    );
    if (res) {
      // TODO: verification pending{

      for (const messageArray of res) {
        if(messageArray.messageAttachmentList){
          for (const item of messageArray.messageAttachmentList) {
            if(item.attachmentName){
            let retrunedItem = await fetch(item.attachmentName, {
              method: "GET",
              headers: {}
            })
              .then(async(response) => {
                // get total length
                const contentLength = +response.headers.get('Content-Length');
      
                const contentLengthSize = await getFileSizeByByteLength(contentLength);
                item['contentLength'] = contentLengthSize;
                return contentLengthSize;
                // setMessageDetails([...tempMessageDetails]);
              })
              .catch(err => {
                console.error(err);
              });
            item["contentLength"]= retrunedItem;
            }
          }
        }
      }
      // }
      setMessageDetails(res);
      scrollItem();
    }
  };

  const selectMessageConversation = async (selectedConversation) => {
    if (selectedConversation) {
      setMessageHeader({
        ...messageHeader,
        messageSourceType: selectedConversation.message_source_type,
        messageSourceId: selectedConversation.id,
        sellerId: selectedConversation.seller_id,
        buyerId: selectedConversation.buyer_id,
        title: selectedConversation.source_detail,
        sellerStoreName: selectedConversation.buyer_name,
        sellerTimezone: selectedConversation.seller_timezone,
        sellerAvailableFrom: selectedConversation.seller_available_from,
        sellerAvailableTo: selectedConversation.seller_available_to,
      });
      setActiveMsgConversationId(selectedConversation.id);
      await getMessageDetails(
        selectedConversation.id,
        selectedConversation.buyer_id
      );

      dispatch(getMessageCountBySellerId(sellerId));
      setShow(false);
      setMessage("");
      setFiles([]);
      setError([]);
    }
  };

  const handleOpenChat = async (e, id) => {
    e.preventDefault();
    setLoadingData(true);
    keepVerifyingRef.current = false;
    // helloWorld(true)
    if (messageConversationList[0][`unread_message_count`] !== 0) {
      messageConversationList[0][`unread_message_count`] = 0;
    }
    const selectedConversation = messageConversationList.find(
      (item) => item.id === id
    );
    if (selectedConversation["latest_read_status"] === 0) {
      for (let message of messageConversationList) {
        if (message.id === id) {
          message[`latest_read_status`] = 1;
          message[`unread_message_count`] = 0;
          break;
        }
      }
      setMessageConversationList([...messageConversationList]);
    }
    await selectMessageConversation(selectedConversation);
  };

  // time converter function from 16:04 to 04:04 PM
  const timeConverter = (time) => {
    if (!time) {
      return "";
    }
    const timeArray = time.split(":");
    let hours = timeArray[0];
    const minutes = timeArray[1];
    //it is PM if hours from 12 onwards
    const suffix = hours >= 12 ? "PM" : "AM";
    //only -12 from hours if it is greater than 12 (if not back at mid night)
    hours = hours > 12 ? hours - 12 : hours;
    //if 00 then it is 12 am
    hours = hours === "00" ? 12 : hours;
    time = hours + ":" + minutes + " " + suffix;
    return time;
  };

  // timestamp converter function from 2021-05-05T16:30:18.000Z to 04:30 PM
  const timestampConverter = (time) => {
    if (!time) {
      return "";
    }
    Number.prototype.zeroPad = function (length) {
      length = length || 2; // defaults to 2 if no parameter is passed
      return (new Array(length).join("0") + this).slice(length * -1);
    };

    const date = new Date(time);
    time = timeConverter(
      date.getHours().zeroPad(2) + ":" + date.getMinutes().zeroPad(2)
    );
    return time;
  };

  const getShortName = (name) => {
    if (!name) {
      return "";
    }
    const nameArray = name.split(" ");
    let shortName = "";
    for (const name of nameArray) {
      shortName += name[0];
    }
    return shortName;
  };

  const getUrlByString = (message) => {
    var array = [];
    var array2 = [];
    var array3;
    array = message.split(" ");
    array2 = array.map((singlemessage) => {
      if (singlemessage.includes("\n")) {
        const tempVar = singlemessage.split("\n").map((temp_message) =>
          temp_message.match(
            /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gi
          ) ? (
            <>
              <a className="usermessage" href={temp_message} target="_blank">
                {temp_message}
              </a>
              {`${"\n"}`}
            </>
          ) : (
            <>
              <span className="span" style={{ fontSize: 16 }}>
                {temp_message} {`${"\n"}`}
              </span>
            </>
          )
        );
        return tempVar;
      } else {
        return singlemessage.match(
          /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gi
        ) ? (
          <a className="usermessage" href={singlemessage} target="_blank">
            {singlemessage}{" "}
          </a>
        ) : (
          <span className="span" style={{ fontSize: 16 }}>
            {singlemessage}{" "}
          </span>
        );
      }
    });
    return array2;
  };

  const handleSubmit = async (e) => {
    const data = {
      messageConversationId: activeMsgConversationId,
      sellerId: sellerId,
      buyerId: messageHeader.buyerId,
      senderType: "seller",
      message,
      files,
      message_source_type: messageHeader.messageSourceType,
      message_source_id: messageHeader.messageSourceId,
    };
    let isValidFormat = 0;
    let totalMediaSize = 0;
    data.files.length && data.files.map((i) => {
      totalMediaSize += i.size
      if (!fileFormat.includes(i?.type.split("/")[1])){
        isValidFormat+=1;
      }
    })
    if(totalMediaSize > 19000000 && !isValidFormat){
      setErrorMessage({sizeError : "You can not upload more then 20 mb size"})
      setMessageSubmitButton(false);
    }
    else {
      e.preventDefault();
      if (message.trim().length || files.length > 0) {
        // if ((message.trim().length || files.length > 0) && activeMsgConversationId) {
        setMessageSubmitButton(true);
        setErrorMessage("");
        let res = await dispatch(createMessage(data));
        if (res?.data && res.data?.messageConversationId) {
          setMessage("");
          setPreviousMessageListOffSet(0);
          setMessageListOffSet(0);
          setFiles([]);
          setLoadData(true);
          setActiveMsgConversationId(res.data.messageConversationId);
          scrollItem();
          setLoadingData(true);
          setMessageSubmitButton(false);
        }
      }
    }
  };

  // function to trigger our input file click
  const fileUploadVideoClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.video);
    fileUploadVideo.click();
    return false;
  };
  const fileUploadImageClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.image);
    fileUploadImage.click();
    return false;
  };
  const fileUploadDocClick = (e) => {
    e.preventDefault();
    setFileFormat(fileFormats.doc);
    fileUploadDoc.click();
    return false;
  };
  const fileUploadAddMoreClick = (e) => {
    e.preventDefault();
    fileUploadAddMore.click();
    return false;
  };

  const fileUploadHandler = (fileList, addNew) => {
    const errors = [];
    const fileArray = [];

    for (const file of fileList) {
      //read file stream and set in state
      function readFile(file) {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          fileArray.push({
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            stream: event.currentTarget.result,
          });
          if (addNew) {
            setFiles([...files, ...fileArray]);
          } else {
            setFiles([...fileArray]);
          }
        };
        fileReader.readAsDataURL(file);
      }

      let imgType = file?.type?.split("/");
      if (
        fileFormats.video.includes(imgType[1]) ||
        fileFormats.image.includes(imgType[1]) ||
        fileFormats.doc.includes(imgType[1])
      ) {
        if (
          !files.find(
            (item) => item.name === file.name && item.type === file.type
          )
        ) {
          readFile(file);
        }
      } else {
        errors.push("File [" + file.name + "] Format is not valid.");
      }
    }
    setError(errors);
  };

  const fileSelectHandler = (e) => {
    e.preventDefault();
    setShow(false);
    fileUploadHandler(e.target.files, false);
  };

  const addMoreFiles = (e) => {
    e.preventDefault();
    fileUploadHandler(e.target.files, true);
    return false;
  };

  const deleteSelectedFile = (e, index) => {
    e.preventDefault();
    files.splice(index, 1);
    setFiles([...files]);
  };

  const getFileNameFromUrl = (fileUrl, fileType) => {
    if (!fileUrl) {
      return "";
    }
    const fileName =
      fileUrl.split("?")[0].substring(fileUrl.lastIndexOf("/") + 1) +
      "." +
      fileType;
    return fileName;
  };

  const getFileSizeByByteLength = (byteLength) => {
    let size = 0;
    let unit = "Bytes";
    if (Math.round(byteLength) < 1000) {
      size = Math.round(byteLength);
      unit = "Bytes";
    } else if (Math.round(byteLength) > 999 && byteLength < 1000000) {
      size = Math.round(byteLength / 1000);
      unit = "KB";
    } else if (Math.round(byteLength) > 999999 && byteLength < 1000000000) {
      size = Math.round(byteLength / 1000000);
      unit = "MB";
    }
    return size + " " + unit;
  };

  const getFileSizeByUrl = (item) => {
    const tempMessageDetails = messageDetails;
    const messageDetailMap = tempMessageDetails.find(
      (map) => map.id === item.messagesId
    );
    const messageAttachmentMap = messageDetailMap?.messageAttachmentList.find(
      (map) => map.id === item.id
    );

    if (messageAttachmentMap) {
      // start the fetch
      fetch(item.attachmentName, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          // get total length
          const contentLength = +response.headers.get("Content-Length");

          const contentLengthSize = getFileSizeByByteLength(contentLength);
          messageAttachmentMap["contentLength"] = contentLengthSize;
          setMessageDetails([...tempMessageDetails]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const downloadFileByUrl = (e, item) => {
    keepVerifyingRef.current = true;
    const fileName = getFileNameFromUrl(item.attachmentName, item.type);
    const tempMessageDetails = messageDetails;
    const messageDetailMap = tempMessageDetails.find(
      (map) => map.id === item.messagesId
    );
    const messageAttachmentMap = messageDetailMap?.messageAttachmentList.find(
      (map) => map.id === item.id
    );
    if (messageAttachmentMap) {
      // start the fetch
      // const controller = new AbortController();
      // setCurrentControllers([...currentControllers, controller])
      // const { signal } = controller;
      fetch(item.attachmentName, {
        method: "GET",
        headers: {},
        // signal
      })
        .then(async (response) => {
          // obtain a reader
          const reader = response.body.getReader();

          // get total length
          const contentLength = +response.headers.get("Content-Length");

          // read the data
          let receivedLength = 0; // received that many bytes at the moment
          let chunks = []; // array of received binary chunks (comprises the body)

          // helloWorld(false, reader, contentLength, receivedLength, chunks, messageAttachmentMap, tempMessageDetails)
          while (true) {
            if (!keepVerifyingRef.current) {
              break;
            }
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            chunks.push(value);
            receivedLength += value.length;

            const receivedFileSize = getFileSizeByByteLength(receivedLength);
            messageAttachmentMap["receivedLength"] = receivedFileSize;
            messageAttachmentMap["receivedLengthPercent"] = Math.round(
              (receivedLength / contentLength) * 100
            );
            setMessageDetails([...tempMessageDetails]);
          }

          // concatenate chunks into single Uint8Array
          let chunksAll = new Uint8Array(receivedLength);
          let position = 0;
          for (let chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
          }

          // download file with chunksAll
          const url = window.URL.createObjectURL(new Blob([chunksAll]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  /*const downloadFileByUrl = (e, url, fileName) => {
      const link = document.createElement("a");
      fetch(url, {
        method: "GET",
        headers: {}
      })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.error(err);
      });
    };*/

  const scrollItem = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      if (cleartime) {
        clearTimeout(cleartime);
      }
      if (clearLoadMore) {
        clearTimeout(clearLoadMore);
      }
      myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollHeight * 0.8;
      allowScroll = false;
      cleartime = setTimeout(() => {
        if (clearLoadMore) {
          clearTimeout(clearLoadMore);
        }
        myDiv.scrollTop = myDiv.scrollHeight + myDiv.scrollTop;
        allowScroll = true;
      }, 1000);
    }
  };
  const handleScroll = (e) => {
    let element = e.target;
    if (clearLoadMore) {
      clearTimeout(clearLoadMore);
    }
    if (
      element.scrollTop <=
        (2 * (element.clientHeight + element.scrollHeight)) / 100 &&
      allowScroll
    ) {
      // do something at end of scroll
      clearLoadMore = setTimeout(async () => {
        allowScroll = false;
        loadMore();
      }, 1000);
    }
  };
  const loadMore = async () => {
    if (loadingData) {
      let res = await dispatch(
        getMessagesByConversationId(
          activeMsgConversationId,
          sellerId,
          !!!messageHeader.buyerId
            ? location.state.buyer_id
            : messageHeader.buyerId,
          offset ? offset : 10
        )
      );
      // TODO: verification pending{
      if (res){
        for (const messageArray of res) {
          if(messageArray.messageAttachmentList){
            for (const item of messageArray.messageAttachmentList) {
              if(item.attachmentName){
              let retrunedItem = await fetch(item.attachmentName, {
                method: "GET",
                headers: {}
              })
                .then(async(response) => {
                  // get total length
                  const contentLength = +response.headers.get('Content-Length');
        
                  const contentLengthSize = await getFileSizeByByteLength(contentLength);
                  item['contentLength'] = contentLengthSize;
                  return contentLengthSize;
                  // setMessageDetails([...tempMessageDetails]);
                })
                .catch(err => {
                  console.error(err);
                });
              item["contentLength"]= retrunedItem;
              }
            }
          }
        }
        if (res && res.length > 0) {
          setMessageDetails([...messageDetails, ...res]);
          scrollAfterLoadMore();
          setOffSet(offset + 10);
          if (res.length < 10) {
            setLoadingData(false);
            setOffSet(0);
          }
        // }
        }
      }
    } else {
      allowScroll = false;
    }
  };
  const scrollAfterLoadMore = () => {
    var myDiv = document.getElementById("hey1");
    if (myDiv) {
      myDiv.scrollTop = myDiv.clientHeight * 0.8;
    }
    allowScroll = true;
  };
  const convertTime = (timestamp) => {
    let unixTimeStamp = new Date(`${timestamp}`).getTime();
    let message = new Date(Math.ceil(unixTimeStamp));
    let finalDate;
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      finalDate = moment(message).format("hh:mm A");
    } else if (diff === 1) {
      finalDate = "Yesterday";
    } else {
      finalDate = moment(message).format("DD/MM/YYYY");
    }
    return finalDate;
  };
  const setValue = (timestamp) => {
    let unixTimeStamp = new Date(timestamp).getTime();
    let message = new Date(Math.ceil(unixTimeStamp));
    let today = new Date();
    let diff = moment(today, "DD.MM.YYYY")
      .startOf("day")
      .diff(moment(message, "DD.MM.YYYY").startOf("day"), "days");
    if (diff === 0) {
      if (showData === 1) {
        showData = 2;
      }
      if (showData === 0) {
        showData = 1;
      }
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    if (timeOutState) {
      clearInterval(timeOutState);
    }
    if (isEmpty(value)) {
      setAllowReload(true);
      setMessageConversationList([]);
    }
    let result = setTimeout(async () => {
      await fetchFilteredData(value);
    }, 800);
    setTimeOutState(result);
  };

  useEffect(() => {
    if (allowReload) {
      fetchMessages(true);
      setAllowReload(false);
    }
  }, [messageConversationList, allowReload]);

  const fetchFilteredData = async (value) => {
    if (isEmpty(value)) {
      return;
    }
    const response = await dispatch(searchMessages(value, sellerId));
    if (response.isError) {
      return setMessageConversationList([]);
    }
    setMessageConversationList(response.data);
    setPreviousMessageListOffSet(0);
    setMessageListOffSet(0);
  };

  return (
    <div className="admin-card">
      <div className="admin-card-head">
        <h2>messages</h2>
      </div>
      <div className="admin-card-box messages-box p-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 p-0 border-msg-right">
              <div className="message-search">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search here.."
                  onChange={(e) => handleSearch(e)}
                />
                <span>
                  <i className="fas fa-search"></i>
                </span>
              </div>
              <InfiniteScroll
                dataLength={messageConversationList.length}
                next={() => fetchMessages(false)}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
              >
                <ul
                  className="main-side-select"
                  id="scrollableDiv"
                  style={{ overflow: "auto" }}
                >
                  {messageConversationList.length > 0 ? (
                    <>
                      {messageConversationList.map((message, index) => (
                        <li
                          key={index}
                          className={
                            activeMsgConversationId === message.id
                              ? "active"
                              : ""
                          }
                          onClick={(e) => handleOpenChat(e, message.id)}
                        >
                          <div className="username-short">
                            {getShortName(message.buyer_name)}
                          </div>
                          <div className="username-desc">
                            <h6
                              className={
                                message.latest_read_status === 0
                                  ? index === 0
                                    ? ""
                                    : "active-head"
                                  : ""
                              }
                            >
                              {message.source_detail}-{message.buyer_name}
                            </h6>
                            <p>
                              {message.latest_message_type != null ? (
                                fileFormats.image.includes(
                                  message.latest_message_type
                                ) ? (
                                  <>
                                    <i className="far fa-image"></i> Image
                                  </>
                                ) : fileFormats.video.includes(
                                    message.latest_message_type
                                  ) ? (
                                  <>
                                    <i className="far fa-file-video"></i> Video
                                  </>
                                ) : (
                                  <>
                                    <i className="far fa-file"></i> File
                                  </>
                                )
                              ) : (
                                message.latest_message?.substring(0, 35) +
                                (message.latest_message?.length > 35
                                  ? "..."
                                  : "")
                              )}
                            </p>
                            {message.unread_message_count > 0 && (
                              <span className="badge badge-dark">
                                {message.unread_message_count}
                              </span>
                            )}
                          </div>
                        </li>
                      ))}
                    </>
                  ) : (
                    <p className="text-center">"No Conversation found"</p>
                  )}
                </ul>
              </InfiniteScroll>
            </div>
            <div className="col-md-8 p-0">
              {activeMsgConversationId ||
              (messageHeader.messageSourceType &&
                messageHeader.messageSourceId &&
                messageHeader.sellerId &&
                sellerId) ? (
                <div className="message-desc-chat">
                  <div className="message-head">
                    <div className="subhead-message">
                      <h6>{messageHeader.sellerStoreName}</h6>
                    </div>
                    <h6>
                      {messageHeader.messageSourceType === "order"
                        ? "Order ID "
                        : ""}
                      {messageHeader.title}
                    </h6>
                  </div>
                  <div
                    className="message-chat-date"
                    id="hey1"
                    ref={messagesEndRef}
                    onScroll={handleScroll}
                  >
                    {/*<div className="message-date">
                      <p>Tuesday, 13, 2021</p>
                    </div>*/}
                    {messageDetails.sort((a, b) => a.id - b.id).length > 0 ? (
                      <ul className="chat-desc-sub">
                        {messageDetails.map((message, index) => (
                          <li key={index} className="differ-username">
                            <div>
                              {setValue(message.created_ts)}
                              {showData === 1 ? (
                                <div className="dayseparation">
                                  <span>Today</span>
                                </div>
                              ) : null}
                            </div>
                            {message.sender_type == "seller" ? (
                              <div className="flex-image-username">
                                <div className="username-short username-short-sub">
                                  {getShortName(message.sender_name)}
                                </div>
                                <div className="username-desc">
                                  <div className="time-add-sub">
                                    <h6>{message.sender_name}</h6>
                                    <span className="time-msg">
                                      {convertTime(message.created_ts)}
                                    </span>
                                  </div>

                                  {getUrlByString(message.message)}

                                  {/*{message.read_status === 1 && (
                                  <span>&#10003; Seen</span>
                                )}*/}
                                </div>
                              </div>
                            ) : (
                              <div className="flex-image-vendorside">
                                <div className="username-short username-short-sub">
                                  {getShortName(message.sender_name)}
                                </div>
                                <div className="username-desc">
                                  <div className="time-add-sub">
                                    <h6>{message.sender_name}</h6>
                                    <span className="time-msg">
                                      {convertTime(message.created_ts)}
                                    </span>
                                  </div>
                                  {getUrlByString(message.message)}
                                  {/*{message.read_status === 1 && (
                                  <span>&#10003; Seen</span>
                                )}*/}
                                </div>
                              </div>
                            )}

                            {message.messageAttachmentList?.length > 0 ? (
                              <>
                                {message.messageAttachmentList.map(
                                  (item, index) => (
                                    <div
                                      className={`username-desc-map-image ${
                                        [
                                          ...fileFormats.doc,
                                          ...fileFormats.video,
                                          ...fileFormats.image,
                                        ].includes(item.type) &&
                                        message.sender_type === "seller"
                                          ? "left-chat-map"
                                          : ""
                                      }`}
                                      key={index}
                                    >
                                      <div>
                                        {fileFormats.image.includes(
                                          item.type
                                        ) ? (
                                          message.sender_type == "seller" ? (
                                            <div
                                              className="chat-img-desc-sub"
                                              title={getFileNameFromUrl(
                                                item.attachmentName,
                                                item.type
                                              )}
                                              onClick={(e) => {
                                                if (!item.receivedLength) {
                                                  downloadFileByUrl(e, item);
                                                }
                                              }}
                                            >
                                              <img
                                                src={item.attachmentName}
                                                alt=""
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="chat-img-desc-sub-vendor"
                                              title={getFileNameFromUrl(
                                                item.attachmentName,
                                                item.type
                                              )}
                                              onClick={(e) => {
                                                if (!item.receivedLength) {
                                                  downloadFileByUrl(e, item);
                                                }
                                              }}
                                            >
                                              <img
                                                src={item.attachmentName}
                                                alt=""
                                              />
                                            </div>
                                          )
                                        ) : null}
                                        {message.sender_type == "seller" ? (
                                          [
                                            ...fileFormats.doc,
                                            ...fileFormats.video,
                                          ].includes(item.type) ? (
                                            <div
                                              className="download-doc-preview"
                                              title={getFileNameFromUrl(
                                                item.attachmentName,
                                                item.type
                                              )}
                                              onClick={(e) => {
                                                if (!item.receivedLength) {
                                                  downloadFileByUrl(e, item);
                                                }
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                {getFileNameFromUrl(
                                                  item.attachmentName,
                                                  item.type
                                                )}
                                              </h5>
                                              {item.receivedLength ? (
                                                <>
                                                  <p>
                                                    {item.receivedLength}{" "}
                                                    Download Complete
                                                  </p>
                                                </>
                                              ) : (
                                                <p>
                                                  {item.contentLength ||
                                                    getFileSizeByUrl(item)}
                                                </p>
                                              )}
                                              <span>
                                                {fileFormats.video.includes(
                                                  item.type
                                                ) ? (
                                                  <>
                                                    <i className="far fa-file-video"></i>{" "}
                                                    Video
                                                  </>
                                                ) : (
                                                  <>
                                                    <i className="far fa-file"></i>{" "}
                                                    File
                                                  </>
                                                )}
                                              </span>
                                              {item.receivedLengthPercent &&
                                              item.receivedLengthPercent <
                                                100 ? (
                                                <progress
                                                  id="file"
                                                  value={
                                                    item.receivedLengthPercent
                                                  }
                                                  max="100"
                                                >
                                                  {" "}
                                                  {
                                                    item.receivedLengthPercent
                                                  }%{" "}
                                                </progress>
                                              ) : null}
                                              <div className="download-option-preview">
                                                <h6>Download</h6>
                                              </div>
                                            </div>
                                          ) : null
                                        ) : [
                                            ...fileFormats.doc,
                                            ...fileFormats.video,
                                          ].includes(item.type) ? (
                                          <div
                                            className="download-doc-preview-vendor"
                                            title={getFileNameFromUrl(
                                              item.attachmentName,
                                              item.type
                                            )}
                                            onClick={(e) => {
                                              if (!item.receivedLength) {
                                                downloadFileByUrl(e, item);
                                              }
                                            }}
                                          >
                                            <h5
                                              style={{
                                                wordBreak: "break-word",
                                              }}
                                            >
                                              {getFileNameFromUrl(
                                                item.attachmentName,
                                                item.type
                                              )}
                                            </h5>
                                            {item.receivedLength ? (
                                              <>
                                                <p>
                                                  {item.receivedLength} Download
                                                  Complete
                                                </p>
                                              </>
                                            ) : (
                                              <p>
                                                {item.contentLength ||
                                                  getFileSizeByUrl(item)}
                                              </p>
                                            )}
                                            <span>
                                              {fileFormats.video.includes(
                                                item.type
                                              ) ? (
                                                <>
                                                  <i className="far fa-file-video"></i>{" "}
                                                  Video
                                                </>
                                              ) : (
                                                <>
                                                  <i className="far fa-file"></i>{" "}
                                                  File
                                                </>
                                              )}
                                            </span>
                                            {item.receivedLengthPercent &&
                                            item.receivedLengthPercent < 100 ? (
                                              <progress
                                                id="file"
                                                value={
                                                  item.receivedLengthPercent
                                                }
                                                max="100"
                                              >
                                                {" "}
                                                {
                                                  item.receivedLengthPercent
                                                }%{" "}
                                              </progress>
                                            ) : null}
                                            <div className="download-option-preview">
                                              <h6>Download</h6>
                                            </div>
                                          </div>
                                        ) : null}
                                        {/*{fileFormats.video.includes(item.type) ? (
                                        <div
                                          onClick={(e) =>
                                          downloadFileByUrl(e, item.attachmentName, getFileNameFromUrl(item.attachmentName, item.type))}
                                        >
                                          <video width="320" height="240">
                                            <source src={item.attachmentName} type={"video/" + item.type}/>
                                            Your browser does not support the video tag.
                                          </video>
                                        </div>
                                      ) : null}*/}
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>

                  <div className="chat-box">
                    <form
                      onSubmit={(e) => {
                        if (!messageSubmitButton) {
                          handleSubmit(e);
                        }
                      }}
                    >
                      <div
                        className="chat-icon"
                        onClick={() => setShow(!show)}
                        style={
                          files.length > 0
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <span>
                          <i className="fas fa-plus-circle"> </i>
                        </span>
                      </div>
                      {show && files.length <= 0 ? (
                        <>
                          <input
                            type="file"
                            hidden
                            multiple
                            ref={(input) => {
                              fileUploadVideo = input;
                            }}
                            accept={fileFormats.video
                              .map((item) => "." + item)
                              .join(",")}
                            onChange={(e) => fileSelectHandler(e)}
                          />
                          <input
                            type="file"
                            hidden
                            multiple
                            ref={(input) => {
                              fileUploadImage = input;
                            }}
                            accept={fileFormats.image
                              .map((item) => "." + item)
                              .join(",")}
                            onChange={(e) => fileSelectHandler(e)}
                          />
                          <input
                            type="file"
                            hidden
                            multiple
                            ref={(input) => {
                              fileUploadDoc = input;
                            }}
                            accept={fileFormats.doc
                              .map((item) => "." + item)
                              .join(",")}
                            onChange={(e) => fileSelectHandler(e)}
                          />
                          <ul>
                            <li onClick={fileUploadVideoClick}>
                              <p>
                                <i className="fas fa-video"></i>
                              </p>
                            </li>
                            <li onClick={fileUploadImageClick}>
                              <p>
                                <i className="fas fa-image"></i>
                              </p>
                            </li>
                            <li onClick={fileUploadDocClick}>
                              <p>
                                <i className="fas fa-file-alt"></i>
                              </p>
                            </li>
                          </ul>
                        </>
                      ) : null}
                      <textarea
                        placeholder="Your text here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows="1"
                      />
                      <div
                        className="chat-icon"
                        onClick={(e) => {
                          if (!messageSubmitButton) {
                            handleSubmit(e);
                          }
                        }}
                      >
                        {!messageSubmitButton ? (
                          <span>
                            <i className="far fa-paper-plane" type="submit"></i>
                          </span>
                        ) : (
                          <span class="spinner-border spinner-border-sm ml-1"></span>
                        )}
                      </div>
                    </form>
                    {files.length > 0 ? (
                      <>
                        <input
                          type="file"
                          hidden
                          multiple
                          ref={(input) => {
                            fileUploadAddMore = input;
                          }}
                          accept={fileFormat
                            .map((item) => "." + item)
                            .join(",")}
                          onChange={(e) => addMoreFiles(e)}
                        />
                        <ul className="preview-copy-img">
                          {files.map((item, index) => (
                            <li key={index}>
                              {fileFormats.image.includes(
                                item.type.split("/")[1]
                              ) ? (
                                <>
                                  <img src={item.stream} alt="preview" />
                                  <p
                                    onClick={(e) =>
                                      deleteSelectedFile(e, index)
                                    }
                                  >
                                    <i className="far fa-times-circle"> </i>
                                  </p>
                                </>
                              ) : null}
                              {[
                                ...fileFormats.doc,
                                ...fileFormats.video,
                              ].includes(item.type.split("/")[1]) ? (
                                <div className="filesize-main-depth">
                                  <h6>{item.name}</h6>
                                  <label>
                                    {getFileSizeByByteLength(item.size)}
                                  </label>
                                  <p
                                    onClick={(e) =>
                                      deleteSelectedFile(e, index)
                                    }
                                  >
                                    <i className="far fa-times-circle"> </i>
                                  </p>
                                </div>
                              ) : null}
                              {/*{fileFormats.video.includes(item.type.split("/")[1]) ? (
                                <>
                                  <video width="320" height="240">
                                    <source src={item.stream} type={item.type}/>
                                    Your browser does not support the video tag.
                                  </video>
                                  <p
                                    onClick={(e) =>
                                      deleteSelectedFile(e, index)
                                    }
                                  >
                                    <i className="far fa-times-circle"> </i>
                                  </p>
                                </>
                              ) : null}*/}
                            </li>
                          ))}
                          <li onClick={fileUploadAddMoreClick}>
                            <span>
                              <i className="fas fa-plus-square"> </i>
                            </span>
                          </li>
                        </ul>
                      </>
                    ) : null}
                    {/*<ul className="preview-copy-img">
                      <li>
                        <img src={Preview} alt="preview"/>
                        <p>
                          <i className="far fa-times-circle"></i>
                        </p>
                      </li>
                      <li>
                        <span>
                          <i className="fas fa-plus-square"></i>
                        </span>
                      </li>
                    </ul>*/}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
            <p style={{color: "#ff0000", fontSize: '14px', marginTop: '10px'}}>{errorMessage.sizeError}</p>
    </div>
  );
};

export default Messages;
