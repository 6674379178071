import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const excludedPath = ['/onboard-step', '/personal-details', '/store-details', '/payment-configuration', '/onboarding-subscription', '/store-preview'];

const HeaderMessage = () => {
  const { userInfo, storeSetupMessage } = useSelector((state) => state.userReducer);
  const location = useLocation();
  const showMessage = () => {
    if (storeSetupMessage && !excludedPath.includes(location.pathname)) {
      return true;
    }
    return false;
  };


  const getCompletePercentage = () => {
    const isStripeConnected = JSON.parse(localStorage.getItem("sellerData")).stripe_details_submitted;
    let percent = 25;
    if(userInfo?.phonenumber && userInfo?.phonenumber !=='null'){
      percent+=25;
    }
    if(userInfo?.storename && userInfo?.storename !=='null'){
      percent+=25;
    }
    if(isStripeConnected || userInfo?.stripe_details_submitted || userInfo?.stripe_details_submitted === 1){
      percent+=25;
    }
    return percent;
  };

  const getLink = () => {
    let link = "personal-details";
    if(userInfo?.phonenumber && userInfo?.phonenumber !=='null'){
      link = "store-details";
    }
    if(userInfo?.storename && userInfo?.storename !=='null'){
      link = "payment-configuration";
    }
    return link;
  };

  return (
    <div>
      {showMessage() && (
          <div className="notify-top">
            <div className="profile-status">
              <div className="head-progress-bar">
                <CircularProgressbar
                  value={getCompletePercentage()}
                  text={`${getCompletePercentage()}%`}
                  styles={buildStyles({
                    pathColor: `#E25E52`,
                    textColor: "#E25E52",
                  })}
                />
              </div>

              <p>Profile Completed</p>
            </div>
            <p className="notify-alert-head">
              To get your product approved and listed on our platform, please{" "}
              <Link to={`/${getLink()}`}>Complete Your Profile</Link>{" "}
            </p>
          </div>
        )}
    </div>
  );
};

export default React.memo(HeaderMessage);
