import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getPageContent} from "../../Redux/Actions/dashboardAction";
import logo from "../../assets/images-2/logo.png";
import { useParams, useNavigate } from "react-router-dom";

const Page = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    const pageSlug = params.slug;
    const [pageContent, setPageContent] = useState('')

    useEffect(()=>{
        loadPage()
    },[])

    const loadPage = async () => {
        const response = await dispatch(getPageContent(pageSlug))
        if(response && response.data){
            setPageContent(response.data)
        }
    }

  return (
    <div className="container mt-4 mb-4">
        <div className="mb-4">
            <img src={logo} alt={logo} onClick={()=>navigate("/")} style={{cursor:"pointer"}}/>
        </div>

        {
            pageContent && pageContent.content && <div dangerouslySetInnerHTML={{
                __html:pageContent.content
            }}/>
        }
    </div>
  );
};

export default Page;
