import React, { Component } from "react";
import "../../Styles/user-detail.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import TimePicker from "react-time-picker";

import DollarIcon from "../../assets/images-2/dollar-icon.png";
import ProfileImage from "../../assets/images-2/profile-img.png";
import {
  addProduct,
  getProductDetail,
  updateProductDetail,
  updateProductFiles,
  removeProductFile,
  getActiveSubscriptionCustomerListByProudctId,
  sendInvitationRequest,
  updateGroupInviteMeeting,
  createFreeSeminar,
  addGroupAndOneOnOneMeetingDetails,
  getGroupAndOneOnOneMeetingDetails,
} from "../../Redux/Actions/productAction";
import {
  getMeetingsByProductIdAndSellerId,
  updateSelectedEvent,
  deleteSelectedEvent,
  validateCancelSlot,
  sendResheduleMailFromForm,
} from "../../Redux/Actions/meetingActions";
import { getProductCategoryList } from "../../Redux/Actions/productCategoryAction";
import { getAttributeList } from "../../Redux/Actions/attributeAction";
import {
  getShopifyDetails,
  createMeeting,
} from "../../Redux/Actions/userAction";

import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import {
  addFiles,
  addFilesError,
  addMoreFiles,
  uploadFiles,
  uploadAttibute,
  updateAttibute,
} from "../../Redux/Actions/fileUploadAction";

import { fetchPrivateFiles } from "../../Redux/Actions/azureStorageAction";
import Calendar from "./Calendar";
import AlertModal from "../../Components/AlertModal/AlertModal";
import {
  getChildCategories,
  hierarchyList,
} from "../../Redux/Actions/searchCategoryAction";
import { isInteger } from "lodash";
import One_Time_Event from "../One-Time-Event/One_Time_Event";
import { confirmAlert } from "react-confirm-alert";
import { decryptAccessToken } from "../../Utils/helper";
import withNavigate from "../../Components/customHooks/navigate";

class Add_Product extends Component {
  parentCategoryOptions = [
    { label: "Access", value: 3 },
    { label: "Replenish", value: 101 },
    {
      label: "Customize",
      value: 119,
    },
  ];
  slug = "";
  userId = localStorage.getItem("sellerId");
  timezone = localStorage.getItem("timezone");
  AvailableFromTime = localStorage.getItem("availableFrom");
  AvailableToTime = localStorage.getItem("availableTo");
  currentDate = new Date();
  productId = "";

  productFields = {
    productDetail: {},
    productId: null,
    productModelNo: "",
    productTitle: "",
    pleaseWaitLoader: false,
    productPrice: "",
    productImage: "",
    galleryImages: [],
    primaryIndex:null,
    attributeGlobalPrice: null,
    base64GalleryImages: [],
    removedGalleryImages: [],
    productTimeFrame: "",
    productHeight: 0,
    productWidth: 0,
    productLength: 0,
    productWeight: 0,
    specificAttributeGlobalPrice: [],
    specificAttributeGlobalImage: [],
    isPrimary: false,
    isActive: 1,
    isFirstVariantAdded: false,
    attibuteGlobalValue: "",
    attibuteGlobalIndex: null,
    isImageVisible:false,
    // isChatActive: 1,
    productDescription: "",
    previewProductImage: null,
    productCategoryList: [],
    attributeImage: [],
    attributeGlobalImage: "",
    firstLoadCategoryList: [],
    productParentCategoryList: [],
    // selectedProductCategoryId: [],
    selectedParentProductCategoryId: [],
    defaultSelectedOptions: [],
    defaultParentSelectedOption: [],
    courierList: ["Fedex", "USPS", "UPS"],
    selectedCourierList: [],
    attributeChange : "",
    defaultSelectedCourierOptions: [],
    isEdit: false,
    successMessage: "",
    errorMessage: "",
    bottomErrorMessage: "",
    errorData: {},
    showLoader: false,
    showCalanderLoader: false,
    src: null,
    crop: {
      unit: "%",
      width: 64,
      height: 36,
      x: 25,
      y: 25,
      aspect: 16 / 9,
    },
    withAttribute: 0, //0 = false | 1 = true
    attributesList: [],
    attributesDefaultSelectOptions: [],
    attributesSelectOptions: [],
    selectedAttributeVariants: [],
    variantCombination: [],
    stripe_product_id: null,
    is_shipping_free: false,
    is_sale: 0,
    order_limit: "",
    minimumOrderQuantityLimit: 0,
    last_price_updated_time: null,
    diffMonths: false,
    sale_price: 0,
    o_o_o_meeting_price: 0,
    price_type: 1,
    type: null,
    galleryVideos: [],
    removedGalleryVideos: [],
    acsCatSubcategories: [
      {
        acsCatTitle: "",
        acsCatVideoGallery: [],
        acsCatVideoGalleryTitles: [],
        removedAcsCatVideoGallery: [],
        acsCatVideos: [],
        acsCatVideosTitles: [],
        acsCatAudioGallery: [],
        acsCatAudioGalleryTitles: [],
        removedAcsCatAudioGallery: [],
        acsCatAudios: [],
        acsCatAudiosTitles: [],
        acsCatImageGallery: [],
        acsCatImageGalleryTitles: [],
        removedAcsCatImageGallery: [],
        acsCatImages: [],
        acsCatImagesTitles: [],
        acsCatDocGallery: [],
        acsCatDocGalleryTitles: [],
        removedAcsCatDocGallery: [],
        acsCatDocuments: [],
        acsCatDocumentsTitles: [],
        p_acsCatVideos: [],
        p_acsCatAudios: [],
        p_acsCatImages: [],
        p_acsCatDocuments: [],
      },
    ],
    videos: [],
    images: [],
    refreshComponent: "",
    acsCatDescription: "",
    setCategoryAddedMessage: "",
    productSearchCategory: "",
    searchCategoryParents: [],
    searchCategoryId: 0,
    searchCategoryChildOptions: [],
    searchCategoryValue: [],
    isVariantChange : "",
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.productFields,
      shop: null,
      accessToken: null,
      isAccessCategoryInCategories: false,
      currentFileFormat: [],
      currentFileType: "",
      currentFileContentType: "",
      currentUploadSubCategoryIndex: null,
      showVideos: true,
      downloadProcessMap: {},
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingStartTime: "",
      meetingEndTime: "",
      activeSubscriptionList: this.props.activeSubscriptionMemberList,
      hasMore: true,
      offset: 0,
      prevOffset: 0,
      discounted_percentage: 0,
      createVarientProductValidation: 0,
      zoom_meeting_url: null,
      zoom_host_url: null,
      isDisabledAttributesForWoocommerceShopify: false,
      // limit:0
      alertModalData: {
        open: false,
        message: "",
        singleButton: true,
      },
      isSeeAllChecked: false,
      isDigitalChecked: false,
      customizedProductTime: 0,
      isCropImage: false,
      showCropper: true,
      isOneOnOneMeetingPriceChecked: false,
      isGroupMeetingChecked: false,
      isCustomizeCategory: false,
      showHideFreeSeminarModal: false,
      formData: {
        start: "",
        end: "",
        date: "",
        name: "",
        price: null,
      },
      isFreeSeminarCreated: false,
      popupErrorMessage: "",
      isWeekDaySelected: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      oneOnOneId: null,
      selectedFrequency: {
        All: false,
        "One Time": false,
        Weekly: false,
        "Bi weekly": false,
        Monthly: false,
      },
      slots: {},
      selectedSlots: {},
      sessionHour: "00",
      sessionMinute: "00",
      trialCost: 0,
      subscriptionCost: 0,
      groupMeetingDetails: [
        {
          id: null,
          meetingName: "",
          subscriptionCost: 0,
          sessionHour: "00",
          sessionMinute: "00",
          meetingMonthlyStartTime: "",
          meetingMonthlyEndTime: "",
          meetingMonthlyWeekDay: "",
          meetingMonthlyWeekOfMonth: "",
          groupMeetingFrequency: "",
          link: "",
          host_link: "",
          isMonthlyGroupFrequencyChecked: false,
          isWeekDaySelected: {
            Sun: false,
            Mon: false,
            Tue: false,
            Wed: false,
            Thu: false,
            Fri: false,
            Sat: false,
          },

          slots: {},
          selectedSlots: {},
        },
      ],
      // groupMeetingName: "",
      // groupSubscriptionCost: 0,
      // groupSessionHour: "",
      // groupSessionMinute: "",
      // groupMeetingMonthlyStartTime: "",
      // groupMeetingMonthlyEndTime: ""
      isOneOnOneMeetingChecked: false,
      isOneOnOneMeetingDetailsEdited: false,
      isGroupMeetingDetailsEdited: false,
      deletedGroupMeetingsIdArray: [],
      eventPriceError: "",
      eventNameError: "",
      isFreePriceChecked: false,
      customizeQuestions: [],
    };

    this.subscriptionCostEditedArray = [];
    this.editedMeetingFromCalendar = [];
    this.editedMeetingFromForm = [];
    this.editedGroupMeetingFromForm = [];
    this.canceledMeetingFromCalendar = [];
    this.selectOneOnOneFrequency = [
      "All",
      "One Time",
      "Weekly",
      "Bi weekly",
      "Monthly",
    ];

    this.selectGroupFrequency = ["Weekly", "Bi weekly", "Monthly"];

    this.weekOfMonth = ["First", "Second", "Third"];
    this.weekDays = {
      Sun: "Sunday",
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
    };
    this.sessionHours = [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    this.sessionMinutes = ["00", "15", "30", "45"];
    /*this.fileSelectHandler = this.fileSelectHandler.bind(this);*/
    this.fileUpload = React.createRef();
    this.fileUploadAttribute = React.createRef();
    this.uploadGlobalImage = React.createRef()

    this.fileUploadVideo = React.createRef();
    this.fileUploadAudio = React.createRef();
    this.fileUploadImage = React.createRef();
    this.fileUploadDoc = React.createRef();
  }

  fileFormats = {
    video: ["mp4", "webm", "ogg", "ogv"],
    mediaFormat: ["wav", "ogg", "mpeg", "mp3"],
    audio: ["wav", "ogg", "mp3"],
    image: ["jpeg", "png", "jpg", "gif"],
    doc: [
      "pdf",
      "txt",
      "plain",
      "xls",
      "vnd.ms-excel",
      "xlsx",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "doc",
      "msword",
      "docx",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  };

  onSelectFile = (e) => {
   if(e.target.files[0]?.size > 10000000){
    this.setState({
      errorData : {
        ...this.state.errorData, 
      largeImageError : "You can not upload images more than 10mb size"
      }
    })
    return
   }
    if (e.target.files && e.target.files?.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          croppedImageUrl: reader.result,
          previewProductImage: reader.result,
          productImage: reader.result,
          isCropImage: false,
          showCropper: true,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image.target;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageURL = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({
        croppedImageUrl: croppedImageURL,
        productImage: croppedImageURL,
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL();
      resolve(dataURL);
    });
  }

  async componentDidMount() {
    if (this.props.location.pathname === `/edit-product/${this.props.params.slug}`) {
      this.slug = this.props.params.slug;
      await this.fetchProductDetail();
      this.fetchVendorShopifyAccountDetails();
      await this.fetchSearchHierarchyList();
    } else {
      await this.fetchProductCategoryList();
      this.setCourierList();
    }
    this.setState({
      isOneOnOneMeetingPriceChecked: !!this.state.o_o_o_meeting_price,
    });
    await this.getSearchCategories();
  }

  getSearchCategories = async () => {
    if (this.state.searchCategoryId.length > 0) {
      let searchCategoryChildOptions = [
        ...this.state.searchCategoryChildOptions,
      ];
      this.state.searchCategoryParents.map(async (item, index) => {
        const responseCats = await this.props.getChildCategories(
          item[item.length - 1].id,
          this.state.isSeeAllChecked,
          this.state.selectedParentProductCategoryId
        );
        if (responseCats && !responseCats.isError) {
          searchCategoryChildOptions[index] = responseCats.data;
        }
        await this.setState({
          searchCategoryChildOptions,
        });
      });
    }
  };
  fetchVendorShopifyAccountDetails = async () => {
    let res = await this.props.getShopifyDetails(this.userId);
    if (res?.data.isFound) {
      const access_token = decryptAccessToken(res.data.data.access_token)
      this.setState({
        shop: res.data?.data.shop,
        accessToken: access_token,
      });
    }
  };

  organizeAccessSubcategoryData = (productDetail) => {
    if (productDetail.access_cat_titles == null) {
      return [];
    }
    const titles = productDetail.access_cat_titles
      ? JSON.parse(productDetail.access_cat_titles)
      : "";
    const videos = JSON.parse(productDetail.access_cat_videos);
    const audios = JSON.parse(productDetail.access_cat_audios);
    const images = JSON.parse(productDetail.access_cat_images);
    const documents = JSON.parse(productDetail.access_cat_documents);
    const length = videos && videos.length > 0 ? videos.length : 0;
    let organizedData = [];
    for (let i = 0; i < length; i++) {
      const acsCatVideoGallery =
        videos && videos.length > 0 && videos[i][i].length > 0
          ? videos[i][i].split(",").map((value) => ({ url: value }))
          : [];
      const acsCatAudioGallery =
        audios && audios.length > 0 && audios[i][i].length > 0
          ? audios[i][i].split(",").map((value) => ({ url: value }))
          : [];
      const acsCatImageGallery =
        images && images.length > 0 && images[i][i].length > 0
          ? images[i][i].split(",").map((value) => ({ url: value }))
          : [];
      const acsCatDocGallery =
        documents && documents.length > 0 && documents[i][i].length > 0
          ? documents[i][i].split(",").map((value) => ({ url: value }))
          : [];
      organizedData.push({
        acsCatTitle:
          titles && titles.length > 0 && titles[i][i].length > 0
            ? titles[i][i]
            : "",
        acsCatVideoGallery,
        acsCatAudioGallery,
        acsCatImageGallery,
        acsCatDocGallery,
        acsCatVideos: [],
        acsCatAudios: [],
        acsCatImages: [],
        acsCatDocuments: [],
        acsCatVideosTitles: [],
        acsCatAudiosTitles: [],
        acsCatImagesTitles: [],
        acsCatDocumentsTitles: [],
        removedAcsCatVideoGallery: [],
        removedAcsCatAudioGallery: [],
        removedAcsCatImageGallery: [],
        removedAcsCatDocGallery: [],
        acsCatVideoGalleryTitles: acsCatVideoGallery.map(
          (v) => this.getFileNameFromUrl(v.url).split(".")[0]
        ),
        acsCatAudioGalleryTitles: acsCatAudioGallery.map(
          (v) => this.getFileNameFromUrl(v.url).split(".")[0]
        ),
        acsCatImageGalleryTitles: acsCatImageGallery.map(
          (v) => this.getFileNameFromUrl(v.url).split(".")[0]
        ),
        acsCatDocGalleryTitles: acsCatDocGallery.map(
          (v) => this.getFileNameFromUrl(v.url).split(".")[0]
        ),
        p_acsCatVideos: [],
        p_acsCatAudios: [],
        p_acsCatImages: [],
        p_acsCatDocuments: [],
      });
    }

    return organizedData;
  };

  fetchProductDetail = async () => {
    let productDetail = await this.props.getProductDetail(
      this.userId,
      this.slug 
    );
    if (
      productDetail?.data?.data[0]?.type === "woocommerce" ||
      productDetail?.data?.data[0]?.type === "shopify"
    ) {
      this.setState({
        isDisabledAttributesForWoocommerceShopify: true,
      });
    } else {
      this.setState({
        isDisabledAttributesForWoocommerceShopify: false,
      });
    }

    if (productDetail?.data?.data?.length > 0) {
      // product found
      productDetail = productDetail.data.data[0];

      let selectedCategories = [];

      let selectedParentCategories = [];
      if (
        productDetail.selectedCategories &&
        productDetail.selectedCategories.length > 0
      ) {
        productDetail.selectedCategories.forEach((category) => {
          if (category.parent_id === 0) {
            selectedParentCategories.push(category.productCategoryId);
          } else {
            selectedCategories.push(category.productCategoryId);
          }
        });
      }
      const res = this.createProductCategorySelectOptions(
        productDetail.categoriesList,
        selectedCategories,
        selectedParentCategories
      );
      let selectedCouriers = [];

      if (productDetail.couriers_option) {
        selectedCouriers = JSON.parse(productDetail.couriers_option);
      }

      const courierRes = this.createAvailableCourierSelectOptions(
        ["Fedex", "USPS", "UPS"],
        selectedCouriers
      );

      const attributesSelectOptions = this.createAttributeSelectOptions(
        productDetail.attributes,
        productDetail.selectedAttributes
      );
      const defaultCombination = this.createDefaultVariantCombination(
        productDetail.productAttributeVariantDetail
      );

      const defaultGalleryImages = this.createDefaultGalleryImagePattern(
        productDetail && productDetail?.galleryImages
          ? JSON.parse(productDetail.galleryImages)
          : []
      );

      const seminarDetails =
        productDetail.free_seminar_details &&
        productDetail.free_seminar_details.length
          ? JSON.parse(productDetail.free_seminar_details)
          : null;

      let accessCategoryId = res.selectParentOptions?.find(
        (i) => i.label.toLocaleLowerCase() === "access"
      )?.value;
      let customizeCategory = res?.defaultParentSelectedOptionsList?.find(
        (i) => i.label.toLocaleLowerCase() === "customize"
      )?.label;
      let isCategoryIncludesAccessCategory = selectedParentCategories.includes(
        +accessCategoryId
      );
      const galleryVideos = productDetail.gallery_videos
        ? productDetail.gallery_videos.split(",").map((value) => {
            return { url: value };
          })
        : [];

      const accessSubcategoryList = await this.organizeAccessSubcategoryData(
        productDetail
      );
      const searchCategoryId = productDetail.searchCategoryId;
      await this.getGroupAndOneOnOneMeetingDetails(productDetail.id);

      this.setState(
        {
          primaryIndex: productDetail.attributePrimaryIndex,
          productDetail: productDetail,
          productId: productDetail.id,
          productTitle: productDetail.name,
          productPrice: productDetail?.price?.toString(),
          productImage: productDetail.imageName,
          productTimeFrame: productDetail.timeFrame
            ? productDetail.timeFrame.split(",")[1]
            : "annual",
          productHeight: productDetail.height,
          productWidth: productDetail.width,
          productLength: productDetail.length,
          productWeight: productDetail.weight,
          isActive: productDetail.isActive,
          formData: {
            start: seminarDetails && seminarDetails.start,
            end: seminarDetails && seminarDetails.end,
            date: seminarDetails && seminarDetails.date,
            link: seminarDetails && seminarDetails.host_link,
            note: seminarDetails && seminarDetails.note,
            name: seminarDetails && seminarDetails.name,
            price: seminarDetails && seminarDetails.price,
          },
          isFreeSeminarCreated: seminarDetails ? true : false,
          isFreePriceChecked:
            seminarDetails && seminarDetails.price == "" ? true : false,
          type: productDetail.type,
          productDescription: productDetail.description,
          previewProductImage: productDetail.imageName,
          galleryImages: defaultGalleryImages,
          finalImgArray: defaultGalleryImages,
          selectedParentProductCategoryId: selectedParentCategories,
          productCategoryList: res.selectOptions ? res.selectOptions : [],
          productParentCategoryList: res.selectParentOptions
            ? res.selectParentOptions
            : [], // Parent category
          defaultSelectedOptions: res.defaultSelectedOptions
            ? res.defaultSelectedOptions
            : [],
          defaultParentSelectedOption: res.defaultParentSelectedOptionsList
            ? res.defaultParentSelectedOptionsList
            : [], // Parent category
          courierList: courierRes.selectOptions ? courierRes.selectOptions : [],
          selectedCourierList: selectedCouriers,
          defaultSelectedCourierOptions: courierRes.defaultSelectedOptions
            ? courierRes.defaultSelectedOptions
            : [],
          isEdit: true,
          withAttribute:
            productDetail.is_variant &&
            attributesSelectOptions.defaultSelectedOptions.length > 0
              ? 1
              : 0,
          attributesList: productDetail.attributes
            ? productDetail.attributes
            : [],
          attributesDefaultSelectOptions:
            attributesSelectOptions.defaultSelectedOptions,
          attributesSelectOptions: attributesSelectOptions.selectOptions,
          variantCombination: defaultCombination,
          stripe_product_id: productDetail.stripe_product_id,
          is_shipping_free: productDetail.is_shipping_free,
          is_sale: productDetail.is_sale,
          price_type:
            attributesSelectOptions.defaultSelectedOptions.length > 0
              ? 2
              : productDetail.price_type,
          sale_price:
            productDetail.price_type == 2
              ? Math.round(
                  100 * (1 - productDetail.sale_price / productDetail.price)
                )
              : productDetail.sale_price,
          o_o_o_meeting_price: productDetail.one_on_one_meeting_price,
          isDigitalChecked: !!productDetail.is_digital,
          order_limit: productDetail.order_limit,
          minimumOrderQuantityLimit: productDetail.totalsubscription,
          last_price_updated_time: productDetail.last_price_updated_time,
          seller_store_product_id: productDetail.seller_store_product_id,
          zoom_meeting_url: productDetail.zoom_meeting_url,
          zoom_host_url: productDetail.zoom_host_url,
          is_registered_on_store: productDetail.is_registered_on_store,
          isAccessCategoryInCategories: isCategoryIncludesAccessCategory,
          galleryVideos: galleryVideos,
          acsCatSubcategories: accessSubcategoryList,
          acsCatDescription: productDetail.access_cat_description,
          videos: [],
          discounted_percentage:
            productDetail.price_type == 2
              ? Math.round(
                  100 * (1 - productDetail.sale_price / productDetail.price)
                )
              : productDetail.sale_price,
          searchCategoryId,
          isCustomizeCategory:
            customizeCategory?.toLocaleLowerCase() === "customize",
          customizedProductTime: productDetail?.customize_delivery_time
            ? productDetail?.customize_delivery_time
            : 0,
          customizeQuestions: productDetail.customize_questions && productDetail.customize_questions !== "undefined"
            ? JSON.parse(productDetail.customize_questions)
            : [],
        },
        () => {
          this.getSelectedAttributeVariants(
            productDetail.productAttributeVariantDetail
          );
          this.fetchSearchHierarchyList();
        }
      );
    } else {
      // product not found
      // redirect user to product-list page
    }
  };

  fetchProductCategoryList = async () => {
    const res = await this.props.getProductCategoryList(this.userId);
    if (res?.data?.data?.length > 0) {
      const selectOptionRes = this.createProductCategorySelectOptions(
        res.data.data,
        [],
        []
      );

      this.setState({
        firstLoadCategoryList: res.data.data,
        productCategoryList: selectOptionRes.selectOptions
          ? selectOptionRes.selectOptions
          : [],
        productParentCategoryList: selectOptionRes.selectParentOptions
          ? selectOptionRes.selectParentOptions
          : [],
      });
    }
  };

  setCourierList = async () => {
    const courierRes = this.createAvailableCourierSelectOptions(
      this.state.courierList,
      []
    );
    await this.setState({
      courierList: courierRes.selectOptions ? courierRes.selectOptions : [],
    });
  };

  createDefaultGalleryImagePattern = (images) => {
    let defaultImages = images.map((imgPath) => {
      return {
        stream: imgPath,
      };
    });

    return defaultImages;
  };

  createProductCategorySelectOptions = (
    categoryList,
    selectedProductCategory = [],
    selectedParentCategories = []
  ) => {
    let defaultSelectedOptions = [];
    let defaultParentSelectedOptionsList = [];
    let filterParentCategoryList,
      filterCategoryList,
      filterByParentcategoryId = [];

    filterParentCategoryList = categoryList.filter((key) => key.parentId == 0);

    filterCategoryList = categoryList.filter((key) => key.parentId !== 0);
    selectedParentCategories.forEach((element) => {
      if (filterByParentcategoryId.length > 0) {
        let tempArr = filterByParentcategoryId;
        filterByParentcategoryId = filterCategoryList.filter(
          (ele) => ele.parentId === element
        );
        filterByParentcategoryId = filterByParentcategoryId.concat(tempArr);
      } else {
        filterByParentcategoryId = filterCategoryList.filter(
          (ele) => ele.parentId === element
        );
      }
    });
    let selectOptions = filterByParentcategoryId.map((item) => {
      if (selectedProductCategory.includes(item.id)) {
        defaultSelectedOptions.push({
          value: `${item.id}`,
          label: `${item.name}`,
          parentId: `${item.parentId}`,
        });
      }
      return {
        value: `${item.id}`,
        label: `${item.name}`,
        parentId: `${item.parentId}`,
      };
    });
    let selectParentOptions = filterParentCategoryList.map((item) => {
      if (selectedParentCategories.includes(item.id)) {
        defaultParentSelectedOptionsList.push({
          value: `${item.id}`,
          label: `${item.name}`,
          parentId: `${item.parentId}`,
        });
      }
      return {
        value: `${item.id}`,
        label: `${item.name}`,
        parentId: `${item.parentId}`,
      };
    });
    return {
      defaultSelectedOptions,
      selectOptions,
      selectParentOptions,
      defaultParentSelectedOptionsList,
    };
  };

  createAvailableCourierSelectOptions = (
    courierList,
    selectedCourierList = []
  ) => {
    let defaultSelectedOptions = [];

    let selectOptions = courierList.map((item) => {
      if (selectedCourierList.includes(item)) {
        defaultSelectedOptions.push({ value: `${item}`, label: `${item}` });
      }
      return { value: `${item}`, label: `${item}` };
    });

    return { defaultSelectedOptions, selectOptions };
  };

  createAttributeSelectOptions = (attributesList, selectedAttributes = []) => {
    let defaultSelectedOptions = [];

    let selectedAttributesNameArray = [];

    if (selectedAttributes.length > 0) {
      selectedAttributes.forEach((attribute) => {
        selectedAttributesNameArray.push(attribute.name.toLowerCase());
      });
    }
    let selectOptions = attributesList.map((attribute) => {
      let attributeName = attribute.name;
     
      if (selectedAttributesNameArray.includes(attributeName.toLowerCase())) {
        defaultSelectedOptions[selectedAttributesNameArray.indexOf(attributeName.toLowerCase())] = {
          value: attributeName,
          label: attributeName.replace("_", " "),
        };
      }
      return { value: attributeName, label: attributeName.replace("_", " ") };
    });

    return { selectOptions, defaultSelectedOptions };
  };

  createDefaultVariantCombination = (productAttributeVariantDetail = []) => {
    let defaultCombination = [];
    let tempAttributeImage = []

    productAttributeVariantDetail.forEach((pair) => {
      defaultCombination.push([
        JSON.parse(pair.variant_value),
        {
          price: pair.price,
          one_on_one_meeting_price: pair.one_on_one_meeting_price,
          image: pair.image ? pair.image : "",
        },
      ]);
      tempAttributeImage.push(pair.image ? pair.image : "")
    });
    this.setState({
      attributeImage :tempAttributeImage
    })

    return defaultCombination;
  };

  handleChange = (e, key, index, titleIndex) => {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }

    let { value } = e.target;
    if (key === "acsCatTitle") {
      let subCatList = [...this.state.acsCatSubcategories];
      subCatList[index][key] = value;
      this.setState({
        acsCatSubcategories: [...subCatList],
      });
    } else if (key.includes("Titles")) {
      let subCatList = [...this.state.acsCatSubcategories];
      subCatList[index][key][titleIndex] = value;
      this.setState({
        acsCatSubcategories: [...subCatList],
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }

    if (key == "price_type") {
      this.state.errorData.salePrice = [];
    }

    if (key == "sale_price") {
      this.setState({
        discounted_percentage: parseInt(value),
      });
    }
    if (key == "sale_price") {
      if(this.state.errorData &&
        this.state.errorData["salePrice"] && 
        this.state.errorData["salePrice"][0])
        {
        this.state.errorData["salePrice"][0] = null;
      }
      this.setState((prevState) => ({
        ...prevState,
        [key]: parseInt(value),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }

    if (key == "o_o_o_meeting_price") {
      this.setState((prevState) => ({
        ...prevState,
        [key]: parseInt(value),
      }));
    }

    if (key == "customizeQuestions") {
      let tempCustomizeQuesArray = [...this.state.customizeQuestions];
      tempCustomizeQuesArray[index] = value;
      this.setState({
        [key]: [...tempCustomizeQuesArray],
      });
    }
  };

  confirmProductInactiveAlert(event, key) {
    event.persist();
    this.alertModal({
      title: "Confirm change?",
      message:
        "Inactive this product will cancel all the customer's subscription associated with this product.",
      singleButton: false,
      positiveButton: "Continue",
      negativeButton: "Cancel",
      id: 1,
      data: event,
    });
  }

  handleGalleryImages = async (images) => {
    if (images == ""){
      this.setState({
        galleryImages: []
      })
    }
    let totalMediaSize = 0;
     images?.length && images.map((item)=>{
      totalMediaSize += item?.file?.size
    })
    if(totalMediaSize > 20000000){
      this.setState({
        errorData : {
          ...this.state.errorData, 
        largeFileError : "You can not upload images more than 20mb file size"
        }
      })
      return
    }
    if (images?.length > 0) {
      images = images?.map((img) => {
        if (img?.file) {
          img["fileName"] = img?.file?.name;
          return img;
        }
        return img;
      });
    }

    this.setState({
      images: images,
      finalImgArray: images
    });
  };
  handleParentProductCategoryChange = async (event) => {
    if (
      this.state.errorData &&
      this.state.errorData["selectedParentProductCategoryId"] &&
      this.state.errorData["selectedParentProductCategoryId"][0]
    ) {
      this.state.errorData["selectedParentProductCategoryId"][0] = null;
    }

    let selectedParentCategories = [];
    let isAccessCategory = false;
    if (event) {
      isAccessCategory = event.label.toLocaleLowerCase() === "access";
      this.setState({
        isCustomizeCategory: event.label.toLocaleLowerCase() === "customize",
      });
      if (event.label.toLocaleLowerCase() !== "customize") {
        this.setState({
          isDigitalChecked: false,
        });
      }
      selectedParentCategories.push(+event.value);
    }
    let defaultParentSelectedOption = [event];
    const res = await this.createProductCategorySelectOptions(
      this.state.productDetail.categoriesList
        ? this.state.productDetail.categoriesList
        : this.state.firstLoadCategoryList,
      selectedParentCategories
    );

    this.setState({
      productCategoryList: res.selectOptions ? res.selectOptions : [], // child category
      productParentCategoryList: res.selectParentOptions
        ? res.selectParentOptions
        : [], // Parent category
      defaultSelectedOptions: res.defaultSelectedOptions
        ? res.defaultSelectedOptions
        : [], // child category
      defaultParentSelectedOption: res.defaultParentSelectedOptionsList
        ? res.defaultParentSelectedOptionsList
        : [], // Parent category
    });

    if (isAccessCategory || !event) {
      const productDeatils = this.state;

      productDeatils["productHeight"] = 0;
      productDeatils["productWidth"] = 0;
      productDeatils["productLength"] = 0;
      productDeatils["productWeight"] = 0;
      productDeatils["selectedCourierList"] = [];

      this.setState({
        ...productDeatils,
        selectedParentProductCategoryId: selectedParentCategories,
        defaultParentSelectedOption,
        isAccessCategoryInCategories: true,
        is_shipping_free: true,
      });
    } else {
      this.setState({
        selectedParentProductCategoryId: selectedParentCategories,
        defaultParentSelectedOption,
        isAccessCategoryInCategories: false,
      });
    }
    this.fetchSearchCategoryList();
  };

  handleAlertParentAccessCategory = async (event, value) => {
    if (this.state.defaultParentSelectedOption.length){

      this.setState({
        is_sale: false,
      });
    }
    if (this.state?.defaultParentSelectedOption[0]?.label === event.label) {
      return null;
    }
    this.setState({
      searchCategoryParents: [],
      searchCategoryValue: "",
      searchCategoryId: 0,
      searchCategoryChildOptions: [],
      customizeQuestions: [],
      customizedProductTime: 0,
    });

    let category = event.label;

    if (category && category.toLocaleLowerCase() !== "access") {
      return this.handleParentProductCategoryChange(event);
    }
    if (value.action === "clear") {
      return this.handleParentProductCategoryChange(event);
    }
    this.alertModal({
      message: `Are you sure want to Add Access category? `,
      positiveButton: "Yes",
      negativeButton: "No",
      id: 2,
      data: event,
    });
    this.setState({
      productTimeFrame: "",
      sale_price: 0,
    });
  };

  handleAlertAccessCategory = async (event, value) => {
    let category = null;
    let flag = false;
    if (value.action === "remove-value") {
      category = value?.removedValue.label;
      flag = true;
    } else {
      category = value?.option?.label;
      flag = false;
    }
    if (category?.toLocaleLowerCase() !== "access") {
      return this.handleProductCategoryChange(event);
    }
    if (value.action === "clear") {
      return this.handleProductCategoryChange(event);
    }
  };

  handleCourierChange = (event) => {
    if (
      this.state.errorData &&
      this.state.errorData["selectedCourierList"] &&
      this.state.errorData["selectedCourierList"][0]
    ) {
      this.state.errorData["selectedCourierList"][0] = null;
    }

    let selectedCourier = [];
    if (event && Object.keys(event).length > 0) {
      selectedCourier.push(event.value);
    }

    let defaultSelectedCourierOptions = event;
    this.setState({
      selectedCourierList: selectedCourier,
      defaultSelectedCourierOptions,
    });
  };

  convertImageToBase64(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState((prevState) => {
        return {
          base64GalleryImages: [
            ...prevState.base64GalleryImages,
            fileReader.result,
          ],
        };
      });

    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  readFile(file) {
    //Create an instance of FileReader
    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) =>
      this.setState({
        previewProductImage: [fileReader.result],
      });
    //Start reading the file
    fileReader.readAsDataURL(file);
  }

  triggerClick = () => {
    this.fileUpload.current.click();
  };
  
  validateProductInfo = async (existingSlotOverwritten) => {
    let isFormValid = true;
    this.state.errorData = {
      ...this.state.errorData,
      productTitle: [],
      productPrice: [],
      productImage: [],
      productTimeFrame: [],
      productHeight: [],
      productWidth: [],
      productLength: [],
      productWeight: [],
      productDescription: [],
      selectedParentProductCategoryId: [],
      productAttribute: [],
      selectedAttributeVariants: [],
      variantCombination:[],
      selectedCourierList: [],
      order_limit: [],
      acsCatTitle: [],
      acsCatDescription: [],
      getVarientCombination: [],
      acsCatSubcategories: this.state.acsCatSubcategories.map((a) => ({
        acsCatVideos: [],
        acsCatAudios: [],
        acsCatImages: [],
        acsCatDocuments: [],
      })),
      freeSeminar: [],
      selectedOneOnOneFrequency: [],
      groupOneOnOne: [],
      trialCost: [],
      subscriptionCost: [],
      sessionHour: [],
      selectedWeekDay: [],
      selectedOneOnOneMeetingSlots: {
        Sun: [],
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
      },
      mainError: []
    };

    let notSelectedOneOnOneFrequencyCount = 0;
    let notSelectedOneOnOneWeekDayCount = 0;

    if (this.state.isOneOnOneMeetingChecked) {
      Object.entries(this.state.selectedFrequency)
        .slice(1, 5)
        .map(([freq, bool]) => {
          if (!bool) {
            notSelectedOneOnOneFrequencyCount += 1;
          }
          if (notSelectedOneOnOneFrequencyCount == 4) {
            isFormValid = false;
            this.state.errorData.selectedOneOnOneFrequency = [
              "Please enter frequency.",
            ];
          }
        });

      Object.entries(this.state.isWeekDaySelected).map(([weekDay, bool]) => {
        if (!bool) {
          notSelectedOneOnOneWeekDayCount += 1;
        }
        if (notSelectedOneOnOneWeekDayCount == 7) {
          isFormValid = false;
          this.state.errorData.selectedWeekDay = ["Please select week day"];
        }
      });

      Object.entries(this.state.slots).map(([weekDay, slotsArray]) => {
        slotsArray.map((startEndTimeArray) => {
          const slotsNumber = this.calculateHourMinuteDiff(
            startEndTimeArray,
            -1
          );
          if (
            !startEndTimeArray[0] ||
            !startEndTimeArray[1] ||
            !slotsNumber ||
            !isInteger(slotsNumber)
          ) {
            isFormValid = false;
            this.state.errorData.selectedOneOnOneMeetingSlots[weekDay].push([
              "The selected slot should be as per the session duration",
            ]);
            this.state.errorData["mainError"] = ["Please Check Time Slots!"]
          } else {
            this.state.errorData.selectedOneOnOneMeetingSlots[weekDay].push([]);
            this.state.errorData["mainError"] = []
          }
        });
      });

      if (this.state.trialCost == 0) {
        isFormValid = false;
        this.state.errorData.trialCost = ["Please enter trial cost"];
      }

      if (this.state.subscriptionCost == 0) {
        isFormValid = false;
        this.state.errorData.subscriptionCost = [
          "Please enter subscription cost",
        ];
      }

      if (this.state.sessionHour == "00" && this.state.sessionMinute == "00") {
        isFormValid = false;
        this.state.errorData.sessionHour = ["Please enter session duration"];
      }
    }

    if (this.state.isGroupMeetingChecked) {
      this.state.groupMeetingDetails.map((groupMeetingDetail, index) => {
        this.state.errorData["groupMeetingDetails"][index]["slots"] = {
          Sun: [],
          Mon: [],
          Tue: [],
          Wed: [],
          Thu: [],
          Fri: [],
          Sat: [],
        };
        if (!groupMeetingDetail["meetingName"].length) {
          isFormValid = false;
          this.state.errorData["groupMeetingDetails"][index]["meetingName"] = [
            "Enter meeting name",
          ];
        }
        if (!groupMeetingDetail["subscriptionCost"]) {
          isFormValid = false;
          this.state.errorData["groupMeetingDetails"][index][
            "subscriptionCost"
          ] = ["Enter subscription cost"];
        }

        if (
          groupMeetingDetail["sessionHour"] == "00" &&
          groupMeetingDetail["sessionMinute"] == "00"
        ) {
          isFormValid = false;
          this.state.errorData["groupMeetingDetails"][index]["sessionHour"] = [
            "Enter session duration",
          ];
        }

        if (!groupMeetingDetail["groupMeetingFrequency"].length) {
          isFormValid = false;
          this.state.errorData["groupMeetingDetails"][index][
            "groupMeetingFrequency"
          ] = ["Select group frequency"];
        }

        if (!groupMeetingDetail["isMonthlyGroupFrequencyChecked"]) {
          let notSelectedGroupWeekDayCount = 0;
          Object.entries(groupMeetingDetail["isWeekDaySelected"]).map(
            ([weekDay, bool]) => {
              if (!bool) {
                notSelectedGroupWeekDayCount += 1;
              }
              if (notSelectedGroupWeekDayCount == 7) {
                isFormValid = false;
                this.state.errorData["groupMeetingDetails"][index][
                  "selectedWeekDay"
                ] = ["Select week day"];
              }
            }
          );

          Object.entries(groupMeetingDetail["slots"]).map(
            ([weekDay, slotsArray]) => {
              slotsArray &&
                slotsArray.map((startEndTimeArray) => {
                  const slotsNumber = this.calculateHourMinuteDiff(
                    startEndTimeArray,
                    index
                  );
                  if (
                    !startEndTimeArray[0] ||
                    !startEndTimeArray[1] ||
                    !slotsNumber ||
                    !isInteger(slotsNumber) ||
                    slotsNumber > 1
                  ) {
                    isFormValid = false;
                    this.state.errorData["groupMeetingDetails"][index]["slots"][
                      weekDay
                    ].push([
                      "The selected slot should be as per the session duration",
                    ]);
                    this.state.errorData["groupMeetingDetails"][index]["mainError"] = ["Please Check Time Slots!"]
                  } else {
                    this.state.errorData["groupMeetingDetails"][index]["slots"][
                      weekDay
                    ].push([]);
                    this.state.errorData["groupMeetingDetails"][index]["mainError"] = []
                  }
                });
            }
          );
        } else {
          if (!groupMeetingDetail["meetingMonthlyWeekDay"]) {
            isFormValid = false;
            this.state.errorData["groupMeetingDetails"][index][
              "meetingMonthlyWeekDay"
            ] = ["Select week day"];
          }

          if (!groupMeetingDetail["meetingMonthlyWeekOfMonth"]) {
            isFormValid = false;
            this.state.errorData["groupMeetingDetails"][index][
              "meetingMonthlyWeekOfMonth"
            ] = ["Select month week"];
          }

          const slotsNumber = this.calculateHourMinuteDiff(
            [
              groupMeetingDetail["meetingMonthlyStartTime"],
              groupMeetingDetail["meetingMonthlyEndTime"],
            ],
            index
          );

          if (
            (!groupMeetingDetail["meetingMonthlyEndTime"]?.split(":")[0] &&
              !groupMeetingDetail["meetingMonthlyEndTime"]?.split(":")[1]) ||
            (!groupMeetingDetail["meetingMonthlyStartTime"]?.split(":")[0] &&
              !groupMeetingDetail["meetingMonthlyStartTime"]?.split(":")[1]) ||
            !slotsNumber ||
            !isInteger(slotsNumber)
          ) {
            isFormValid = false;
            this.state.errorData["groupMeetingDetails"][index][
              "meetingMonthlyStartTime"
            ] = ["Select valid time"];
          } else {
            this.state.errorData["groupMeetingDetails"][index][
              "meetingMonthlyStartTime"
            ] = [];
          }
        }
      });
    }
    if (
      this.state.isFreeSeminarCreated &&
      this.state.isAccessCategoryInCategories
    ) {
      if (!this.state.formData.start) {
        isFormValid = false;
        this.state.errorData.freeSeminar = ["Please enter meeting Details"];
      } else {
        const startDate = new Date(
          this.state.formData.date + " " + this.state.formData.start
        );
        const endDate = new Date(
          this.state.formData.date + " " + this.state.formData.end
        );
        if (startDate.getTime() >= endDate.getTime()) {
          isFormValid = false;
          this.state.errorData.freeSeminar = [
            "Meeting start-time cannot be greater than end-time",
          ];
        }
      }
    }

    if (
      this.state.isAccessCategoryInCategories &&
      !this.state.isOneOnOneMeetingChecked &&
      !this.state.isGroupMeetingChecked
    ) {
      isFormValid = false;
      this.state.errorData.groupOneOnOne = [
        "Please create either one on one or group meeting.",
      ];
    }
    if ((this.state.isVariantChange) && this.state.withAttribute
     ) {
      isFormValid = false;
      this.state.errorData.getVarientCombination = [
        "Please select the ( CREATE VARIENT COMBINATION ) to create varient products.",
      ];
    }
    if (
      this.state.withAttribute &&
      this.state.attributesDefaultSelectOptions == ""
    ) {
      isFormValid = false;
      this.state.errorData.productAttribute = [
        "Please select product attribute.",
      ];
    }

    if(this.state.withAttribute && !this.state.variantCombination.length){
      isFormValid = false;
      this.state.errorData.variantCombination = [
        "Please Create Combination. ",
      ];
    }

    if (
      this.state.withAttribute && 
      this.state.selectedAttributeVariants.length != this.state.attributesDefaultSelectOptions.length 
    ) {
      isFormValid = false;
      this.state.errorData.selectedAttributeVariants = [
        "Please select varients.",
      ];
    }

    if (this.state.withAttribute && this.state.variantCombination.length > 0) {
      for (let combination of this.state.variantCombination) {
        if (!combination[1]) {
          isFormValid = false;
          this.state.errorData.getVarientCombination = [
            "Please enter variation detail.",
          ];
          break;
        } else if (!combination[1].price || combination[1].price == 0) {
          isFormValid = false;
          this.state.errorData.getVarientCombination = [
            "Please enter variation price.",
          ];
          break;
        }
        if (
          this.state.isAccessCategoryInCategories &&
          this.state.isOneOnOneMeetingPriceChecked
        ) {
          if (
            !combination[1]?.one_on_one_meeting_price ||
            combination[1]?.one_on_one_meeting_price === 0
          ) {
            isFormValid = false;
            this.state.errorData.getVarientCombination = [
              "Please enter one on one meeting price for variants.",
            ];
            break;
          }
        }
      }
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWeight === 0
    ) {
      isFormValid = false;
      this.state.errorData.productWeight = ["Product weight required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWeight > 0 &&
      isNaN(+this.state.productWeight)
    ) {
      isFormValid = false;
      this.state.errorData.productWeight = ["Enter a valid product weight."];
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productLength === 0
    ) {
      isFormValid = false;
      this.state.errorData.productLength = ["Product length required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productLength > 0 &&
      isNaN(+this.state.productLength)
    ) {
      isFormValid = false;
      this.state.errorData.productLength = ["Enter a valid product length."];
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWidth === 0
    ) {
      isFormValid = false;
      this.state.errorData.productWidth = ["Product width required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productWidth > 0 &&
      isNaN(+this.state.productWidth)
    ) {
      isFormValid = false;
      this.state.errorData.productWidth = ["Enter a valid product weight."];
    }
    if (!this.state.isAccessCategoryInCategories) {
      if (this.state.order_limit === 0) {
        isFormValid = false;
        this.state.errorData.order_limit = ["Order limit required."];
      } else if (
        this.state.order_limit < this.state.minimumOrderQuantityLimit
      ) {
        isFormValid = false;
        this.state.errorData.order_limit = [
          "Order limit quantity must be great than active subscription.",
        ];
      }
    }

    if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productHeight === 0
    ) {
      isFormValid = false;
      this.state.errorData.productHeight = ["Product height required."];
    } else if (
      !this.state.isAccessCategoryInCategories &&
      !this.state.isDigitalChecked &&
      this.state.productHeight > 0 &&
      isNaN(+this.state.productHeight)
    ) {
      isFormValid = false;
      this.state.errorData.productHeight = ["Enter a valid product height."];
    }

    if (
      !this.state.productTitle &&
      this.state.productTitle.trim().length === 0
    ) {
      isFormValid = false;
      this.state.errorData.productTitle = [
        this.state.isAccessCategoryInCategories
          ? `Company name field cannot be empty.`
          : `Product title field cannot be empty.`,
      ];
    }

    if (this.state.productTitle.search("%")) {
      this.state.productTitle = this.state.productTitle.replace("%", "");
    }

    if (!this.state.isAccessCategoryInCategories) {
      if (this.state.productPrice.trim().length == 0) {
        isFormValid = false;
        this.state.errorData.productPrice = ["Price field cannot be empty."];
      }

      if (
        this.state.is_sale &&
        this.state.price_type == 1 &&
        +this.state.productPrice <= +this.state.sale_price
      ) {
        isFormValid = false;
        this.state.errorData.salePrice = [
          "sale price cannot be greater than base price. ",
        ];
      }
    }

    if (
      this.state.is_sale &&
      this.state.price_type == 2 &&
      this.state.sale_price > 100
    ) {
      isFormValid = false;
      this.state.errorData.salePrice = [
        "sale price cannot be greater than 100%. ",
      ];
    }

    if (!this.state.isAccessCategoryInCategories){
      if (this.state.is_sale && !this.state.sale_price) {
      isFormValid = false;
      this.state.errorData.salePrice = ["Please enter a sale price."];
    }

    if (this.state.is_sale) {
      if (
        !this.state.sale_price || this.state.sale_price < 0 ||
        this.state.sale_price.toString().search("^[0-9.]+$")
      ) {
        isFormValid = false;
        this.state.errorData.salePrice = ["Please enter valid sale price."];
      }
    }}

    if (!this.state.productImage || this.state.productImage == "") {
      isFormValid = false;
      this.state.errorData.productImage = [
        "Product Image field cannot be empty.",
      ];
    }

    if (
      this.state.selectedParentProductCategoryId &&
      this.state.selectedParentProductCategoryId.includes(3) &&
      !this.state.productTimeFrame
    ) {
      isFormValid = false;
      this.state.errorData.productTimeFrame = [
        "Product time-frame field cannot be empty.",
      ];
    } else if (
      this.state.selectedParentProductCategoryId &&
      !this.state.selectedParentProductCategoryId.includes(3) &&
      !(this.state.productTimeFrame || !this.state.productTimeFrame)
    ) {
      isFormValid = false;
      this.state.errorData.productTimeFrame = [
        "Product time-frame field cannot be empty.",
      ];
    }

    if (this.state.productDescription.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.productDescription = [
        "Product Description field cannot be empty.",
      ];
    }

    if (this.state.selectedParentProductCategoryId.length === 0) {
      isFormValid = false;
      this.state.errorData.selectedParentProductCategoryId = [
        "Select alteast one parent category.",
      ];
    }

    if (
      this.state.selectedCourierList.length === 0 &&
      !this.state.isDigitalChecked &&
      !this.state.isAccessCategoryInCategories
    ) {
      isFormValid = false;
      this.state.errorData.selectedCourierList = [
        "Select alteast one courier service.",
      ];
    }
    if (this.state.isAccessCategoryInCategories) {
      this.state.acsCatSubcategories.map((subCategory, index) => {
        if (subCategory.acsCatTitle?.trim()?.length > 0) {
          this.state.errorData.acsCatTitle.push("");
        } else {
          this.state.errorData.acsCatTitle.push("Title cannot be empty!");
          isFormValid = false;
        }

        subCategory.acsCatVideosTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle + "." + subCategory.acsCatVideos[vIndex].type.split("/")[1];
          let oldNames = subCategory.acsCatVideoGallery.map((i) =>
            this.getFileNameFromUrl(i.url)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatVideos.push(`Video name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatVideosTitles.filter((t) => t === vTitle).length >
              1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatVideos.push(
              `Video name already in use!`
            );
          }
        });

        subCategory.acsCatAudiosTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle + "." + subCategory.acsCatAudios[vIndex].type.split("/")[1];
          let oldNames = subCategory.acsCatAudioGallery.map((i) =>
            this.getFileNameFromUrl(i.url)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatAudios.push(`Image name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatAudiosTitles.filter((t) => t === vTitle).length >
              1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatAudios.push(
              `Image name already in use!`
            );
          }
        });

        subCategory.acsCatImagesTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle + "." + subCategory.acsCatImages[vIndex]?.type.split("/")[1];
          let oldNames = subCategory.acsCatImageGallery.map((i) =>
            this.getFileNameFromUrl(i.url)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatImages.push(`Image name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatImagesTitles.filter((t) => t === vTitle).length >
              1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatImages.push(
              `Image name already in use!`
            );
          }
        });

        subCategory.acsCatDocumentsTitles.map((vTitle, vIndex) => {
          vTitle =
            vTitle +
            "." +
            subCategory.acsCatDocuments[vIndex].type.split("/")[1];
          let oldNames = subCategory.acsCatDocGallery.map((i) =>
            this.getFileNameFromUrl(i.url)
          );
          if (vTitle === "") {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[index].acsCatSubcategories[
              index
            ].acsCatDocuments.push(`Image name cannot be empty`);
          } else if (
            oldNames.includes(vTitle) ||
            subCategory.acsCatDocumentsTitles.filter((t) => t === vTitle)
              .length > 1
          ) {
            isFormValid = false;
            this.state.errorData.acsCatSubcategories[
              index
            ].acsCatDocuments.push(`Image name already in use!`);
          }
        });
      });
    }
    let tempState = this.state;
    this.setState({
      ...tempState,
    });
    if (!existingSlotOverwritten && this.editedMeetingFromCalendar.length) {
      for (const item of this.editedMeetingFromCalendar) {
        const res = await this.props.updateSelectedEvent({
          ...item,
          validate: false,
        });
        if (res.isError) {
          isFormValid = false;
        }
      }
    }
    if (this.state.showCalanderLoader){
      isFormValid = false;
    }
    return isFormValid;
  };

  submitAlertMessage = async (body) => {
    const pleaseWaitLoader = this.state.pleaseWaitLoader
    this.alertModal({
      title: "Product creation",
      message:
        "Processing your new product creation, do not reload the screen.",
      id: 1,
      from: true,
      pleaseWaitLoader
    });
  };

  alertVariant = async() => {
    this.alertModal({
      message:
        "If you want to do change in variant then you have to create combination again.",
        singleButton: true,
    });
  }
  handleSubmit = async (existingSlotOverwritten) => {
   await this.setState({
      pleaseWaitLoader : true
    })
    const weekDay = {
      Sun: "Sunday",
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
    };

    if (this.userId) {
      await this.setState({
        successMessage: "",
        errorMessage: "",
        bottomErrorMessage: "",
        showLoader: true,
      });

      if (await this.validateProductInfo(existingSlotOverwritten)) {
        this.submitAlertMessage();
        let res;

        if (this.state.videos && this.state.videos.length > 0) {
          for (const file of this.state.videos) {
            file["status"] = "Waiting";
          }
          this.setState({ videos: this.state.videos });
        }
        let acsSubcategoryList = this.state.acsCatSubcategories;
        for (let i = 0; i < this.state.acsCatSubcategories; i++) {
          if (
            this.state.acsCatSubcategories[i].acsCatVideos &&
            this.state.acsCatSubcategories[i].acsCatVideos.length > 0
          ) {
            for (const file of this.state.acsCatSubcategories[i].acsCatVideos) {
              file["status"] = "Waiting";
            }
            acsSubcategoryList[i].acsCatVideos =
              this.state.acsCatSubcategories[i].acsCatVideos;
          }
          if (
            this.state.acsCatSubcategories[i].acsCatAudios &&
            this.state.acsCatSubcategories[i].acsCatAudios.length > 0
          ) {
            for (const file of this.state.acsCatSubcategories[i].acsCatAudios) {
              file["status"] = "Waiting";
            }
            acsSubcategoryList[i].acsCatAudios =
              this.state.acsCatSubcategories[i].acsCatAudios;
          }
          if (
            this.state.acsCatSubcategories[i].acsCatImages &&
            this.state.acsCatSubcategories[i].acsCatImages.length > 0
          ) {
            for (const file of this.state.acsCatSubcategories[i].acsCatImages) {
              file["status"] = "Waiting";
            }
            acsSubcategoryList[i].acsCatImages =
              this.state.acsCatSubcategories[i].acsCatImages;
          }
          if (
            this.state.acsCatSubcategories[i].acsCatDocuments &&
            this.state.acsCatSubcategories[i].acsCatDocuments.length > 0
          ) {
            for (const file of this.state.acsCatSubcategories[i]
              .acsCatDocuments) {
              file["status"] = "Waiting";
            }
            acsSubcategoryList[i].acsCatDocuments =
              this.state.acsCatSubcategories[i].acsCatDocuments;
          }
        }
        this.setState({
          acsCatSubcategories: [...acsSubcategoryList],
        });

        const {
          productModelNo,
          productTitle,
          productPrice,
          productImage,
          productTimeFrame,
          selectedCourierList,
          productHeight,
          productWidth,
          productLength,
          productWeight,
          isActive,
          productDescription,
          type,
          selectedParentProductCategoryId,
          withAttribute,
          selectedAttributeVariants,
          variantCombination,
          stripe_product_id,
          primaryIndex,
          is_shipping_free,
          is_sale,
          order_limit,
          is_registered_on_store,
          seller_store_product_id,
          accessToken,
          price_type,
          sale_price,
          o_o_o_meeting_price,
          shop,
          isDigitalChecked,
          isCustomizeCategory,
          customizedProductTime,
          customizeQuestions,
        } = this.state;
        if (this.state.isEdit) {
          // call edit product api
          res = await this.props.updateProductDetail(
            {
              productModelNo,
              productTitle,
              productPrice: this.state.isAccessCategoryInCategories
                ? 0
                : productPrice
                ? productPrice
                : 0,
              type,
              productImage,
              // galleryImages: JSON.stringify(galleryImages),
              stripe_product_id,
              productTimeFrame,
              primaryIndex,
              is_registered_on_store,
              accessToken,
              shop,
              seller_store_product_id,
              selectedCourierList:
                isDigitalChecked && isCustomizeCategory
                  ? "[]"
                  : JSON.stringify(selectedCourierList),
              productHeight:
                isDigitalChecked && isCustomizeCategory ? 0 : productHeight,
              productWidth:
                isDigitalChecked && isCustomizeCategory ? 0 : productWidth,
              productLength:
                isDigitalChecked && isCustomizeCategory ? 0 : productLength,
              productWeight:
                isDigitalChecked && isCustomizeCategory ? 0 : productWeight,
              isActive,
              // isChatActive,
              productDescription,
              // selectedProductCategoryId: JSON.stringify(
              //     selectedProductCategoryId.concat(
              //         selectedParentProductCategoryId
              //     )
              // ),
              selectedProductCategoryId: JSON.stringify(
                selectedParentProductCategoryId
              ),
              accessCategoryDescription: this.state.acsCatDescription,
              withAttribute,
              selectedAttributeVariants: JSON.stringify(
                selectedAttributeVariants
              ),
              variantCombination: JSON.stringify(variantCombination),
              is_shipping_free,
              order_limit: this.state.isAccessCategoryInCategories
                ? 0
                : order_limit,
              is_sale: JSON.stringify(
                selectedParentProductCategoryId == 3 ? 0 : is_sale),
              price_type,
              sale_price,
              o_o_o_meeting_price: this.state.isOneOnOneMeetingPriceChecked
                ? o_o_o_meeting_price
                : null,
              isDigitalChecked: isDigitalChecked && isCustomizeCategory ? 1 : 0,
              searchCategoryId: this.state.searchCategoryId.length
                ? this.state.searchCategoryId
                : null,
              updateStatusWhenDenied:
                this.state.productDetail.status == "denied" ? 1 : 0,
              customizedProductTime: this.state.customizedProductTime,
              customizeQuestions: customizeQuestions?.length
                ? JSON.stringify(
                    customizeQuestions
                      ?.filter((i) => i?.trim() !== "")
                      ?.map((i) => i?.trim())
                  )
                : JSON.stringify(customizeQuestions),
              subscriptionCostEditedArray: this.subscriptionCostEditedArray
                .length
                ? JSON.stringify(this.subscriptionCostEditedArray)
                : null,
            },
            this.userId,
            this.state.productId
          );
        } else {
          // call add product api
          res = await this.props.addProduct(
            {
              productModelNo,
              productTitle,
              productPrice: this.state.isAccessCategoryInCategories
                ? 0
                : productPrice
                ? productPrice
                : 0,
              productImage,
              productTimeFrame,
              selectedCourierList:
                isDigitalChecked && isCustomizeCategory
                  ? "[]"
                  : JSON.stringify(selectedCourierList),
              productHeight:
                isDigitalChecked && isCustomizeCategory ? 0 : productHeight,
              productWidth:
                isDigitalChecked && isCustomizeCategory ? 0 : productWidth,
              productLength:
                isDigitalChecked && isCustomizeCategory ? 0 : productLength,
              productWeight:
                isDigitalChecked && isCustomizeCategory ? 0 : productWeight,
              isActive,
              productDescription,
              selectedProductCategoryId: JSON.stringify(
                selectedParentProductCategoryId
              ),
              accessCategoryDescription: this.state.acsCatDescription,
              withAttribute,
              selectedAttributeVariants: JSON.stringify(
                selectedAttributeVariants
              ),
              variantCombination: JSON.stringify(variantCombination),
              is_shipping_free,
              primaryIndex,
              order_limit: this.state.isAccessCategoryInCategories
                ? 0
                : order_limit,
                is_sale: JSON.stringify(
                  selectedParentProductCategoryId == 3 ? 0 : is_sale),
              price_type,
              sale_price,
              o_o_o_meeting_price: this.state.isOneOnOneMeetingPriceChecked
                ? o_o_o_meeting_price
                : null,
              isDigitalChecked: isDigitalChecked && isCustomizeCategory ? 1 : 0,
              searchCategoryId: this.state.searchCategoryId.length
                ? this.state.searchCategoryId
                : null,
              customizedProductTime: this.state.customizedProductTime,
              customizeQuestions: customizeQuestions?.length
                ? JSON.stringify(
                    customizeQuestions
                      ?.filter((i) => i?.trim() !== "")
                      ?.map((i) => i?.trim())
                  )
                : JSON.stringify(customizeQuestions),
            },
            this.userId
          );
        }
        if (res && res.data) {
          this.setState({
            slug : res.data.data.slug,
            productId : res.data.data.productId
          })
          this.productId = this.state.productId
            ? this.state.productId
            : res.data.data.productId;

          if (
            this.state.isSeminarEdited ||
            this.state.formData.start == "" ||
            this.state.formData.end == "" ||
            this.state.formData.date == ""
          ) {
            await this.handleSubmitFreeSeminar(this.productId);
          }
          if (this.canceledMeetingFromCalendar.length) {
            for (const item of this.canceledMeetingFromCalendar) {
              await this.props.deleteSelectedEvent(item);
            }
          }
          let isOneOnOneMeetingChecked = this.state.isOneOnOneMeetingChecked;
          let isGroupMeetingChecked = this.state.isGroupMeetingChecked;

          if (
            this.state.isOneOnOneMeetingDetailsEdited ||
            this.state.isGroupMeetingDetailsEdited
          ) {
            await this.addGroupAndOneOnOneMeetingDetails(
              {
                oneOnOneMeetingDetails: isOneOnOneMeetingChecked
                  ? {
                      id: this.state.oneOnOneId,
                      isWeekDaySelected: this.state.isWeekDaySelected,
                      isFrequencySelected: this.state.selectedFrequency,
                      slots: this.state.slots,
                      sessionHour: this.state.sessionHour,
                      sessionMinute: this.state.sessionMinute,
                      trialCost: this.state.trialCost,
                      subscriptionCost: this.state.subscriptionCost,
                    }
                  : 0,
                groupMeetingDetails: isGroupMeetingChecked
                  ? this.state.groupMeetingDetails
                  : 0,
                deletedGroupMeetingsIdArray:
                  this.state.deletedGroupMeetingsIdArray,
              },
              this.productId
            );
          }
          if (
            this.state.isSeminarEdited ||
            this.state.formData.start == "" ||
            this.state.formData.end == "" ||
            this.state.formData.date == "" ||
            this.state.formData.date == "" ||
            this.state.formData.name == ""
          )
            await this.handleSubmitFreeSeminar(this.productId);

          if (res.data.isSuccess) {
            this.productId = this.state.productId
              ? this.state.productId
              : res.data.data.productId;
              const newImages = this.state.images;
            const newVideos = this.state.videos;
            const attributeImg = this.state.attributeImage 
            const newAcsCatVideos = this.state.acsCatSubcategories.map(
              (item) => item.acsCatVideos
            );
            const newAcsCatAudios = this.state.acsCatSubcategories.map(
              (item) => item.acsCatAudios
            );
            const newAcsCatImages = this.state.acsCatSubcategories.map(
              (item) => item.acsCatImages
            );
            const newAcsCatDocuments = this.state.acsCatSubcategories.map(
              (item) => item.acsCatDocuments
            );
            if (
              (attributeImg && attributeImg?.length > 0) ||
              (newImages && newImages.length > 0) ||
              (newVideos && newVideos.length > 0) ||
              (newAcsCatVideos && newAcsCatVideos.length > 0) ||
              (newAcsCatAudios && newAcsCatAudios.length > 0) ||
              (newAcsCatImages && newAcsCatImages.length > 0) ||
              (newAcsCatDocuments && newAcsCatDocuments.length > 0)
            ) {
              const fileUploadResults = await this.uploadAllSelectedFile();
            }
            if (
              (newVideos && newVideos.length > 0) ||
              (newAcsCatVideos && newAcsCatVideos.length > 0) ||
              (newAcsCatAudios && newAcsCatAudios.length > 0) ||
              (newAcsCatImages && newAcsCatImages.length > 0) ||
              (newAcsCatDocuments && newAcsCatDocuments.length > 0) ||
              (this.state.removedGalleryVideos &&
                this.state.removedGalleryVideos.length > 0) ||
              (this.state.removedAcsCatVideoGallery &&
                this.state.removedAcsCatVideoGallery.length > 0) ||
              (this.state.removedAcsCatAudioGallery &&
                this.state.removedAcsCatAudioGallery.length > 0) ||
              (this.state.removedAcsCatImageGallery &&
                this.state.removedAcsCatImageGallery.length > 0) ||
              (this.state.removedAcsCatDocGallery &&
                this.state.removedAcsCatDocGallery.length > 0)
            ) {
            }
            const galleryVideosUrl = this.state.galleryVideos
              .map((map) => map.url)
              ?.join();
              const galleryImageUrl = this.state.galleryImages.map(i => i.url || i.stream || i)
            const categoryLength = this.state.acsCatSubcategories.length;
            const updateProductFilesRes = await this.props.updateProductFiles(
              {
                productId: this.productId,
                galleryVideosUrl: galleryVideosUrl,
                galleryImageUrl: JSON.stringify(galleryImageUrl),
                accessCategorySubcategoryTitle: categoryLength
                  ? JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatTitle,
                      }))
                    )
                  : null,
                accessCategoryVideoGalleryUrl: categoryLength
                  ? JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatVideoGallery
                          .map((map) => map.url)
                          ?.join(", "),
                      }))
                    )
                  : null,
                accessCategoryAudioGalleryUrl: categoryLength
                  ? JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatAudioGallery
                          .map((map) => map.url)
                          ?.join(", "),
                      }))
                    )
                  : null,
                accessCategoryImageGalleryUrl: categoryLength
                  ? JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatImageGallery
                          .map((map) => map.url)
                          ?.join(", "),
                      }))
                    )
                  : null,
                accessCategoryDocGalleryUrl: categoryLength
                  ? JSON.stringify(
                      this.state.acsCatSubcategories.map((item, index) => ({
                        [index]: item.acsCatDocGallery
                          .map((map) => map.url)
                          ?.join(", "),
                      }))
                    )
                  : null,
              },
              this.userId
            );
            if (
              this.state.removedGalleryVideos &&
              this.state.removedGalleryVideos.length > 0
            ) {
              this.props.removeProductFile({
                productId: this.state.productId,
                filesUrl: this.state.removedGalleryVideos,
                fileUploadPathEndPoint: "/video_gallery",
              });
            }
            if (
              this.state.removedAcsCatVideoGallery &&
              this.state.removedAcsCatVideoGallery.length > 0
            ) {
              this.props.removeProductFile({
                productId: this.state.productId,
                filesUrl: this.state.removedAcsCatVideoGallery,
                fileUploadPathEndPoint: "/access/video",
              });
            }
            if (
              this.state.removedAcsCatAudioGallery &&
              this.state.removedAcsCatAudioGallery.length > 0
            ) {
              this.props.removeProductFile({
                productId: this.state.productId,
                filesUrl: this.state.removedAcsCatAudioGallery,
                fileUploadPathEndPoint: "/access/audio",
              });
            }
            if (
              this.state.removedAcsCatImageGallery &&
              this.state.removedAcsCatImageGallery.length > 0
            ) {
              this.props.removeProductFile({
                productId: this.state.productId,
                filesUrl: this.state.removedAcsCatImageGallery,
                fileUploadPathEndPoint: "/access/image",
              });
            }
            if (
              this.state.removedAcsCatDocGallery &&
              this.state.removedAcsCatDocGallery.length > 0
            ) {
              this.props.removeProductFile({
                productId: this.state.productId,
                filesUrl: this.state.removedAcsCatDocGallery,
                fileUploadPathEndPoint: "/access/doc",
              });
            }

            // success case
            this.setState({
              successMessage: res.data.message,
              showLoader: false,
              src: null,
            });
            window.scrollTo(0, 0);
            await this.fetchProductDetail();
            if (this.state.isEdit) {
              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
              }, 4000);
            } else {
              this.setState({
                productId: null,
                productModelNo: "",
                productTitle: "",
                productPrice: "",
                productImage: "",
                galleryImages: [],
                base64GalleryImages: [],
                removedGalleryImages: [],
                productTimeFrame: "",
                productHeight: "",
                productWidth: "",
                productLength: "",
                productWeight: "",
                isActive: 1,
                productDescription: "",
                previewProductImage: null,
                croppedImageUrl: null,
                productCategoryList: [],
                defaultSelectedOptions: [],
                courierList: ["Fedex", "USPS", "UPS"],
                selectedCourierList: [],
                defaultSelectedCourierOptions: [],
                src: null,
                withAttribute: 0, //0 = false | 1 = true
                attributesList: [],
                attributesDefaultSelectOptions: [],
                attributesSelectOptions: [],
                selectedAttributeVariants: [],
                variantCombination: [],
                order_limit: "",
                galleryVideos: [],
                removedGalleryVideos: [],
                acsCatVideoGallery: [],
                removedAcsCatVideoGallery: [],
                acsCatAudioGallery: [],
                removedAcsCatAudioGallery: [],
                acsCatImageGallery: [],
                removedAcsCatImageGallery: [],
                acsCatDocGallery: [],
                removedAcsCatDocGallery: [],
                acsCatDescription: "",
              });

              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
              }, 4000);
            }
            setTimeout(() => {
              this.props.navigate("/product-list");
            }, 1000);
          } else {
            // response with failed case
            this.setState({
              errorMessage: res.data.message,
              showLoader: false,
            });
          }
        } else {
          // error to get respone
          this.setState({
            errorMessage: "Something went wrong. Please try again later.",
            showLoader: false,
          });
        }
        if (
          !existingSlotOverwritten &&
          this.editedGroupMeetingFromForm.length
        ) {
          let selectedDay = [];
          for (let item of this.state.groupMeetingDetails) {
            for (let day of Object.keys(item.isWeekDaySelected)) {
              if (item.isWeekDaySelected[day]) {
                selectedDay.push(weekDay[day]);
              }
            }
          }
          var uniqueDay = selectedDay.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
          const body = {
            selectedSlots: this.editedGroupMeetingFromForm,
            selectedWeekDay: uniqueDay,
          };
          await this.props.sendResheduleMailFromForm(body);
        }
        if (!existingSlotOverwritten && this.editedMeetingFromForm.length) {
          let selectedDay = [];
          for (let item of Object.keys(this.state.selectedSlots)) {
            selectedDay.push(weekDay[item]);
          }
          const body = {
            selectedSlots: this.editedMeetingFromForm,
            selectedWeekDay: selectedDay,
          };
          await this.props.sendResheduleMailFromForm(body);
        }
      } else {
        if (this.state.showCalanderLoader){
          this.setState({
            bottomErrorMessage: "Slots are loading please wait!",
          });
        }else{

          this.setState({
            bottomErrorMessage: "Please Fill All the (*) Details.",
          });
        }
        this.setState({
          showLoader: false,
        });
      }
    } else {
      // user is not logged in
      this.props.navigate("/login");
    }
  };

  async handleWithAttributeChange(event) {
    const value = +event.target.value;
    let attributesInfo = null;
    let state = {};
    if (value == 1) {
     await this.setState({
        withAttribute: value,
        price_type: 2,
        isFirstVariantAdded: true
      });
      attributesInfo = await this.fetchAttributesList();
      state = {
        ...this.state,
        price_type: 2,
        attributesList: attributesInfo?.attributesList,
        attributesDefaultSelectOptions: [],
        attributesSelectOptions: attributesInfo?.attributesSelectOptions,
        selectedAttributeVariants: [],
        variantCombination: [],
      };
    } else {
      state = {
        ...this.state,
        withAttribute: 0, //0 = false | 1 = true
        attributesList: [],
        price_type: 1,
        attributesDefaultSelectOptions: [],
        attributesSelectOptions: [],
        selectedAttributeVariants: [],
        variantCombination: [],
      };
    }

    this.setState({
      ...state,
    });
  }

  async fetchAttributesList() {
    const attributesList = await this.props.getAttributeList(
      localStorage.getItem("sellerId"),
      0
    );

    if (attributesList?.data?.data?.length > 0) {
      const attributesSelectOptions = attributesList.data.data.map(
        (attribute) => {
          return {
            value: attribute.name,
            label: attribute.name.replace("_", " "),
          };
        }
      );

      return {
        attributesList: attributesList.data.data,
        attributesSelectOptions,
      };
    }
  }

  attributeStateChange = () => {
    this.setState({
      attributeChange: true
    })
  }
  async handleProductAttributeChange(event, key) {
    if (
      this.state.errorData &&
      this.state.errorData[key] &&
      this.state.errorData[key][0]
    ) {
      this.state.errorData[key][0] = null;
    }
    this.setState({
      attributesDefaultSelectOptions: event ? event : [],
    }, await this.attributeStateChange())
    if(this.state.attributeChange){
      this.setState({
        selectedAttributeVariants: "",
        variantCombination: "",
        attributeImage: "",
        primaryIndex:null,
      })
    }
  }
  hidePrice = (e) => {
    if (e.target.checked) {
      this.setState({
        formData: {
          ...this.state.formData,
          price: "",
        },
      });
    }
    this.setState({
      eventPriceError: "",
    });
    this.setState({
      isFreePriceChecked: e.target.checked,
    });
  };

  getSelectedAttributeVariants = async (productAttributeVariantDetail = []) => {
    let selectedAttributeVariants = [...this.state.selectedAttributeVariants];
    let attributeVariantObj = {};

    if (productAttributeVariantDetail.length > 0) {
      const allKeys = Object.keys(
        JSON.parse(productAttributeVariantDetail[0].variant_value)
      );

      productAttributeVariantDetail.forEach((variant) => {
        variant = JSON.parse(variant.variant_value);
        allKeys.forEach((key) => {
          if (!attributeVariantObj[key]) {
            attributeVariantObj[key] = [variant[key]];
          } else {
            if (!attributeVariantObj[key].includes(variant[key])) {
              attributeVariantObj[key].push(variant[key]);
            }
          }
        });
      });
    }

    await this.state.attributesDefaultSelectOptions.forEach(async (variant,index) => {
      const createOptions = await this.createVariantsSelectOption(
        variant.value,
        attributeVariantObj[variant.value]
      );
      const tempArray = this.state.attributesDefaultSelectOptions.map((item) => {
        return item.value
     })
     if(!tempArray[index]?.includes(this.state.selectedAttributeVariants[index]?.attributeName)){

       selectedAttributeVariants.push(createOptions);
     }
    });
    this.setState({
      selectedAttributeVariants: selectedAttributeVariants,
    });
  };

  createVariantsSelectOption(attributeName, selectedVariantValue = []) {
    let attributeInfo = {};
    this.state.attributesList.forEach((variant) => {
      if (variant.name === attributeName) {
        attributeInfo = variant;
      }
    });

    const variationSelectOptions = this.variationValuesSplitter(
      attributeInfo.variation_values,
      selectedVariantValue
    );

    return {
      attributeName,
      attributeSelectOptions: variationSelectOptions.selectOptions,
      attributeDefaultSelectedOptions:
        variationSelectOptions.defaultSelectedOptions,
    };
  }

  variationValuesSplitter = (variation_values, selected_variant_values) => {
    variation_values = variation_values.split(",");

    let defaultSelectedOptions = [];

    let selectOptions = variation_values.map((variation) => {
      if (selected_variant_values.includes(variation)) {
        defaultSelectedOptions.push({
          value: `${variation}`,
          label: `${variation}`,
        });
      }
      return { value: `${variation}`, label: `${variation}` };
    });

    return { selectOptions, defaultSelectedOptions };
  };

  handleVariantChange = async(event, index) => {
    if(!this.state.isFirstVariantAdded){
      await this.alertVariant()
    }
    let selectedAttributeVariants = this.state.selectedAttributeVariants;
    selectedAttributeVariants[index].attributeDefaultSelectedOptions = event
    ? event
    : [];
    
    this.setState({
      selectedAttributeVariants,
      isVariantChange:true,
      attributeImage: ""
    });
  };

  makePrimary=async(e,index) =>{
    if(e.target.checked){
      this.setState({
        isPrimary: true,
        primaryIndex:index
      })
    } else {
      this.setState({
        isPrimary: false,
        primaryIndex:null
        })
    }
  }
  createVariantCombination = async () => {
    const selectedAttributeVariants = this.state.selectedAttributeVariants;

    const combinations = this.cartesian(selectedAttributeVariants);
    this.setState({
      variantCombination: combinations,
      createVarientProductValidation: 1,
      isVariantChange:false,
    });
  };

  cartesian(args) {
    var r = [],
      max = args.length - 1;

    function helper(arr, i) {
      for (
        var j = 0, l = args[i].attributeDefaultSelectedOptions.length;
        j < l;
        j++
      ) {
        var a = JSON.parse(JSON.stringify(arr)); // clone arr
        a = Object.assign(a, {
          [args[i].attributeName]:
            args[i].attributeDefaultSelectedOptions[j].value,
        });
        if (i == max) {
          r.push([a]);
        } else {
          helper(a, i + 1);
        }
      }
    }

    helper({}, 0);
    return r;
  }

  onRemoveSpecificCombination = (index) => {
    const newCombinationArray = this.state.variantCombination.splice(index, 1);
    const newAttributeImageArray = this.state.attributeImage.splice(index,1)
    this.setState({
      newCombinationArray,
      newAttributeImageArray,
    });
  };

  handleVariantCombinationPriceChange = (event, index) => {
    const variantCombination = this.state.variantCombination;
    variantCombination[index][1] = {
      ...variantCombination[index][1],
      price: event.target.value,
    };
    let errorData = this.state.errorData;
    errorData["getVarientCombination"] = [];
    this.setState({
      variantCombination,
      errorData,
    });
  };
  handleVariantCombinationOneOnOneMeetingPriceChange = (event, index) => {
    const variantCombination = this.state.variantCombination;
    if (this.state.isOneOnOneMeetingPriceChecked) {
      variantCombination[index][1] = {
        ...variantCombination[index][1],
        one_on_one_meeting_price: event.target.value ? event.target.value : 0,
      };
    } else {
      variantCombination[index][1] = {
        ...variantCombination[index][1],
        one_on_one_meeting_price: null,
      };
    }
    let errorData = this.state.errorData;
    errorData["getVarientCombination"] = [];
    this.setState({
      variantCombination,
      errorData,
    });
  };
  handleChangeChk = async (e, key, weekDay, frequency) => {
    let checked = e.target.checked;
    try {
      if (!checked && frequency === "All") {
        if (this.state.selectedSlots) {
          if (
            Object.keys(this.state.selectedSlots) &&
            Object.keys(this.state.selectedSlots)?.length > 0
          ) {
            for (const value of Object.keys(this.state.selectedSlots)) {
              for (const item of this.state.selectedSlots[value]) {
                if (item[7]) {
                  await this.validateCancelSlotFromForm();
                  return null;
                }
              }
            }
          }
        }
      } else if (this.state.selectedSlots) {
        if (
          Object.keys(this.state.selectedSlots) &&
          Object.keys(this.state.selectedSlots)?.length > 0
        ) {
          for (const value of Object.keys(this.state.selectedSlots)) {
            for (const item of this.state.selectedSlots[value]) {
              if (
                item[7] &&
                item[8] &&
                item[8].toLowerCase().replace(/ /g, "_") ===
                  frequency.toLowerCase().replace(/ /g, "_")
              ) {
                await this.validateCancelSlotFromForm();
                return null;
              }
            }
          }
        }
      } else if (weekDay) {
        if (weekDay && this.state.selectedSlots[weekDay]) {
          for (const value of this.state.selectedSlots[weekDay]) {
            if (value[7]) {
              await this.validateCancelSlotFromForm();
              return null;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    this.state.errorData.selectedOneOnOneFrequency = [];
    this.setState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    if (key == "isWeekDaySelected") {
      this.state.errorData.selectedWeekDay = [];
      let tempArray = { ...this.state.isWeekDaySelected };
      let tempOneOnOneMeetingTimeSlotArray = {
        ...this.state.slots,
      };
      tempArray[weekDay] = checked;
      if (checked) {
        tempOneOnOneMeetingTimeSlotArray[weekDay] = [[0, 0]];
      } else {
        delete tempOneOnOneMeetingTimeSlotArray[weekDay];
      }
      this.setState(
        {
          isWeekDaySelected: { ...tempArray },
          slots: { ...tempOneOnOneMeetingTimeSlotArray },
          // selectedSlots: { ...tempOneOnOneMeetingTimeSlotArray },
        });
      return;
    } else if (key == "selectedFrequency") {
      let tempFrequencyArray = { ...this.state.selectedFrequency };
      tempFrequencyArray[`${frequency}`] = checked;

      let isAllFrequencySelected = 0;
      Object.entries(tempFrequencyArray)
        .slice(1, 5)
        .map(([freq, bool]) => {
          if (bool) {
            isAllFrequencySelected += 1;
          }
        });

      if (isAllFrequencySelected == 4) {
        tempFrequencyArray["All"] = true;
      } else {
        tempFrequencyArray["All"] = false;
      }
      this.setState(
        {
          selectedFrequency: { ...tempFrequencyArray },
        });
      return;
    } else if (key == "allFrequencySelected") {
      let tempFrequencyArray = { ...this.state.selectedFrequency };
      this.selectOneOnOneFrequency.map((item) => {
        tempFrequencyArray[item] = e.target.checked;
      });

      this.setState({
        selectedFrequency: { ...tempFrequencyArray },
      });
    }
    this.setState({
      [key]: this.state[key] === 0 ? 1 : 0,
    });
  };

  updateSubscription = async (event, productId) => {};

  dateDiff(startingDate, endingDate) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    if (yearDiff === 0 && monthDiff < 3) {
      return this.setState({
        diffMonths: true,
      });
    } else {
      return this.setState({
        diffMonths: false,
      });
    }
  }

  // function to trigger our input file click
  triggerFileUploadClick = (e, type, index) => {
    e.preventDefault();
    switch (type) {
      case "videos":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.video,
          currentFileType: type,
          currentFileContentType: "video",
          currentUploadSubCategoryIndex: 0,
        }));
        this.fileUploadVideo.current.click();
        break;
      case "images":
        break;
      case "documents":
        break;
      case "acsCatVideos":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.video,
          currentFileType: type,
          currentFileContentType: "video",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadVideo.current.click();
        break;
      case "acsCatAudios":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.mediaFormat,
          currentFileType: type,
          currentFileContentType: "audio",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadAudio.current.click();
        break;
      case "acsCatImages":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.image,
          currentFileType: type,
          currentFileContentType: "image",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadImage.current.click();
        break;
      case "acsCatDocuments":
        this.setState((prevState) => ({
          ...prevState,
          currentFileFormat: this.fileFormats.doc,
          currentFileType: type,
          currentFileContentType: "application",
          currentUploadSubCategoryIndex: index,
        }));
        this.fileUploadDoc.current.click();
        break;
    }
  };

  fileUploadHandler = (fileList) => {
    this.setState({
      errorData : {
        ...this.state.errorData, 
      largeFileError : ""
      }
    })
    const state = this;
    const errors = [];
    let type =
    state.state.currentFileType === "videos"
    ? "videos"
    : "acsCatSubcategories";
    let tempList=[...state.state[type]]
    for (const file of fileList) {

    if(file?.type?.includes("image") && file?.size>10000000){
      this.setState({
        errorData : {
          ...this.state.errorData, 
        largeFileError : "You can not upload more than 10mb file size"
        }
      })
      return
    }

      if (file?.size>19000000){
        this.setState({
          errorData : {
            ...this.state.errorData, 
          largeFileError : "You can not upload more than 20mb file size"
          }
        })
        return
      }
      let mediaList = [...state.state[type]];

      //read file stream and set in state
      function readFile(file) {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          const fileArray = {
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            stream: event.currentTarget.result,
          };

          if (type === "videos") {
            tempList.push({...fileArray})
          } else {
            tempList[state.state.currentUploadSubCategoryIndex][
              state.state.currentFileType
            ].push({ ...fileArray })
            tempList[state.state.currentUploadSubCategoryIndex][
              state.state.currentFileType + "Titles"
            ].push("")
          }
        };
        fileReader.readAsDataURL(file);
      }

      let imgType = file?.type?.split("/");
      let fileContentTypes = [imgType[0]];
      if (fileContentTypes[0] === "text") {
        fileContentTypes.push("application");
      }
      if (
        this.state.currentFileFormat.includes(imgType[1]) &&
        fileContentTypes.includes(this.state.currentFileContentType)
      ) {
        if (
          (type === "videos" &&
            !mediaList?.find(
              (item) => item.name === file.name && item.type === file.type
            )) ||
          (type !== "videos" &&
            !mediaList[this.state.currentUploadSubCategoryIndex][
              this.state.currentFileType
            ]?.find(
              (item) => item.name === file.name && item.type === file.type
            ))
        ) {
          readFile(file);
        }
      } else {
        errors.push(
          "File [" +
            file.name +
            "] format is not valid, Please upload a valid format."
        );
      }
    }
    state.setState({
      [type]: tempList,
    });

    if (errors && errors.length > 0) {
      errors.push(
        `Valid formats are ${this.state.currentFileFormat.join(", ")}.`
      );
    }
    this.props.addFilesError(this.state.currentFileType, errors);
  };

  fileSelectHandler = async (e) => {
    e.preventDefault();
    await this.fileUploadHandler(e.target.files);
    setTimeout(() => {
      this.setState({
        refreshComponent: "yes",
      });
    }, [500]);
  };

  deleteSelectedFile = (e, type, index, catIndex) => {
    e.preventDefault();
    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      this.setState((prevState) => {
        return {
          ...prevState,
          showVideos: false,
        };
      });
    }

    if (type === "videos") {
      let videos = this.state.videos;
      videos.splice(index, 1);
      this.setState({
        videos,
      });
    } else {
      let acsSubcategoryList = this.state.acsCatSubcategories;
      acsSubcategoryList[catIndex][type].splice(index, 1);
      acsSubcategoryList[catIndex][type + "Titles"].splice(index, 1);
      this.setState({
        acsCatSubcategories: acsSubcategoryList,
      });
    }

    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      setTimeout(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            showVideos: true,
          };
        });
      }, 100);
    }
  };
  deleteSelectedGalleryFile = async (e, type, url, index, catIndex) => {
    e.preventDefault();
    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      this.setState((prevState) => {
        return {
          ...prevState,
          showVideos: false,
        };
      });
    }
    let acsCatList = [...this.state.acsCatSubcategories];
    switch (type) {
      case "videos":
        this.state.galleryVideos.splice(index, 1);
        await this.setState((prevState) => {
          return {
            ...prevState,
            galleryVideos: this.state.galleryVideos,
            removedGalleryVideos: [...this.state.removedGalleryVideos, url],
          };
        });
        break;
      case "acsCatVideos":
        acsCatList[catIndex].acsCatVideoGallery.splice(index, 1);
        acsCatList[catIndex].removedAcsCatVideoGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatVideoGallery,
          url,
        ];
        break;
      case "acsCatAudios":
        acsCatList[catIndex].acsCatAudioGallery.splice(index, 1);
        acsCatList[catIndex].removedAcsCatAudioGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatAudioGallery,
          url,
        ];
        break;
      case "acsCatImages":
        acsCatList[catIndex].acsCatImageGallery.splice(index, 1);
        acsCatList[catIndex].removedAcsCatImageGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatImageGallery,
          url,
        ];
        break;
      case "acsCatDocuments":
        acsCatList[catIndex].acsCatDocGallery.splice(index, 1);
        acsCatList[catIndex].removedAcsCatDocGallery = [
          ...this.state.acsCatSubcategories[catIndex].removedAcsCatDocGallery,
          url,
        ];
        break;
    }

    await this.setState({
      acsCatSubcategories: acsCatList,
    });

    if (["videos", "acsCatVideos", "acsCatAudios"].includes(type)) {
      setTimeout(() => {
        this.setState((prevState) => {
          return {
            ...prevState,
            showVideos: true,
          };
        });
      }, 100);
    }
  };
  deleteAllGalleryFiles = (e, type, index) => {
    e.preventDefault();
    let acsCatList = [...this.state.acsCatSubcategories];
    switch (type) {
      case "videos":
        const galleryVideos = this.state.galleryVideos
          .map((map) => map.url)
          ?.join();
        this.setState((prevState) => {
          return {
            ...prevState,
            galleryVideos: [],
            removedGalleryVideos: [...galleryVideos],
          };
        });
        break;
      case "acsCatVideos":
        acsCatList[index].acsCatVideoGallery = [];
        acsCatList[index].acsCatVideoGalleryTitles = [];
        acsCatList[index].removedAcsCatVideoGallery = [
          ...this.state.acsCatSubcategories[index].acsCatVideoGallery,
        ];
        break;
      case "acsCatAudios":
        acsCatList[index].acsCatAudioGallery = [];
        acsCatList[index].acsCatAudioGalleryTitles = [];
        acsCatList[index].removedAcsCatAudioGallery = [
          ...this.state.acsCatSubcategories[index].acsCatAudioGallery,
        ];
        break;
      case "acsCatImages":
        acsCatList[index].acsCatImageGallery = [];
        acsCatList[index].acsCatImageGalleryTitles = [];
        acsCatList[index].removedAcsCatImageGallery = [
          ...this.state.acsCatSubcategories[index].acsCatImageGallery,
        ];
        break;
      case "acsCatDocuments":
        acsCatList[index].acsCatDocGallery = [];
        acsCatList[index].acsCatDocGalleryTitles = [];
        acsCatList[index].removedAcsCatDocGallery = [
          ...this.state.acsCatSubcategories[index].acsCatDocGallery,
        ];
        break;
    }
    this.setState({
      acsCatSubcategories: acsCatList,
    });
    this.props.addFiles(type, []);
  };

  uploadAllSelectedFile = async () => {
    return await new Promise(async (resolve, reject) => {
      const errors = [];

    if (this.state.attributeImage && this.state.attributeImage?.length >0){
      const imageUrlList = [];
      let finalResult=[]
      for(let i=0 ; i<this.state.attributeImage?.length; i++){
        let imageUrl;
        if (this.state.attributeImage[i]?.includes("base64")){
          imageUrl = this.uploadAttributeSelectedImage(
            i,
            this.state.attributeImage[i]
          );
        }else{
          imageUrl = this.state.attributeImage[i]
        }
        finalResult.push(imageUrl)
      }
    
      await Promise.allSettled(finalResult)
      
      finalResult && finalResult.map(i=>{

        if (i) {
          imageUrlList.push({ url: i });
        } else {
          errors.push("Error Occurred in file :" + i.name);
        }
      })
      this.setState({
        galleryAttributeImages: [...imageUrlList]
      },() => {
        this.props.updateAttibute(this.state.productId,this.state.variantCombination)
      })
    }

      if (this.state.images && this.state.images.length > 0){
        const imageUrlList = [];
        let finalResult = [];
        for(let i =0; i<this.state.images.length; i++) {
          const value = this.state.images[i];
          const index = i;
          let imageUrl;
          if (value?.stream?.includes("base64")){
            imageUrl =  this.uploadSelectedImage(
              "images",
              "/image_gallery",
              index,
              false,
              null
            );

          }else{
            imageUrl = value.stream
          }
          finalResult.push(imageUrl)
        }
      const resolved  = await Promise.allSettled(finalResult)
      resolved.map((i)=> {
        if (i) {
    imageUrlList.push({ url: i.value });
        } else {
          errors.push("Error Occurred in file :" + i.name);
        }
      })
        
         this.setState({
          galleryImages: [...imageUrlList]
        })
      }

      if (this.state.videos && this.state.videos.length > 0) {
        const imageUrlList = [];
        let finalResult= []
        for (let i = 0; i < this.state.videos?.length; i++) {
          const value = this.state.videos[i];
          const index = i;
          const imageUrl =  this.uploadSelectedFile(
            "videos",
            "/video_gallery",
            index,
            false,
            null
          );
           finalResult.push(imageUrl)
        }

      const resolved  = await Promise.allSettled(finalResult)

      resolved.map((i)=> {
        if (i) {
    imageUrlList.push({ url: i.value });
        } else {
          errors.push("Error Occurred in file :" + i.name);
        }
      })
        this.state.galleryVideos= [...this.state.galleryVideos, ...imageUrlList];
        await this.setState({
            videos: [],
        });
      }
      let subCatIndex = 0;
      let acsSubcategoryList = this.state.acsCatSubcategories;
      for (let subcategory of this.state.acsCatSubcategories) {
        if (subcategory.acsCatVideos && subcategory.acsCatVideos.length > 0) {
        let finalResult= []
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatVideos.entries()) {
            const imageUrl =  this.uploadSelectedFile(
              "acsCatVideos",
              "/access/video",
              index,
              true,
              subCatIndex
            );
            finalResult.push(imageUrl)
          }
        const resolved = await Promise.allSettled(finalResult)
        resolved.map((i)=>{
          if(i){
            imageUrlList.push({url: i.value})
          }else{
            errors.push("Error Occurred in file :" + i.name);
          }
         })

          acsSubcategoryList[subCatIndex].acsCatVideos = [];
          acsSubcategoryList[subCatIndex].acsCatVideoGallery = [
            ...this.state.acsCatSubcategories[subCatIndex][
              "acsCatVideoGallery"
            ],
            ...imageUrlList,
          ];
        }

        if (subcategory.acsCatAudios && subcategory.acsCatAudios.length > 0) {
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatAudios.entries()) {
            const imageUrl = await this.uploadSelectedFile(
              "acsCatAudios",
              "/access/audio",
              index,
              true,
              subCatIndex
            );
            if (imageUrl) {
              imageUrlList.push({ url: imageUrl });
            } else {
              errors.push("Error Occurred in file :" + value.name);
            }
          }
          acsSubcategoryList[subCatIndex].acsCatAudios = [];
          acsSubcategoryList[subCatIndex].acsCatAudioGallery = [
            ...this.state.acsCatSubcategories[subCatIndex][
              "acsCatAudioGallery"
            ],
            ...imageUrlList,
          ];
        }

        if (subcategory.acsCatImages && subcategory.acsCatImages.length > 0) {
          const imageUrlList = [];
          let finalResult=[]
          for (const [index, value] of subcategory.acsCatImages.entries()) {
            const imageUrl =  this.uploadSelectedFile(
              "acsCatImages",
              "/access/image",
              index,
              true,
              subCatIndex
            );

           finalResult.push(imageUrl)
          }

         const resolved = await Promise.allSettled(finalResult)
         resolved.map((i)=>{
          if(i){
            imageUrlList.push({url: i.value})
          
          }else{
          errors.push("Error Occurred in file :", + i.name)
          }
         })

          acsSubcategoryList[subCatIndex].acsCatImages = [];
          acsSubcategoryList[subCatIndex].acsCatImageGallery = [
            ...this.state.acsCatSubcategories[subCatIndex][
              "acsCatImageGallery"
            ],
            ...imageUrlList,
          ];
        }
        if (
          subcategory.acsCatDocuments &&
          subcategory.acsCatDocuments.length > 0
        ) {
          const imageUrlList = [];
          for (const [index, value] of subcategory.acsCatDocuments.entries()) {
            const imageUrl = await this.uploadSelectedFile(
              "acsCatDocuments",
              "/access/doc",
              index,
              true,
              subCatIndex
            );
            if (imageUrl) {
              imageUrlList.push({ url: imageUrl });
            } else {
              errors.push("Error Occurred in file :" + value.name);
            }
          }
          acsSubcategoryList[subCatIndex].acsCatDocuments = [];
          acsSubcategoryList[subCatIndex].acsCatDocGallery = [
            ...this.state.acsCatSubcategories[subCatIndex]["acsCatDocGallery"],
            ...imageUrlList,
          ];
        }
        ++subCatIndex;
      }

      this.setState({
        acsCatSubcategories: acsSubcategoryList,
      });

      if (errors && errors.length > 0) {
        this.setState({
          showLoader : false,
          alertModalData: {
            ...this.state.alertModalData,
            open: false,
          }
        })      
        if(this.state.fileResponse && this.state.fileResponse?.response?.response?.status === 413){
          this.setState({
            errorData : {
              ...this.state.errorData, 
            largeFileError : "You can not upload more than 20mb file size"
            }
          })
        }
        reject({ isError: true, errorMessages: errors.join("\n") });
      } else {
        resolve({ isError: false });
      }
    });
  };

  uploadAttributeSelectedImage = async(index, base64)=> {   
      return new Promise(async (resolve,reject)=>{
        const file = [this.state.attributeImage];
        const slug =  this.state.slug;
        const productId = this.state.productId
        const res = await this.props.uploadAttibute(base64,index,slug)
        let tempAttribute = [...this.state.variantCombination];
        tempAttribute[index][1]["image"] = res?.data 
        this.setState({
          variantCombination: [...tempAttribute]
        })
        resolve (res?.data)
      })
   }

  uploadSelectedImage = async(
    type,
    fileUploadPathEndPoint,
    index,
    isPrivate,
    subCatIndex
  ) => {
    return new Promise(async(resolve,reject)=>{
      
      const files = 
       subCatIndex === null ? this.state.images : null;
       const file = files[index];
       if (file) {
        file["status"] = "Uploading";
        this.props.addFiles(type, files);
        const res = await this.props.uploadFiles(
          "/api/v1/seller/product/upload",
          null,
          null,
          type,
          index,
          {
            productId: this.productId,
            file: file,
            fileUploadPathEndPoint: fileUploadPathEndPoint,
            isPrivate: isPrivate,
            fileName: "",
          }
        );
        this.setState({
          fileResponse: res
        })
        if (!res.isError && res?.data && res?.data?.imageUrl) {
          file["status"] = "Uploaded";
          this.props.addFiles(type, files);
          resolve (res.data.imageUrl);
        } else {
          file["status"] = "Error Occurred";
          this.props.addFiles(type, files);
          reject (false);
        }
      }
    })
  };
 
  uploadSelectedFile = async (
    type,
    fileUploadPathEndPoint,
    index,
    isPrivate,
    subCatIndex
  ) => {
    return new Promise(async (resolve,reject)=>{
      const files =
      subCatIndex === null
        ? this.state.videos
        : this.state.acsCatSubcategories[subCatIndex][type];
    const file = files[index];
    if (file) {
      file["status"] = "Uploading";
      this.props.addFiles(type, files);
      const res = await this.props.uploadFiles(
        "/api/v1/seller/product/upload",
        null,
        null,
        type,
        index,
        {
          productId: this.productId,
          file: file,
          fileUploadPathEndPoint: fileUploadPathEndPoint,
          isPrivate: isPrivate,
          fileName:
            subCatIndex === null
              ? ""
              : this.state.acsCatSubcategories[subCatIndex][type + "Titles"] &&
                this.state.acsCatSubcategories[subCatIndex][type + "Titles"][
                  index
                ].trim(),
        }
      );
      this.setState({
        fileResponse: res
      })
      if (!res.isError && res.data && res.data.imageUrl) {
        file["status"] = "Uploaded";
        this.props.addFiles(type, files);
        resolve (res.data.imageUrl);
      } else {
        file["status"] = "Error Occurred";
        this.props.addFiles(type, files);
        reject (false);
      }
    }
    })
  };

  getFileNameFromUrl = (fileUrl) => {
    if (!fileUrl) {
      return "";
    }
    return fileUrl.split("?")[0].substring(fileUrl.lastIndexOf("/") + 1);
  };

  getFileSizeByByteLength = (byteLength) => {
    let size = 0;
    let unit = "Bytes";
    if (Math.round(byteLength) < 1000) {
      size = Math.round(byteLength);
      unit = "Bytes";
    } else if (Math.round(byteLength) > 999 && byteLength < 1000000) {
      size = Math.round(byteLength / 1000);
      unit = "KB";
    } else if (Math.round(byteLength) > 999999 && byteLength < 1000000000) {
      size = Math.round(byteLength / 1000000);
      unit = "MB";
    }
    return size + " " + unit;
  };

  getFileSizeByUrl = (fileUrl) => {
    if (!fileUrl) {
      return "";
    }
    const urlQueries = fileUrl.split("?")[1].split("&");
    const queriesMap = {};
    for (const query of urlQueries) {
      const queryList = query.split("=");
      queriesMap[queryList[0]] = queryList[1];
    }

    return this.getFileSizeByByteLength(queriesMap.size);
  };

  downloadPrivateFileByUrl = async (e, type, url, index) => {
    let fileRes = null;
    let acsSubcategory = this.state.acsCatSubcategories;
    let gallery = acsSubcategory[index].acsCatImageGallery?.find(
      (map) => map.url.trim() === url.trim()
    );

    switch (type) {
      case "acsCatDocuments":
        gallery = acsSubcategory[index].acsCatDocGallery?.find(
          (map) => map.url.trim() === url.trim()
        );
        gallery["status"] = "Downloading";

        fileRes = acsSubcategory[index].p_acsCatDocuments?.find(
          (map) => map.url.trim() === url.trim()
        );
        break;
      case "acsCatImages":
        gallery = acsSubcategory[index].acsCatImageGallery?.find(
          (map) => map.url.trim() === url.trim()
        );
        gallery["status"] = "Processing";
        fileRes = acsSubcategory[index].p_acsCatImages?.find(
          (map) => map.url.trim() === url.trim()
        );
        break;
      case "acsCatAudios":
        gallery = acsSubcategory[index].acsCatAudioGallery?.find(
          (map) => map.url.trim() === url.trim()
        );
        gallery["status"] = "Processing";
        fileRes = acsSubcategory[index].p_acsCatAudios?.find(
          (map) => map.url.trim() === url.trim()
        );
        break;
      case "acsCatVideos":
        gallery = acsSubcategory[index].acsCatVideoGallery?.find(
          (map) => map.url.trim() === url.trim()
        );
        gallery["status"] = "Processing";
        fileRes = acsSubcategory[index].p_acsCatVideos?.find(
          (map) => map.url.trim() === url.trim()
        );
        break;
    }

    this.setState({
      acsCatSubcategories: acsSubcategory,
    });
    if (!fileRes) {
      fileRes = await this.props.fetchPrivateFiles(type, url.trim(), {
        url: url.trim(),
      });
      acsSubcategory = this.state.acsCatSubcategories;
      acsSubcategory[index][`p_${type}`] = [
        ...this.state.acsCatSubcategories[index][`p_${type}`],
        ...fileRes,
      ];
      fileRes = fileRes[0];
      this.setState({
        acsCatSubcategories: acsSubcategory,
      });
    }
    let subcategoryList = this.state.acsCatSubcategories;
    let objIndex = 0;
    switch (type) {
      case "acsCatDocuments":
        if (gallery) {
          gallery["stream"] = fileRes.stream;
          gallery["status"] = "";
          this.setState((prevState) => {
            return {
              ...prevState,
              acsCatSubcategories: [...acsSubcategory],
            };
          });
        }
        const base64Response = await fetch(fileRes.stream);
        const blob = await base64Response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", fileRes.fileName);
        document.body.appendChild(link);
        link.click();
        break;
      case "acsCatImages":
        if (gallery) {
          gallery["stream"] = fileRes.stream;
          gallery["contentType"] = fileRes.contentType;
          gallery["status"] = "";
          this.setState((prevState) => {
            return {
              ...prevState,
              acsCatSubcategories: [...acsSubcategory],
            };
          });
        }
        break;
      case "acsCatAudios":
        objIndex = 0;
        let acsCatAudio = subcategoryList[index].acsCatAudioGallery?.find(
          (map, i) => {
            if (map.url.trim() === url.trim()) {
              objIndex = i;
              return map;
            }
          }
        );
        if (acsCatAudio) {
          acsCatAudio["stream"] = fileRes.stream;
          acsCatAudio["contentType"] = fileRes.contentType;
          gallery["status"] = "";
          subcategoryList[index].acsCatAudioGallery[objIndex] = acsCatAudio;
          this.setState((prevState) => {
            return {
              ...prevState,
              acsCatSubcategories: [...subcategoryList],
            };
          });
        }
        break;
      case "acsCatVideos":
        objIndex = 0;
        let acsCatVideo = subcategoryList[index].acsCatVideoGallery?.find(
          (map, i) => {
            if (map.url.trim() === url.trim()) {
              objIndex = i;
              return map;
            }
          }
        );
        if (acsCatVideo) {
          acsCatVideo["stream"] = fileRes.stream;
          acsCatVideo["contentType"] = fileRes.contentType;
          gallery["status"] = "";
          subcategoryList[index].acsCatVideoGallery[objIndex] = acsCatVideo;
          this.setState((prevState) => {
            return {
              ...prevState,
              acsCatSubcategories: [...subcategoryList],
            };
          });
        }
        break;
      default:
    }
  };

  handleCreateZoomMeeting = async () => {
    const res = await this.props.createMeeting(
      this.userId,
      this.state.productId
    );
    if (res && res.join_url)
      this.setState({
        zoom_meeting_url: res.join_url,
        zoom_host_url: res.start_url,
      });
  };

  handleChangeMeetingDetails = (e, key) => {
    let { value } = e.target;
    this.setState({
      [key]: value,
    });
    if (key === "meetingDate") {
      if (
        new Date(e.target.value).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
      ) {
        this.setState({
          errorData: {
            meetingDateError: "Please enter future date.",
          },
        });
      } else {
        this.setState({
          errorData: {
            meetingDate: "",
          },
        });
      }
    }
  };

  handleIsOneOnOneMeeting = async (e) => {
    let flag = true;
    let isBookingReceived = false;
    let checked = e.target?.checked;
    try {
      if (this.state.selectedSlots) {
        if (
          Object.keys(this.state.selectedSlots) &&
          Object.keys(this.state.selectedSlots)?.length > 0
        ) {
            for (let value of Object.keys(this.state.selectedSlots)) {
              this.state.selectedSlots[value].map(async (item) => {
                if (item[7]) {
                  isBookingReceived = true;
                  await this.validateCancelSlotFromForm();
                  return null;
                }
              });
            }
            if(isBookingReceived){
              return null
            }
        }
      }
    } catch (err) {
      console.log(err, "ERROR");
    }
    this.setState({
      isOneOnOneMeetingChecked: checked,
      isOneOnOneMeetingDetailsEdited: true,
    });
  };
  handleIsGroupMeeting = async (e) => {
    let isBookingReceived;
    try {
      this.state.groupMeetingDetails.map((item,index)=>{
        if (
          this.state.groupMeetingDetails && 
          this.state.groupMeetingDetails.length > 0 &&
          this.state.groupMeetingDetails[index]?.selectedSlots 
        ) {
          isBookingReceived = false;
          this.state.groupMeetingDetails.map(async (groupMeetingDetails) => {
            try {
              if (
                groupMeetingDetails &&
                groupMeetingDetails?.groupMeetingFrequency === "Monthly" &&
                groupMeetingDetails?.selectedSlots &&
                groupMeetingDetails?.selectedSlots?.isBooked
              ) {
                isBookingReceived = true;
                await this.validateCancelSlotFromForm();
                return null;
              } else {
                if (
                  groupMeetingDetails?.selectedSlots &&
                  Object.keys(groupMeetingDetails?.selectedSlots) &&
                  Object.keys(groupMeetingDetails?.selectedSlots).length > 0
                ) {
                  for (const [item, slotArray] of Object.entries(groupMeetingDetails?.selectedSlots)) {
                    if (slotArray && slotArray.length > 0) {
                      for (const data of slotArray) {
                        if (data[7]) {
                          isBookingReceived = true;
                          await this.validateCancelSlotFromForm();
                          return null;
                        }
                      }
                    }
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
          });
          if (isBookingReceived)
          return null;
        }
      })
    } catch (err) {
      console.log(err, "-----------------ERROR");
    }
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let errorData = { ...this.state.errorData };
    errorData["groupMeetingDetails"] = [];
    if (e.target.value) {
      this.state.groupMeetingDetails.map((item) => {
        errorData["groupMeetingDetails"].push({
          meetingName: [],
          subscriptionCost: [],
          sessionHour: [],
          sessionMinute: [],
          meetingMonthlyStartTime: [],
          meetingMonthlyEndTime: [],
          meetingMonthlyWeekDay: [],
          meetingMonthlyWeekOfMonth: [],
          groupMeetingFrequency: [],
          isWeekDaySelected: [],
          slots: {
            Sun: [],
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sat: [],
          },
        });
      });

      this.setState({
        isGroupMeetingChecked: isBookingReceived ? true :e.target.checked,
        errorData,
      });
    } else {
      this.setState({
        errorData: {},
        isGroupMeetingChecked: e.target.checked,
      });
    }
  };

  onLoadMore = async () => {
    if (this.state.hasMore === true) {
      const activeSubscriptionList =
        await this.props.getActiveSubscriptionCustomerListByProudctId(
          this.state.productId,
          this.state.offset
        );
      if (activeSubscriptionList && activeSubscriptionList.data?.length > 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            prevOffset: prevState.offset,
            offset: prevState.offset + 2,
            hasMore: true,
            activeSubscriptionList: prevState.activeSubscriptionList.concat(
              activeSubscriptionList.data
            ),
          };
        });
      } else {
        this.setState({ hasMore: false });
      }
      if (activeSubscriptionList && activeSubscriptionList.data?.length < 2) {
        this.setState({ hasMore: false });
      }
    }
  };

  onValidateDateForm = () => {
    let isMeetingFormValid = true;
    let splitStarttime = this.state.meetingStartTime.split(":");
    let splitEndtime = this.state.meetingEndTime.split(":");
    let meetingStartTimeStamp = new Date(this.state.meetingDate).setHours(
      splitStarttime[0],
      splitStarttime[1]
    );
    let meetingEndTimeStamp = new Date(this.state.meetingDate).setHours(
      splitEndtime[0],
      splitEndtime[1]
    );
    if (
      !this.state.meetingDate ||
      this.state.meetingStartTime === "" ||
      this.state.meetingEndTime === ""
    ) {
      isMeetingFormValid = false;
      this.setState({
        errorData: {
          meetingDate: "Please enter meeting time.",
        },
      });
    }
    if (
      this.state.errorData.meetingDateError !== undefined ||
      this.state.errorData.meetingDateError === "Please enter future date."
    ) {
      isMeetingFormValid = false;
    }
    if (meetingStartTimeStamp >= meetingEndTimeStamp) {
      isMeetingFormValid = false;
      this.setState({
        errorData: {
          meetingDate: "Please select the start time and end time correctly.",
        },
      });
    }

    return isMeetingFormValid;
  };

  handleCreateEvent = async () => {
    const valid = this.onValidateDateForm();
    if (valid) {
      if (this.state.isEditMeeting) {
        const {
          meetingDate,
          meetingStartTime,
          meetingEndTime,
          productId,
          meetingId,
        } = this.state;
        this.setState({
          showHide: false,
        });
        await this.props.updateGroupInviteMeeting({
          meetingDate,
          meetingStartTime,
          meetingEndTime,
          productId,
          meetingId,
        });
        this.fetchMeetingList();
        return;
      }

      this.setState({
        hasMore: true,
        errorData: {
          meetingDate: "",
        },
      });

      const activeSubscriptionList =
        await this.props.getActiveSubscriptionCustomerListByProudctId(
          this.state.productId,
          0
        );
      if (activeSubscriptionList && activeSubscriptionList.data?.length > 0) {
        this.setState((prevState) => {
          return {
            ...prevState,
            prevOffset: prevState.offset,
            offset: prevState.offset + 2,
            activeSubscriptionList: prevState.activeSubscriptionList.concat(
              activeSubscriptionList.data
            ),
          };
        });
      } else {
        this.setState({ hasMore: false });
      }
      if (activeSubscriptionList && activeSubscriptionList.data?.length < 2) {
        this.setState({ hasMore: false });
      }
      this.setState({
        [`showActiveListModal`]: true,
      });
      this.setState({
        showHide: false,
      });
    }
    this.fetchMeetingList();
  };

  fetchMeetingList = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const selectedDay = new Date();
    const startOfWeekTime = moment(selectedDay)
      .startOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    const endOfWeekTime = moment(selectedDay)
      .endOf("week")
      .format("YYYY-MM-DD HH:mm:ss");
    this.props.getMeetingsByProductIdAndSellerId(
      this.userId,
      this.state.productId,
      startOfWeekTime,
      endOfWeekTime,
      timezone
    );
  };

  handleSendInvite = async () => {
    const { meetingDate, meetingStartTime, meetingEndTime, productId } =
      this.state;
    let selectedCustomerList = this.state.activeSubscriptionList.filter(
      (item) => item.isChecked === true
    );
    let invitationRequestResponse = await this.props.sendInvitationRequest({
      meetingDate,
      meetingStartTime,
      meetingEndTime,
      seller_id: this.userId,
      productId: productId,
      seller_timezone: this.timezone,
      CustomerList: selectedCustomerList,
      meeting_link: "",
    });
    if (
      invitationRequestResponse &&
      invitationRequestResponse.data.isError === false
    ) {
      this.handleActiveListModalShowHide();
    } else {
      this.setState({
        errorData: {
          setMeetingError: "Meeting is already scheduled",
        },
      });
    }
  };

  handleActiveListModalShowHide = () => {
    this.setState({
      showActiveListModal: false,
      errorData: {
        meetingDate: "",
      },
      meetingStartTime: "",
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingEndTime: "",
      activeSubscriptionList: [],
    });
  };

  handleSelectCustomer = () => {};

  handleSelectAllCustomers = async (event) => {
    let customerList = this.state.activeSubscriptionList;
    customerList.forEach((item) => {
      item.isChecked = event.target.checked;
    });
    this.setState({ activeSubscriptionList: customerList });
  };

  handleSelectActiveCustomer = (event, customer) => {
    let customerList = this.state.activeSubscriptionList;
    customerList.forEach((item) => {
      if (item.id === customer.id) item.isChecked = event.target.checked;
    });
    this.setState({ activeSubscriptionList: customerList });
  };

  setActiveSubscriptionCustomerListModal = () => (
    <Modal
      show={this.state.showActiveListModal}
      onHide={this.handleActiveListModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header
        closeButton
        onClick={() => this.handleActiveListModalShowHide()}
      >
        <p>SCHEDULE MEETING</p>
      </Modal.Header>
      <Modal.Body>
        <p className="meeting-p">Meeting Attandees:</p>
        <div className="row">
          <div className="col-md-12">
            <span className="form-field-error">
              {this.state.errorData.setMeetingError}
            </span>
            <div className="schedule-show-b active">
              <div className="schedule-check">
                <input
                  type="checkbox"
                  onClick={(event) =>
                    this.handleSelectAllCustomers(
                      event,
                      this.props.activeSubscriptionMemberList
                    )
                  }
                  value="checkedall"
                />
                <p>Customer Name</p>
              </div>
              <p>Email Address</p>
            </div>
            <div className="scroll-infinite">
              {this.state.activeSubscriptionList.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={this.handleSelectCustomer}
                    className="schedule-show-b"
                  >
                    <div className="schedule-check">
                      <input
                        onClick={(event) =>
                          this.handleSelectActiveCustomer(event, item)
                        }
                        type="checkbox"
                        checked={item.isChecked}
                      />{" "}
                      <p>
                        {item.firstname} {item.lastname}
                      </p>
                    </div>
                    <p>{item.email}</p>
                  </div>
                );
              })}
            </div>
            {this.state.hasMore === true && (
              <div className="text-right">
                <a
                  href="javascript:;"
                  className="load-button"
                  onClick={() => this.onLoadMore()}
                >
                  Load more
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleSendInvite()}
            className="orange-btn"
          >
            Share Link
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  handleOpenMeetingSchedule = (event) => {
    let isEditMeeting = false;
    if (event) {
      let startTime = moment(new Date(event.start), "hh:mm A").format("HH:mm");
      let endTime = moment(new Date(event.end), "hh:mm A").format("HH:mm");
      let meetingDate = moment(event.start, "YYYY-MM-DD").format("YYYY-MM-DD");
      isEditMeeting = true;
      this.setState({
        meetingStartTime: startTime,
        meetingEndTime: endTime,
        meetingDate: meetingDate,
        meetingId: event.meetingId,
      });
    } else {
      isEditMeeting = false;
    }
    this.setState({
      showHide: true,
      isEditMeeting,
    });
  };

  handleModalShowHide = () => {
    this.setState({
      showHide: false,
      errorData: {
        meetingDate: "",
      },
      meetingStartTime: "",
      meetingDate: moment().format("YYYY-MM-DD"),
      meetingEndTime: "",
      activeSubscriptionList: [],
    });
  };

  addNewAcsSubcategory = () => {
    let newAcsArray = this.state.acsCatSubcategories.concat({
      acsCatTitle: "",
      acsCatVideoGallery: [],
      acsCatVideoGalleryNames: [],
      removedAcsCatVideoGallery: [],
      acsCatVideos: [],
      acsCatVideosTitles: [],
      acsCatAudioGallery: [],
      acsCatAudioGalleryTitles: [],
      removedAcsCatAudioGallery: [],
      acsCatAudios: [],
      acsCatAudiosTitles: [],
      acsCatImageGallery: [],
      acsCatImageGalleryTitles: [],
      removedAcsCatImageGallery: [],
      acsCatImages: [],
      acsCatImagesTitles: [],
      acsCatDocGallery: [],
      acsCatDocGalleryTitles: [],
      removedAcsCatDocGallery: [],
      acsCatDocuments: [],
      acsCatDocumentsTitles: [],
    });
    this.setState({
      acsCatSubcategories: newAcsArray,
      setCategoryAddedMessage: "New subcategory added successfully",
    });
    setTimeout(() => {
      this.setState({
        setCategoryAddedMessage: "",
      });
    }, 5000);
  };

  removeAcsSubcategory = (index) => {
    let newCategory = this.state.acsCatSubcategories;
    newCategory.splice(index, 1);
    if (newCategory.length === 0) {
      newCategory = [];
    }
    this.setState({
      acsCatSubcategories: [...newCategory],
    });
  };

  makePrivateUrl = (url) => {
    const azureSasToken = process.env.REACT_APP_AZURE_SAS;

    return url + (url.includes("?") ? "&" : "?") + azureSasToken;
  };

  alertModal = (data) => {
    this.setState({
      alertModalData: {
        open: true,
        message: data.message,
        singleButton: data.singleButton,
        positiveButton: data.positiveButton,
        negativeButton: data.negativeButton,
        id: data.id,
        data: data.data,
        from: data.from ? data.from : false,
        pleaseWaitLoader : data.pleaseWaitLoader
      },
    });
  };

  closeAlertModal = () => {
    this.setState({
      alertModalData: {
        open: false,
        message: "",
        singleButton: true,
      },
    });
  };

  handleAlertResponse = (type, id, data) => {
    if (id === 1) {
      if (type === "positive") {
        this.handleChange(data, "isActive");
      }
    } else if (id === 2) {
      if (type === "positive") {
        this.setState((prevState) => ({
          ...prevState,
          productTimeFrame: "monthly",
        }));
        this.handleParentProductCategoryChange(data);
      }
    }

    this.closeAlertModal();
  };

  fetchSearchCategoryList = async (parentIndex) => {
    const responseCats = await this.props.getChildCategories(
      this.state.searchCategoryId.length
        ? this.state.searchCategoryId[parentIndex]
        : 0,
      this.state.isSeeAllChecked,
      this.state.selectedParentProductCategoryId
    );

    // const responseCats = await this.props.getChildCategories(
    //   this.state.isSeeAllChecked ? undefined : this.state.searchCategoryId
    // );
    if (parentIndex !== undefined) {
      let searchCategoryChildOptions = [
        ...this.state.searchCategoryChildOptions,
      ];
      searchCategoryChildOptions[parentIndex] = responseCats?.data;
      if (responseCats && !responseCats.isError) {
        this.setState({
          searchCategoryChildOptions,
          searchCategoryValue: [],
        });
      }
    }
    let searchCategoryParents = [...this.state.searchCategoryParents];
    searchCategoryParents.map((item, index) => {
      if (item.length == 0 && parentIndex == undefined) {
        let searchCategoryChildOptions = [
          ...this.state.searchCategoryChildOptions,
        ];
        searchCategoryChildOptions[index] = responseCats?.data;
        if (responseCats && !responseCats.isError) {
          this.setState({
            searchCategoryChildOptions,
            searchCategoryValue: [],
          });
        }
      }
    });
  };

  fetchSearchHierarchyList = async () => {
    if (!this.state.searchCategoryId) {
      return null;
    }
    const response = await this.props.hierarchyList([
      this.state.searchCategoryId,
    ]);
    if (response && Object.keys(response.data).length !== 0) {
      let tempArray = [];
      Object.entries(response.data).map((item) => {
        tempArray.push(item[1]);
      });
      this.setState({
        searchCategoryParents: tempArray,
      });
    }
  };

  handleSearchCategoryChange = (e, parentIndex) => {
    let newCatId = [];
    const { value } = e.target;
    const category = this.state.searchCategoryChildOptions[parentIndex].filter(
      (cat) => cat.id == value
    )[0];
    // const searchCategoryId = category.id;
    const searchCategoryParents = [...this.state.searchCategoryParents];
    searchCategoryParents[parentIndex].push(category);
    searchCategoryParents.map((item) => {
      newCatId.push(item[item.length - 1]?.id);
    });

    // const searchCategoryValue = [...this.state.searchCategoryValue]
    // searchCategoryValue[parentIndex] = value
    this.setState(
      {
        searchCategoryId: newCatId,
        searchCategoryParents,
        // searchCategoryValue: value,
        // searchCategoryValue,
      },
      () => this.fetchSearchCategoryList(parentIndex)
    );
  };

  removeSearchCategory = (category, index, parentIndex) => {
    let newCatId = [];
    let searchCategoryParents = [...this.state.searchCategoryParents];
    let searchCategoryChildOptions = [...this.state.searchCategoryChildOptions];
    // const deleteParentCount = this.state.searchCategoryParents[parentIndex].length - index;
    const deleteParentCount = searchCategoryParents[parentIndex].length - index;
    searchCategoryParents[parentIndex].splice(index, deleteParentCount);
    // newCatId = searchCategoryParents[parentIndex][searchCategoryParents[parentIndex].length - 1].id;
    searchCategoryParents.map((item) => {
      newCatId.push(item[item.length - 1]?.id);
    });
    this.setState(
      {
        searchCategoryParents,
        searchCategoryChildOptions,
        searchCategoryId: newCatId,
      },
      () => this.fetchSearchCategoryList(parentIndex)
    );
  };

  handleDeleteSearchCategory = async (parentIndex) => {
    let newCatId = [];
    const searchCategoryParents = [...this.state.searchCategoryParents];
    let searchCategoryChildOptions = [...this.state.searchCategoryChildOptions];
    searchCategoryParents.splice(parentIndex, 1);
    searchCategoryChildOptions.splice(parentIndex, 1);
    searchCategoryParents.map((item) => {
      newCatId.push(item[item.length - 1]?.id);
    });
    this.setState({
      searchCategoryParents,
      searchCategoryChildOptions,
      searchCategoryId: newCatId,
    });
  };

  handleDeleteCustomizeQuestion = async (index) => {
    let tempCustomizeQuesArray = [...this.state.customizeQuestions];
    tempCustomizeQuesArray.splice(index, 1);
    this.setState({
      customizeQuestions: [...tempCustomizeQuesArray],
    });
  };

  uploadAttributeImage = async (e) => {
    e.preventDefault();
    let index = this.state.uploadAttributeIndex;
    const file = e.target.files[0];

    if(file?.size > 10000000){
      this.setState({
        errorData : {
          ...this.state.errorData, 
        largeFileError : "You can not upload images more than 10mb file size."
        }
      })
      return
    }

    const fileReader = new FileReader();
    //Implement onLoad function
    fileReader.onloadend = (event) => {
      const variantCombination = this.state.variantCombination;
      variantCombination[index][1] = {
        ...variantCombination[index][1],
        // image: fileReader.result,
      };
      let tempAttributeImage = [...this.state.attributeImage]
      tempAttributeImage[index] = fileReader.result
      this.setState({
        variantCombination,
        attributeImage: tempAttributeImage
      });
    };
    //Start reading the file
    fileReader.readAsDataURL(file);
  };

  errorMessage = (file)=>{
    if(file?.size > 10000000){
      this.setState({
        errorData : {
          ...this.state.errorData, 
          attributeFileError : "You can not upload images more than 10mb file size."
        }
      })
      return true
    }
  }

  uploadGlobalAttributes = async(e,key,value,index)=> {
    e.preventDefault();
    if(value && key === "globalPrice"){
      let price = e?.target?.value
      const tempObj = {
        [value]: price
      };
      this.setState((prevState) => {
        const updatedArray = prevState.specificAttributeGlobalPrice.map((obj,i) => {
          if (obj.hasOwnProperty(value)) {
            return { ...obj, [value]: price };
          }
          return obj;
        });
        if (!updatedArray.some((obj) => obj.hasOwnProperty(value))) {
          updatedArray.push(tempObj);
        }
        return {
          specificAttributeGlobalPrice: updatedArray
        };
      });
    }
    else if(!value && key === "globalPrice"){
      this.setState({
        attributeGlobalPrice: e.target.value
      })
    } 
    else if(!value && key === "globalImage"){
      const file = e.target.files[0]
      const fileReader = new FileReader();
      const z = this.errorMessage(file)
      if(!z){
        fileReader.onloadend= ()=>{
         this.setState({
           attributeGlobalImage: fileReader.result,
         })
       }
      }
    fileReader.readAsDataURL(file)
    }
    else if (value && key === "globalImage") {
      const file = e.target.files[0]
      const fileReader = new FileReader();
      const z = this.errorMessage(file)
        if(!z){
          fileReader.onloadend = () => {
            const tempObj = {
              [value]: fileReader.result
            };
            this.setState((prevState) => {
              const updatedArray = prevState.specificAttributeGlobalImage.map((obj,i) => {
                if (obj.hasOwnProperty(value)) {
                  return { ...obj, [value]: fileReader.result };
                }
                return obj;
              });
              if (!updatedArray.some((obj) => obj.hasOwnProperty(value))) {
    
                updatedArray[index]= tempObj
                // updatedArray.push(tempObj);
              }
              return {
                specificAttributeGlobalImage: updatedArray
              };
            });
          };
          fileReader.readAsDataURL(file);
        }
    }
  }
  applyGlobalAttribute = async (globalPrice,value,globalIndex) => {
    let variantCombination = this.state.variantCombination;
    if(value){
      if(!this.state.specificAttributeGlobalImage[globalIndex] || !this.state.specificAttributeGlobalPrice[globalIndex]){
        this.setState({
          errorData : {
            ...this.state.errorData, 
            specificAttributeGlobalImageError : "Please Check Your Details."
          }
        })
        return
      }
      if(Object.values(this.state.specificAttributeGlobalPrice[globalIndex])[0] <=0 ){
        this.setState({
          errorData : {
            ...this.state.errorData, 
            specificAttributeGlobalImageError : "You can not enter either 0 or negative value."
          }
        })
        return
      }
      if(this.state.specificAttributeGlobalImage && this.state.variantCombination?.length){
        const temp = [...this.state.attributeImage]
        variantCombination && variantCombination.forEach((items, index) => {
          Object.values(items[0]).forEach((item) => {
            if (item === value) {
              temp[index] = this.state.specificAttributeGlobalImage[globalIndex][item];
            }
          });
        });
        this.setState({
          attributeImage: temp
        });
      }
      if(this.state.specificAttributeGlobalPrice && this.state.variantCombination?.length){
        const temp = [...this.state.variantCombination]
        variantCombination && variantCombination.map((items,index) => {
          Object.values(items[0]).map((item)=>{
            if(item === value){
              temp[index][1] = {"price" : this.state.specificAttributeGlobalPrice[globalIndex][item]}
            }
          })
        });
        this.setState({
          variantCombination : temp
        })
      }
    }
    if(!value){
      if(globalPrice == null || !this.state.attributeGlobalImage){
        this.setState({
          errorData : {
            ...this.state.errorData, 
            specificAttributeGlobalImageError : "Please Check Your Details."
          }
        })
        return
      } 
      if(globalPrice <= 0){
        this.setState({
          errorData : {
            ...this.state.errorData, 
            specificAttributeGlobalImageError : "You can not enter either 0 or negative value."
          }
        })
        return
      }
      if (this.state.attributeGlobalImage  && this.state.variantCombination?.length) {
        const temp = [].concat(Array(this.state.variantCombination.length).fill(this.state.attributeGlobalImage));
        this.setState({
          attributeImage: temp
        })
      }
      variantCombination && variantCombination.map((item) => {
        item[1] = { "price": globalPrice }
      });
      this.setState({
        variantCombination
      })
    }
  };
  handleSeeAllSearchCategory = (e) => {
    this.setState(
      {
        isSeeAllChecked: e.target.checked,
      },
      () => {
        this.fetchSearchCategoryList();
      }
    );
  };
  handleIsDigital = (e) => {
    this.setState({
      isDigitalChecked: e.target.checked,
    });
  };

  handleProductTime = (e) => {
    this.setState({
      customizedProductTime: +e.target.value ? +e.target.value : 0,
    });
  };

  handleAddSearchCat = async () => {
    const searchCategoryParents = [...this.state.searchCategoryParents];
    searchCategoryParents.push([]);
    let searchCategoryChildOptions = [...this.state.searchCategoryChildOptions];
    const responseCats = await this.props.getChildCategories(
      0,
      this.state.isSeeAllChecked,
      this.state.selectedParentProductCategoryId
    );
    if (responseCats && !responseCats.isError) {
      searchCategoryChildOptions.push(responseCats.data);
    }
    this.setState({
      searchCategoryParents,
      searchCategoryChildOptions,
    });
  };

  handleAddCustomizeQuestion = async () => {
    let tempCustomizeQuesArray = [...this.state.customizeQuestions];
    tempCustomizeQuesArray.push("");
    this.setState({
      customizeQuestions: [...tempCustomizeQuesArray],
    });
  };

  handleFreeSeminarModalShowHide = (key) => {
    this.setState({ isFreeSeminar: false });
    if (key == 1 || key == 2) {
      this.setState({
        showHideFreeSeminarModal: false,
      });
    } else {
      this.setState({
        showHideFreeSeminarModal: false,
        formData: {
          start: "",
          end: "",
          date: "",
          name: "",
        },
        // returnOrderMessage: "",
      });
    }
  };

  handleReturnOrderAlert = () => {
    this.setState({
      showHideFreeSeminarModal: true,
      tempSeminarData: {},
    });
  };

  handleSaveChanges = () => {
    const { formData } = this.state;
    const startTimeDateMilliseconds = new Date(
      formData.date?.split("-")[0],
      formData.date?.split("-")[1] - 1,
      formData.date?.split("-")[2],
      formData.start?.split(":")[0],
      formData.start?.split(":")[1]
    ).getTime();
    const endTimeDateMilliseconds = new Date(
      formData.date?.split("-")[0],
      formData.date?.split("-")[1] - 1,
      formData.date?.split("-")[2],
      formData.end?.split(":")[0],
      formData.end?.split(":")[1]
    ).getTime();
    if (
      !!!startTimeDateMilliseconds ||
      !!!endTimeDateMilliseconds ||
      startTimeDateMilliseconds >= endTimeDateMilliseconds ||
      startTimeDateMilliseconds <= Date.now()
    ) {
      this.setState({
        popupErrorMessage: "enter valid date and time",
      });
      this.setState({
        showDenyLoader: false,
      });
      return;
    }
    if (this.state.formData.name == "" || this.state.formData.name == null) {
      this.setState({
        eventNameError: "enter event name",
      });

      this.setState({
        showDenyLoader: false,
      });
      return;
    }
    if (
      (!this.state.isFreePriceChecked && this.state.formData.price == "") ||
      this.state.formData.price == null
    ) {
      this.setState({
        eventPriceError: "enter the event price",
      });
      this.setState({
        showDenyLoader: false,
      });
      return;
    }
    this.handleFreeSeminarModalShowHide(1);
    this.handleIsSeminarUpdated();
  };
  setEventDescription = (note) => {
    this.setState({
      formData: {
        ...this.state.formData,
        note,
      },
    });
  };
  setEventName = (name) => {
    this.setState({
      eventNameError: "",
    });
    this.setState({
      formData: {
        ...this.state.formData,
        name,
      },
    });
  };
  setEventPrice = (price) => {
    this.setState({
      eventPriceError: "",
    });
    this.setState({
      formData: {
        ...this.state.formData,
        price,
      },
    });
  };

  handleSubmitFreeSeminar = async (productId) => {
    const timezone = JSON.parse(localStorage.getItem("sellerData")).timezone;
    const res = await this.props.createFreeSeminar(
      this.state.formData,
      this.userId,
      productId,
      timezone
    );
    if (res?.status == 201) {
      this.setState({
        reqMessage: "Seminar created successfully",
      });

      setTimeout(() => {
        this.setState({
          reqMessage: "",
        });
        this.handleFreeSeminarModalShowHide();
      }, 1500);
      this.fetchProductDetail();
    }
    this.setState({
      showDenyLoader: false,
    });
  };

  handleDateTimeChange = (e, key) => {
    this.setState({
      popupErrorMessage: "",
    });
    const { formData } = this.state;
    if (key == "date") {
      formData[key] = e ? moment(e).format("YYYY-MM-DD") : "";
    } else {
      formData[key] = e;
    }
    this.setState({
      ...formData,
    });
  };

  handleOneOnOneMeetingTimeChange = (e, weekDay, index, type, slots) => {
    let payload = {};
    let isUpdate = true;
    try {
      if (this.editedMeetingFromForm.length > 0) {
        let tempOneOnOneMeetingSlots1 = { ...this.state.selectedSlots };
        for (let item of this.editedMeetingFromForm) {
          if (
            item?.weekDay === weekDay &&
            item?.index === index &&
            item?.tempOneOnOneMeetingSlots ===
              tempOneOnOneMeetingSlots1[weekDay][index]
          ) {
            if (type === "start") {
              item.tempOneOnOneMeetingSlots[0] = e;
              item.slot[0].start = e;
            } else {
              item.slot[0].end = e;
            }
            item.tempOneOnOneMeetingSlots[1] = e;
            isUpdate = false;
          }
        }
      }
      if (isUpdate) {
        payload = {
          orginalTimeSlot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          seller_id: this.userId,
          product_id: this.state.productId,
          weekDay: weekDay,
          index: index,
          slot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          structureId: this.state.oneOnOneId,
        };
      }
    } catch (error) {
      console.log("error", error);
    }
    this.setState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    let tempOneOnOneMeetingSlots = { ...this.state.slots };
    this.state.errorData.selectedOneOnOneMeetingSlots = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };
    this.state.errorData["mainError"] = []
    if (type == "start") {
      tempOneOnOneMeetingSlots[weekDay][index][0] = e;
    } else {
      tempOneOnOneMeetingSlots[weekDay][index][1] = e;
    }
    try {
      if (isUpdate) {
        payload["tempOneOnOneMeetingSlots"] =
          tempOneOnOneMeetingSlots[weekDay][index];
        payload["slot"][0].start = tempOneOnOneMeetingSlots[weekDay][index][0];
        payload["slot"][0].end = tempOneOnOneMeetingSlots[weekDay][index][1];
        this.editedMeetingFromForm.push({ ...payload });
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({
      slots: { ...tempOneOnOneMeetingSlots },
    });
  };
  handleIsSeminarUpdated = () => {
    this.setState({
      isSeminarEdited: true,
    });
  };
  handleCreateFreeSeminar = () => {
    this.setState({
      showHideFreeSeminarModal: true,
    });
  };
  showLoaderForCalander = (loader) => {
    this.setState({
      showCalanderLoader: loader,
    });
  };

  handleClickOnFreeSeminar = (e) => {
    this.setState({
      isFreeSeminarCreated: e.target.checked,
    });
    if (e.target.checked) {
      this.setState({
        showHideFreeSeminarModal: true,
      });
    } else {
      this.handleFreeSeminarModalShowHide();
    }
  };

  handleAddDeleteOneOnOneTimeSlot = async (weekDay, index, action, slots) => {
    this.setState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    let tempOneOnOneMeetingTimeSlot = { ...this.state.slots };
    this.state.errorData.selectedOneOnOneMeetingSlots = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };

    if (action === "add") {
      tempOneOnOneMeetingTimeSlot[weekDay] &&
        tempOneOnOneMeetingTimeSlot[weekDay].push([0, 0]);
    } else if (action === "delete") {
      try {
        if (this.state.selectedSlots && this.state.selectedSlots[weekDay]) {
          for (let slot of this.state.selectedSlots[weekDay]) {
            if (
              slot &&
              slot.length > 0 &&
              slot[0] === slots[0] &&
              slot[1] === slots[1] && slot[7]
            ) {
              await this.validateCancelSlotFromForm();
              return null;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }

      if (tempOneOnOneMeetingTimeSlot[weekDay].length == 1) {
        delete tempOneOnOneMeetingTimeSlot[weekDay];
        let tempweekDaySelected = { ...this.state.isWeekDaySelected };
        tempweekDaySelected[weekDay] = false;
        this.setState({
          isWeekDaySelected: { ...tempweekDaySelected },
        });
      } else {
        tempOneOnOneMeetingTimeSlot[weekDay] &&
          tempOneOnOneMeetingTimeSlot[weekDay].splice(index, 1);
        if (tempOneOnOneMeetingTimeSlot[weekDay].length == 0) {
          let tempweekDaySelected = { ...this.state.isWeekDaySelected };
          tempweekDaySelected[weekDay] = false;
          this.setState({
            isWeekDaySelected: { ...tempweekDaySelected },
          });
        }
      }
    }
    this.setState({
      slots: { ...tempOneOnOneMeetingTimeSlot },
    });
  };

  handleSessionHourMinuteTrialSubscriptionCostChange = (e, key) => {
    this.state.errorData[key] = [];
    this.setState({
      [key]: e.target.value,
      isOneOnOneMeetingDetailsEdited: true,
    });
    if ((key = "subscriptionCost")) {
      if (this.state.oneOnOneId)
        this.subscriptionCostEditedArray.push(this.state.oneOnOneId);
    }
  };

  handleGroupMeetingDetailsChange = (e, key, index) => {
    if (key == "subscriptionCost") {
      this.subscriptionCostEditedArray.push(
        this.state.groupMeetingDetails[index]?.id
      );
    }
    let isUpdate = true;
    let payload = {};
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    try {
      if (this.editedGroupMeetingFromForm.length > 0) {
        for (let item of this.editedGroupMeetingFromForm) {
          if (
            item?.weekDay ===
              tempGroupMeetingDetails[index]["meetingMonthlyWeekDay"]?.slice(
                0,
                3
              ) &&
            item?.index === index
          ) {
            if (key === "meetingMonthlyStartTime") {
              item.slot[0].start = e;
              item.tempGroupMeetingSlot.startTime = e;
            } else if (key === "meetingMonthlyEndTime") {
              item.slot[0].end = e;
              item.tempGroupMeetingSlot.endTime = e;
            }
            isUpdate = false;
          }
        }
      }
      if (isUpdate) {
        payload = {
          orginalTimeSlot: [
            {
              start: tempGroupMeetingDetails[index]?.slots?.startTime,
              end: tempGroupMeetingDetails[index]?.slots?.endTime,
            },
          ],
          seller_id: this.userId,
          product_id: this.state.productId,
          weekDay: tempGroupMeetingDetails[index][
            "meetingMonthlyWeekDay"
          ]?.slice(0, 3),
          index: index,
          slot: [
            {
              start: tempGroupMeetingDetails[index]?.meetingMonthlyStartTime,
              end: tempGroupMeetingDetails[index]?.meetingMonthlyEndTime,
            },
          ],
          structureId: tempGroupMeetingDetails[index]?.id,
        };
      }
    } catch (error) {
      console.log("error", error);
    }

    try {
      if (isUpdate) {
        payload["tempGroupMeetingSlot"] = {
          ...tempGroupMeetingDetails[index]["slots"],
          endTime: tempGroupMeetingDetails[index].meetingMonthlyEndTime,
          weekOfMonth: tempGroupMeetingDetails[index].meetingMonthlyWeekOfMonth,
          weekDay: tempGroupMeetingDetails[index].meetingMonthlyWeekDay,
          startTime: tempGroupMeetingDetails[index].meetingMonthlyStartTime,
        };
        payload["slot"][0].start =
          tempGroupMeetingDetails[index]?.meetingMonthlyStartTime;
        payload["slot"][0].end =
          tempGroupMeetingDetails[index]?.meetingMonthlyEndTime;
        this.editedGroupMeetingFromForm.push({ ...payload });
      }
    } catch (error) {
      console.log("error", error);
    }

    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let errorData = { ...this.state.errorData };
    if (key == "meetingMonthlyStartTime" || key == "meetingMonthlyEndTime") {
      tempGroupMeetingDetails[index][key] = e;
      errorData["groupMeetingDetails"][index][key] = [];
      this.setState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        errorData: { ...errorData },
      });
      return;
    }
    tempGroupMeetingDetails[index][key] = e.target.value;
    errorData["groupMeetingDetails"][index][key] = [];
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
      errorData: { ...errorData },
    });
  };

  handleGroupMeetingFrequencyChange = async (
    e,
    freq,
    index,
    groupMeetingDetails
  ) => {
    let flag = false;
    try {
      if (
        groupMeetingDetails &&
        groupMeetingDetails?.id &&
        groupMeetingDetails?.slots
      ) {
        
        let isBookingReceived = false;

        if (groupMeetingDetails.groupMeetingFrequency !== "Monthly"){

          Object.entries(groupMeetingDetails?.slots).map(([weekday, slotsArray]) => {
            slotsArray && slotsArray.map(async (i) => {
              if (i[7]){
                isBookingReceived = true
                await this.validateCancelSlotFromForm();
  
              }
            })
          })
        }else{

          if(groupMeetingDetails?.selectedSlots?.isBooked){
            isBookingReceived = true
            await this.validateCancelSlotFromForm();
          }

        }
        if (isBookingReceived){
          return null
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    if (!flag) {
      this.setState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
      let errorData = { ...this.state.errorData };

      errorData["groupMeetingDetails"][index]["groupMeetingFrequency"] = [];

      tempGroupMeetingDetails[index]["groupMeetingFrequency"] = freq;
      tempGroupMeetingDetails[index]["slots"] = {};
      Object.entries(
        this.state.groupMeetingDetails[index]["isWeekDaySelected"]
      ).map(([weekDay, bool]) => {
        tempGroupMeetingDetails[index]["isWeekDaySelected"][weekDay] = false;
      });
      tempGroupMeetingDetails[index]["isMonthlyGroupFrequencyChecked"] =
        freq == "Monthly" ? true : false;
      this.setState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        errorData: { ...errorData },
      });
    }
  };

  handleAddNewGroupMeeting = () => {
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    tempGroupMeetingDetails.push({
      id: null,
      meetingName: "",
      subscriptionCost: 0,
      sessionHour: "00",
      sessionMinute: "00",
      meetingMonthlyStartTime: "",
      meetingMonthlyEndTime: "",
      groupMeetingFrequency: "",
      isMonthlyGroupFrequencyChecked: false,
      isWeekDaySelected: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      slots: {},
    });

    this.state.errorData.groupMeetingDetails.push({
      meetingName: [],
      subscriptionCost: [],
      sessionHour: [],
      sessionMinute: [],
      meetingMonthlyStartTime: [],
      meetingMonthlyEndTime: [],
      meetingMonthlyWeekDay: [],
      meetingMonthlyWeekOfMonth: [],
      groupMeetingFrequency: [],
      isWeekDaySelected: [],

      slots: [],
    });
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
    });
  };

  handleDeleteNewGroupMeeting = async (index, groupMeetingDetails) => {
    let flag = false;
    if (
      groupMeetingDetails &&
      groupMeetingDetails?.id
    ) {


      let isBookingReceived = false;

      if (groupMeetingDetails.groupMeetingFrequency !== "Monthly"){

        Object.entries(groupMeetingDetails?.slots).map(([weekday, slotsArray]) => {
          slotsArray && slotsArray.map(async (i) => {
            if (i[7]){
              isBookingReceived = true
              await this.validateCancelSlotFromForm();

            }
          })
        })
      }else{

        if(groupMeetingDetails?.selectedSlots?.isBooked){
          isBookingReceived = true
          await this.validateCancelSlotFromForm();
        }

      }
      if (isBookingReceived){
        return null
      }
    }

    if (!flag) {
      this.setState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
      let deletedGroupMeetingsIdArray = [
        ...this.state.deletedGroupMeetingsIdArray,
      ];
      if (tempGroupMeetingDetails[index]["id"] !== null) {
        deletedGroupMeetingsIdArray.push(tempGroupMeetingDetails[index]["id"]);
      }
      tempGroupMeetingDetails.splice(index, 1);
      this.state.errorData.groupMeetingDetails.splice(index, 1);
      this.setState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        deletedGroupMeetingsIdArray,
      });
    }
  };
  handleGroupWeeklyBiweeklySlotChange = async (
    e,
    weekDay,
    index,
    groupMeetingDetails
  ) => {
    const checked = e.target.checked;
    let flag = false;
    try {
      if (
        groupMeetingDetails &&
        groupMeetingDetails?.id &&
        groupMeetingDetails?.slots &&
        groupMeetingDetails?.selectedSlots[weekDay]
      ) {

        let isBookingReceived = false;

        if (groupMeetingDetails.groupMeetingFrequency !== "Monthly"){

          Object.entries(groupMeetingDetails?.slots).map(([weekday, slotsArray]) => {
            slotsArray && slotsArray.map(async (i) => {
              if (i[7]){
                isBookingReceived = true
                await this.validateCancelSlotFromForm();
  
              }
            })
          })
        }else{

          if(groupMeetingDetails?.selectedSlots?.isBooked){
            isBookingReceived = true
            await this.validateCancelSlotFromForm();
          }

        }
        if (isBookingReceived){
          return null
        }
      }
    } catch (err) {
      console.error(err);
    }
    if (!flag) {
      this.setState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempArray = [...this.state.groupMeetingDetails];
      let errorData = { ...this.state.errorData };

      errorData["groupMeetingDetails"][index]["selectedWeekDay"] = [];

      tempArray[index]["isWeekDaySelected"][weekDay] = checked;

      if (checked) {
        tempArray[index]["slots"][weekDay] = [[0, 0]];
      } else {
        delete tempArray[index]["slots"][weekDay];
      }
      this.setState({
        groupMeetingDetails: [...tempArray],
        errorData: { ...errorData },
      });
    }
  };

  handleAddDeleteGroupTimeSlot = async (
    weekDay,
    position,
    action,
    index,
    mettingDetail,
    slotsBlock
  ) => {
    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingSlots = [...this.state.groupMeetingDetails];

    if (action == "add") {
      tempGroupMeetingSlots[index]["slots"][weekDay] &&
        tempGroupMeetingSlots[index]["slots"][weekDay].push([0, 0]);
    } else if (action === "delete") {
      try {
        const slots = mettingDetail?.selectedSlots[weekDay];
        if (slotsBlock[0] && slotsBlock[1] && slots && slots?.length > 0) {

          if (slots[position][7]){
            await this.validateCancelSlotFromForm();
              return null;
          }
        } if (
          slotsBlock[0] && slotsBlock[1] && slotsBlock[7] &&
          mettingDetail &&
          mettingDetail?.id
        ) {

          let isBookingReceived = false;

          if (mettingDetail.groupMeetingFrequency !== "Monthly"){
  
            Object.entries(mettingDetail?.slots).map(([weekday, slotsArray]) => {
              slotsArray && slotsArray.map(async (i) => {
                if (i[7]){
                  isBookingReceived = true
                  await this.validateCancelSlotFromForm();
    
                }
              })
            })
          }else{
  
            if(mettingDetail?.selectedSlots?.isBooked){
              isBookingReceived = true
              await this.validateCancelSlotFromForm();
            }
  
          }
          if (isBookingReceived){
            return null
          }
        }
      } catch (error) {
        console.log("error", error);
      }
      if (tempGroupMeetingSlots[index]["slots"][weekDay].length == 1) {
        delete tempGroupMeetingSlots[index]["slots"][weekDay];
        let tempweekDaySelected = {
          ...this.state.groupMeetingDetails[index]["isWeekDaySelected"],
        };
        tempweekDaySelected[weekDay] = false;
        tempGroupMeetingSlots[index]["isWeekDaySelected"] = {
          ...tempweekDaySelected,
        };
      } else {
        tempGroupMeetingSlots[index]["slots"][weekDay] &&
          tempGroupMeetingSlots[index]["slots"][weekDay].splice(position, 1);
        if (tempGroupMeetingSlots[index]["slots"][weekDay].length == 0) {
          let tempweekDaySelected = {
            ...this.state.groupMeetingDetails[index]["isWeekDaySelected"],
          };
          tempweekDaySelected[weekDay] = false;
          tempGroupMeetingSlots[index]["isWeekDaySelected"] = {
            ...tempweekDaySelected,
          };
        }
      }
    }
    this.state.errorData["groupMeetingDetails"][index]["slots"] = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };
    this.setState({
      groupMeetingDetails: [...tempGroupMeetingSlots],
    });
  };

  handleGroupMeetingTimeChange = (
    e,
    weekDay,
    position,
    type,
    index,
    slots,
    mettingDetail
  ) => {
    let payload = {};
    let isUpdate = true;
    try {
      if (this.editedGroupMeetingFromForm.length > 0) {
        let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
        for (let item of this.editedGroupMeetingFromForm) {
          if (
            item?.weekDay === weekDay &&
            item?.index === position &&
            item?.tempGroupMeetingSlot ===
              tempGroupMeetingDetails[index]["slots"][weekDay][position]
          ) {
            if (type === "start") {
              item.slot[0].start = e;
              item.tempGroupMeetingSlot[0] = e;
            } else {
              item.slot[0].end = e;
              item.tempGroupMeetingSlot[1] = e;
            }
            isUpdate = false;
          }
        }
      }
      if (isUpdate) {
        payload = {
          orginalTimeSlot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          seller_id: this.userId,
          product_id: this.state.productId,
          weekDay: weekDay,
          index: position,
          slot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          structureId: mettingDetail?.id,
        };
      }
    } catch (error) {
      console.log("error", error);
    }

    this.setState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
    if (type == "start") {
      tempGroupMeetingDetails[index]["slots"][weekDay][position][0] = e;
    } else {
      tempGroupMeetingDetails[index]["slots"][weekDay][position][1] = e;
    }
    try {
      if (isUpdate) {
        payload["tempGroupMeetingSlot"] =
          tempGroupMeetingDetails[index]["slots"][weekDay][position];
        payload["slot"][0].start =
          tempGroupMeetingDetails[index]["slots"][weekDay][position][0];
        payload["slot"][0].end =
          tempGroupMeetingDetails[index]["slots"][weekDay][position][1];
        this.editedGroupMeetingFromForm.push({ ...payload });
      }
    } catch (error) {
      console.log("error", error);
    }

    this.state.errorData["groupMeetingDetails"][index]["slots"] = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };
    this.state.errorData["groupMeetingDetails"][index]["mainError"] = []

    this.setState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
    });
  };

  addGroupAndOneOnOneMeetingDetails = async (meetingDetails, productId) => {
    let trimmedName =
      meetingDetails["groupMeetingDetails"] &&
      meetingDetails["groupMeetingDetails"]?.map(
        (item) => (item["meetingName"] = item["meetingName"].trim())
      );

    const res = await this.props.addGroupAndOneOnOneMeetingDetails(
      meetingDetails,
      productId
    );
  };

  updateSelectedEvent = (body) => {
    this.editedMeetingFromCalendar.push(body);
  };
  deleteSelectedEvent = (body) => {
    this.canceledMeetingFromCalendar.push(body);
  };

  validateCancelSlot = async (body, isReschedule) => {
    const res = await this.props.validateCancelSlot(body);

    if (!isReschedule) {
      if (res?.isError) {
        return await new Promise((resolve, reject) =>
          confirmAlert({
            title: "",
            message: `${
              res.message.includes("Cancelled")
                ? res.message
                : `${res.message}, Do you wish to cancel ?`
            }`,
            buttons: res.message.includes("Cancelled")
              ? [
                  {
                    label: "Ok",
                    onClick: () => {
                      resolve(false);
                    },
                  },
                ]
              : [
                  {
                    label: "Yes",
                    onClick: () => {
                      // this.deleteSelectedEvent(body)
                      let tempBody = {
                        ...body,
                        isRunningSubscriptionCancelled: 1,
                      };
                      this.deleteSelectedEvent(tempBody);
                      resolve(false);
                    },
                  },
                  {
                    label: "No",
                    onClick: () => {
                      resolve(false);
                    },
                  },
                ],
          })
        );
      } else {
        this.deleteSelectedEvent(body);
        return true;
      }
    } else {
      if (res?.isError && res.type == 2) {
        return await new Promise((resolve, reject) =>
          confirmAlert({
            title: "",
            message: `${
              res.message.includes("Cancelled")
                ? res.message
                : `${res.message}, Do you wish to cancel ?`
            }`,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  resolve(false);
                },
              },
            ],
          })
        );
      } else {
        return true;
      }
    }
  };
  validateCancelSlotFromForm = async (body) => {
    return await new Promise((resolve, reject) =>
      confirmAlert({
        title: "",
        message: `You have running subscriptions for this meeting.`,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              resolve(false);
            },
          },
        ],
      })
    );
  };

  setGroupAndOneOnOneMeetingDetailsFromCalendar = (data, original) => {
    if (data.oneOnOneMeetingDetails) {
      let oneOnOneMeetingDetails = data.oneOnOneMeetingDetails;

      this.setState({
        isOneOnOneMeetingDetailsEdited: true,
        isOneOnOneMeetingChecked: true,
        oneOnOneId: oneOnOneMeetingDetails.id,
        isWeekDaySelected: oneOnOneMeetingDetails.isWeekDaySelected,
        selectedFrequency: oneOnOneMeetingDetails.isFrequencySelected,
        slots: oneOnOneMeetingDetails.slots,
        sessionHour: oneOnOneMeetingDetails.sessionHour,
        sessionMinute: oneOnOneMeetingDetails.sessionMinute,
        trialCost: oneOnOneMeetingDetails.trialCost,
        subscriptionCost: oneOnOneMeetingDetails.subscriptionCost,
        selectedSlots: oneOnOneMeetingDetails.slots,
      });
    }
    if (data.groupMeetingDetails) {
      let meetingDetails = data.groupMeetingDetails;

      let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];

      if (
        data?.groupMeetingDetails?.groupMeetingFrequency?.toLowerCase() !==
        "monthly"
      ) {
        let incomingTime =
          original &&
          Object.keys(original).length &&
          Object.values(original["slots"])[0][0];
        let newTimeSlots = Object.values(meetingDetails["slots"])[0][0];
        if (incomingTime) {
          this.state.groupMeetingDetails.map((item, index) => {
            if (item.meetingName == original.meetingName) {
              let tempSlots = { ...item.slots };
              Object.entries(item.slots).map(([weekDay, timeslotArray]) => {
                if (original && weekDay == Object.keys(original["slots"])[0]) {
                  timeslotArray.map((timeslot, index) => {
                    let startTimeHour = +timeslot[0].split(":")[0];
                    let startTimeMinute = +timeslot[0].split(":")[1];
                    let endTimeHour = +timeslot[1].split(":")[0];
                    let endTimeMinute = +timeslot[1].split(":")[1];

                    let incomingStartTimeHour = +incomingTime[0].split(":")[0];
                    let incomingStartTimeMinute =
                      +incomingTime[0].split(":")[1];
                    let incomingEndTimeHour = +incomingTime[1].split(":")[0];
                    let incomingEndTimeMinute = +incomingTime[1].split(":")[1];
                    if (
                      startTimeHour == incomingStartTimeHour &&
                      startTimeMinute == incomingStartTimeMinute &&
                      endTimeHour == incomingEndTimeHour &&
                      endTimeMinute == incomingEndTimeMinute
                    ) {
                      tempSlots[weekDay][index] = newTimeSlots;
                    } else {
                      tempSlots[weekDay][index] = tempSlots[weekDay][index]
                        ? [...tempSlots[weekDay][index], timeslot]
                        : [[timeslot]];
                    }
                  });
                } else {
                  tempSlots[weekDay] = timeslotArray;
                }
              });
            }
          });
        } else {
          if (this.state.isGroupMeetingChecked)
            tempGroupMeetingDetails.push(meetingDetails);
          else tempGroupMeetingDetails = [meetingDetails];
        }
      } else if (
        data?.groupMeetingDetails?.groupMeetingFrequency?.toLowerCase() ==
        "monthly"
      ) {
        if (original) {
          this.state.groupMeetingDetails.map((item, index) => {
            if (item.meetingName == original.meetingName) {
              let originalStartTimeHour =
                +original.meetingMonthlyStartTime.split(":")[0];
              let originalStartTimeMinute =
                +original.meetingMonthlyStartTime.split(":")[1];
              let originalEndTimeHour =
                +original.meetingMonthlyStartTime.split(":")[0];
              let originalEndTimeMinute =
                +original.meetingMonthlyStartTime.split(":")[1];

              let currentStartTimeHour =
                +item.meetingMonthlyStartTime.split(":")[0];
              let currentStartTimeMinute =
                +item.meetingMonthlyStartTime.split(":")[1];
              let currentEndTimeHour =
                +item.meetingMonthlyStartTime.split(":")[0];
              let currentEndTimeMinute =
                +item.meetingMonthlyStartTime.split(":")[1];

              if (
                originalStartTimeHour == currentStartTimeHour &&
                originalStartTimeMinute == currentStartTimeMinute &&
                originalEndTimeHour == currentEndTimeHour &&
                originalEndTimeMinute == currentEndTimeMinute
              ) {
                item["meetingMonthlyEndTime"] =
                  data?.groupMeetingDetails?.meetingMonthlyEndTime;
                item["meetingMonthlyStartTime"] =
                  data?.groupMeetingDetails?.meetingMonthlyStartTime;
              }
            }
          });
        } else {
          if (this.state.isGroupMeetingChecked)
            tempGroupMeetingDetails.push(meetingDetails);
          else tempGroupMeetingDetails = [meetingDetails];
        }
      }

      this.setState({
        isGroupMeetingDetailsEdited: true,
        isGroupMeetingChecked: true,
        groupMeetingDetails: tempGroupMeetingDetails,
      });

      let errorData = { ...this.state.errorData };
      if (!!!errorData["groupMeetingDetails"]) {
        errorData["groupMeetingDetails"] = [
          {
            meetingName: [],
            subscriptionCost: [],
            sessionHour: [],
            sessionMinute: [],
            meetingMonthlyStartTime: [],
            meetingMonthlyEndTime: [],
            meetingMonthlyWeekDay: [],
            meetingMonthlyWeekOfMonth: [],
            groupMeetingFrequency: [],
            isWeekDaySelected: [],
            slots: [],
          },
        ];
      } else {
        errorData["groupMeetingDetails"].push({
          meetingName: [],
          subscriptionCost: [],
          sessionHour: [],
          sessionMinute: [],
          meetingMonthlyStartTime: [],
          meetingMonthlyEndTime: [],
          meetingMonthlyWeekDay: [],
          meetingMonthlyWeekOfMonth: [],
          groupMeetingFrequency: [],
          isWeekDaySelected: [],
          slots: [],
        });
      }
      this.setState(
        {
          errorData,
        });
    }
  };

  getGroupAndOneOnOneMeetingDetails = async (productId) => {
    const res = await this.props.getGroupAndOneOnOneMeetingDetails(productId);

    let slots = { ...this.state.slots };
    let oneOnOneSelectedWeekDays = {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    };
    let selectedFrequency = { ...this.state.selectedFrequency };
    let oneOnOneTrialCost = 0;
    let oneOnOneSubscriptionCost = 0;
    let oneOneOneSessionHour = "00";
    let oneOneOneSessionMinute = "00";
    let id = null;

    let groupMeetingDetails = [];

    if (res.data.data.length) {
      const groupOneOnOneMeetingData = res.data.data;

      let errorData = { ...this.state.errorData };

      groupOneOnOneMeetingData.map((data) => {
        if (data.type == "group") {
          if (!!!errorData["groupMeetingDetails"]) {
            errorData["groupMeetingDetails"] = [
              {
                meetingName: [],
                subscriptionCost: [],
                sessionHour: [],
                sessionMinute: [],
                meetingMonthlyStartTime: [],
                meetingMonthlyEndTime: [],
                meetingMonthlyWeekDay: [],
                meetingMonthlyWeekOfMonth: [],
                groupMeetingFrequency: [],
                isWeekDaySelected: [],
                slots: [],
              },
            ];
          } else {
            errorData["groupMeetingDetails"].push({
              meetingName: [],
              subscriptionCost: [],
              sessionHour: [],
              sessionMinute: [],
              meetingMonthlyStartTime: [],
              meetingMonthlyEndTime: [],
              meetingMonthlyWeekDay: [],
              meetingMonthlyWeekOfMonth: [],
              groupMeetingFrequency: [],
              isWeekDaySelected: [],
              slots: [],
            });
          }
        }
      });

      this.setState(
        {
          errorData,
        });

      groupOneOnOneMeetingData.map((item) => {
        if (item.type == "one_on_one") {
          if (item.status == "enable") {
            this.setState({
              isOneOnOneMeetingChecked: true,
            });
          }
          let meetingSlot = item.availability
            ? JSON.parse(item.availability)
            : null;
          let sessionDuration = item.session_duration
            ? JSON.parse(item.session_duration)
            : null;

          if (!Object.keys(meetingSlot).length)
            this.setState({ isOneOnOneMeetingChecked: false });
          if (meetingSlot) {
            slots = { ...meetingSlot };
            Object.entries(slots).map(([weekDay, timeSlot]) => {
              oneOnOneSelectedWeekDays[weekDay] = true;
            });
          }
          if (sessionDuration) {
            oneOneOneSessionHour = sessionDuration.sessionHour
              ? sessionDuration.sessionHour
              : "";
            oneOneOneSessionMinute = sessionDuration.sessionMinute
              ? sessionDuration.sessionMinute
              : "";
          }

          let oneOnOneFreq = item.frequency.split(",");
          oneOnOneFreq.map((frequency) => {
            selectedFrequency[frequency] = true;
          });

          oneOnOneTrialCost = item.one_time_cost;
          oneOnOneSubscriptionCost = item.subscription_cost;
          id = item.id;
        } else if (item.type == "group") {
          if (item.status == "enable") {
            this.setState({
              isGroupMeetingChecked: true,
            });
          }

          let tempGroupMeetingObj = {
            id: null,
            meetingName: "",
            subscriptionCost: 0,
            sessionHour: "00",
            sessionMinute: "00",
            meetingMonthlyStartTime: "",
            meetingMonthlyEndTime: "",
            meetingMonthlyWeekDay: "",
            link: "",
            host_link: "",
            meetingMonthlyWeekOfMonth: "",
            groupMeetingFrequency: "",
            isMonthlyGroupFrequencyChecked: false,
            isWeekDaySelected: {
              Sun: false,
              Mon: false,
              Tue: false,
              Wed: false,
              Thu: false,
              Fri: false,
              Sat: false,
            },
            selectedSlots: {},

            slots: {},
          };

          let groupSessionDuration = item.session_duration
            ? JSON.parse(item.session_duration)
            : null;
          let slots = item.availability ? JSON.parse(item.availability) : null;

          tempGroupMeetingObj["id"] = item.id;
          tempGroupMeetingObj["meetingName"] = item.group_meeting_name;
          tempGroupMeetingObj["subscriptionCost"] = item.subscription_cost;
          tempGroupMeetingObj["sessionHour"] = groupSessionDuration.sessionHour;
          tempGroupMeetingObj["sessionMinute"] =
            groupSessionDuration.sessionMinute;
          tempGroupMeetingObj["groupMeetingFrequency"] = item.frequency;
          tempGroupMeetingObj["slots"] = { ...slots };
          tempGroupMeetingObj["selectedSlots"] = { ...slots };

          if (item.frequency !== "Monthly") {
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = false;

            Object.entries(slots).map(([weekDay, timeSlot]) => {
              tempGroupMeetingObj["isWeekDaySelected"][weekDay] = true;
            });
            groupMeetingDetails.push({ ...tempGroupMeetingObj });
          } else {
            tempGroupMeetingObj["meetingMonthlyStartTime"] = slots.startTime;
            tempGroupMeetingObj["meetingMonthlyEndTime"] = slots.endTime;
            tempGroupMeetingObj["meetingMonthlyWeekOfMonth"] =
              slots.weekOfMonth;
            tempGroupMeetingObj["meetingMonthlyWeekDay"] = slots.weekDay;
            tempGroupMeetingObj["link"] = slots.link ? slots.link : null;
            tempGroupMeetingObj["host_link"] = slots.host_link
              ? slots.host_link
              : null;
            tempGroupMeetingObj["isRunningSubscriptionCancelled"] =
              !!slots.isRunningSubscriptionCancelled
                ? slots.isRunningSubscriptionCancelled
                : 0;
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = true;
            groupMeetingDetails.push({ ...tempGroupMeetingObj });
          }
        }
      });
    }

    let isGroupMeetingChecked = groupMeetingDetails.length == 0 ? false : true;

    this.setState({
      oneOnOneId: id,
      slots: slots,
      selectedSlots: slots,
      isWeekDaySelected: { ...oneOnOneSelectedWeekDays },
      selectedFrequency: selectedFrequency,
      trialCost: oneOnOneTrialCost,
      subscriptionCost: oneOnOneSubscriptionCost,
      sessionHour: oneOneOneSessionHour,
      sessionMinute: oneOneOneSessionMinute,
      groupMeetingDetails: groupMeetingDetails.length
        ? [...groupMeetingDetails]
        : [...this.state.groupMeetingDetails],
    });
    return res;
  };

  handleGroupMeetingMonthlyWeekDayAndWeekChange = async (
    e,
    key,
    index,
    groupMeetingDetails
  ) => {
    let flag = false;
    try {
      if (
        groupMeetingDetails &&
        groupMeetingDetails?.id &&
        groupMeetingDetails?.slots
      ) {

        let isBookingReceived = false;

        if (groupMeetingDetails.groupMeetingFrequency !== "Monthly"){

          Object.entries(groupMeetingDetails?.slots).map(([weekday, slotsArray]) => {
            slotsArray && slotsArray.map(async (i) => {
              if (i[7]){
                isBookingReceived = true
                await this.validateCancelSlotFromForm();
  
              }
            })
          })
        }else{

          if(groupMeetingDetails?.selectedSlots?.isBooked){
            isBookingReceived = true
            await this.validateCancelSlotFromForm();
          }

        }
        if (isBookingReceived){
          return null
        }
      }
    } catch (err) {
      console.log(err);
    }

    if (!flag) {
      this.setState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempGroupMeetingDetails = [...this.state.groupMeetingDetails];
      this.state.errorData["groupMeetingDetails"][index][key] = [];
      tempGroupMeetingDetails[index][key] = e.target.value;
      this.setState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
      });
    }
  };

  showSeminarPopup = () => {
    this.setState({ isFreeSeminar: true });
  };
  calculateHourMinuteDiff = (startEndTimeArray, index) => {
    let numberOfSlots = null;
    let sessionDuration = 1;

    if (startEndTimeArray[0] && startEndTimeArray[1]) {
      let startTimeHour = startEndTimeArray[0].split(":")[0];
      let startTimeMinute = startEndTimeArray[0].split(":")[1];
      let endTimeHour = startEndTimeArray[1].split(":")[0];
      let endTimeMinute = startEndTimeArray[1].split(":")[1];
      
      const start = new Date("August 19, 1975 23:15:30");
      const end = new Date("August 19, 1975 23:15:30");
      start.setMinutes(startTimeMinute);
      start.setHours(startTimeHour);

      end.setMinutes(endTimeMinute);
      end.setHours(endTimeHour);

      let timeDifferenceFromDates = end - start;

      if (timeDifferenceFromDates < 0) {
        return numberOfSlots;
      }

      let timeSlotDiff = parseFloat(
        (timeDifferenceFromDates * (0.001 / 3600)).toFixed(2)
      );

      if (index !== -1) {
        sessionDuration =
          parseInt(this.state.groupMeetingDetails[index]["sessionHour"]) +
          parseFloat(
            (
              +this.state.groupMeetingDetails[index]["sessionMinute"] / 60
            ).toFixed(2)
          );
      } else {
        sessionDuration =
          parseInt(this.state.sessionHour) +
          parseFloat((this.state.sessionMinute / 60).toFixed(2));
      }
      numberOfSlots = timeSlotDiff / sessionDuration;
    }

    return numberOfSlots;
  };
  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const galleryImages =
      this.state.galleryImages.length > 0
        ? this.state.galleryImages.map((imgPath) => {
            return {
              source: imgPath,
            };
          })
        : [];

    var now = moment(new Date()),
      end = moment(this.state.last_price_updated_time),
      days = end.diff(now, "months");
    const date1 = new Date(this.state.last_price_updated_time);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>{this.props.params.slug ? "edit" : "add"} product</h2>
        </div>
        <div className="admin-card-box">
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadVideo}
            accept={this.fileFormats.video.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadAudio}
            accept={this.fileFormats.audio.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadImage}
            accept={this.fileFormats.image.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            type="file"
            hidden
            multiple
            ref={this.fileUploadDoc}
            accept={this.fileFormats.doc.map((item) => "." + item).join(",")}
            onClick={(event) => {
              event.target.value = null;
            }}
            onChange={this.fileSelectHandler}
          />
          <input
            hidden
            type="file"
            ref={this.fileUploadAttribute}
            accept="image/*"
            onChange={(e) => this.uploadAttributeImage(e)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <input
            hidden
            type="file"
            ref={this.uploadGlobalImage}
            accept="image/*"
            onChange={(e) => this.uploadGlobalAttributes(e,"globalImage",this.state.attibuteGlobalValue,this.state.attibuteGlobalIndex)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          {(() => {
            if (
              this.state.successMessage !== "" &&
              this.state.successMessage !== undefined
            ) {
              return (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              );
            } else if (
              this.state.errorMessage !== "" &&
              this.state.errorMessage !== undefined
            ) {
              return (
                <div className="alert alert-danger">
                  {this.state.errorMessage}
                </div>
              );
            }
          })()}

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label className="custom-label-product pb-2">
                  {this.state.isAccessCategoryInCategories
                    ? `company name`
                    : `product title`}
                  <sup>*</sup>
                  <div className="tooltip-custom">
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="together-tooltip">
                      <p>
                        {this.state.isAccessCategoryInCategories
                          ? `Enter The Company Name`
                          : `Enter The Product Title`}
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </label>
                <input
                  type="text"
                  required
                  onChange={(e) => this.handleChange(e, "productTitle")}
                  value={this.state.productTitle}
                  className="form-control"
                />
                <span className="form-field-error">
                  {this.state.errorData?.productTitle &&
                    this.state.errorData.productTitle[0]}
                </span>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="form-group slot-line">
                {this.setActiveSubscriptionCustomerListModal()}
              </div>
            </div>
            {!this.state.isAccessCategoryInCategories && (
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="custom-label-product">
                    base price
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Please Select Price Of The Product
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => this.handleChange(e, "productPrice")}
                    value={this.state.productPrice}
                    disabled={diffDays <= 10}
                    className="form-control"
                  />
                  <span className="form-field-error">
                    {this.state.errorData?.productPrice &&
                      this.state.errorData.productPrice[0]}
                  </span>
                </div>
                <span className="note-ecom">
                    <i className="fas fa-exclamation-circle"></i>
                    <strong>
                      Note<sup>*</sup>
                    </strong>
                    After initial approval, price changes will be reflected after 10 days.
                </span>
              </div>
            )}
          </div>
          {!this.state.isAccessCategoryInCategories && (
            <label className="custom-check custom-label-product d-flex my-2">
              Is Sale
              <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                        Offer discount on offering
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
              <input
                type="checkbox"
                checked={this.state.is_sale}
                onChange={(e) => this.handleChangeChk(e, "is_sale")}
                disabled={this.state.productPrice == 0}
              />
              <span className="checkmark"></span>
            </label>
          )}
          {this.state.is_sale === 1 &&
          !this.state.isAccessCategoryInCategories ? (
            <div className="row">
              {/* price type 1 for fixed and type 2 for percentage */}
              <div className="col-md-6">
                <div className="form-group">
                  <label>Price Type</label>
                  <select
                    className="entries-controls form-control"
                    value={this.state.price_type}
                    onChange={(e) => this.handleChange(e, "price_type")}
                  >
                    {this.state.withAttribute !== 1 && (
                      <option value={"1"}>Fixed</option>
                    )}
                    <option value={"2"}>Percentage</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    sale
                    <sup>*</sup>
                  </label>
                  <input
                    type="number"
                    min={0}
                    onChange={(e) => this.handleChange(e, "sale_price")}
                    value={this.state.sale_price}
                    className="form-control"
                  />
                  {!isNaN(this.state.productPrice) && !!this.state.sale_price && this.state.sale_price > 0
                    ? this.state.price_type == "2"
                      ? "Discounted Price : " +
                        (
                          this.state.productPrice -
                          (this.state.productPrice * this.state.sale_price) /
                            100
                        )
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "Discounted Price : " +
                        (this.state.productPrice - this.state.sale_price)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : null}
                  <span className="form-field-error">
                    {this.state.errorData?.salePrice &&
                      this.state.errorData.salePrice[0]}
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="product-preview-image">
                      {croppedImageUrl && this.state.isCropImage ? (
                        <img alt="Crop" src={croppedImageUrl} />
                      ) : (
                        <img
                          src={
                            this.state.previewProductImage &&
                            this.state.previewProductImage !== ""
                              ? this.state.previewProductImage
                              : null
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div className="upload-btn text-center mt-4">
                      <button
                        onClick={this.triggerClick}
                        type="button"
                        className={`orange-outline-btn mr-2 ${
                          this.state
                            .isDisabledAttributesForWoocommerceShopify &&
                          "disable-btn"
                        }`}
                        disabled={
                          this.state.isDisabledAttributesForWoocommerceShopify
                        }
                      >
                        upload image
                      </button>
                      {src && !this.state.isCropImage && (
                        <button
                          onClick={() =>
                            this.setState({
                              isCropImage: true,
                              showCropper: true,
                            })
                          }
                          type="button"
                        >
                          crop image
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-5">
                    <div className="crop-image-view">
                      <div style={{ display: "none" }}>
                        <input
                          ref={this.fileUpload}
                          type="file"
                          accept="image/*"
                          onChange={this.onSelectFile}
                        />
                      </div>
                      {src &&
                        this.state.isCropImage &&
                        this.state.showCropper && (
                          <ReactCrop
                            crop={crop}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                          >
                            <img src={src} onLoad={this.onImageLoaded} alt=""  />
                          </ReactCrop>
                        )}
                      <div className="mt-4">
                        {this.state.isCropImage && this.state.showCropper && (
                          <>
                            <button
                              onClick={() =>
                                this.setState({
                                  showCropper: false,
                                })
                              }
                              type="button"
                              className="mr-2"
                            >
                              Done
                            </button>
                            <button
                              onClick={() =>
                                this.setState({
                                  isCropImage: false,
                                  showCropper: false,
                                })
                              }
                              type="button"
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <span className="form-field-error">
                  {this.state.errorData?.productImage &&
                    this.state.errorData.productImage[0]}
                </span>
              </div>
            </div>
          </div>
          <p style={{color: "#ff0000", fontSize: '14px', marginTop: '10px'}}>{this.state.errorData.largeImageError}</p>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group label-radio-inline mb-0">
                {!this.state.isAccessCategoryInCategories && (
                  <label className="mr-4 d-flex align-items-center">
                    Select product time-frame
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Would you like to make this a recurring option? Select
                          options
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                )}

                <div className="custom-radio-inline">
                  {!this.state.isAccessCategoryInCategories ? (
                    <>
                      <label className="custon-radio">
                        Weekly
                        <input
                          type="radio"
                          onChange={(e) =>
                            this.handleChange(e, "productTimeFrame")
                          }
                          onClick={() =>
                            this.state.productTimeFrame === "weekly" &&
                            this.setState((prevState) => ({
                              ...prevState,
                              productTimeFrame: "",
                            }))
                          }
                          value="weekly"
                          checked={this.state.productTimeFrame === "weekly"}
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="custon-radio">
                        Monthly
                        <input
                          type="radio"
                          onChange={(e) =>
                            this.handleChange(e, "productTimeFrame")
                          }
                          onClick={() =>
                            this.state.productTimeFrame === "monthly" &&
                            this.setState((prevState) => ({
                              ...prevState,
                              productTimeFrame: "",
                            }))
                          }
                          value="monthly"
                          checked={this.state.productTimeFrame === "monthly"}
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="custon-radio">
                        Annual
                        <input
                          type="radio"
                          onChange={(e) =>
                            this.handleChange(e, "productTimeFrame")
                          }
                          onClick={() =>
                            this.state.productTimeFrame === "annual" &&
                            this.setState((prevState) => ({
                              ...prevState,
                              productTimeFrame: "",
                            }))
                          }
                          value="annual"
                          checked={this.state.productTimeFrame === "annual"}
                          name="radio"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </>
                  ) : !this.state.isAccessCategoryInCategories ? (
                    <label className="custon-radio">
                      Monthly
                      <input
                        type="radio"
                        onChange={(e) =>
                          this.handleChange(e, "productTimeFrame")
                        }
                        onClick={() =>
                          this.state.productTimeFrame === "monthly" &&
                          this.setState((prevState) => ({
                            ...prevState,
                            productTimeFrame: "",
                          }))
                        }
                        value="monthly"
                        checked={this.state.productTimeFrame === "monthly"}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  ) : null}

                  <span className="form-field-error">
                    {this.state.errorData?.productTimeFrame &&
                      this.state.errorData.productTimeFrame[0]}
                  </span>
                </div>
                {this.state.selectedParentProductCategoryId.length !== 0 &&
                  (this.state.selectedParentProductCategoryId.includes(101) ||
                    this.state.selectedParentProductCategoryId.includes(119)) &&
                  !this.state.selectedParentProductCategoryId.includes(3) && (
                    <div className="custom-radio-inline">
                      <label className="custon-radio">
                        One Time
                        <input type="radio" checked={true} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
              </div>
            </div>
   
            <div className="col-lg-6 col-md-6">
            <span className="note-ecom note-ecom-cate">
                    <i className="fas fa-exclamation-circle"></i>
                    <strong>
                      Note<sup>*</sup>
                    </strong>
                    Please Select Parent Category First.
                </span>
              <div className="form-group">             
                <label className="custom-label-product">
                  Parent Category
                  <div className="tooltip-custom">
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="together-tooltip">
                      <p>
                        Select Parent Category
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                  <sup>*</sup>
                </label>
                <Select
                  value={this.state.defaultParentSelectedOption}
                  name="value"
                  options={this.state.productParentCategoryList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e, value) => {
                    this.handleAlertParentAccessCategory(e, value);
                  }}
                />
                <span className="form-field-error">
                  {this.state.errorData?.selectedParentProductCategoryId &&
                    this.state.errorData.selectedParentProductCategoryId[0]}
                </span>
              </div>
              {this.state.isCustomizeCategory && (
                <div className="form-check-inline">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      onClick={(e) => this.handleIsDigital(e)}
                      className="form-check-input"
                      checked={this.state.isDigitalChecked}
                    />
                    Is digital?
                  </label>
                </div>
              )}
            </div>

            {!this.state.isAccessCategoryInCategories && (
              <div className="col-lg-6 col-md-6">
                <div className="form-group mar32">
                  <label className="custom-label-product">
                    Product Order limit 
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Enter Product Order Limit
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>
                  <select
                    name="order_limit"
                    className="form-control"
                    onChange={(e) => this.handleChange(e, "order_limit")}
                  >
                    <option value="" selected disabled>
                      Select Order Limit
                    </option>
                    {[...Array(999).keys()].map((index) => (
                      <option
                        key={index}
                        selected={index + 1 == this.state.order_limit}
                        value={index + 1}
                      >
                        {index + 1}
                      </option>
                    ))}
                  </select>
                  <span className="form-field-error">
                    {this.state.errorData?.order_limit &&
                      this.state.errorData.order_limit[0]}
                  </span>
                </div>
              </div>
            )}

            
            {this.state.isCustomizeCategory && (
              <div className="col-md-6">
                <div className="form-group">
                  <label className="mr-4 d-flex align-items-center">
                    Product Creation Time (In Days)
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Time To Completion
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => this.handleProductTime(e)}
                    value={this.state.customizedProductTime}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            {/* TODO uncomment ooo meeting */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <div className="mb-2">
                  <label className="custom-label-product mr-2">
                    Search Categories
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Searchable categories to find your offering
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
                {this.state?.searchCategoryParents.map((item, index) => (
                  <div className="border-seprate">
                    {this.state.searchCategoryParents.length > 0 && (
                      <label className="mt-2">
                        Search Category{" "}
                        {this.state.searchCategoryParents.length > 1
                          ? index + 1
                          : ""}
                      </label>
                    )}
                    <i
                      className="fa fa-trash fa-sm add-section-cursor ml-2"
                      aria-hidden="true"
                      onClick={() => this.handleDeleteSearchCategory(index)}
                    ></i>
                    <ul className="search-pills-category mt-1">
                      {item?.map((parent, pIndex) => (
                        <li key={pIndex}>
                          {parent?.name}
                          <span>
                            <i
                              className="fas fa-times"
                              onClick={() =>
                                this.removeSearchCategory(parent, pIndex, index)
                              }
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                    <select
                      placeholder="Select search category"
                      className="form-control basic-multi-select border-seprate"
                      classNamePrefix="select"
                      value={this.state.searchCategoryValue}
                      onChange={(e) =>
                        this.handleSearchCategoryChange(e, index)
                      }
                    >
                      <option value="" selected disabled>
                        Select search category
                      </option>
                      {Array.isArray(
                        this.state.searchCategoryChildOptions[index]
                      ) &&
                        this.state.searchCategoryChildOptions[index].map(
                          (category, ind) => (
                            <option value={category.id} key={ind}>
                              {category.name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                ))}
                <span
                  className="add-section-cursor"
                  onClick={() => this.handleAddSearchCat()}
                >
                  <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>
                  Add New Category
                </span>

                {this.state.isAccessCategoryInCategories &&
                  this.state.showCalanderLoader && (
                    <span
                      className="spinner-border spinner-border-sm ml-2"
                      style={{ minWidth: "1rem", minHeight: "1rem" }}
                    ></span>
                  )}
              </div>
              {this.state.isCustomizeCategory ? (
                <div className="form-group">
                  <div className="mb-2">
                    <label className="custom-label-product mr-2">
                      Customize Questions
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            required information from customers for customized
                            offering
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                  {this.state.customizeQuestions &&
                  this.state.customizeQuestions?.length
                    ? this.state.customizeQuestions.map((item, index) => {
                        return (
                          <>
                            <div className="border-seprate">
                              <label className="mt-2">
                                Question{" "}
                                {this.state.customizeQuestions.length > 1
                                  ? index + 1
                                  : ""}
                              </label>
                              <i
                                className="fa fa-trash fa-sm add-section-cursor ml-2"
                                aria-hidden="true"
                                onClick={() =>
                                  this.handleDeleteCustomizeQuestion(index)
                                }
                              ></i>
                            </div>
                            <input
                              type="text"
                              required
                              onChange={(e) =>
                                this.handleChange(
                                  e,
                                  "customizeQuestions",
                                  index
                                )
                              }
                              value={this.state.customizeQuestions[index]}
                              className="form-control"
                            />
                          </>
                        );
                      })
                    : null}
                  <span
                    className="add-section-cursor"
                    onClick={() => this.handleAddCustomizeQuestion()}
                  >
                    <i
                      className="fa fa-plus-circle mr-2"
                      aria-hidden="true"
                    ></i>
                    Add Question
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          {this.state.isAccessCategoryInCategories ? (
            <>
              <div className="form-group bb1">
                <Calendar
                  showLoaderForCalander={
                    (loader) =>this.showLoaderForCalander(loader)
                  }
                  showHideFreeSeminarModal={this.state.showHideFreeSeminarModal}
                  showSeminarPopup={this.showSeminarPopup}
                  handleCreateFreeSeminar={this.handleCreateFreeSeminar}
                  title={this.state.productTitle}
                  productId={this.state.productId}
                  seller_id={this.userId}
                  isOneOnOneMeetingChecked={this.state.isOneOnOneMeetingChecked}
                  handleOpenMeetingSchedule={this.handleOpenMeetingSchedule}
                  zoomLink={this.state.zoom_host_url}
                  getGroupAndOneOnOneMeetingDetails={
                    this.getGroupAndOneOnOneMeetingDetails
                  }
                  setGroupAndOneOnOneMeetingDetailsFromCalendar={
                    this.setGroupAndOneOnOneMeetingDetailsFromCalendar
                  }
                  updateSelectedEvent={this.updateSelectedEvent}
                  deleteSelectedEvent={this.deleteSelectedEvent}
                  validateCancelSlot={this.validateCancelSlot}
                  calculateHourMinuteDiff={this.calculateHourMinuteDiff}
                  freeSeminarDetails={this.state.formData}
                  isWeekDaySelected={this.state.isWeekDaySelected}
                  handleSubmit={this.handleSubmit}
                />
              </div>
            </>
          ) : null}
          {this.state.isAccessCategoryInCategories && (
            <div className="col-lg-6 col-md-6 p-0">
              <div className="form-group">
                <div className="d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    checked={this.state.isOneOnOneMeetingChecked}
                    onChange={this.handleIsOneOnOneMeeting}
                    className="mr-1"
                  />
                  <label className=" custom-label-product m-0">
                    Create One on one Session
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Create One-On-One Session
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
                <span className="form-field-error">
                  {this.state.errorData?.o_o_o_meeting_price &&
                    this.state.errorData.o_o_o_meeting_price[0]}
                </span>
              </div>
            </div>
          )}
          {this.state.isOneOnOneMeetingChecked &&
            this.state.isAccessCategoryInCategories && (
              <div className="container-fluid">
                <div className="row">
                  <div className="form-group col-lg-12 col-md-12 border-show-seprate">
                    <h5>Select Frequency</h5>
                    <div className="form-group">
                      <label className="theme-color ">
                        <b>one on one</b>
                      </label>
                      <div className="frequency black-bg-op">
                        <label className="custom-label-product">
                          Select frequency
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Select the type of one on one sessions you would
                                like to offer
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                        {this.selectOneOnOneFrequency.map(
                          (frequency, freqIndex) => {
                            return (
                              <div className="check-outer" key={freqIndex}>
                                <label className="outer-label mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      this.state.selectedFrequency[frequency]
                                    }
                                    onChange={(e) =>
                                      this.handleChangeChk(
                                        e,
                                        frequency == `All`
                                          ? `allFrequencySelected`
                                          : `selectedFrequency`,
                                        "",
                                        frequency
                                      )
                                    }
                                  />{" "}
                                  {frequency}
                                  <span class="checkmark" />
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <span className="form-field-error ml-2">
                        {this.state.errorData?.selectedOneOnOneFrequency &&
                          this.state.errorData.selectedOneOnOneFrequency[0]}
                      </span>
                    </div>
                    <div className="form-group px-2">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group d-flex mb-0">
                            <label className="custom-label-product">
                              Trial or 1-time cost
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Enter the cost you would like to charge for
                                    a trial or one time meeting
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="positionRelative">
                              <span className="dollar-set">
                                <img src={DollarIcon} alt="" />
                              </span>
                              <input
                                className="form-control pl25"
                                type="number"
                                value={this.state.trialCost}
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "trialCost"
                                  )
                                }
                                min={0}
                              />
                            </div>
                          </div>
                          <p className="form-field-error">
                            {this.state.errorData?.trialCost &&
                              this.state.errorData.trialCost[0]}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group d-flex mb-0">
                            <label className="custom-label-product d-flex">
                              Subscription Cost
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Enter the cost you would like to charge per
                                    recurring session. For Example: A $20/per
                                    session cost for a weekly option will create
                                    a $80/month subscription offering
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div>
                              <input
                                className="form-control "
                                type="number" 
                                value={this.state.subscriptionCost}
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "subscriptionCost"
                                  )
                                }
                                min={0}
                              />
                            </div>
                          </div>
                          <p className="form-field-error">
                            {this.state.errorData?.subscriptionCost &&
                              this.state.errorData.subscriptionCost[0]}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group d-flex mb-0">
                            <label className="custom-label-product">
                              Session Duration
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Enter duration you would like to offer per
                                    session
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="d-flex">
                              <label className="d-flex align-items-center mr-2 mb-0">
                                Hours
                              </label>
                              <select
                                className="form-control mr-2"
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "sessionHour"
                                  )
                                }
                                value={this.state.sessionHour}
                              >
                                <option value="Hours" selected disabled>
                                  Hours
                                </option>
                                {this.sessionHours.map((hour) => {
                                  return <option value={hour}>{hour}</option>;
                                })}
                              </select>
                              <label className="d-flex align-items-center mr-2 mb-0">
                                Minutes
                              </label>
                              <select
                                className="form-control "
                                onChange={(e) =>
                                  this.handleSessionHourMinuteTrialSubscriptionCostChange(
                                    e,
                                    "sessionMinute"
                                  )
                                }
                                value={this.state.sessionMinute}
                              >
                                <option value="Minutes" selected disabled>
                                  Minutes
                                </option>
                                {this.sessionMinutes.map((minute) => {
                                  return (
                                    <option value={minute}>{minute}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {this.state.errorData?.sessionHour &&
                              this.state.errorData.sessionHour[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group px-2">
                      <div className="frequency black-bg-op">
                        <label className="custom-label-product">
                          Available days
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Enter Day(s) you would like to offer
                                availability for customers to schedule with you
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>

                        {Object.entries(this.weekDays).map(
                          ([weekDay, weekDayVal]) => {
                            return (
                              <div className="check-outer">
                                <label className="outer-label mb-0">
                                  <input
                                    type="checkbox"
                                    checked={
                                      this.state.isWeekDaySelected[weekDay]
                                    }
                                    onChange={(e) =>
                                      this.handleChangeChk(
                                        e,
                                        "isWeekDaySelected",
                                        weekDay
                                      )
                                    }
                                  />{" "}
                                  {weekDay}
                                  <span class="checkmark" />
                                </label>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <span className="form-field-error">
                        {this.state.errorData?.selectedWeekDay &&
                          this.state.errorData.selectedWeekDay[0]}
                      </span>
                    </div>
                    <div>
                      <label className="custom-label-product pb-2">
                        AVAILABLE TIME SLOTS
                        <span className="form-field-error ml-2">{
                          this.state.errorData["mainError"]
                          && this.state.errorData["mainError"][0]
                        }</span>
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter availability time frame(s) per day(s)
                              selected above
                              <span className="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                      <div className="recurring">
                        {Object.entries(this.weekDays).map(
                          ([weekDay, weekDayVal]) => {
                            return (
                              <>
                                <div
                                  className={`week-card ${
                                    this.state.isWeekDaySelected[weekDay]
                                      ? ``
                                      : `disabled`
                                  }`}
                                >
                                  <div
                                    className={`day active ${
                                      this.state.isWeekDaySelected[weekDay]
                                        ? ``
                                        : `disabled`
                                    }`}
                                  >
                                    {weekDayVal}
                                  </div>

                                  <div>
                                    {this.state.slots[weekDay]?.length > 0 &&
                                      this.state.slots[weekDay]?.map(
                                        (timeSlot, index) => {
                                          return (
                                            <>
                                              <div className="week-bottom">
                                                <div className="d-flex align-items-center">
                                                  <TimePicker
                                                    value={timeSlot[0]}
                                                    format="hh:mm a"
                                                    name="start"
                                                    disableClock={true}
                                                    onChange={(e) =>
                                                      this.handleOneOnOneMeetingTimeChange(
                                                        e,
                                                        weekDay,
                                                        index,
                                                        "start",
                                                        timeSlot
                                                      )
                                                    }
                                                    required={true}
                                                    clearIcon={null}
                                                    className="form-control"
                                                  />
                                                  <p className="mb-0 mx-2">
                                                    To
                                                  </p>
                                                  <TimePicker
                                                    value={timeSlot[1]}
                                                    format="hh:mm a"
                                                    name="start"
                                                    disableClock={true}
                                                    onChange={(e) =>
                                                      this.handleOneOnOneMeetingTimeChange(
                                                        e,
                                                        weekDay,
                                                        index,
                                                        "end",
                                                        timeSlot
                                                      )
                                                    }
                                                    required={true}
                                                    clearIcon={null}
                                                    className="form-control"
                                                  />
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center bd-t mt-2 pt-2">
                                                  {index ==
                                                    this.state.slots[weekDay]
                                                      ?.length -
                                                      1 && (
                                                    <p
                                                      className="add-new mr-2"
                                                      onClick={() =>
                                                        this.handleAddDeleteOneOnOneTimeSlot(
                                                          weekDay,
                                                          index,
                                                          "add"
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className="fa fa-plus-square mr-2"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                      Add new
                                                    </p>
                                                  )}
                                                  <p
                                                    className={`add-new ${
                                                      !(
                                                        index ==
                                                        this.state.slots[
                                                          weekDay
                                                        ]?.length -
                                                          1
                                                      )
                                                        ? `theme-delete mb-0`
                                                        : ``
                                                    }`}
                                                    onClick={() =>
                                                      this.handleAddDeleteOneOnOneTimeSlot(
                                                        weekDay,
                                                        index,
                                                        "delete",
                                                        timeSlot
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-trash "
                                                      aria-hidden="true"
                                                    />
                                                    {!(
                                                      index ==
                                                      this.state.slots[weekDay]
                                                        ?.length -
                                                        1
                                                    )
                                                      ? `Delete`
                                                      : ``}
                                                  </p>
                                                </div>
                                              </div>

                                              <p className="form-field-error">
                                                {!!this.state.errorData
                                                  ?.selectedOneOnOneMeetingSlots &&
                                                this.state.errorData
                                                  ?.selectedOneOnOneMeetingSlots[
                                                  weekDay
                                                ] &&
                                                this.state.errorData
                                                  .selectedOneOnOneMeetingSlots[
                                                  weekDay
                                                ][index]?.length
                                                  ? this.state.errorData
                                                      .selectedOneOnOneMeetingSlots[
                                                      weekDay
                                                    ][index][0]
                                                  : null}
                                              </p>
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {this.state.isAccessCategoryInCategories && (
            <div className="col-lg-12 col-md-12 p-0 mt-4 bd-t pt-3">
              <div className="form-group">
                <div className="d-flex align-items-center mb-1">
                  <input
                    type="checkbox"
                    checked={this.state.isGroupMeetingChecked}
                    onChange={this.handleIsGroupMeeting}
                    className="mr-1"
                  />
                  <label className="custom-label-product m-0">
                    Group meeting
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Create Group Session
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
                <span className="form-field-error">
                  {this.state.errorData?.group_meeting &&
                    this.state.errorData.group_meeting[0]}
                  {this.state.errorData.groupOneOnOne &&
                    this.state.errorData.groupOneOnOne[0]}
                </span>
              </div>
            </div>
          )}

          {this.state.isGroupMeetingChecked &&
            this.state.isAccessCategoryInCategories && (
              <div className="container-fluid">
                <div className="row white-bg p-0">
                  <div className="col-lg-12 col-md-12 border-show-seprate">
                    {this.state.groupMeetingDetails.map(
                      (groupMeetingDetails, index) => {
                        return (
                          <>
                            <h5>Select Frequency</h5>
                            <div className="form-group">
                              <label class="theme-color ">
                                <b>Group</b>
                              </label>
                            </div>
                            <div className="black-bg-op p-3">
                              <div className="form-group d-flex m-0">
                                <label className="custom-label-product">
                                  Meeting Name
                                  <div className="tooltip-custom">
                                    <i
                                      className="fa fa-info-circle info-icon"
                                      aria-hidden="true"
                                    ></i>
                                    <div className="together-tooltip">
                                      <p>
                                        Enter the name of the group session in
                                        which will be shown to customers.
                                        <span class="arrow-down"></span>
                                      </p>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={groupMeetingDetails["meetingName"]}
                                  onChange={(e) =>
                                    this.handleGroupMeetingDetailsChange(
                                      e,
                                      "meetingName",
                                      index
                                    )
                                  }
                                />
                              </div>
                              <p className="form-field-error m-0">
                                {this.state.errorData["groupMeetingDetails"][
                                  index
                                ] &&
                                  this.state.errorData["groupMeetingDetails"][
                                    index
                                  ]["meetingName"] &&
                                  this.state.errorData["groupMeetingDetails"][
                                    index
                                  ]["meetingName"][0]}
                              </p>
                            </div>

                            <div className="form-group mb-0 p-3">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group mb-0 d-flex">
                                    <label className="custom-label-product">
                                      Subscription Cost
                                      <div className="tooltip-custom">
                                        <i
                                          className="fa fa-info-circle info-icon"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="together-tooltip">
                                          <p>
                                            Enter the cost you would like to
                                            charge per recurring session
                                            grouping. For Example: A $50
                                            subscription cost for a weekly
                                            Monday/Wednesday/Friday option will
                                            create a $200/month subscription
                                            offering
                                            <span class="arrow-down"></span>
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="number"
                                      min={0}
                                      value={
                                        groupMeetingDetails["subscriptionCost"]
                                      }
                                      onChange={(e) =>
                                        this.handleGroupMeetingDetailsChange(
                                          e,
                                          "subscriptionCost",
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                  <p className="form-field-error m-0">
                                    {this.state.errorData[
                                      "groupMeetingDetails"
                                    ][index] &&
                                      this.state.errorData[
                                        "groupMeetingDetails"
                                      ][index]["subscriptionCost"] &&
                                      this.state.errorData[
                                        "groupMeetingDetails"
                                      ][index]["subscriptionCost"][0]}
                                  </p>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group mb-0 d-flex">
                                    <label className="custom-label-product">
                                      Session Duration
                                      <div className="tooltip-custom">
                                        <i
                                          className="fa fa-info-circle info-icon"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="together-tooltip">
                                          <p>
                                            Enter duration you would like to
                                            offer per session
                                            <span class="arrow-down"></span>
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                    <div className="d-flex">
                                      <label className="d-flex align-items-center mr-2 mb-0">
                                        Hours
                                      </label>
                                      <select
                                        className="form-control mr-2"
                                        onChange={(e) =>
                                          this.handleGroupMeetingDetailsChange(
                                            e,
                                            "sessionHour",
                                            index
                                          )
                                        }
                                        value={
                                          groupMeetingDetails["sessionHour"]
                                        }
                                      >
                                        <option value="Hours" selected disabled>
                                          Hours
                                        </option>
                                        {this.sessionHours.map((hour) => {
                                          return (
                                            <option value={hour}>{hour}</option>
                                          );
                                        })}
                                      </select>
                                      <label className="d-flex align-items-center mr-2 mb-0">
                                        Minutes
                                      </label>
                                      <select
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleGroupMeetingDetailsChange(
                                            e,
                                            "sessionMinute",
                                            index
                                          )
                                        }
                                        value={
                                          groupMeetingDetails["sessionMinute"]
                                        }
                                      >
                                        <option
                                          value="Minutes"
                                          selected
                                          disabled
                                        >
                                          Minutes
                                        </option>
                                        {this.sessionMinutes.map((minute) => {
                                          return (
                                            <option value={minute}>
                                              {minute}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <p className="form-field-error m-0">
                                    {this.state.errorData[
                                      "groupMeetingDetails"
                                    ][index] &&
                                      this.state.errorData[
                                        "groupMeetingDetails"
                                      ][index]["sessionHour"] &&
                                      this.state.errorData[
                                        "groupMeetingDetails"
                                      ][index]["sessionHour"][0]}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group black-bg-op p-3 m-0">
                              <div className="frequency p-0">
                                <label className="custom-label-product">
                                  Select frequency
                                  <div className="tooltip-custom">
                                    <i
                                      className="fa fa-info-circle info-icon"
                                      aria-hidden="true"
                                    ></i>
                                    <div className="together-tooltip">
                                      <p>
                                        Select the type of one on one sessions
                                        you would like to offer
                                        <span class="arrow-down"></span>
                                      </p>
                                    </div>
                                  </div>
                                </label>

                                {this.selectGroupFrequency.map((frequency) => {
                                  return (
                                    <div class="check-outer">
                                      <label className="outer-label mb-0">
                                        <input
                                          type="radio"
                                          value={frequency}
                                          id={frequency}
                                          checked={
                                            groupMeetingDetails[
                                              "groupMeetingFrequency"
                                            ] == frequency
                                          }
                                          onChange={(e) =>
                                            this.handleGroupMeetingFrequencyChange(
                                              e,
                                              frequency,
                                              index,
                                              groupMeetingDetails
                                            )
                                          }
                                        />{" "}
                                        {frequency}
                                        <span class="checkmark"></span>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                              <p className="form-field-error m-0">
                                {this.state.errorData["groupMeetingDetails"][
                                  index
                                ] &&
                                  this.state.errorData["groupMeetingDetails"][
                                    index
                                  ]["groupMeetingFrequency"] &&
                                  this.state.errorData["groupMeetingDetails"][
                                    index
                                  ]["groupMeetingFrequency"][0]}
                              </p>
                            </div>

                            <div className="form-group mb-0">
                              {groupMeetingDetails[
                                "isMonthlyGroupFrequencyChecked"
                              ] && (
                                <>
                                  <div className="row white-bg">
                                    <div className="col-md-6">
                                      <div className="form-group mb-0 d-flex">
                                        <label className="custom-label-product">
                                          Week
                                          <div className="tooltip-custom">
                                            <i
                                              className="fa fa-info-circle info-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="together-tooltip">
                                              <p>
                                                Select the week number
                                                <span class="arrow-down"></span>
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                        <div className="w-100">
                                          <select
                                            className="form-control"
                                            value={
                                              groupMeetingDetails[
                                                "meetingMonthlyWeekOfMonth"
                                              ]
                                            }
                                            onChange={(e) =>
                                              this.handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                                e,
                                                "meetingMonthlyWeekOfMonth",
                                                index,
                                                groupMeetingDetails
                                              )
                                            }
                                          >
                                            <option value="" selected disabled>
                                              --Select--
                                            </option>
                                            {this.weekOfMonth.map((week) => {
                                              return (
                                                <option value={week}>
                                                  {week}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                      <p className="form-field-error m-0">
                                        {this.state.errorData[
                                          "groupMeetingDetails"
                                        ][index]["meetingMonthlyWeekOfMonth"] &&
                                          this.state.errorData[
                                            "groupMeetingDetails"
                                          ][index][
                                            "meetingMonthlyWeekOfMonth"
                                          ][0]}
                                      </p>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group d-flex m-0">
                                        <label className="custom-label-product">
                                          Week Day
                                          <div className="tooltip-custom">
                                            <i
                                              className="fa fa-info-circle info-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="together-tooltip">
                                              <p>
                                                Select the day of the week
                                                <span class="arrow-down"></span>
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                        <div className="w-100">
                                          <select
                                            className="form-control"
                                            value={
                                              groupMeetingDetails[
                                                "meetingMonthlyWeekDay"
                                              ]
                                            }
                                            onChange={(e) =>
                                              this.handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                                e,
                                                "meetingMonthlyWeekDay",
                                                index,
                                                groupMeetingDetails
                                              )
                                            }
                                          >
                                            <option value="" selected disabled>
                                              --Select--
                                            </option>
                                            {Object.entries(this.weekDays).map(
                                              ([key, weekDayVal]) => {
                                                return (
                                                  <option value={weekDayVal}>
                                                    {weekDayVal}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <p className="form-field-error m-0">
                                        {this.state.errorData[
                                          "groupMeetingDetails"
                                        ][index]["meetingMonthlyWeekDay"] &&
                                          this.state.errorData[
                                            "groupMeetingDetails"
                                          ][index]["meetingMonthlyWeekDay"][0]}
                                      </p>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                      <div className="form-group d-flex m-0">
                                        <label className="custom-label-product">
                                          Time
                                          <div className="tooltip-custom">
                                            <i
                                              className="fa fa-info-circle info-icon"
                                              aria-hidden="true"
                                            ></i>
                                            <div className="together-tooltip">
                                              <p>
                                                Enter The Time You Would Like To
                                                Offer
                                                <span class="arrow-down"></span>
                                              </p>
                                            </div>
                                          </div>
                                        </label>
                                        <div className="d-flex align-items-center">
                                          <div className="mr-2">
                                            <TimePicker
                                              value={
                                                groupMeetingDetails[
                                                  "meetingMonthlyStartTime"
                                                ]
                                              }
                                              format="hh:mm a"
                                              name="start"
                                              disableClock={true}
                                              onChange={(e) =>
                                                this.handleGroupMeetingDetailsChange(
                                                  e,
                                                  "meetingMonthlyStartTime",
                                                  index
                                                )
                                              }
                                              required={true}
                                              clearIcon={null}
                                              className="form-control"
                                            />
                                          </div>
                                          <p className="mb-0 mx-2">To</p>
                                          <div className="ml-2">
                                            <TimePicker
                                              value={
                                                groupMeetingDetails[
                                                  "meetingMonthlyEndTime"
                                                ]
                                              }
                                              format="hh:mm a"
                                              name="start"
                                              disableClock={true}
                                              onChange={(e) =>
                                                this.handleGroupMeetingDetailsChange(
                                                  e,
                                                  "meetingMonthlyEndTime",
                                                  index
                                                )
                                              }
                                              required={true}
                                              clearIcon={null}
                                              className="form-control"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <p className="form-field-error m-0">
                                        {this.state.errorData[
                                          "groupMeetingDetails"
                                        ][index]["meetingMonthlyStartTime"] &&
                                          this.state.errorData[
                                            "groupMeetingDetails"
                                          ][index][
                                            "meetingMonthlyStartTime"
                                          ][0]}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}

                              {!!!groupMeetingDetails[
                                "isMonthlyGroupFrequencyChecked"
                              ] && (
                                <>
                                  <div className="form-group mb-0 p-3">
                                    <div className="frequency p-0">
                                      <label className="custom-label-product">
                                        Available days
                                        <div className="tooltip-custom">
                                          <i
                                            className="fa fa-info-circle info-icon"
                                            aria-hidden="true"
                                          ></i>
                                          <div className="together-tooltip">
                                            <p>
                                              Select Day(s) you would like to
                                              offer for this specific group
                                              session. Example: Select
                                              Monday/Wednesday/Friday to offer
                                              weekly classes on
                                              Monday/Wednesday/Friday
                                              <span class="arrow-down"></span>
                                            </p>
                                          </div>
                                        </div>
                                      </label>
                                      {Object.entries(this.weekDays).map(
                                        ([weekDay, weekDayVal]) => {
                                          return (
                                            <div className="check-outer">
                                              <label className="outer-label mb-0">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    groupMeetingDetails[
                                                      "isWeekDaySelected"
                                                    ][weekDay]
                                                  }
                                                  onChange={(e) =>
                                                    this.handleGroupWeeklyBiweeklySlotChange(
                                                      e,
                                                      weekDay,
                                                      index,
                                                      groupMeetingDetails
                                                    )
                                                  }
                                                />{" "}
                                                {weekDay}
                                                <span class="checkmark"></span>
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <p className="form-field-error m-0">
                                      {this.state.errorData[
                                        "groupMeetingDetails"
                                      ][index] &&
                                        this.state.errorData[
                                          "groupMeetingDetails"
                                        ][index]["selectedWeekDay"] &&
                                        this.state.errorData[
                                          "groupMeetingDetails"
                                        ][index]["selectedWeekDay"][0]}
                                    </p>
                                  </div>
                                  <div>
                                    <label className="custom-label-product pb-2">
                                      available time slots 
                                      <span className="form-field-error ml-2">{
                                      this.state.errorData["groupMeetingDetails"][index]["mainError"]
                                      && this.state.errorData["groupMeetingDetails"][index]["mainError"][0]
                                      }</span>
                                      <div className="tooltip-custom">
                                        <i
                                          className="fa fa-info-circle info-icon"
                                          aria-hidden="true"
                                        ></i>
                                        <div className="together-tooltip">
                                          <p>
                                            Enter time slot per day from chosen
                                            days above
                                            <span className="arrow-down"></span>
                                          </p>
                                        </div>
                                      </div>
                                    </label>
                                    <div className="recurring">
                                      {Object.entries(this.weekDays).map(
                                        ([weekDay, weekDayVal]) => {
                                          return (
                                            <>
                                              <div
                                                className={`week-card ${
                                                  groupMeetingDetails[
                                                    "isWeekDaySelected"
                                                  ][weekDay]
                                                    ? ``
                                                    : `disabled`
                                                }`}
                                              >
                                                <div
                                                  className={`day active ${
                                                    groupMeetingDetails[
                                                      "isWeekDaySelected"
                                                    ][weekDay]
                                                      ? ``
                                                      : `disabled`
                                                  }`}
                                                >
                                                  {weekDayVal}
                                                </div>
                                                <div>
                                                  {groupMeetingDetails["slots"][
                                                    weekDay
                                                  ]?.length > 0 &&
                                                    groupMeetingDetails[
                                                      "slots"
                                                    ][weekDay]?.map(
                                                      (timeSlot, position) => {
                                                        return (
                                                          <>
                                                            <div className="week-bottom">
                                                              <div className="d-flex align-items-center">
                                                                <TimePicker
                                                                  value={
                                                                    timeSlot[0]
                                                                  }
                                                                  format="hh:mm a"
                                                                  name="start"
                                                                  disableClock={
                                                                    true
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.handleGroupMeetingTimeChange(
                                                                      e,
                                                                      weekDay,
                                                                      position,
                                                                      "start",
                                                                      index,
                                                                      timeSlot,
                                                                      groupMeetingDetails
                                                                    )
                                                                  }
                                                                  required={
                                                                    true
                                                                  }
                                                                  clearIcon={
                                                                    null
                                                                  }
                                                                  className="form-control"
                                                                />
                                                                <p className="mb-0 mx-2">
                                                                  To
                                                                </p>
                                                                <TimePicker
                                                                  value={
                                                                    timeSlot[1]
                                                                  }
                                                                  format="hh:mm a"
                                                                  name="start"
                                                                  disableClock={
                                                                    true
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.handleGroupMeetingTimeChange(
                                                                      e,
                                                                      weekDay,
                                                                      position,
                                                                      "end",
                                                                      index,
                                                                      timeSlot,
                                                                      groupMeetingDetails
                                                                    )
                                                                  }
                                                                  required={
                                                                    true
                                                                  }
                                                                  clearIcon={
                                                                    null
                                                                  }
                                                                  className="form-control"
                                                                />
                                                              </div>
                                                              <div className="d-flex align-items-center justify-content-center bd-t mt-2 pt-2">
                                                                {position ==
                                                                  this.state
                                                                    .groupMeetingDetails[
                                                                    index
                                                                  ]["slots"][
                                                                    weekDay
                                                                  ]?.length -
                                                                    1 && (
                                                                  <p
                                                                    className="add-new mr-2 mb-0"
                                                                    onClick={() =>
                                                                      this.handleAddDeleteGroupTimeSlot(
                                                                        weekDay,
                                                                        position,
                                                                        "add",
                                                                        index
                                                                      )
                                                                    }
                                                                  >
                                                                    <i
                                                                      className="fa fa-plus-square mr-2"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                    Add new
                                                                  </p>
                                                                )}
                                                                <p
                                                                  className={`add-new mb-0 ${
                                                                    !(position ==this.state.groupMeetingDetails[index]["slots"][weekDay]?.length -1
                                                                    )
                                                                      ? `theme-delete mb-0`
                                                                      : ``
                                                                  }`}
                                                                  onClick={() =>
                                                                    this.handleAddDeleteGroupTimeSlot(
                                                                      weekDay,
                                                                      position,
                                                                      "delete",
                                                                      index,
                                                                      groupMeetingDetails,
                                                                      timeSlot
                                                                    )
                                                                  }
                                                                >
                                                                  <i
                                                                    className="fa fa-trash "
                                                                    aria-hidden="true"
                                                                  />
                                                                  {!(
                                                                    position ==
                                                                    this.state.groupMeetingDetails[index]["slots"][weekDay]?.length - 1
                                                                  )
                                                                    ? `Delete`
                                                                    : ``}
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <p className="form-field-error">
                                                              {this.state.errorData["groupMeetingDetails"][index] &&
                                                              this.state.errorData["groupMeetingDetails"][index]["slots"] &&
                                                              this.state.errorData["groupMeetingDetails"][index]["slots"][weekDay] &&
                                                              this.state.errorData["groupMeetingDetails"][index]["slots"][weekDay][position]?.length
                                                                ? this.state.errorData["groupMeetingDetails"][index]["slots"][weekDay][position][0]
                                                                : null}
                                                            </p>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              {index ==
                                this.state.groupMeetingDetails.length - 1 && (
                                <button
                                  className="orange-btn my-4 mr-4"
                                  onClick={() => {
                                    this.handleAddNewGroupMeeting();
                                  }}
                                >
                                  Add new group meeting
                                </button>
                              )}
                              {this.state.groupMeetingDetails.length > 1 && (
                                <button
                                  className="orange-outline-btn my-4"
                                  onClick={() => {
                                    this.handleDeleteNewGroupMeeting(
                                      index,
                                      groupMeetingDetails
                                    );
                                  }}
                                >
                                  Delete group meeting
                                </button>
                              )}
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}

          {!this.state.isAccessCategoryInCategories &&
          !this.state.isDigitalChecked ? (
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="custom-label-product">
                    product package height (in inches)
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Please Select Product Package Height
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>
                  <select
                    name="productHeight"
                    className="form-control"
                    onChange={(e) => this.handleChange(e, "productHeight")}
                  >
                    <option value="" selected disabled>
                      Select Product Package Height
                    </option>
                    {Array.from({ length: 100 }).map((item, index) => {
                      return (
                        <option
                          key={index}
                          selected={index + 1 == this.state.productHeight}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      );
                    })}
                  </select>
                  <span className="form-field-error">
                    {this.state.errorData?.productHeight &&
                      this.state.errorData.productHeight[0]}
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="custom-label-product">
                    product package width (in inches)
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Please Select Product Package Width
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>
                  <select
                    name="productWidth"
                    className="form-control"
                    onChange={(e) => this.handleChange(e, "productWidth")}
                  >
                    <option value="" selected disabled>
                      Select Product package Width
                    </option>
                    {Array.from({ length: 100 }).map((item, index) => {
                      return (
                        <option
                          key={index}
                          selected={index + 1 == this.state.productWidth}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      );
                    })}
                  </select>
                  <span className="form-field-error">
                    {this.state.errorData?.productWidth &&
                      this.state.errorData.productWidth[0]}
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="custom-label-product">
                    product package length (in inches)
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Please Select Product Package Length
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>

                  <select
                    name="productLength"
                    className="form-control"
                    onChange={(e) => this.handleChange(e, "productLength")}
                  >
                    <option value="" selected disabled>
                      Select Product Package Length
                    </option>
                    {Array.from({ length: 100 }).map((item, index) => {
                      return (
                        <option
                          key={index}
                          selected={index + 1 == this.state.productLength}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      );
                    })}
                  </select>

                  <span className="form-field-error">
                    {this.state.errorData?.productLength &&
                      this.state.errorData.productLength[0]}
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="custom-label-product">
                    product package weight (in pounds)
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Please Select Product Package Weight
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>

                  <select
                    name="productWeight"
                    className="form-control"
                    onChange={(e) => this.handleChange(e, "productWeight")}
                  >
                    <option value="" selected disabled>
                      Select Product Package weight
                    </option>
                    {Array.from({ length: 70 }).map((item, index) => {
                      return (
                        <option
                          key={index}
                          selected={index + 1 == this.state.productWeight}
                          value={index + 1}
                        >
                          {index + 1}
                        </option>
                      );
                    })}
                  </select>

                  <span className="form-field-error">
                    {this.state.errorData?.productWeight &&
                      this.state.errorData.productWeight[0]}
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label className="custom-label-product">
                    Select Couriers
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Please Select The Couriers Service
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                    <sup>*</sup>
                  </label>
                  <Select
                    value={this.state.defaultSelectedCourierOptions}
                    isMulti={false}
                    name="value"
                    options={this.state.courierList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.handleCourierChange(e);
                    }}
                  />
                  <span className="form-field-error">
                    {this.state.errorData?.selectedCourierList &&
                      this.state.errorData.selectedCourierList[0]}
                  </span>
                  <label className="custom-check mt-2">
                    Free shipping
                    <input
                      type="checkbox"
                      checked={this.state.is_shipping_free}
                      onChange={(e) =>
                        this.handleChangeChk(e, "is_shipping_free")
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <>
            </>
          )}

          {this.state.isAccessCategoryInCategories && (
            <One_Time_Event
              handleCreateFreeSeminar={this.handleCreateFreeSeminar}
              isFreeSeminar={this.state.isFreeSeminar}
              handleClickOnFreeSeminar={this.handleClickOnFreeSeminar}
              isFreeSeminarCreated={this.state.isFreeSeminarCreated}
              errorData={this.state.errorData}
              formData={this.state.formData}
              showHideFreeSeminarModal={this.state.showHideFreeSeminarModal}
              handleFreeSeminarModalShowHide={
                this.handleFreeSeminarModalShowHide
              }
              reqMessage={this.state.reqMessage}
              successMessage={this.state.successMessage}
              popupErrorMessage={this.state.popupErrorMessage}
              eventNameError={this.state.eventNameError}
              handleDateTimeChange={this.handleDateTimeChange}
              handleSaveChanges={this.handleSaveChanges}
              showDenyLoader={this.state.showDenyLoader}
              setEventDescription={this.setEventDescription}
              setEventName={this.setEventName}
              setEventPrice={this.setEventPrice}
              isFreePriceChecked={this.state.isFreePriceChecked}
              hidePrice={this.hidePrice}
              eventPriceError={this.state.eventPriceError}
            />
          )}

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label className="custom-label-product">
                  product Description
                  <div className="tooltip-custom">
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="together-tooltip">
                      <p>
                        Enter Product Description
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </label>
                <textarea
                  rows="5"
                  cols="10"
                  onChange={(e) => this.handleChange(e, "productDescription")}
                  value={this.state.productDescription}
                  className="form-control form-textarea"
                  placeholder="enter your text here..."
                ></textarea>
                <span className="form-field-error">
                  {this.state.errorData?.productDescription &&
                    this.state.errorData.productDescription[0]}
                </span>
              </div>
            </div>
          </div>

          {this.state.isAccessCategoryInCategories ? (
            <>
              <div className="row border-add-select">
                <div className="select-head-inline">
                  <h5 className="custom-label-product">
                    Access Subscription Content
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Content only shown to active subscribers
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </h5>
                  <div>
                    {this.state.setCategoryAddedMessage && (
                      <div className="alert-fixed alert-success">
                        <p> {this.state.setCategoryAddedMessage}</p>
                      </div>
                    )}
                    <button
                      onClick={() => this.addNewAcsSubcategory()}
                      className="orange-btn"
                    >
                      <span>
                        <i className="fas fa-plus-circle"></i>
                      </span>
                      Add more content
                    </button>
                  </div>
                </div>
                {this.state.acsCatSubcategories.map((subCategory, catIndex) => (
                  <div className="border-add-new">
                    <div className="col-lg-12 col-md-12" key={catIndex}>
                      <div className="form-group">
                        <div className="d-flex justify-content-between mb-2">
                          <label className="custom-label-product">
                            Category Title
                            <div className="tooltip-custom">
                              <i
                                className="fa fa-info-circle info-icon"
                                aria-hidden="true"
                              ></i>
                              <div className="together-tooltip">
                                <p>
                                  organise content into different categories
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </label>
                          {
                            <button
                              className="orange-outline-btn"
                              onClick={() =>
                                this.removeAcsSubcategory(catIndex)
                              }
                            >
                              remove category
                            </button>
                          }
                        </div>

                        <div className="form-group">
                          <input
                            value={subCategory.acsCatTitle}
                            className="form-control"
                            onChange={(e) =>
                              this.handleChange(e, "acsCatTitle", catIndex)
                            }
                          />
                        </div>
                        <span className="form-field-error">
                          {this.state.errorData?.acsCatTitle &&
                            this.state.errorData.acsCatTitle[catIndex]}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>Video Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatVideos",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatVideos",
                                    catIndex
                                  )
                                }
                              >
                                Remove all videos
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatVideoGallery &&
                          subCategory.acsCatVideoGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatVideoGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    {item.stream && this.state.showVideos ? (
                                      <video width="200" height="150" controls>
                                        <source
                                          src={item.stream}
                                          type={item.contentType}
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    ) : (
                                      <div className="file-box-select">
                                        <p>
                                          <b>
                                            {this.getFileNameFromUrl(item.url)}
                                          </b>
                                        </p>
                                        <span>
                                          {this.getFileSizeByUrl(item.url)}
                                        </span>
                                        <p className="file-icon-tuff">
                                          <i className="far fa-file-alt"></i>
                                        </p>
                                      </div>
                                    )}
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.stream ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) => {
                                            if (
                                              !this.state.downloadProcessMap[
                                                item.url
                                              ]?.downloadPercentage
                                            ) {
                                              this.downloadPrivateFileByUrl(
                                                e,
                                                "acsCatVideos",
                                                item.url,
                                                catIndex
                                              );
                                            }
                                          }}
                                        >
                                          View
                                        </button>
                                      ) : null}
                                      <button
                                        className="orange-outline-btn"
                                        onClick={(e) =>
                                          this.deleteSelectedGalleryFile(
                                            e,
                                            "acsCatVideos",
                                            item.url,
                                            index,
                                            catIndex
                                          )
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatVideos &&
                          subCategory.acsCatVideos.length > 0 ? (
                            <>
                              {subCategory.acsCatVideos.map((item, index) => (
                                <div
                                  key={index}
                                  style={{ width: "25%", display: "flex" }}
                                  className="image-item"
                                >
                                  {this.state.showVideos ? (
                                    <video
                                      width="200"
                                      height="150"
                                      controls
                                      poster={this.state.previewProductImage}
                                    >
                                      <source
                                        src={item.stream}
                                        type={item.type}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : null}
                                  <div>
                                    <input
                                      className="mb-3"
                                      placeholder="Enter File Name"
                                      value={
                                        subCategory.acsCatVideosTitles &&
                                        subCategory?.acsCatVideosTitles[index]
                                      }
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "acsCatVideosTitles",
                                          catIndex,
                                          index
                                        )
                                      }
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatVideos",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData?.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData?.acsCatSubcategories[
                                catIndex
                              ]?.acsCatVideos[0]}
                          </p>
                          {this.props.errorAcsCatVideos &&
                          this.props.errorAcsCatVideos.length > 0 ? (
                            <>
                              {this.props.errorAcsCatVideos.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group space-add-elite">
                        <label>Audio Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatAudios",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatAudios",
                                    catIndex
                                  )
                                }
                              >
                                Remove all audios
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatAudioGallery &&
                          subCategory.acsCatAudioGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatAudioGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    {item.stream && this.state.showVideos ? (
                                      <audio controls>
                                        <source
                                          src={item.stream}
                                          type={item.contentType}
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    ) : (
                                      <div className="file-box-select">
                                        <p>
                                          <b>
                                            {this.getFileNameFromUrl(item.url)}
                                          </b>
                                        </p>
                                        <span>
                                          {this.getFileSizeByUrl(item.url)}
                                        </span>
                                        <p className="file-icon-tuff">
                                          <i className="far fa-file-alt"></i>
                                        </p>
                                      </div>
                                    )}
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.stream ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) => {
                                            if (
                                              !this.state.downloadProcessMap[
                                                item.url
                                              ]?.downloadPercentage
                                            ) {
                                              this.downloadPrivateFileByUrl(
                                                e,
                                                "acsCatAudios",
                                                item.url,
                                                catIndex
                                              );
                                            }
                                          }}
                                        >
                                          View
                                        </button>
                                      ) : null}
                                      <button
                                        className="orange-outline-btn"
                                        onClick={(e) =>
                                          this.deleteSelectedGalleryFile(
                                            e,
                                            "acsCatAudios",
                                            item.url,
                                            index,
                                            catIndex
                                          )
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatAudios &&
                          subCategory.acsCatAudios.length > 0 ? (
                            <>
                              {subCategory.acsCatAudios.map((item, index) => (
                                <div
                                  key={index}
                                  style={{ width: "25%", display: "flex" }}
                                  className="image-item"
                                >
                                  {this.state.showVideos ? (
                                    <audio controls>
                                      <source
                                        src={item.stream}
                                        type={item.type}
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  ) : null}
                                  <div>
                                    <input
                                      className="mb-3"
                                      placeholder="Enter File Name"
                                      value={
                                        subCategory.acsCatAudiosTitles &&
                                        subCategory.acsCatAudiosTitles[index]
                                      }
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "acsCatAudiosTitles",
                                          catIndex,
                                          index
                                        )
                                      }
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatAudios",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData.acsCatSubcategories[catIndex]
                                ?.acsCatAudios[0]}
                          </p>
                          {this.props.errorAcsCatAudios &&
                          this.props.errorAcsCatAudios.length > 0 ? (
                            <>
                              {this.props.errorAcsCatAudios.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group space-add-elite">
                        <label>Image Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatImages",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatImages",
                                    catIndex
                                  )
                                }
                              >
                                Remove all images
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatImageGallery &&
                          subCategory.acsCatImageGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatImageGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    {item.stream ? (
                                      <img
                                        src={item.stream}
                                        alt=""
                                        width="100"
                                      />
                                    ) : (
                                      <div className="file-box-select">
                                        <p>
                                          <b>
                                            {this.getFileNameFromUrl(item.url)}
                                          </b>
                                        </p>
                                        <span>
                                          {this.getFileSizeByUrl(item.url)}
                                        </span>
                                        <p className="file-icon-tuff">
                                          <i className="far fa-file-alt"></i>
                                        </p>
                                      </div>
                                    )}

                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.stream ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) => {
                                            if (
                                              !this.state.downloadProcessMap[
                                                item.url
                                              ]?.downloadPercentage
                                            ) {
                                              this.downloadPrivateFileByUrl(
                                                e,
                                                "acsCatImages",
                                                item.url,
                                                catIndex
                                              );
                                            }
                                          }}
                                        >
                                          View
                                        </button>
                                      ) : null}

                                      <button
                                        className="orange-outline-btn"
                                        onClick={(e) =>
                                          this.deleteSelectedGalleryFile(
                                            e,
                                            "acsCatImages",
                                            item.url,
                                            index,
                                            catIndex
                                          )
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatImages &&
                          subCategory.acsCatImages.length > 0 ? (
                            <>
                              {subCategory.acsCatImages.map((item, index) => (
                                <div
                                  key={index}
                                  style={{ width: "25%", display: "flex" }}
                                  className="image-item"
                                >
                                  <img src={item.stream} alt="" width="100" />
                                  <div>
                                    <input
                                      className="mb-3"
                                      placeholder="Enter File Name"
                                      value={
                                        subCategory.acsCatImagesTitles &&
                                        subCategory.acsCatImagesTitles[index]
                                      }
                                      onChange={(e) =>
                                        this.handleChange(
                                          e,
                                          "acsCatImagesTitles",
                                          catIndex,
                                          index
                                        )
                                      }
                                    />
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatImages",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData.acsCatSubcategories[catIndex]
                                ?.acsCatImages[0]}
                          </p>
                          {this.props.errorAcsCatImages &&
                          this.props.errorAcsCatImages.length > 0 ? (
                            <>
                              {this.props.errorAcsCatImages.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group space-add-elite">
                        <label>File Gallery</label>
                        <div className="App">
                          <div className="upload__image-wrapper">
                            <div className="text-left mb-4">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.triggerFileUploadClick(
                                    e,
                                    "acsCatDocuments",
                                    catIndex
                                  )
                                }
                              >
                                Click here to upload
                              </button>
                              &nbsp;
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteAllGalleryFiles(
                                    e,
                                    "acsCatDocuments",
                                    catIndex
                                  )
                                }
                              >
                                Remove all files
                              </button>
                            </div>
                          </div>
                          {subCategory.acsCatDocGallery &&
                          subCategory.acsCatDocGallery.length > 0 ? (
                            <>
                              {subCategory.acsCatDocGallery.map(
                                (item, index) => (
                                  <div key={index} className="image-item">
                                    <div className="file-box-select">
                                      <p>
                                        <b>
                                          {this.getFileNameFromUrl(item.url)}
                                        </b>
                                      </p>
                                      <span>
                                        {this.getFileSizeByUrl(item.url)}
                                      </span>
                                      <p className="file-icon-tuff">
                                        <i className="far fa-file-alt"></i>
                                      </p>
                                    </div>
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.stream ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) => {
                                            if (
                                              !this.state.downloadProcessMap[
                                                item.url
                                              ]?.downloadPercentage
                                            ) {
                                              this.downloadPrivateFileByUrl(
                                                e,
                                                "acsCatDocuments",
                                                item.url,
                                                catIndex
                                              );
                                            }
                                          }}
                                        >
                                          Download
                                        </button>
                                      ) : null}
                                      <button
                                        className="orange-outline-btn"
                                        onClick={(e) =>
                                          this.deleteSelectedGalleryFile(
                                            e,
                                            "acsCatDocuments",
                                            item.url,
                                            index,
                                            catIndex
                                          )
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          {subCategory.acsCatDocuments &&
                          subCategory.acsCatDocuments.length > 0 ? (
                            <>
                              {subCategory.acsCatDocuments.map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    style={{ width: "25%", display: "flex" }}
                                    className="image-item"
                                  >
                                    <div className="file-box-select">
                                      <input
                                        className="mb-3"
                                        placeholder="Enter File Name"
                                        value={
                                          subCategory.acsCatDocumentsTitles &&
                                          subCategory.acsCatDocumentsTitles[
                                            index
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "acsCatDocumentsTitles",
                                            catIndex,
                                            index
                                          )
                                        }
                                      />
                                      <span>
                                        {this.getFileSizeByByteLength(
                                          item.file.size
                                        )}
                                      </span>
                                      <p className="file-icon-tuff">
                                        <i className="far fa-file-alt"></i>
                                      </p>
                                    </div>
                                    <div className="image-item__btn-wrapper">
                                      {item.status || ""}
                                      {!item.status ? (
                                        <button
                                          className="orange-outline-btn"
                                          onClick={(e) =>
                                            this.deleteSelectedFile(
                                              e,
                                              "acsCatDocuments",
                                              index,
                                              catIndex
                                            )
                                          }
                                        >
                                          Remove
                                        </button>
                                      ) : null}
                                      {item.uploadPercentage &&
                                      item.uploadPercentage < 100 ? (
                                        <progress
                                          id="file"
                                          value={item.uploadPercentage}
                                          max="100"
                                        >
                                          {" "}
                                          {item.uploadPercentage}%{" "}
                                        </progress>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : null}
                          <p className="files-error">
                            {this.state.errorData.acsCatSubcategories &&
                              this.state.errorData?.acsCatSubcategories[
                                catIndex
                              ] &&
                              this.state.errorData.acsCatSubcategories[catIndex]
                                ?.acsCatDocuments[0]}
                          </p>
                          {this.props.errorAcsCatDocuments &&
                          this.props.errorAcsCatDocuments.length > 0 ? (
                            <>
                              {this.props.errorAcsCatDocuments.map(
                                (item, index) => (
                                  <p className="files-error" key={index}>
                                    {item}
                                  </p>
                                )
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}

          {!this.state.isAccessCategoryInCategories && (  
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label className="custom-label-product">
                    Do you want to set attributes for this product?
                    <div className="tooltip-custom">
                      <i
                        className="fa fa-info-circle info-icon"
                        aria-hidden="true"
                      ></i>
                      <div className="together-tooltip">
                        <p>
                          Offer different variations.Click attributes list on
                          the left column to create attributes
                          <span class="arrow-down"></span>
                        </p>
                      </div>
                    </div>
                  </label>
                  <div className="custom-radio-input">
                    <p className="container-sub mr-2">
                      No
                      <span>
                        <input
                          type="radio"
                          value="0"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={this.state.withAttribute === 0}
                          name="withAttributeRadio"
                          className={
                            this.state
                              .isDisabledAttributesForWoocommerceShopify &&
                            "disable-btn"
                          }
                          disabled={
                            this.state.isDisabledAttributesForWoocommerceShopify
                          }
                        />
                      </span>
                      <span className="checkmark"></span>
                    </p>

                    <p className="container-sub mr-2">
                      Yes
                      <span>
                        <input
                          type="radio"
                          value="1"
                          onChange={(event) =>
                            this.handleWithAttributeChange(
                              event,
                              "withAttribute"
                            )
                          }
                          checked={this.state.withAttribute === 1}
                          name="withAttributeRadio"
                          className={
                            this.state
                              .isDisabledAttributesForWoocommerceShopify &&
                            "disable-btn"
                          }
                          disabled={
                            this.state.isDisabledAttributesForWoocommerceShopify
                          }
                        />
                      </span>
                      <span className="checkmark"></span>
                    </p>
                  </div>
                 {!this.state.isDisabledAttributesForWoocommerceShopify && this.state.withAttribute ? <span className="note-ecom">
                    <i className="fas fa-exclamation-circle"></i>
                    <strong>
                      Note<sup>*</sup>
                    </strong>
                   If you will remove/change the product attribute, then you need to create combination again and all previous data will be remove.
                </span> : null}

                  {this.state.isDisabledAttributesForWoocommerceShopify ? (
                    <span className="note-ecom">
                      <i className="fas fa-exclamation-circle"></i>
                      <strong>
                        Note<sup>*</sup>
                      </strong>
                      You cannot update attributes for Shopify/Woocommerce
                      products from Subsciety!
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          {!this.state.isAccessCategoryInCategories && this.state.withAttribute && this.state.withAttribute === 1 ? (
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <label>
                    Product Attributes
                    <sup>*</sup>
                  </label>
                  <Select
                    value={this.state.attributesDefaultSelectOptions}
                    isMulti
                    name="value"
                    options={this.state.attributesSelectOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      this.handleProductAttributeChange(e, "productAttribute");
                    }}
                    isDisabled={
                      this.state.isDisabledAttributesForWoocommerceShopify
                    }
                  />
                  <span className="form-field-error">
                    {this.state.errorData.productAttribute &&
                      this.state.errorData.productAttribute[0]}
                  </span>
                </div>
                {this.state.attributesDefaultSelectOptions.length > 0 && (
                  <div className="form-group">
                    <button
                      onClick={this.getSelectedAttributeVariants}
                      className={`orange-outline-btn ${
                        this.state.isDisabledAttributesForWoocommerceShopify &&
                        "disable-btn"
                      }`}
                      disabled={
                        this.state.isDisabledAttributesForWoocommerceShopify
                      }
                    >
                      Get variants
                    </button><br />
                    <span className="form-field-error">
                      {this.state.errorData.selectedAttributeVariants &&
                        this.state.errorData.selectedAttributeVariants[0]}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {!this.state.isAccessCategoryInCategories && !this.state.isDisabledAttributesForWoocommerceShopify && this.state.variantCombination.length && this.state.withAttribute ?
            <span className="note-ecom note-ecom-margin">
              <i className="fas fa-exclamation-circle"></i>
              <strong>
                Note<sup>*</sup>
              </strong>
              You can create only one primary at a time.
            </span> : null}

          {!this.state.isAccessCategoryInCategories && this.state.withAttribute &&
          this.state.selectedAttributeVariants?.length > 0
            ? this.state.selectedAttributeVariants.map((variant, index) => {
              return (
                <div key={index} className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <div className="d-flex align-item-center justify-content-between">
                        <label>
                          {variant.attributeName
                            ? variant.attributeName.replace("_", " ")
                            : ""}
                          <sup>*</sup>
                        </label>
                      </div>
                      <Select
                        value={variant.attributeDefaultSelectedOptions}
                        isMulti
                        name="value"
                        options={variant.attributeSelectOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.handleVariantChange(e, index);
                        }}
                        isDisabled={
                          this.state.isDisabledAttributesForWoocommerceShopify
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="add-product-button-align">
                      <button
                        className={`orange-outline-btn ${this.state
                          .isDisabledAttributesForWoocommerceShopify &&
                          "disable-btn"
                          }`}
                        onClick={() =>
                          this.handleVariantChange(
                            variant.attributeSelectOptions,
                            index
                          )
                        }
                        disabled={
                          this.state
                            .isDisabledAttributesForWoocommerceShopify
                        }
                      >
                        Select all variants
                      </button>
                      
                      <div className="switch-main">
                        <p>Make Primary</p>
                        <label class="switch">
                          <input type="checkbox"
                            disabled={
                              index !== this.state.primaryIndex && this.state.isPrimary
                            }
                            onChange={(e) => this.makePrimary(e, index)}
                            checked={ index === this.state.primaryIndex }
                            />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            : null}

          {!this.state.isAccessCategoryInCategories && this.state.withAttribute &&
          this.state.selectedAttributeVariants?.length > 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <button
                    onClick={this.createVariantCombination}
                    className={`orange-outline-btn ${
                      this.state.isDisabledAttributesForWoocommerceShopify &&
                      "disable-btn"
                    }`}
                    disabled={
                      this.state.isDisabledAttributesForWoocommerceShopify
                    }
                  >
                    Create variant combination *
                  </button>
                  <br />
                  <span className="form-field-error">
                    {this.state.errorData?.variantCombination &&
                      this.state.errorData.variantCombination[0]}
                  </span>
                  <span className="form-field-error">
                    {this.state.errorData?.getVarientCombination &&
                      this.state.errorData.getVarientCombination[0]}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          {!this.state.isAccessCategoryInCategories && this.state.primaryIndex != null  && this.state.variantCombination && this.state.selectedAttributeVariants[this.state.primaryIndex]?.attributeDefaultSelectedOptions.map((item, index) => {
            return (
              <div className="make-global-attribute">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="make-global-sub">
                        <h5>{item.label}</h5>
                        <h5>COMMON IMAGE  </h5>
                        <div className="product-global-img">
                          {
                            this.state.specificAttributeGlobalImage.map((items, index) => {
                              if (Object.keys(items)[0] === item.label) {
                                return (
                                  <img key={index} src={Object.values(items)[0] ? Object.values(items)[0] : ProfileImage} alt="product-img" />
                                );
                              }
                              return null;
                            })
                          }
                          <div className="hover-global-show">
                            <input type="image"
                              className={`orange-outline-btn ${this.state
                                .isDisabledAttributesForWoocommerceShopify &&
                                "disable-btn"
                                }`}
                              onClick={() => {
                                this.uploadGlobalImage.current.click()
                                this.setState({
                                  attibuteGlobalValue: item.label,
                                  attibuteGlobalIndex: index
                                })
                              }}
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              } />
                            <svg id="edit-image" xmlns="http://www.w3.org/2000/svg" width="24.037" height="24.015" viewBox="0 0 24.037 24.015">
                              <path id="Path_321" data-name="Path 321" d="M26.49,12.94a3.261,3.261,0,0,0-4.631,0L13.11,21.688a1.875,1.875,0,0,0-.257.429l-.858,4.288a.692.692,0,0,0,.257.772,1.2,1.2,0,0,0,.772.257l4.288-.858a.472.472,0,0,0,.429-.257l8.748-8.748a3.261,3.261,0,0,0,0-4.631ZM16.712,24.947l-2.745.515.515-2.745,6.947-6.947L23.659,18Zm8.577-8.577-.429.429-2.23-2.23.429-.429a1.577,1.577,0,1,1,2.23,2.23Z" transform="translate(-3.418 -3.42)" fill="#fff" />
                              <path id="Path_322" data-name="Path 322" d="M15.98,12.893a.849.849,0,1,0,1.2-1.2L15.466,9.976a.829.829,0,0,0-1.2,0L9.719,14.522,7.746,12.55a.829.829,0,0,0-1.2,0l-2.83,2.83V4.573a.81.81,0,0,1,.858-.858H20.011a.81.81,0,0,1,.858.858V8a.858.858,0,0,0,1.715,0V4.573A2.527,2.527,0,0,0,20.011,2H4.573A2.527,2.527,0,0,0,2,4.573V20.011a2.527,2.527,0,0,0,2.573,2.573H8.861a.858.858,0,0,0,0-1.715H4.573a.81.81,0,0,1-.858-.858v-2.23l3.431-3.431,1.973,1.973a.829.829,0,0,0,1.2,0l4.546-4.546Z" transform="translate(-2 -2)" fill="#fff" />
                              <path id="Path_323" data-name="Path 323" d="M8,8.573a2.573,2.573,0,1,0,5.146,0A2.573,2.573,0,1,0,8,8.573Zm3.431,0a.858.858,0,0,1-1.715,0,.858.858,0,1,1,1.715,0Z" transform="translate(-2.854 -2.569)" fill="#fff" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div className="make-global-sub border-0">
                        <h5>COMMON PRICE</h5>
                        <div className="form-group m-0">
                          <input
                            type="number"
                            onChange={(e) => {
                              // if (/^[1-9]\d*$/.test(e.target.value) ) {
                                this.uploadGlobalAttributes(e, "globalPrice", item.label,index)
                              // }
                            }}    
                            className="form-control"
                            min="1"
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            }
                          />
                        </div>
                        <button className="orange-btn" onClick={() => this.applyGlobalAttribute(this.state.specificAttributeGlobalPrice,item.label,index)}>Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {this.state.primaryIndex == null && !this.state.isAccessCategoryInCategories && this.state.withAttribute &&
          this.state.selectedAttributeVariants?.length > 0 && this.state.variantCombination?.length > 0 ?
          <div className="make-global-attribute">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5">
                  <div className="make-global-sub">
                    <h5>GLOBAL IMAGE</h5>
                    <div className="product-global-img">
                      <img src={this.state.attributeGlobalImage ? this.state.attributeGlobalImage : ProfileImage} alt="product-img" />
                      <div className="hover-global-show">
                          <input type="image"
                            className={`orange-outline-btn ${this.state
                              .isDisabledAttributesForWoocommerceShopify &&
                              "disable-btn"
                              }`}
                            onClick={() => {
                              this.uploadGlobalImage.current.click();
                            }}
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            } />
                        <svg id="edit-image" xmlns="http://www.w3.org/2000/svg" width="24.037" height="24.015" viewBox="0 0 24.037 24.015">
                          <path id="Path_321" data-name="Path 321" d="M26.49,12.94a3.261,3.261,0,0,0-4.631,0L13.11,21.688a1.875,1.875,0,0,0-.257.429l-.858,4.288a.692.692,0,0,0,.257.772,1.2,1.2,0,0,0,.772.257l4.288-.858a.472.472,0,0,0,.429-.257l8.748-8.748a3.261,3.261,0,0,0,0-4.631ZM16.712,24.947l-2.745.515.515-2.745,6.947-6.947L23.659,18Zm8.577-8.577-.429.429-2.23-2.23.429-.429a1.577,1.577,0,1,1,2.23,2.23Z" transform="translate(-3.418 -3.42)" fill="#fff" />
                          <path id="Path_322" data-name="Path 322" d="M15.98,12.893a.849.849,0,1,0,1.2-1.2L15.466,9.976a.829.829,0,0,0-1.2,0L9.719,14.522,7.746,12.55a.829.829,0,0,0-1.2,0l-2.83,2.83V4.573a.81.81,0,0,1,.858-.858H20.011a.81.81,0,0,1,.858.858V8a.858.858,0,0,0,1.715,0V4.573A2.527,2.527,0,0,0,20.011,2H4.573A2.527,2.527,0,0,0,2,4.573V20.011a2.527,2.527,0,0,0,2.573,2.573H8.861a.858.858,0,0,0,0-1.715H4.573a.81.81,0,0,1-.858-.858v-2.23l3.431-3.431,1.973,1.973a.829.829,0,0,0,1.2,0l4.546-4.546Z" transform="translate(-2 -2)" fill="#fff" />
                          <path id="Path_323" data-name="Path 323" d="M8,8.573a2.573,2.573,0,1,0,5.146,0A2.573,2.573,0,1,0,8,8.573Zm3.431,0a.858.858,0,0,1-1.715,0,.858.858,0,1,1,1.715,0Z" transform="translate(-2.854 -2.569)" fill="#fff" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <p style={{color: "#ff0000", fontSize: '14px', marginTop: '15px'}}>{this.state.errorData.attributeFileError}</p>
                </div>

                <div className="col-md-7">
                  <div className="make-global-sub border-0">
                    <h5>GLOBAL PRICE</h5>
                    <div className="form-group m-0">
                        <input
                          type="number"
                          onChange={(e) => {
                            // if (/^[1-9]\d*$/.test(e.target.value) ) {
                              this.uploadGlobalAttributes(e, "globalPrice");
                            // }
                          }}                        
                          value={this.state.attributeGlobalPrice}
                          className="form-control"
                          min="1"
                          disabled={
                            this.state
                              .isDisabledAttributesForWoocommerceShopify
                          }
                        />
                    </div>
                    <button className="orange-btn" onClick={()=>this.applyGlobalAttribute(this.state.attributeGlobalPrice)}>Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div>: null}

          <p style={{color: "#ff0000", fontSize: '14px', marginTop: '0px'}}>{this.state.errorData.specificAttributeGlobalImageError}</p>
          {this.state.primaryIndex !== null && <p style={{color: "#ff0000", fontSize: '14px', marginTop: '15px'}}>{this.state.errorData.attributeFileError}</p>}
          {!this.state.isAccessCategoryInCategories && this.state.withAttribute &&
          this.state.selectedAttributeVariants?.length > 0 && this.state.variantCombination?.length > 0 ?
          <div className="table-responsive add-product-table">
            <table className="table">
              <thead>
                <tr>
                  <th>IMAGE</th>
                  <th>VARIANT</th>
                  <th>PRICE</th>
                  {this.state.is_sale === 1 ? <th>SALE PRICE</th> : null}
                  <th>ACTION</th>
                </tr>
              </thead>

                {this.state.variantCombination && this.state.variantCombination.map((combination, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>
                          <div className="product-global-img global-img-sm">
                            <img src={this.state.attributeImage[index] ? this.state.attributeImage[index] : ProfileImage} alt="product-img" />
                            <div className="hover-global-show">
                              <input type="image"
                                className={`orange-outline-btn ${this.state
                                    .isDisabledAttributesForWoocommerceShopify &&
                                  "disable-btn"
                                  }`}
                                onClick={() => {
                                  this.fileUploadAttribute.current.click();
                                  this.setState({
                                    uploadAttributeIndex: index,
                                  });
                                }}
                                disabled={
                                  this.state
                                    .isDisabledAttributesForWoocommerceShopify
                                } />
                              <svg id="edit-image" xmlns="http://www.w3.org/2000/svg" width="24.037" height="24.015" viewBox="0 0 24.037 24.015">
                                <path id="Path_321" data-name="Path 321" d="M26.49,12.94a3.261,3.261,0,0,0-4.631,0L13.11,21.688a1.875,1.875,0,0,0-.257.429l-.858,4.288a.692.692,0,0,0,.257.772,1.2,1.2,0,0,0,.772.257l4.288-.858a.472.472,0,0,0,.429-.257l8.748-8.748a3.261,3.261,0,0,0,0-4.631ZM16.712,24.947l-2.745.515.515-2.745,6.947-6.947L23.659,18Zm8.577-8.577-.429.429-2.23-2.23.429-.429a1.577,1.577,0,1,1,2.23,2.23Z" transform="translate(-3.418 -3.42)" fill="#fff" />
                                <path id="Path_322" data-name="Path 322" d="M15.98,12.893a.849.849,0,1,0,1.2-1.2L15.466,9.976a.829.829,0,0,0-1.2,0L9.719,14.522,7.746,12.55a.829.829,0,0,0-1.2,0l-2.83,2.83V4.573a.81.81,0,0,1,.858-.858H20.011a.81.81,0,0,1,.858.858V8a.858.858,0,0,0,1.715,0V4.573A2.527,2.527,0,0,0,20.011,2H4.573A2.527,2.527,0,0,0,2,4.573V20.011a2.527,2.527,0,0,0,2.573,2.573H8.861a.858.858,0,0,0,0-1.715H4.573a.81.81,0,0,1-.858-.858v-2.23l3.431-3.431,1.973,1.973a.829.829,0,0,0,1.2,0l4.546-4.546Z" transform="translate(-2 -2)" fill="#fff" />
                                <path id="Path_323" data-name="Path 323" d="M8,8.573a2.573,2.573,0,1,0,5.146,0A2.573,2.573,0,1,0,8,8.573Zm3.431,0a.858.858,0,0,1-1.715,0,.858.858,0,1,1,1.715,0Z" transform="translate(-2.854 -2.569)" fill="#fff" />
                              </svg>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="variant-label">
                            <p> {Object.values(combination[0]).join(", ")}</p>
                          </div>
                        </td>
                        <td>
                          <div className="form-group m-0">
                            <input
                            type="number"
                            onChange={(e) => {
                              if (/^\d*\.?\d+$/.test(e.target.value) || e.target.value === "") {
                                this.handleVariantCombinationPriceChange(e, index)
                              }
                            }}    
                            value={combination[1] ? combination[1].price : ""}
                            className="form-control"
                            min="1"
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            }
                          />
                          </div>
                        </td>
                       {this.state.is_sale === 1 ?
                       <td>
                          <div className="variant-label">
                          {this.state.is_sale === 1 &&
                          combination[1] &&
                          combination[1].price && (
                            <div>
                              {combination[1]
                                ? `${parseFloat(
                                  (
                                    combination[1].price *
                                    (1 - this.state.sale_price / 100)
                                  ).toFixed(2)
                                )}`
                                : ""}
                            </div>
                          )}
                          </div>
                        </td> : null}

                        <td>
                          <button
                            className={`orange-outline-btn ${this.state
                                .isDisabledAttributesForWoocommerceShopify &&
                              "disable-btn"
                              }`}
                            onClick={() =>
                              this.onRemoveSpecificCombination(index)
                            }
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  )
                })}
              
            </table>
          </div> : null}

          <h5 className="custom-label-product">
            Public Facing Marketing Content
            <div className="tooltip-custom">
              <i className="fa fa-info-circle info-icon" aria-hidden="true"></i>
              <div className="together-tooltip">
                <p>
                  content shown on product page
                  <span class="arrow-down"></span>
                </p>
              </div>
            </div>
          </h5>

          {/* multiple image upload */}
          <div className="border-show-seprate p-3">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Gallery Images</label>
                  <div className="App">
                    <ImageUploading
                      multiple
                      value={this.state.finalImgArray}
                      onChange={this.handleGalleryImages}
                      maxNumber={10}
                      acceptType={["jpg", "jpeg"]}
                      dataURLKey="stream"
                    >
                      {({
                        galleryImages,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <div className="text-left mb-4">
                            <button
                              style={isDragging ? { color: "red" } : null}
                              onClick={onImageUpload}
                              {...dragProps}
                              className={`orange-outline-btn ${
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify &&
                                "disable-btn"
                              }`}
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              }
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            <button
                              onClick={onImageRemoveAll}
                              className={`orange-outline-btn ${
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify &&
                                "disable-btn"
                              }`}
                              disabled={
                                this.state
                                  .isDisabledAttributesForWoocommerceShopify
                              }
                            >
                              Remove all images
                            </button>
                          </div>
                          {this.state.finalImgArray?.map((image, index) => (
                            <div
                            key={index}
                            style={{ width: "25%", display: "flex" }}
                            className="image-item"
                            >
                              <img id="imageId" src={image?.stream} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className={`orange-outline-btn mb-2 ${
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify &&
                                    "disable-btn"
                                  }`}
                                  onClick={() => onImageUpdate(index)}
                                  disabled={
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify
                                  }
                                >
                                  Update
                                </button>
                                <button
                                  className={`orange-outline-btn ${
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify &&
                                    "disable-btn"
                                  }`}
                                  onClick={() => {onImageRemove(index)}}
                                  disabled={
                                    this.state
                                      .isDisabledAttributesForWoocommerceShopify
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </div>

            {/* multiple video upload */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Gallery Videos</label>
                  <div className="App">
                    <div className="upload__image-wrapper">
                      <div className="text-left mb-4">
                        <button
                            className={`orange-outline-btn ${
                              this.state
                                .isDisabledAttributesForWoocommerceShopify &&
                              "disable-btn"
                            }`}
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            }
                          onClick={(e) =>
                            this.triggerFileUploadClick(e, "videos")
                          }
                        >
                          Click here to upload
                        </button>
                        &nbsp;
                        <button
                            className={`orange-outline-btn ${
                              this.state
                                .isDisabledAttributesForWoocommerceShopify &&
                              "disable-btn"
                            }`}
                            disabled={
                              this.state
                                .isDisabledAttributesForWoocommerceShopify
                            }
                          onClick={(e) =>
                            this.deleteAllGalleryFiles(e, "videos")
                          }
                        >
                          Remove all videos
                        </button>
                      </div>
                    </div>
                    {this.state.galleryVideos &&
                    this.state.galleryVideos.length > 0 ? (
                      <>
                        {this.state.galleryVideos.map((item, index) => (
                          <div key={index} className="image-item">
                            {this.state.showVideos ? (
                              <video width="200" height="150" controls>
                                <source src={item.url} type="video/mp4" />
                                <source src={item.url} type="video/webm" />
                                <source src={item.url} type="video/ogg" />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}
                            <div className="image-item__btn-wrapper">
                              <button
                                className="orange-outline-btn"
                                onClick={(e) =>
                                  this.deleteSelectedGalleryFile(
                                    e,
                                    "videos",
                                    item.url,
                                    index
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                    {this.state.videos && this.state.videos.length > 0 ? (
                      <>
                        {this.state.videos.map((item, index) => (
                          <div
                            key={index}
                            style={{ width: "25%", display: "flex" }}
                            className="image-item"
                          >
                            {this.state.showVideos ? (
                              <video width="200" height="150" controls>
                                <source src={item.stream} type={item.type} />
                                Your browser does not support the video tag.
                              </video>
                            ) : null}
                            <div className="image-item__btn-wrapper">
                              {item.status || ""}
                              {!item.status ? (
                                <button
                                  className="orange-outline-btn"
                                  onClick={(e) =>
                                    this.deleteSelectedFile(e, "videos", index)
                                  }
                                >
                                  Remove
                                </button>
                              ) : null}
                              {item.uploadPercentage &&
                              item.uploadPercentage < 100 ? (
                                <progress
                                  id="file"
                                  value={item.uploadPercentage}
                                  max="100"
                                >
                                  {" "}
                                  {item.uploadPercentage}%{" "}
                                </progress>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                    {this.props.errorVideos &&
                    this.props.errorVideos.length > 0 ? (
                      <>
                        {this.props.errorVideos.map((item, index) => (
                          <p className="files-error" key={index}>
                            {item}
                          </p>
                        ))}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group mt-3">
                <label className="custom-label-product">
                  Activate Offering ?
                  <div className="tooltip-custom">
                    <i
                      className="fa fa-info-circle info-icon"
                      aria-hidden="true"
                    ></i>
                    <div className="together-tooltip">
                      <p>
                        Activate Offering ?<span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </label>
                <div className="custom-radio-input">
                  <p className="container-sub mr-2">
                    Inactive
                    <span>
                      <input
                        type="radio"
                        value="2"
                        onChange={(event) =>
                          this.confirmProductInactiveAlert(event, "isActive")
                        }
                        checked={this.state.isActive == 2}
                        name="isActiveRadio"
                      />
                    </span>
                    <span className="checkmark"></span>
                  </p>

                  <p className="container-sub mr-2">
                    Active
                    <span>
                      <input
                        type="radio"
                        value="1"
                        onChange={(event) =>
                          this.handleChange(event, "isActive")
                        }
                        checked={this.state.isActive == 1}
                        name="isActiveRadio"
                      />
                    </span>
                    <span className="checkmark"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-btn text-center mt-3">
                <button
                  type="button"
                  onClick={() => this.handleSubmit(false)}
                  className="orange-outline-btn"
                >
                  submit
                  {(() => {
                    if (this.state?.showLoader) {
                      return (
                        <span className="spinner-border spinner-border-sm ml-1"></span>
                      );
                    }
                  })()}
                </button>
                <div>
                  <p style={{ color: "red" }}>
                    {this.state.bottomErrorMessage}
                  </p>
                  <p style={{color: "#ff0000", fontSize: '14px', marginTop: '10px'}}>{this.state.errorData.largeFileError}</p>
                </div>
              </div>
              <AlertModal
                data={this.state.alertModalData}
                closeAlertModal={this.closeAlertModal}
                handleAlertResponse={(type, id, data) =>
                  this.handleAlertResponse(type, id, data)
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    videos: state.fileUploadReducer.videos,
    errorVideos: state.fileUploadReducer.errorVideos,
    acsCatVideos: state.fileUploadReducer.acsCatVideos,
    errorAcsCatVideos: state.fileUploadReducer.errorAcsCatVideos,
    acsCatAudios: state.fileUploadReducer.acsCatAudios,
    errorAcsCatAudios: state.fileUploadReducer.errorAcsCatAudios,
    acsCatImages: state.fileUploadReducer.acsCatImages,
    errorAcsCatImages: state.fileUploadReducer.errorAcsCatImages,
    acsCatDocuments: state.fileUploadReducer.acsCatDocuments,
    errorAcsCatDocuments: state.fileUploadReducer.errorAcsCatDocuments,
    p_acsCatDocuments: state.azureStorageReducer.documents,
    p_acsCatImages: state.azureStorageReducer.images,
    p_acsCatAudios: state.azureStorageReducer.audios,
    p_acsCatVideos: state.azureStorageReducer.videos,
    activeSubscriptionMemberList:
      state.productReducer.activeSubscriptionMemberList,
    meetingList: state.meetingReducer.meetingList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addProduct,
      getProductDetail,
      updateProductDetail,
      updateProductFiles,
      removeProductFile,
      addFiles,
      addMoreFiles,
      addFilesError,
      uploadFiles,
      uploadAttibute,
      updateAttibute,
      fetchPrivateFiles,
      getProductCategoryList,
      getAttributeList,
      getShopifyDetails,
      createMeeting,
      getActiveSubscriptionCustomerListByProudctId,
      sendInvitationRequest,
      updateGroupInviteMeeting,
      getMeetingsByProductIdAndSellerId,
      hierarchyList,
      getChildCategories,
      createFreeSeminar,
      addGroupAndOneOnOneMeetingDetails,
      getGroupAndOneOnOneMeetingDetails,
      updateSelectedEvent,
      deleteSelectedEvent,
      validateCancelSlot,
      sendResheduleMailFromForm,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Add_Product));
