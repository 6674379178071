import React from "react";
import "./Onboarding.scss";
import CommonButton from "../../Components/FormElements/CommonButton";
import { BackIcon } from "../../Components/svgElements/BackIcon";
import ProfileImage from "../../assets/images-2/profile-img.png";
import product1 from "../../assets/images-2/product-1.png";
import product2 from "../../assets/images-2/product-2.png";
import product3 from "../../assets/images-2/product-3.png";
import product4 from "../../assets/images-2/product-4.png";
import { StarIcon } from "../../Components/svgElements/StarIcon";
import { SendIcon } from "../../Components/svgElements/SendIcon";
import { ClockIcon } from "../../Components/svgElements/ClockIcon";
import { LocationIcon } from "../../Components/svgElements/LocationIcon";
import { useSelector } from "react-redux";
import HeaderParent from "../../Components/Header/HeaderParent";
import StepsView from "./StepsView";
import { useNavigate } from "react-router";

const sampleProducts = [
  {
    id: 1,
    name: "Winter Women's Ankle Boots Black Plus Cotton Warm...",
    img: product1,
    type: "New",
  },
  {
    id: 2,
    name: "Cross Pendant New Simple Necklace Titanium Steel Plating...",
    img: product2,
    type: "Free shipping",
  },
  {
    id: 3,
    name: "Summer new tide simple ligh...",
    img: product3,
    type: "Free shipping",
  },
  {
    id: 4,
    name: "Plated Silver Feather Pendant...",
    img: product4,
    type: "Free shipping",
  },
];

const StorePreview = (props) => {
  const authData = useSelector((state) => state.userReducer.userInfo);
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/onboarding-subscription");
  };

  const handleSubmit = () => {
    navigate("/dashboard");
  };

  const cancelPage = () => {
    navigate("/");
  };

  return (
    <>
    <HeaderParent/>
    <div className="container">
      <div className="onboarding-page">
        <div className="page-heading">
          <button
            onClick={goBack}
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Onboarding</h2>
        </div>
        <StepsView step={5} />
        <div className="page-inner">
          <div className="store-preview-card">
            <div className="card-top">
              <div className="row">
                <div className="col-md-12 col-lg-7">
                  <div className="store-detail">
                    <div className="store-img">
                      <img
                        src={
                          authData?.imagename
                            ? authData?.imagename
                            : ProfileImage
                        }
                        alt="ProfileImage"
                      />
                    </div>
                    <div className="content">
                      <div className="name">
                        <h3 className="text-uppercase">{authData?.storename}</h3>
                        <div className="star-icons">
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                        </div>
                      </div>
                      <div className="brief">
                        <p>{authData?.store_overview}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-5">
                  <div className="store-detail-right">
                    <div className="top-actions">
                      <CommonButton
                        value="Rate This Store"
                        buttonClass="theme-button primary-outline-btn radius-btn"
                      />
                      <CommonButton
                        value="Contact  Vendor"
                        buttonClass="theme-button primary-outline-btn radius-btn"
                      />
                      <button
                        type="button"
                        className="icon-button primary-outline-btn"
                      >
                        <SendIcon />
                      </button>
                    </div>
                    <div className="time-info">
                      <div className="item">
                        <p>
                          <ClockIcon />
                          {authData?.timezone}
                        </p>
                      </div>
                      <div className="item">
                        <p>
                          <LocationIcon />
                          {authData?.address} {authData?.address_2}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sample-products-section">
            <div className="section-heading">
              <h2>Sample Products</h2>
            </div>
            <div className="section-inner">
              <div className="row">
                {sampleProducts.map((item) => (
                  <div className="col-md-4 col-lg-4 col-xl-3">
                    <div className="product-card">
                      <div className="card-img">
                        <img
                          src={item.img}
                          alt="product-img"
                          width="640"
                          height="320"
                          loading="lazy"
                        />
                        <div className="card-tags">
                          <span>{item.type}</span>
                        </div>
                        <div className="image-status-label">
                          <div className="status-label">
                            <p>Quick View</p>
                          </div>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="name">
                          <h3>{item.name}</h3>
                        </div>
                        <div className="price-with-offer">
                          <div className="price">
                            <p>
                              $24.99
                              <span>
                                <del> $34.99</del>
                              </span>
                            </p>
                          </div>
                          <div className="offer-tag">
                            <span>30% Off</span>
                          </div>
                        </div>
                        <div className="rating-info">
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                        </div>
                        <div className="product-owner-info">
                          <div className="product-owner-img">
                            <img
                              src={authData?.imagename || ProfileImage} 
                              alt="homeBanner"
                              width="640"
                              height="320"
                              loading="lazy"
                            />
                          </div>
                          <div className="product-owner-name">
                            <p>
                              By <span>{authData?.storename}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="action-btn">
          <CommonButton
            value="Finish"
            onClick={handleSubmit}
            buttonClass="theme-button primary-btn radius-btn"
          />
          <CommonButton
            value="Cancel"
            onClick={cancelPage}
            buttonClass="theme-button dark-outline-btn radius-btn"
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default StorePreview;
