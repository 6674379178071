import React, { Component } from 'react';
import '../../Styles/user-detail.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addAttribute, getAttributeDetail, updateAttributeDetail } from '../../Redux/Actions/attributeAction';

import Creatable from 'react-select/creatable';
import withNavigate from '../../Components/customHooks/navigate';


class Add_Attribute extends Component {

    attributeId = null;
    userId = localStorage.getItem("sellerId");

    attributeFields = {
        attributeId: null,
        attributeTitle: "",
        variation_values: [],
        isEdit: false
    };

    constructor(props) {
        super(props);
        this.state = { ...this.attributeFields };
    }

    componentDidMount() {
        if (this.props.location.pathname === `/edit-attribute/${this.props.params.attributeId}`) {
            this.attributeId = this.props.params.attributeId;
            this.fetchAttributeDetail();
        }
    }


    fetchAttributeDetail = async () => {
        let attributeDetail = await this.props.getAttributeDetail(this.userId, this.attributeId);

        if (attributeDetail ?.data ?.data ?.length > 0) {
            // attribute found
            attributeDetail = attributeDetail.data.data[0];
            
            const variants = this.createAttributeVariationSelectOptions(attributeDetail.variation_values);

            this.setState({
                attributeId: attributeDetail.id,
                attributeTitle: (attributeDetail.name).replace(/_/g , " "),
                variation_values: variants,
                isEdit: true
            })

        }
        else {
            // attribute not found
            // redirect user to attribute-list page
            this.props.navigate("/attribute-list");
        }
    }

    createAttributeVariationSelectOptions = (variation_values) => {

        variation_values = variation_values.split(",")

        let selectOptions = variation_values.map(variation => {
            return (
                { value: `${variation}`, label: `${variation}` }
            )
        })

        return selectOptions;
    }

    handleChange = (e, key) => {
        if (this.state.errorData && this.state.errorData[key] && this.state.errorData[key][0]) {
            this.state.errorData[key][0] = null;
        }

        let { value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    validateAttributeInfo = () => {
        let isFormValid = true;
        this.state.errorData = {
            // attributeId: [],
            attributeTitle: [],
            variation_values: []
        }

        if (this.state.attributeTitle.trim().length === 0) {
            isFormValid = false;
            this.state.errorData.attributeTitle = ["Attribute title field cannot be empty."]
        }
        else if (this.state.attributeTitle.includes(`"`) || this.state.attributeTitle.includes(`'`) || this.state.attributeTitle.includes("`")) {
            isFormValid = false;
            this.state.errorData.attributeTitle = ["This field cannot contain quotes"];
          }

        if (this.state.variation_values.length === 0) {
            isFormValid = false;
            this.state.errorData.variation_values = ["Required atleast one variant value"]
        }


        let tempState = this.state;
        this.setState({
            ...tempState
        })

        return isFormValid;
    }

    handleSubmit = async () => {
        if (this.userId) {
            await this.setState({
                successMessage: '',
                errorMessage: '',
                showLoader: true
            });

            if (this.validateAttributeInfo()) {
                let res;
                const { attributeId, attributeTitle, variation_values } = this.state;

                if (this.state.isEdit) {
                    // call edit attribute api
                    res = await this.props.updateAttributeDetail(
                        { attributeId, attributeTitle, variation_values },
                        this.userId
                    )
                }
                else {
                    // call add attribute api
                    res = await this.props.addAttribute(
                        { attributeTitle, variation_values },
                        this.userId
                    )
                }
                if (res && res.data) {
                    if (res.data.isSuccess) {
                        // success case                        
                        this.setState({
                            successMessage: res.data.message,
                            showLoader: false
                        });
                        window.scrollTo(0, 0);

                        if (this.state.isEdit) {
                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                        else {

                            this.setState({
                                attributeId: null,
                                attributeTitle: "",
                                variation_values: []
                            })

                            setTimeout(() => {
                                this.setState({
                                    successMessage: ''
                                })
                            }, 4000);
                        }
                        setTimeout(()=>{
                            this.props.navigate('/attribute-list');
                        },2000);
                        
                    }
                    else {
                        // response with failed case
                        this.setState({
                            errorMessage: res.data.message,
                            showLoader: false
                        })
                    }
                }
                else {
                    // error to get respone
                    this.setState({
                        errorMessage: "Something went wrong. Please try again later.",
                        showLoader: false
                    })
                }

            }
            else {
                console.log("attribute invalid");
                this.setState({
                    showLoader: false
                });
            }
        }
        else {
            // user is not logged in
            this.props.navigate('/login');
        }
    }

    handleAttributeCategoryChange = (event) => {
        if (event && event.length > 0) {
            if (this.state.errorData && this.state.errorData["variation_values"] && this.state.errorData["variation_values"][0]) {
                this.state.errorData["variation_values"][0] = null;
            }
            event.forEach((variant, index) => {
                variant.value = variant.value.trim();
                if (variant.value.length == 0) {
                    event.splice(index, 1)
                }
            })
        }

        this.setState(prevState => ({
            ...prevState,
            variation_values: event ? event : []
        }))
    }


    render(selectProps) {
        return (
            <div className="admin-card">
                <div className="admin-card-head">
                    <h2>{this.props.params.attributeId ? "edit" : "add"} attribute</h2>
                </div>
                <div className="admin-card-box">
                    {(() => {
                        if (this.state.successMessage !== '' && this.state.successMessage !== undefined) {
                            return <div className="alert alert-success">{this.state.successMessage}</div>
                        } else if (this.state.errorMessage !== '' && this.state.errorMessage !== undefined) {
                            return <div className="alert alert-danger">{this.state.errorMessage}</div>
                        }
                    })()}
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Attribute title
                                    <sup>*</sup>
                                </label>
                                <input type="text" onChange={(e) => this.handleChange(e, "attributeTitle")} value={this.state.attributeTitle} className="form-control" />
                                <span className="form-field-error">
                                    {this.state.errorData ?.attributeTitle &&
                                        this.state.errorData.attributeTitle[0]}
                                </span>
                            </div>
                        </div>




                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <label>Variation
                                    <sup>*</sup>
                                </label>
                                <Creatable
                                    {...selectProps}
                                    value={this.state.variation_values}
                                    isMulti={true}
                                    children={(itemObject) => {
                                        console.log(itemObject, 'itemObject');
                                    }}
                                    isOptionUnique={true}
                                    newOptionCreator={(label, labelKey, valueKey) => {
                                        console.log(label, labelKey, valueKey, 'newOptionCreator');
                                    }}
                                    onNewOptionClick={(option) => {
                                        console.log(option, 'onNewOptionClick');
                                    }}
                                    shouldKeyDownEventCreateNewOption={(keyCode) => {
                                        console.log(keyCode);
                                    }}
                                    promptTextCreator={(keyCode) => {
                                        console.log(keyCode, 'promptTextCreator');
                                    }}
                                    onChange={(e) => { this.handleAttributeCategoryChange(e) }}
                                    options={this.state.variation_values}
                                />
                                <span className="form-field-error">
                                    {this.state.errorData ?.variation_values &&
                                        this.state.errorData.variation_values[0]}
                                </span>
                            </div>
                        </div>


                        <div className="col-lg-12 col-md-12">
                            <div className="form-btn text-center">
                                <button type="button" onClick={this.handleSubmit} className="orange-outline-btn">submit
                                {(() => {
                                        if (this.state ?.showLoader) {
                                            return <span className="spinner-border spinner-border-sm ml-1"></span>
                                        }
                                    })()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ addAttribute, getAttributeDetail, updateAttributeDetail }, dispatch);
}

export default connect(null, mapDispatchToProps)(withNavigate(Add_Attribute))