import React from "react";

export const UploadLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M9 10.75C7.48 10.75 6.25 9.52 6.25 8C6.25 6.48 7.48 5.25 9 5.25C10.52 5.25 11.75 6.48 11.75 8C11.75 9.52 10.52 10.75 9 10.75ZM9 6.75C8.31 6.75 7.75 7.31 7.75 8C7.75 8.69 8.31 9.25 9 9.25C9.69 9.25 10.25 8.69 10.25 8C10.25 7.31 9.69 6.75 9 6.75Z" />
      <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H13C13.41 1.25 13.75 1.59 13.75 2C13.75 2.41 13.41 2.75 13 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" />
      <path d="M18 8.74994C17.59 8.74994 17.25 8.40994 17.25 7.99994V1.99994C17.25 1.69994 17.43 1.41994 17.71 1.30994C17.99 1.19994 18.31 1.25994 18.53 1.46994L20.53 3.46994C20.82 3.75994 20.82 4.23994 20.53 4.52994C20.24 4.81994 19.76 4.81994 19.47 4.52994L18.75 3.80994V7.99994C18.75 8.40994 18.41 8.74994 18 8.74994Z" />
      <path d="M15.9999 4.74994C15.8099 4.74994 15.6199 4.67994 15.4699 4.52994C15.1799 4.23994 15.1799 3.75994 15.4699 3.46994L17.4699 1.46994C17.7599 1.17994 18.2399 1.17994 18.5299 1.46994C18.8199 1.75994 18.8199 2.23994 18.5299 2.52994L16.5299 4.52994C16.3799 4.67994 16.1899 4.74994 15.9999 4.74994Z" />
      <path d="M2.6698 19.7001C2.4298 19.7001 2.1898 19.5801 2.0498 19.3701C1.8198 19.0301 1.9098 18.5601 2.2498 18.3301L7.1798 15.0201C8.2598 14.3001 9.7498 14.3801 10.7298 15.2101L11.0598 15.5001C11.5598 15.9301 12.4098 15.9301 12.8998 15.5001L17.0598 11.9301C18.1198 11.0201 19.7898 11.0201 20.8598 11.9301L22.4898 13.3301C22.7998 13.6001 22.8398 14.0701 22.5698 14.3901C22.2998 14.7001 21.8198 14.7401 21.5098 14.4701L19.8798 13.0701C19.3798 12.6401 18.5398 12.6401 18.0398 13.0701L13.8798 16.6401C12.8198 17.5501 11.1498 17.5501 10.0798 16.6401L9.7498 16.3501C9.2898 15.9601 8.52981 15.9201 8.01981 16.2701L3.0998 19.5801C2.9598 19.6601 2.8098 19.7001 2.6698 19.7001Z" />
    </svg>
  );
};
