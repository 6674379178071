import React from "react";

export const PrimaryInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_225_75)">
        <path
          d="M12 0C5.36761 0 0 5.367 0 12C0 18.6323 5.367 24 12 24C18.6324 24 24 18.633 24 12C24 5.3677 18.633 0 12 0ZM12 22.3256C6.30647 22.3256 1.67442 17.6936 1.67442 12C1.67442 6.30642 6.30647 1.67442 12 1.67442C17.6935 1.67442 22.3256 6.30642 22.3256 12C22.3256 17.6936 17.6935 22.3256 12 22.3256Z"
          fill="#E25E52"
        />
        <path
          d="M12.0002 10.0029C11.2894 10.0029 10.7839 10.3031 10.7839 10.7454V16.7635C10.7839 17.1426 11.2894 17.5216 12.0002 17.5216C12.6794 17.5216 13.2322 17.1426 13.2322 16.7635V10.7453C13.2322 10.3031 12.6794 10.0029 12.0002 10.0029Z"
          fill="#E25E52"
        />
        <path
          d="M12.0003 6.29102C11.2737 6.29102 10.7051 6.81227 10.7051 7.4125C10.7051 8.01278 11.2737 8.54983 12.0003 8.54983C12.7111 8.54983 13.2798 8.01278 13.2798 7.4125C13.2798 6.81227 12.711 6.29102 12.0003 6.29102Z"
          fill="#E25E52"
        />
      </g>
      <defs>
        <clipPath id="clip0_225_75">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
