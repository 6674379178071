import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;


export const orderReturnRequestList = (userId, offset, limit = 10) => async (dispatch) => {
    try {
        const res = await axios({
          method: "get",
          url: `${URL}/api/v1/seller/request-order-return/get-all/${userId}?offset=${offset}&limit=${limit}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        
        return res;
      } catch (error) {
        dispatch(handleError(error));
      }
}
export const updateOrderReturnRequest = (returnOrderStatus, orderId, vendorMessage) => async (dispatch) => {
    try {
        const res = await axios({
          method: "post",
          url: `${URL}/api/v1/seller/request-order-return/update-status/${orderId}/${returnOrderStatus}`,
          data: {vendor_message: vendorMessage},
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        
        return res;
      } catch (error) {
        dispatch(handleError(error));
      }
}

export const searchReturnOrderRequest =
  (sellerId, offset, searchKeyword) => async (dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: `${URL}/api/v1/seller/request-order-return/by/search/${sellerId}?offset=${offset}&search=${searchKeyword}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };




