import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Onboarding.scss";
import InputBox from "../../Components/FormElements/InputBox";
import CommonButton from "../../Components/FormElements/CommonButton";
import { BackIcon } from "../../Components/svgElements/BackIcon";
import { Link, useNavigate } from "react-router-dom";
import { FireworksIcon } from "../../Components/svgElements/FireworksIcon";
import {
  checkStripeDetailsCompleted,
  connectStripeAccount,
  getUserDetail,
} from "../../Redux/Actions/userAction";
import HeaderParent from "../../Components/Header/HeaderParent";
import StepsView from "./StepsView";
import { CloseIcon } from "../../Components/svgElements/CloseIcon";

const PaymentConfiguration = (props) => {
  let intervalId = useRef(null);
  const authData = useSelector((state) => state.userReducer.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stripeErrorMessage, setStripErrorMessage] = useState("");
  const [isDetailSubmit, setDetailSubmit] = useState(false);
  const [state, setState] = useState({
    userId: "",
    stripe_vendor_account_id: "",
    stripeEmail: "",
    stripeUrl: "",
    loading: false,
  });

  const stateDep = authData?.stripe_vendor_email;
  const setUserState = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      userId: authData?.id,
      stripe_vendor_account_id: authData?.stripe_vendor_account_id,
      stripeEmail: authData?.stripe_vendor_email,
    }));
  }, [authData]);

  useEffect(() => {
    setUserState();
  }, [stateDep, setUserState]);


  useEffect(() => {
    checkStripeDetails();
  }, [state.stripe_vendor_account_id, state.userId]);
  

  const setStateValues = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const checkEmailValidOrNot = () => {
    let isValid = true;
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      !state.stripeEmail ||
      state.stripeEmail === "null" ||
      !pattern.test(String(state.stripeEmail).toLowerCase())
    ) {
      isValid = false;
      setStripErrorMessage("Please enter valid email address");
    }
    return isValid;
  };

  const checkStripeDetails = async () => {
    if(state.stripe_vendor_account_id && state.userId){
      const res = await dispatch(
        checkStripeDetailsCompleted(state.stripe_vendor_account_id, state.userId)
      );
      const isSubmit = res?.data?.stripe_details_submitted || res?.data?.details_submitted;
      const isConnect = (isSubmit || Number(isSubmit) === 1) ? true : false;
      if(isConnect){
        setState((prevState) => ({ ...prevState, stripeUrl: '' }));
        if(state.userId){
          dispatch(getUserDetail({id:state.userId}));
        }
        clearInterval(intervalId.current);
      }
      setDetailSubmit(isConnect);
    }
  };

  const handleConnectStripeAccount = async (event) => {
    event.preventDefault();
    setStripErrorMessage("");
    const isValid = checkEmailValidOrNot();
    if (isValid) {
      setStateValues("loading", true);
      let emailChange = false;
      if (authData?.stripe_vendor_email !== state.stripeEmail) {
        emailChange = true;
      }
      const res = await dispatch(
        connectStripeAccount(
          state.stripeEmail,
          state.userId,
          state.stripe_vendor_account_id,
          emailChange
        )
      );
      if (!res?.isError) {
        setState((prevState) => ({
          ...prevState,
          stripeUrl: res?.accountLink.url,
          stripe_vendor_account_id: res?.accountId,
          loading: false,
        }));
        intervalId.current = setInterval(() => {
          checkStripeDetails();
        }, 3000);
      } else {
        setStateValues("loading", false);
        if (res.statusCode) {
          setStripErrorMessage(res?.raw?.message);
        } else {
          setStripErrorMessage(res?.message);
        }
      }
    }
  };

  const goToNext = () => {
    // if(isDetailSubmit){
    navigate("/onboarding-subscription");
    clearInterval(intervalId.current);
    // }
  };

  const goBack = () => {
    navigate("/store-details");
  };

  const cancelPage = () => {
    navigate("/");
  };

  return (
    <>
    <HeaderParent/>
    <div className="container">
      <div className="onboarding-page">
        <div className="page-heading">
          <button
            onClick={goBack}
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Onboarding</h2>
        </div>
        <StepsView step={3} />
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12 col-lg-10 col-xl-9">
              <div className="payment-configuration-section">
                <div className="section-heading">
                  <h3>Connecting Your Stripe Account to Subsciety</h3>
                  <p>
                    Start earning revenue faster by linking your Stripe account
                    to Subsciety.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="configuration-card">
                      <div className="name">
                        <h4>Connecting your stripe account to Subciety</h4>
                      </div>
                      <div className="brief">
                        <p>Connect Stripe to Subsciety:</p>
                        <p>
                          Simply <a href="#" data-toggle="modal" data-target="#stripeInfo">click here</a> to follow the
                          steps that will guide you through connecting your
                          existing Stripe account to Subsciety.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="configuration-card">
                      <div className="name">
                        <h4>If You Do Not Have a Stripe Account</h4>
                      </div>
                      <div className="brief">
                        <p>Sign Up for Stripe:</p>
                        <p>
                          Don’t have a Stripe account yet? No problem! Sign up
                          for a new account by{" "}
                          <a target="_blank" rel="noopener noreferrer" href="https://dashboard.stripe.com/register">clicking here.</a> 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-0">
                  <label>
                    Stripe Email<sup>*</sup>
                  </label>
                  <div className="row">
                    <div className="col-md-12 col-lg-8">
                      <InputBox
                        type="text"
                        onChange={(event) =>
                          setStateValues("stripeEmail", event.target?.value)
                        }
                        required={false}
                        value={state.stripeEmail}
                        error={stripeErrorMessage}
                        maxLength={100}
                        placeholder="Enter stripe email"
                        parentClass="mb-4"
                      />
                    </div>
                    <div className="col-md-12 col-lg-4">
                      <CommonButton
                        value={
                          !state.stripe_vendor_account_id
                            ? "Connect/Create Account"
                            : "Update your stripe account"
                        }
                        loading={state.loading}
                        onClick={handleConnectStripeAccount}
                        buttonClass="theme-button primary-outline-btn radius-btn btn-lg mb-4"
                      />
                    </div>
                  </div>
                </div>
                {state.stripeUrl && (
                  <div className="stripe-address">
                    <a href={state.stripeUrl} target="_blank">
                      Click here to Setup stripe address
                    </a>
                  </div>
                )}
                {isDetailSubmit ? (
                  <div className="stripe-store">
                    <div className="fireworks-icon">
                      <FireworksIcon />
                    </div>
                    <div className="brief">
                      <p>Your stripe account connected successfully</p>
                    </div>
                  </div>
                ) : <div className="stripe-store">
                <div className="fireworks-icon">
                  <FireworksIcon />
                </div>
                <div className="brief">
                  <p>After connecting your stripe account you're all set to add new products to your store.</p>
                </div>
              </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="action-btn">
          <CommonButton
            value="Continue"
            onClick={goToNext}
            buttonClass="theme-button primary-btn radius-btn"
          />
          <CommonButton
            value="Cancel"
            onClick={cancelPage}
            buttonClass="theme-button dark-outline-btn radius-btn"
          />
        </div>
      </div>
    </div>


    <div className="theme-modal modal" id="stripeInfo">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="modal-closer" data-dismiss="modal">
                 <CloseIcon />
              </button>
            <div className="modal-body">
            <div className="section-process">
              <div className="heading">
                <h2>Connecting your stripe account to Subciety</h2>
              </div>
              <div className="section-content">
                <ul>
                  <li>
                    <p>Enter your existing stripe email.</p>
                  </li>
                  <li>
                    <p>Use the generated link to start setup the stripe address.</p>
                  </li>
                  <li>
                    <p>Login to Stripe to use saved business information or create new business.</p>
                  </li>
                  <li>
                    <p>Add personal and business details.</p>
                  </li>
                  <li>
                    <p>Select account for payouts.</p>
                  </li>
                  <li>
                    <p>Add public details for customers.</p>
                  </li>
                  <li>
                    <p>Review and Submit.</p>
                  </li>
                </ul>
              </div>
              <div className="bottom-info">
                <p>You're all set!</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

  
    </>
  );
};

export default PaymentConfiguration;
