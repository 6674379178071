import React from "react";

export const PrimaryCheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <g clip-path="url(#clip0_202_191)">
        <path
          d="M17.5818 3.44155C17.0248 2.88389 16.1205 2.88424 15.5628 3.44155L6.47616 12.5286L2.43754 8.48996C1.87988 7.9323 0.975913 7.9323 0.418249 8.48996C-0.139416 9.04763 -0.139416 9.95159 0.418249 10.5093L5.46631 15.5573C5.74496 15.836 6.11035 15.9757 6.47578 15.9757C6.8412 15.9757 7.20694 15.8363 7.4856 15.5573L17.5818 5.46081C18.1394 4.90353 18.1394 3.99918 17.5818 3.44155Z"
          fill="#E25E52"
        />
      </g>
      <defs>
        <clipPath id="clip0_202_191">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
