import React from "react";
import ZoomDocs from "../../assets/document/ZoomDocs.pdf";


const DocumentList = (props) => {

    return (
        <>
            <iframe src={process.env.REACT_APP_ZOOM_DOCUMENT_URL} width="100%" height="750"></iframe>
        </>
    )
        
}

export default DocumentList;